import {
  computed,
  action,
  decorate,
  extendObservable,
  autorun,
  observable,
} from "mobx";
import React from "react";
import { toJS } from "mobx";
const uuid = require("uuid");
import { timezones } from "../../utils/timezones";
import { generateRowLabels } from "../FormBuilder/FormComponents/Table/TableHelpers";
import ProjectStore from "../ProjectCanvas/ProjectStore";

class Container {
  constructor() {
    extendObservable(this, {
      pages: [],
      node: "",
      editTarget: "",
      onEdit: false,
      pageTitles: [{ page_title: "Page Title", page_description: "" }],
      position: null,
      dataQuestion: [],
      movedQuestions: [],
      message:
        "<p>Hello 👋</p> \n <p>You have been assigned a task to complete. To open and view this task, click the button at the end of this email.</p> \n <p>Link to task: !{taskURL}</p>",
      notificationMethod: "email",
      email: [],
      emailSubject: "You have been assigned a task to complete",
      users: [],
      dueDateStatus: "no_due_date",
      dueDatePeriod: "null",
      dueDateOn: [],
      reminderUsers: [],
      placeholders: [],
      SelectedPlaceholder: {},
      disableButtonsWhileLoading: false,
      reminderEmailMessage: "",
      reminderEmail: [],
      reminderCcEmails: [],
      reminderReplyToEmails:[],
      reminderEmailSubject: "",
      reminderInterval: 1,
      reminderType: "default",
      reminderTimezone: "GMT+10",
      reminderFrequency: "ONCE",
      reminder: false,
      reminderLimit: 1,
      reminderOption: "time",
      reminderOnPlaceholderValue: [],
      toDoColor: "",
      toDoLabel: "",
      completedColor: "",
      completedLabel: "",
      credentialId: null,
      onSave: false,
      columnId: "",
      databaseId: "",
      ccEmails: [],
      replyToEmails:[],
    });
  }
  getNotificationMethod = () => {
    return this.notificationMethod;
  };

  setNotificationMethod = (notificationMethod) => {
    this.notificationMethod = notificationMethod;
  };

  getDueDateStatus = () => {
    return this.dueDateStatus;
  };

  setDueDateStatus = (dueDateStatus) => {
    this.dueDateStatus = dueDateStatus;
  };

  getCredentialId = () => {
    return this.credentialId;
  };

  setCredentialId = (credentialId) => {
    this.credentialId = credentialId;
  };

  getReminderOption = () => {
    return this.reminderOption;
  };

  setReminderOption = (reminderOption) => {
    this.reminderOption = reminderOption;
  };

  getReminderLimit = () => {
    return this.reminderLimit;
  };

  setReminderLimit = (limit) => {
    this.reminderLimit = limit;
  };

  setReminderInterval = (interval) => {
    this.reminderInterval = interval;
  };

  getReminderFrequency = () => {
    return this.reminderFrequency;
  };

  setReminderFrequency = (frequency) => {
    this.reminderFrequency = frequency;
  };

  getReminderType = () => {
    switch (this.reminderType) {
      case "minutes":
        return 1;
        break;
      case "hours":
        return 2;
        break;
      case "days":
        return 3;
        break;
      case "weeks":
        return 4;
        break;
      case "months":
        return 5;
        break;
      case "businessDays":
        return 6;
        break;
    }
  };

  setReminderType = (type) => {
    let intervalType = "";
    switch (toJS(type)) {
      case 1:
        intervalType = "minutes";
        break;
      case 2:
        intervalType = "hours";
        break;
      case 3:
        intervalType = "days";
        break;
      case 4:
        intervalType = "weeks";
        break;
      case 5:
        intervalType = "months";
        break;
      case 6:
        intervalType = "businessDays";
        break;
    }
    this.reminderType = intervalType;
  };

  setReminderMessage = (html) => {
    this.reminderEmailMessage = html;
  };
  getReminderEmailMessage = (html) => {
    return this.reminderEmailMessage;
  };

  setMessage = (html) => {
    this.message = html;
  };
  addToMovedQuestions(component) {
    this.movedQuestions.push(component);
  }
  getMovedQuestions() {
    return this.movedQuestions;
  }
  emptyMovedQuestopns() {
    this.movedQuestions.length = 0;
  }
  setPosition(pst) {
    this.position = pst;
  }
  editPagedata(id, data, type) {
    let index = this.pages.findIndex(function(element) {
      return element.id == id;
    });
    if (index >= 0) {
      if (this.pages[index].config) {
        this.pages[index].config[type] = data;
      } else {
        this.pages[index].config = { [type]: data };
      }
    }
  }
  copyItem = (id) => {
    //find the card to be copied
    const data = this.pages;
    const filterArray = [];
    var idx = 0;
    let result = data.filter((item) => item.id == id);
    for (let index = 0; index < data.length; index++) {
      if (data[index].id == result[0].id) {
        filterArray.push(data[index]);
        idx = index;
        break;
      }
    }
    var toBeCopied = result[0];
    var copy = toJS(toBeCopied);
    //create new id for the node
    copy.id = uuid();
    //create new key for the question
    copy.config.key =
      copy.text.replace(" ", "") +
      (Math.floor(Math.random() * 90000000) + 10000).toString();
    //add to the next position in the cards array
    copy.config.placeholderValue = null;
    this.pages.splice(idx + 1, 0, copy);
  };
  getPage() {
    return this.pages;
  }
  saveConfig = (data) => {
    const targetId = this.editTarget.id;
    let index = this.pages.findIndex(function(element) {
      return element.id == targetId;
    });
    if (index >= 0) {
      this.pages[index].config = data;
    }
  };
  convertFormat = (type) => {
    switch (type) {
      case 1:
        return "number_input";
      case 2:
        return "free_text";
      case 3:
        return "custom_map";
      case 4:
        return "single_choice";
      case 5:
        return "multiple_choice";
      case 6:
        return "upload_media";
      case 7:
        return "calendar";
      case 8:
        return "";
      case 9:
        return "single_line_text";
      case 10:
        return "single_select";
      case 11:
        return "row_text";
      case 12:
        return "list_input";
      case 13:
        return "e_signature";
      case 14:
        return "file_preview";
      case 15:
        return "table";
        case 16:
          return "video_embed";
      case 17:
        return "rich_text_editor"
      case 19:
        return "repeatable_section"
      default:
        return "";
    }
  };
  encodeObj = (type) => {
    switch (type) {
      case "number_input":
        return { type: 1, text: "Number Input" };
      case "free_text":
        return {
          type: 2,
          text: "Long Text",
        };
      case "custom_map":
        return {
          type: 3,
          text: "Map",
        };
      case "single_choice":
        return {
          type: 4,
          text: "Radio Button",
        };
      case "multiple_choice":
        return {
          type: 5,
          text: "Checkbox",
        };
      case "upload_media":
        return {
          type: 6,
          text: "Upload File",
        };
      case "calendar":
        return {
          type: 7,
          text: "Calendar",
        };
      case "single_line_text":
        return {
          type: 9,
          text: "Short Text",
        };
      case "single_select":
        return {
          type: 10,
          text: "Dropdown",
        };
      case "row_text":
        return {
          type: 11,
          text: "Content Block",
        };
      case "list_input":
        return {
          type: 12,
          text: "List Input",
        };
      case "e_signature":
        return {
          type: 13,
          text: "E-Signature",
        };
      case "file_preview":
        return {
          type: 14,
          text: "File preview",
        };
      case "table":
        return {
          type: 15,
          text: "Table",
        };
        case "video_embed":
        return {
          type: 16,
          text: "Embed video"
        };

      case "rich_text_editor":
        return {
          type: 17,
          text: "Rich Text Editor"
        };
      case "repeatable_section":
        return {
          type: 19,
          text: "Repeatable Section"
        }
      default:
        return "";
    }
  };
  parseJSON = (data) => {
    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  };
  clearStorage = () => {
    this.pages = [];
    this.editTarget = "";
    this.onEdit = false;
    this.onSave = false;
    this.pageTitles = [{ page_title: "Page Title", page_description: "" }];
    this.credentialId = null;
    this.SelectedPlaceholder = {};
  };
  reloadTemplate = (data, form_length) => {
    try {
      SequentialFormContainer.clearStorage();
      for (let key in data) {
        for (let i in data[key].components) {
          let obj = this.encodeObj(
            data[key].components[i].views.answer.view.format
          );

          if (obj) {
            const id = uuid();
            obj.id = id;
            obj.config = {
              required: data[key].components[i].required,
              requiredText: data[key].components[i].required,
              comment: data[key].components[i].comment,
              visibility: data[key].components[i].visibility,
              visibilityText: data[key].components[i].visibility,
              readonly: data[key].components[i].read_only,
              readonlyText: data[key].components[i].readonly,
              autoAnswer: data[key].components[i].auto,
              auto_answer: data[key].components[i].auto,
              dataAuto: this.dataAuto(data[key].components[i].key_active_auto),
              dataVisibility: this.dataVisibility(
                data[key].components[i].key_visibility
              ),
              dataReadOnly: this.dataReadOnly(
                data[key].components[i].key_readonly
              ),
              dataRequired: this.dataRequired(
                data[key].components[i].key_required
              ),
              dataComment: this.dataComment(
                data[key].components[i].key_comment
              ),
              question_description_default:
                data[key].components[i].views.question.description.default,
              question_text_default:
                data[key].components[i].views.question.view.data.default,
              key: data[key].components[i].key,
              answer_list: this.parseJSON(
                data[key].components[i].views.answer.view.data.default
              ) || [["", ""]],
              answer_data_default: this.parseJSON(
                data[key].components[i].views.answer.view.data.default
              ),
              rte_answer_data_default: this.parseJSON(
                  data[key].components[i].views.answer.view.data.default
              ),
              drop_down: data[key].components[i].drop_down,
              numRows: parseInt(data[key].components[i].numRows),
              exportType: data[key].components[i].exportType,
              exportFormat: data[key].components[i].exportFormat,
              tableCells: data[key].components[i].tableCells,
              columns: this.parseJSON(
                data[key].components[i].views.answer.view.data.default
              ),
              rowLabels: data[key].components[i].views.answer.view.data
                .rowLabels
                ? data[key].components[i].views.answer.view.data.rowLabels
                : generateRowLabels(parseInt(data[key].components[i].numRows)),
              list_min: parseInt(data[key].components[i].list_min),
              list_max: parseInt(data[key].components[i].list_max),
              outputFormat: data[key].components[i].outputFormat,
              timezone: data[key].components[i].timezone,
              columnId: data[key].components[i].columnId,
              databaseId: data[key].components[i].databaseId,
              labelColumnId: data[key].components[i].labelColumnId,
              valueColumnId: data[key].components[i].valueColumnId,
              file_type: data[key].components[i].file_type,
              notificationEmails: data[key].components[i].notificationEmails,
              choiceAnswerOption: data[key].components[i].choiceAnswerOption,
              size: data[key].components[i].size,//size for embedding a video
              height: data[key].components[i].height,//height for embedding a video
              width: data[key].components[i].width,//width for embedding a video
              embedType: data[key].components[i].embedType,//embedType for embedding a video
              url: data[key].components[i].url,//url for embedding a video
              snippet: data[key].components[i].snippet,//snippet for embedding a video
            };
            this.pages.push(obj);
          }
        }

        if (key == 0) {
          this.pageTitles = [
            {
              page_title: data[key].title.view.data.default,
              page_description: data[key].title.description.default,
            },
          ];
        }
        if (key < parseInt(form_length) - 1) {
          this.pages.push({
            id: uuid(),
            type: 8,
            text: "Page Breaker",
            config: {
              page_title: data[parseInt(key) + 1].title.view.data.default,
              page_description:
                data[parseInt(key) + 1].title.description.default,
            },
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  getKeyButtonGroup = (data) => {
    if (data) {
      const obj = data.filter((ele) => ele.active == true);
      if (obj && obj[0]) {
        return obj[0].key;
      }
    }
    return "";
  };
  saveTemplate() {
    let result = {
      0: {
        title: {
          view: {
            format: "",
            data: { default: this.pageTitles[0].page_title, dynamic: "" },
          },
          style: "",
          description: {
            default: this.pageTitles[0].page_description,
            dynamic: "",
          },
        },
        components: [],
      },
    };
    let page_index = 0;
    this.pages.map((n) => {
      if (n.type == 8) {
        page_index = page_index + 1;
        result[page_index.toString()] = {
          title: {
            view: {
              format: "",
              data: { default: n.config.page_title, dynamic: "" },
            },
            style: "",
            description: { default: n.config.page_description, dynamic: "" },
          },
          components: [],
        };
      } else {
        if (n.type == 4 || n.type == 5 || n.type == 10) {
          for (let i in n.config.answer_list) {
            if (!n.config.answer_list[i][0]) {
              n.config.answer_list[i][0] = n.config.answer_list[i][1];
            }
          }
        }
        result[page_index.toString()].components.push({
          key:
            n.config.key ||
            n.text.replace(" ", "") +
              (Math.floor(Math.random() * 90000000) + 10000).toString(),
          required: n.config.required,
          comment: n.config.comment,
          visibility: n.config.visibility,
          read_only: n.config.readonly,
          auto: n.config.autoAnswer,
          key_active_auto: this.getKeyButtonGroup(n.config.dataAuto),
          key_visibility: this.getKeyButtonGroup(n.config.dataVisibility),
          key_readonly: this.getKeyButtonGroup(n.config.dataReadOnly),
          key_comment: this.getKeyButtonGroup(n.config.dataComment),
          key_required: this.getKeyButtonGroup(n.config.dataRequired),
          notificationEmails: n.config.notificationEmails,
          choiceAnswerOption: n.config.choiceAnswerOption,
          numRows: n.config.numRows,
          exportType: n.config.exportType,
          exportFormat: n.config.exportFormat,
          tableCells: n.config.tableCells,
          list_min: n.config.list_min,
          list_max: n.config.list_max,
          drop_down: n.config.drop_down.toString(),
          outputFormat: n.config.outputFormat,
          timezone: n.config.timezone,
          databaseId: n.config.databaseId,
          columnId: n.config.columnId,
          labelColumnId: n.config.labelColumnId,
          valueColumnId: n.config.valueColumnId,
          file_type: n.config.file_type,
          size: n.config.size,//size for embedding video
          height: n.config.height,//height for embedding video
          width: n.config.width,//width for embedding video
          embedType: n.config.embedType,//embedType for embedding video
          url: n.config.url,//url for embedding video
          snippet: n.config.snippet,//snippet for embedding video
          rte_answer_data_default:n.config.rte_answer_data_default,
          views: {
            question: {
              view: {
                format: "row_text",
                data: {
                  default: n.config.question_text_default,
                  dynamic: "",
                },
              },
              style: "",
              description: {
                default: n.config.question_description_default,
                dynamic: "",
              },
            },
            answer: {
              view: {
                format: this.convertFormat(n.type),
                data: {
                  rowLabels: n.config.rowLabels,
                  default:
                    n.type == 4 || n.type == 5 || n.type == 10
                      ? n.config.answer_list
                      : (n.type == 15 || n.type == 19)
                      ? n.config.columns
                      : n.type==17
                      ? n.config.rte_answer_data_default
                      : n.config.answer_data_default,
                  dynamic: "",
                },
              },
              style: "",
              description: {
                default: "",
                dynamic: "",
              },
            },
          },
        });
      }
    });
    let output = { pages: result };
    output["form_length"] = page_index + 1;
    return output;
  }

  dataAuto = (key) => {
    const data = [
      { key: 1, name: "ON", active: false },
      { key: 2, name: "OFF", active: true },
      { key: 3, name: "CONDITIONAL", active: false },
    ];
    return this.changeButtonGroup(data, key);
  };

  dataVisibility = (key) => {
    const data = [
      { key: 1, name: "ON", active: true },
      { key: 2, name: "OFF", active: false },
      { key: 3, name: "CONDITIONAL", active: false },
    ];
    return this.changeButtonGroup(data, key);
  };

  dataReadOnly = (key) => {
    const data = [
      { key: 1, name: "ON", active: false },
      { key: 2, name: "OFF", active: true },
      { key: 3, name: "CONDITIONAL", active: false },
    ];
    return this.changeButtonGroup(data, key);
  };

  dataRequired = (key) => {
    const data = [
      { key: 1, name: "ON", active: false },
      { key: 2, name: "OFF", active: true },
      { key: 3, name: "CONDITIONAL", active: false },
    ];
    return this.changeButtonGroup(data, key);
  };

  dataComment = (key) => {
    const data = [
      { key: 1, name: "ON", active: false },
      { key: 2, name: "OFF", active: true },
    ];
    return this.changeButtonGroup(data, key);
  };

  changeButtonGroup = (data, key) => {
    if (key) {
      const dataChange = data.map((item) => {
        if (item.key === key) {
          return { ...item, active: true };
        } else {
          return { ...item, active: false };
        }
      });
      return dataChange;
    }
    return data;
  };

  setComponentAndPosition(data, index) {
    const id = uuid();
    const dataAuto = this.dataAuto();
    const dataVisibility = this.dataVisibility();
    const dataReadOnly = this.dataReadOnly();
    const dataRequired = this.dataRequired();
    const dataComment = this.dataComment();

    let colId = uuid();
    const tableCells = {};
    tableCells[colId] = [
      {
        id: "row1",
        isRequired: false,
        isDisabled: false,
        isReadOnly: false,
        defaultValue: "",
        rawValue: "",
      },
      {
        id: "row2",
        isRequired: false,
        isDisabled: false,
        isReadOnly: false,
        defaultValue: "",
        rawValue: "",
      },
      {
        id: "row3",
        isRequired: false,
        isDisabled: false,
        isReadOnly: false,
        defaultValue: "",
        rawValue: "",
      },
    ];

    data.id = id;
    data.config = {
      required: false,
      requiredText: "",
      comment: false,
      visibility: true,
      visibilityText: "",
      readonly: false,
      readonlyText: "",
      autoAnswer: "",
      auto_answer: "",
      notificationEmails: {
        emailList: [],
        shouldNotify: false,
      },
      choiceAnswerOption: "manually",
      dataAuto: dataAuto,
      dataVisibility: dataVisibility,
      dataReadOnly: dataReadOnly,
      dataRequired: dataRequired,
      dataComment: dataComment,
      question_description_default: "",
      question_text_default: "",
      key: data.text.replace(/ /g, "") +
        (Math.floor(Math.random() * 90000000) + 10000).toString(),
      answer_list: [["", ""]],
      answer_data_default: "",
      rte_answer_data_default: "",
      drop_down: "",
      numRows: 3,
      exportType: "columnsAsLists",
      exportFormat: "JSON",
      columns: [
        {
          id: colId,
          type: "text",
          columnName: "Column 1",
          question: "Question 1",
          description: "",
          options: [
            {
              id: uuid(),
              value: "",
              label: "",
            },
          ],
          dtFormat: {
            outputFormat: "dd/MM/YYYY kk:mm",
            timezone: "GMT+10",
          },
          required: false,
        },
      ],
      tableCells: tableCells,
      rowLabels: [
        {
          rowNumber: "1",
          rowLabel: "",
          rowKey: "row1",
        },
        {
          rowNumber: "2",
          rowLabel: "",
          rowKey: "row2",
        },
        {
          rowNumber: "3",
          rowLabel: "",
          rowKey: "row3",
        },
      ],
      list_min: 0,
      list_max: 0,
      outputFormat: "",
      timezone: timezones.find(
        (timezone) => timezone.name === ProjectStore.state.timezone
      ).offset,
      databaseId: "",
      columnId: "",
      valueColumnId: "",
      labelColumnId: "",
      page_title: "Page Title",
      file_type: "pdf",
      size: "RESPONSIVE",
      height: "",
      width: "",
      embedType: "URL",
      url: "",
      snippet: "",
    };

    if (this.pages.length - 1 == index) {
      this.pages.push(data);
    } else this.pages.splice(index, 0, data);
  }

  addComponent = (data) => {
    const id = uuid();
    const dataAuto = this.dataAuto();
    const dataVisibility = this.dataVisibility();
    const dataReadOnly = this.dataReadOnly();
    const dataRequired = this.dataRequired();
    const dataComment = this.dataComment();

    data.id = id;

    let colId = uuid();
    const tableCells = {};
    tableCells[colId] = [
      {
        id: "row1",
        isRequired: false,
        isDisabled: false,
        isReadOnly: false,
        defaultValue: "",
        rawValue: "",
      },
      {
        id: "row2",
        isRequired: false,
        isDisabled: false,
        isReadOnly: false,
        defaultValue: "",
        rawValue: "",
      },
      {
        id: "row3",
        isRequired: false,
        isDisabled: false,
        isReadOnly: false,
        defaultValue: "",
        rawValue: "",
      },
    ];

    data.config = {
      required: false,
      requiredText: "",
      comment: false,
      visibility: true,
      visibilityText: "",
      readonly: false,
      readonlyText: "",
      autoAnswer: "",
      auto_answer: "",
      notificationEmails: {
        emailList: [],
        shouldNotify: false,
      },
      choiceAnswerOption: "manually",
      dataAuto: dataAuto,
      dataVisibility: dataVisibility,
      dataReadOnly: dataReadOnly,
      dataRequired: dataRequired,
      dataComment: dataComment,
      question_description_default: "",
      question_text_default: "",
      // Changed the replace method to replace all spaces in the text
      key:
          data.text.replace(/ /g, "") +
        (Math.floor(Math.random() * 90000000) + 10000).toString(),
      answer_list: [["", ""]],
      answer_data_default: "",
      rte_answer_data_default: "",
      drop_down: "",
      numRows: 3,
      exportType: "columnsAsLists",
      exportFormat: "JSON",
      columns: [
        {
          id: colId,
          type: "text",
          columnName: "Column 1",
          question: "Question 1",
          description: "",
          options: [
            {
              id: uuid(),
              value: "",
              label: "",
            },
          ],
          dtFormat: {
            outputFormat: "dd/MM/YYYY kk:mm",
            timezone: "GMT+10",
          },
          required: false,
        },
      ],
      tableCells: tableCells,
      rowLabels: [
        {
          rowNumber: "1",
          rowLabel: "",
          rowKey: "row1",
        },
        {
          rowNumber: "2",
          rowLabel: "",
          rowKey: "row2",
        },
        {
          rowNumber: "3",
          rowLabel: "",
          rowKey: "row3",
        },
      ],
      list_min: 0,
      list_max: 0,
      outputFormat: "",
      timezone: "",
      page_title: "Page Title",
      databaseId: "",
      columnId: "",
      valueColumnId: "",
      labelColumnId: "",
      file_type: "pdf",
      size: "RESPONSIVE",//the size for the embedded video
      height: "",
      width: "",
      embedType: "URL",
      url: "",
      snippet: "",
    };
    this.pages.push(data);
  };
  editComponent = (id) => {
    console.log(id)
    
    const data = this.pages;
    const filterArray = [];

    let result = data.filter((item) => item.id == id);
    
    for (let index = 0; index < data.length; index++) {
      if (data[index].id == result[0].id) break;
      filterArray.push(data[index]);
    }
    
    this.dataQuestion = filterArray;
    this.editTarget = result[0];
  };

  reloadEditComponent = () => {
    this.editTarget = "";
  };

  setSubject = (subject) => {
    this.emailSubject = subject;
  };
  getData = (id) => {
    let result = this.pages.filter((item) => item.id == id);
    return result[0];
  };
  clearPage = (id) => {
    let currentIndex = this.pages.findIndex((item) => item.id == id);
    this.pages.splice(currentIndex, 1);

    if (currentIndex >= 0) {
      for (let i = currentIndex; i < this.pages.length; i++) {
        if (this.pages[i].type != 8) {
          this.pages[i] = "";
        } else {
          break;
        }
      }
      this.pages = this.pages.filter((item) => item != "");
    }
  };
  deletePageKeepQuestions = (id) => {
    let currentIndex = this.pages.findIndex((item) => item.id == id);
    let leftover_array = this.pages.slice(currentIndex, this.pages.length + 1);
    this.pages.splice(currentIndex, 1);
    this.pages.concat(leftover_array);
  };
}
decorate(Container, {
  addComponent: action,
  getPage: action,
  editPagedata: action,
  editComponent: action,
  reloadEditComponent: action,
  saveConfig: action,
  getData: action,
  saveTemplate: action,
  reloadTemplate: action,
  clearPage: action,
  clearStorage: action,
  setPosition: action,
  setComponentAndPosition: action,
  changeButtonGroup: action,
  email: observable,
  dueDateOn: observable
});

var SequentialFormContainer = new Container();

export default SequentialFormContainer;
