import React, { useState, useEffect } from "react";
import "./index.css";
import "./App.css";
import { Amplify } from "aws-amplify";
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import AuthWrapper from "./components/Component/AuthWrapper";
import aws_config from "./auth/awsConfig";
import { BrowserRouter } from "react-router-dom";

Amplify.configure(aws_config);

function App() {
  const [authState, setAuthState] = useState("signIn");
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const user = await getCurrentUser();
      setUser(user);
      setAuthState("signedIn");
    } catch (error) {
      setUser(null);
      setAuthState("signIn");
    }
  }

  async function handleStateChange(state, data) {
    setAuthState(state);
    if (state === "signedIn") {
      setUser(data);
    }
  }

  return (
    <BrowserRouter>
      <AuthWrapper
        authState={authState}
        onStateChange={handleStateChange}
        user={user}
      />
    </BrowserRouter>
  );
}

export default App;
