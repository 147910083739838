import React, { Component } from "react";
import Button from "@mui/material/Button";
import { Grid, Snackbar } from "@mui/material";
import { Error, Link as LinkIcon } from "@mui/icons-material";
import { toJS, reaction } from "mobx";
import Target from "./DropTarget";
import { inject, observer } from "mobx-react";
import ComponentConfig from "./ComponentConfig";
import { send_request } from "../../utils/Request";
import { CustomDialog } from "../Component/Dialog";
import ComponentsSidebar from "./ComponentsSidebar";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import FormBuilderContainer from "./FormBuilderContainer";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { searchForInnerFormPlaceholders } from "../../utils/InnerFormPlaceholders";
import ValidationErrors from "../pages/ComponentPageDialogs/ValidationErrors";
import "react-image-crop/dist/ReactCrop.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert } from "@mui/lab";
import { withParams } from "../../Routes";
import config from "../../config";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import { createSaveEvent } from "../../utils/createSaveEvent";
import { send_request_graphql_mutation } from "../../utils/Request";
import { SAVE_EVENT } from "../pages/Canvas/CanvasQueries";
import { ComponentFactory } from "../ProjectCanvas/ComponentRegistry";
import ComponentAIDraftDiscardDialog from "../pages/Canvas/CanvasUtil/ComponentAIDraftDiscardDialog";
import { FormContext } from "../pages/ComponentPane/ComponentPane";
import { FormSettingsDialog } from "./FormDialogs/FormSettingsDialog";
import { FormLinkDialog } from "./FormDialogs/FormLinkDialog";
import { FormExportDialog } from "./FormDialogs/FormExportDialog";
import { FormEmbedDialog } from "./FormDialogs/FormEmbedDialog";
import { FormComponentSettings } from "./FormDialogs/FormComponentSettings";
import { FormStylingDialog } from "./FormDialogs/FormStylingDialog";
import DiscardChangesButton from "../pages/ComponentPane/DiscardChangesButton";

const fileService = config.FILE_SERVICE;
class FormBuilderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "",
      errors: [],
      submMsg: "",
      form_id: "",
      saveD: false,
      error: false,
      exportType: 0,
      loading: false,
      uploadIcon: "",
      saveStatus: "",
      iframeLink: "",
      form_title: "",
      lastUpdate: "",
      embedWidth: 1080,
      embedHeight: 960,
      embedOpen: false,
      uploadedLogo: "",
      validationError: false,
      canEditSubmission: true,
      canAddSubmission: false,
      canSaveSubmission: true,
      canAnnotateContent: false,
      canSubmitSubmission: true,
      canReadOnly: false,
      autoSave: false,
      uploadedCoverImage: "",
      coverImageSrc: "",
      coverImageCrop: {
        unit: "%",
        width: 30,
        aspect: 7 / 1,
      },
      logoSrc: "",
      logoCrop: {
        unit: "%",
        width: 30,
        aspect: 1,
      },
      croppedLogoUrl: "",
      loadingUpload: false,
      errorCoverImageMessage: "",
      errorLogoMessage: "",
      exposeMeta: false,
      linkedComponent: false,
      linkedComponents: [],
      relatedSequentialForm: [],
      innerFormPlaceholders: [],
      instruction: "",
      aiComponentBuilderOpen: false,
      aiComponentBuilderData: [],
      status: null,
      openTestPanel: false,
      fetchTestOutput: false,
      compData: {},
      openSessionHistory: false,
      selectedHistorySession: false,
      resetHistory: false,
      isInAIDraft: false,

      instructions: {},
      showBuildPrompt: false,
      sidebarState: "INITIAL_COMP_BUILD",
      isLoadedFromPane: false,
      isFullScreen: window.location.href.includes("fullScreen=true"),
      compSessionId: null,
      ranAIOnce: false,
    };
    this.Config = React.createRef();
  }

  setAiComponentBuilderData = (aiComponentBuilderData) => {

    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;

    if (aiComponentBuilderData && aiComponentBuilderData.data) {
      this.setState({
        aiComponentBuilderData: aiComponentBuilderData,
      });

      if (aiComponentBuilderData.data) {
        this.setState({
          form_title: aiComponentBuilderData.name,
          linkedComponents: aiComponentBuilderData.data.linkedComponents,
          linkedComponent: aiComponentBuilderData.data.linkedComponent,
          canAddSubmission: aiComponentBuilderData.data.canAddSubmission,
          canAnnotateContent:aiComponentBuilderData.data.canAnnotateContent,
          canEditSubmission:aiComponentBuilderData.data.canEditSubmission,
          canReadOnly:aiComponentBuilderData.data.canReadOnly,
          canSaveSubmission:aiComponentBuilderData.data.canSaveSubmission,
          canSubmitSubmission:aiComponentBuilderData.data.canSubmitSubmission,

        });

        this.getLinkedComponents();

        FormBuilderContainer.reloadTemplate(
          aiComponentBuilderData.data.pages,
          aiComponentBuilderData.data.form_length
        );

        this.setState({
          instruction: aiComponentBuilderData["explanation"],
        });

        this.props.onComponentDescriptionChanged(aiComponentBuilderData["explanation"]);

        this.setState({
          status: "AI_BUILD_GENERATED",
          loading: false,
          form_id: formId,
        });

      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {

    const currentId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;


    if (this.state.form_id !== currentId) {
      if (currentId) {
        this.updateForm();
      } else {
        FormBuilderContainer.clearStorage();
      }
    }
  }

  onDiscard = () => {
    this.props.navigate(-1);
  };

  onBreadcrumb = (path) => {
    this.onSave();

    if (this.state.isLoadedFromPane) {
      this.props.handleClose(true);
    } else {

      if (ProjectStore.state.production && !ProjectStore.state.canvasView) {
        this.props.navigate(`/project/production/${this.props.params.id}`);
      } else {
        this.props.navigate(`/project/canvas/${this.props.params.id}`);

      }

    }
  };

  onSave = () => {
    if (!ProjectStore.state.production) this.handleSubmit("published");
  };

  handleCancelSettings = (setOpenSettings) => {
    if (this.baseState) {
      this.setState({
        state: JSON.parse(this.baseState),
      });
      setOpenSettings(false);
    } else {
      setOpenSettings(false);
    }

    // If there isn't a link component selected, set back to default
    if (this.state.linkedComponent && this.state.linkedComponents.length === 0)
      this.setState({ linkedComponent: false });
  };

  /**
   * This function get the Form data from the Project.
   * This is the latest way of getting the Form data as the result of merging Form and Project
   * However, the previous way is still kept for backward compatibility
   */
  updateFormFromProject = async () => {

    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;


    let version = null;
    if (ProjectStore.state.production) version = ProjectStore.publishedVersion;
    else version = ProjectStore.draftVersion;
    if (formId) {
      await this.setCompData();
      return send_request(
        `project-service/project/component/query/${formId}/at-version/${version}`,
        "",
        {}
      )
        .then(async (response) => {
          if (response.data && response.data.components) {
            if (response.data.components[0].componentData) {
              let cData = response.data.components[0].componentData;
              let rData = cData.data;

              if (rData) {
                FormBuilderContainer.reloadTemplate(
                  rData.pages,
                  rData.form_length
                );

                const coverImageCrop = {
                  unit: "%",
                  width: 30,
                  aspect: 7 / 1,
                };

                document.title = `${
                  rData.form_title ? rData.form_title : "Untitled"
                } | Workflow86`;

                this.setState({
                  form_id: formId,
                  lastUpdate: cData.lastModified,
                  uploadIcon: rData.form_logo,
                  form_title: rData.form_title,
                  submMsg: rData.submMsg ? rData.submMsg : "",
                  canAddSubmission: rData.canAddSubmission
                    ? rData.canAddSubmission
                    : false,
                  canSaveSubmission:
                    rData.canSaveSubmission !== undefined
                      ? rData.canSaveSubmission
                      : true,
                  canAnnotateContent:
                    rData.canAnnotateContent !== undefined
                      ? rData.canAnnotateContent
                      : false,
                  autoSave:
                    rData.autoSave !== undefined ? rData.autoSave : false,
                  canSubmitSubmission:
                    rData.canSubmitSubmission !== undefined
                      ? rData.canSubmitSubmission
                      : true,
                  canEditSubmission: rData.canEditSubmission,
                  canReadOnly: rData.canReadOnly ? rData.canReadOnly : false,
                  prefixLabel: response.data.components[0].prefixLabel,
                  exposeMeta:
                    response.data.components[0].prefixLabel &&
                    response.data.components[0].prefixLabel != ""
                      ? true
                      : false,
                  formSubmittedDateFormat: rData.formSubmittedDateFormat,
                  uploadedCoverImage:
                    rData.coverImage !== undefined
                      ? rData.coverImage.uploaded
                      : "",
                  coverImageSrc:
                    rData.coverImage !== undefined ? rData.coverImage.src : "",
                  coverImageCrop:
                    rData.coverImage !== undefined
                      ? rData.coverImage.crop
                      : coverImageCrop,
                  uploadedLogo:
                    rData.logo !== undefined ? rData.logo.uploaded : "",
                  linkedComponent: rData.linkedComponent ? true : false,
                  linkedComponents: rData.linkedComponents,
                  instruction: response.data.components[0].description
                    ? response.data.components[0].description
                    : this.state.description,
                  instructions: response.data.components[0].instructions
                    ? response.data.components[0].instructions
                    : this.state.instructions,
                });
                
                this.props.onLastModifiedChanged(cData.lastModified);
                this.props.onComponentDescriptionChanged(response.data.components[0]?.description || "");
                this.baseState = JSON.stringify(this.state); //enable Cancel button for the FormSettings dialog
                this.setState({ loading: false });
                this.setLast;
              }
            }
          }

          if (!this.state.form_title) {
            this.setState({ form_title: ProjectStore.ComponentName.value });
          }

        })
        .catch((err) => {
          this.setState({ loading: false });
          throw Error(err.message);
        });
    }
  };

  setCompData = async () => {
    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;


    try {
      if (!ProjectStore.components) return;

      let comp = ProjectStore.components.find(
        (comp) => comp.componentId === formId
      );

      // Set type
      let type = "";
      if (comp) type = comp.type;
      else type = "form";

      let compNode = ComponentFactory[type];
      if (!compNode) return;

      let res = {
        id: formId,
        type: "component",
        data: {
          ...compNode,
          type: type,
        },
      };

      this.setState({ compData: res });
    } catch (e) {
      console.log(e);
    }
  };

  updateForm = (isInnerSave) => {
    const projectId = this.props.params.id;

    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;

    if (ProjectStore.draftVersion == undefined) {
      // We need to add the set timeout so it places the navigate
      // at the end of the JS event loop
      setTimeout(() => {
        return this.props.navigate(`/project/canvas/${projectId}`);
      }, 0);
    } else {
      if (!isInnerSave) FormBuilderContainer.clearStorage();

      if (formId) {
        this.updateFormFromProject()
          .then(() => {
            //get linked components
            this.getLinkedComponents();

            if (
              !FormBuilderContainer.pages ||
              FormBuilderContainer.pages.length == 0
            ) {
              /**
               * the Form could not be found in the Project.
               * Then, we will try the Form DB.
               */
              send_request("/form-builder/form", "", { form_id: formId }, "get")
                .then((res) => {
                  const result = res.data.data;
                  FormBuilderContainer.reloadTemplate(
                    result.pages,
                    result.form_length
                  );
                  this.setState({
                    form_id: formId,
                    lastUpdate: result.created_at,
                  });
                  this.props.onLastModifiedChanged(result.created_at);
                  this.baseState = JSON.stringify(this.state); //enable Cancel button for the FormSettings dialog
                  this.setState({ loading: false });
                })

                .catch((e) => {
                  this.setState({ loading: false });
                  return;
                });
              send_request(
                "/form-builder/form_meta",
                "",
                { form_id: formId },
                "get"
              )
                .then(async (res) => {
                  const coverImageCrop = {
                    unit: "%",
                    width: 30,
                    aspect: 7 / 1,
                  };

                  this.setState({
                    uploadIcon: res.data.data.form_logo,
                    form_title: res.data.data.form_title,
                    submMsg: res.data.data.submMsg ? res.data.data.submMsg : "",
                    canAddSubmission: res.data.data.canAddSubmission
                      ? res.data.data.canAddSubmission
                      : false,
                    canSaveSubmission:
                      res.data.data.canSaveSubmission !== undefined
                        ? res.data.data.canSaveSubmission
                        : true,
                    canAnnotateContent:
                      res.data.data.canAnnotateContent !== undefined
                        ? res.data.data.canAnnotateContent
                        : false,
                    canSubmitSubmission:
                      res.data.data.canSubmitSubmission !== undefined
                        ? res.data.data.canSubmitSubmission
                        : true,
                    canEditSubmission: res.data.data.canEditSubmission,
                    canReadOnly: res.data.data.canReadOnly
                      ? res.data.data.canReadOnly
                      : false,
                    uploadedCoverImage:
                      res.data.data.coverImage !== undefined
                        ? res.data.data.coverImage.uploaded
                        : "",
                    coverImageCrop:
                      res.data.data.coverImage !== undefined
                        ? res.data.data.coverImage.crop
                        : coverImageCrop,
                    coverImageSrc:
                      res.data.data.coverImage !== undefined
                        ? res.data.data.coverImage.src
                        : "",
                    uploadedLogo:
                      res.data.data.logo !== undefined
                        ? res.data.data.logo.uploaded
                        : "",
                  });

                  if (!this.state.form_title) {
                    this.setState({
                      form_title: ProjectStore.ComponentName.value,
                      ranAIOnce: false,
                    });
                  }

                 
                })

                .catch((e) => {
                  return;
                });
            }
          })
          .then(() => {
            //Search for inner form placeholders

            this.setState({
              innerFormPlaceholders: searchForInnerFormPlaceholders(
                FormBuilderContainer.pages
              ),
            });


          });
      }

    }
  };

  getLinkedComponents() {
    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;

    send_request(
      "project-service/project/find_related_sequential_forms/" + formId,
      "",
      {}
    ).then((resp) => {
      if (resp && resp.data) {
        let k = Object.keys(resp.data);
        let SFormsList = [];
        for (let a = 0; a < k.length; a++) {
          SFormsList.push({
            label:
              resp.data[k[a]].componentName &&
              resp.data[k[a]].componentName != ""
                ? resp.data[k[a]].componentName
                : k[a],
            componentId: k[a],
          });
        }

        let lc = [];

        if (this.state.linkedComponents) {
          for (let a = 0; a < this.state.linkedComponents.length; a++) {
            if (resp.data[this.state.linkedComponents[a]]) {
              lc.push({
                label:
                  resp.data[this.state.linkedComponents[a]].componentName &&
                  resp.data[this.state.linkedComponents[a]].componentName != ""
                    ? resp.data[this.state.linkedComponents[a]].componentName
                    : this.state.linkedComponents[a],
                componentId: this.state.linkedComponents[a],
              });
            }
          }
        }

        this.setState({
          relatedSequentialForm: SFormsList,
          linkedComponents: lc,
        });
      }
    });
  }

  componentDidMount() {
    this.disposer = reaction(
      () => FormBuilderContainer.editTarget,
      (editTarget) => {
        const isOpen = editTarget !== null;
        this.setState({ isComponentConfigDialogOpen: isOpen });
      }
    );

    // Determine the id based on whether form_id is present
    const id = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;
    const isLoadedFromPane = this.props.params.form_id ? false : true;

    // Find the component in ProjectStore using the determined id
    const component = ProjectStore.components.find((c) => c.componentId === id);
    // Set state based on the found component, with fallbacks for instruction and instructions
    this.setState({
      instruction: component?.description || "",
      instructions: component?.instructions || "",
      form_id: id,
      loading: true,
      isLoadedFromPane: isLoadedFromPane,
    });

    if (isLoadedFromPane) this.props.setChildTemplateLoaded(true);

    let aiComponentBuilderData = this.props.aiComponentBuilderData;

    if (
      aiComponentBuilderData !== undefined &&
      aiComponentBuilderData &&
      aiComponentBuilderData["data"] &&
      aiComponentBuilderData["data"] !== ""
    ) {
      this.setAiComponentBuilderData(aiComponentBuilderData);
      this.setState({
        isInAIDraft: this.props.isInAIDraft
      })
    } else {
      // Continue with form update and validation processes
      this.updateForm();
      this.validate();
    }
  }

  validate = () => {
    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;

    this.props.ProjectStore.errors.map((error, index) => {
      if (error.component.component_id == formId) this.state.errors.push(error);

    });
    this.setState({ errors: this.state.errors });
    if (this.state.errors.length > 0) this.setState({ validationError: true });
  };

  onDone = (e) => {
    if (!ProjectStore.state.production ) {
      this.Config.current.handleSave(); //settle changes
      this.handleSubmit("published"); //send save request
      if(!this.state.isInAIDraft)
      {
        // handlesubmit on normal flow resets the flag after the request is done asynchronously
        // but in case of AI draft this makes the save state unusable
        FormBuilderContainer.onSave = true;
      }
    }
    this.setInnerPlaceholders();
  };

  handleSubmit = (status, executingTest, sessionId) => {
  if(this.state.isInAIDraft || this.state.loading ){
    FormBuilderContainer.onEdit = false;
    FormBuilderContainer.editTarget = "";
    FormBuilderContainer.onSave = false;
    return;
  }
    function dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    }
    function isDataURL(s) {
      return !!s.match(isDataURL.regex);
    }
    isDataURL.regex = /^\s*data:([a-z]+\/[a-z0-9\-\+]+(;[a-z\-]+\=[a-z0-9\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
    try {
      let data = toJS(FormBuilderContainer.saveTemplate());
      const pages = data["pages"];

      const logoUploaded = {
        name: this.state.uploadedLogo.name,
      };

      let src = "";
      if (this.state.uploadedCoverImage.id != undefined) {
        src = `${fileService.QUERY_URL}/${this.state.uploadedCoverImage.id}`;
      }

      const coverImage = {
        uploaded: this.state.uploadedCoverImage,
        crop: this.state.coverImageCrop,
        src: src,
      };

      const logo = {
        uploaded: logoUploaded,
      };

      let form_template = {};
      form_template["pages"] = pages;
      form_template["status"] = status;
      form_template["submMsg"] = this.state.submMsg;
      form_template["form_id"] = this.state.form_id;
      form_template["form_length"] = data["form_length"];
      form_template["form_logo"] = this.state.uploadIcon;
      form_template["form_title"] = ProjectStore.ComponentName.value;
      form_template["canAddSubmission"] = this.state.canAddSubmission;
      form_template["canSaveSubmission"] = this.state.canSaveSubmission;
      form_template["canAnnotateContent"] = this.state.canAnnotateContent;
      form_template["autoSave"] = this.state.autoSave;
      form_template["canSubmitSubmission"] = this.state.canSubmitSubmission;
      form_template["canEditSubmission"] = this.state.canEditSubmission;
      form_template["canReadOnly"] = this.state.canReadOnly;
      form_template["coverImage"] = coverImage;
      form_template["logo"] = logo;

      // Update page title
      document.title = `${
        this.state.form_title ? this.state.form_title : "Untitled"
      } | Workflow86`;

      //if there is new logo uploaded
      if (this.state.uploadedLogo && this.state.uploadedLogo.name)
        form_template["logo_url"] = this.state.uploadIcon;

      form_template[
        "formSubmittedDateFormat"
      ] = this.state.formSubmittedDateFormat;
      let submitData = {
        componentData: {
          data: form_template,
          name: this.props.ComponentName.value,
          lastModified: getCurrentTime(),
        },
        componentId: this.state.form_id,
        prefixLabel: this.state.prefixLabel,
        type: "form",
        description: this.props.ComponentDescription.value,
        instruction: this.state.instruction, // required to show instruction on first Save
        // instructions:this.state.instructions, //skipping instructions as it is failing form save
        status: this.state.status,
      };

      if (sessionId) submitData["sessionId"] = sessionId; // Attach session id if given
      if (this.state.isInAIDraft) {
        // setting session id to ensure that proposal reloads the translation
        submitData["sessionId"] = this.state.compSessionId;
      }
      form_template["linkedComponent"] = this.state.linkedComponent;

      if (this.state.linkedComponents) {
        let lc = [];
        for (let a = 0; a < this.state.linkedComponents.length; a++) {
          lc.push(this.state.linkedComponents[a].componentId);
        }
        form_template["linkedComponents"] = lc;
      }

      return send_request("component-service/form/data", submitData, "", "POST")
        .then((response) => {
          this.setState({
            saveD: true,
            saveStatus: 200,
            lastUpdate: getCurrentTime(),
          });
          this.props.onLastModifiedChanged(getCurrentTime());
          ProjectStore.savedComponent = true;

          FormBuilderContainer.onEdit = false;
          FormBuilderContainer.editTarget = "";
          FormBuilderContainer.onSave = false;

          if (this.state.openTestPanel && !executingTest)
            this.setState({ fetchTestOutput: true });

        })
        .catch((err) => {
          console.log(err);

          throw err;
        });
    } catch (e) {
      console.log(e);
      this.setState({ saveD: true, saveStatus: 500 });
    } finally {
      if (sessionId) {
        // reload form to ensure correct state comes up
        setTimeout(() => {
          this.updateForm(true);
        }, 1000);
      }
    }
  };

  goBackToCanvas = () => {
    window.location.href = `/project/canvas/${this.props.params.id}`;
  };

  deleteItem = (id) => {
    let cards = toJS(FormBuilderContainer.pages);
    let result = cards.filter((item) => item.id !== id);
    FormBuilderContainer.pages = result;
  };

  onImageDrop = async (files, type) => {
    this.setState({
      loadingUpload: true,
    });
    let project_id = this.props.params.id;

    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;

    if (project_id) {
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("formId", formId);
      formData.append("projectId", project_id);

      const json = await send_request(
        "component-service/form/upload-cover-image",
        formData,
        "",
        "POST",
        {
          "content-type": "multipart/form-data",
        }
      ).catch((err) => {
        this.setState({
          loadingUpload: false,
        });
        throw err;
      });

      if (json && json.data) {
        if (files && files.length > 0) {
          const coverImageUploaded = {
            name: json.data.fileName,
            id: json.data.id,
          };
          const reader = new FileReader();
          if (type === "coverImage") {
            reader.addEventListener("load", () =>
              this.setState({
                coverImageSrc: reader.result,
                uploadedCoverImage: coverImageUploaded,
                loadingUpload: false,
                errorCoverImageMessage: "",
              })
            );
          } else if (type === "logo") {
            reader.addEventListener("load", () =>
              this.setState({
                uploadedLogo: files[0],
                loadingUpload: false,
                errorLogoMessage: "",
              })
            );
          }
          reader.readAsDataURL(files[0]);
        }
      }
    }
  };

  previewIcon = (uploadedLogo) => {
    const reader = (file) => {
      if (Object.keys(file).length > 0) {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => resolve(fileReader.result);
          fileReader.readAsDataURL(file);
        });
      }
    };
    if (uploadedLogo !== "") {
      if (Object.keys(uploadedLogo).length > 0) {
        reader(uploadedLogo).then((result) => {
          this.setState({ uploadIcon: result });
        });
      }
    }
  };

  exportFormDataAsCsv = () => {
    var project_id = this.props.params.id;
    var form_id = this.state.form_id;
    send_request(
      "project-service/project-session/download_form_submission_csv/" +
        project_id +
        "/" +
        form_id,
      {},
      "",
      "GET",
      {},
      "arraybuffer"
    )
      .then((response) => {
        const filename = "submissionData.csv";
        var blob = new Blob([response.data]);
        let url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      })
      .catch((err) => console.log(err));
  };

  exportFormDataAsXLSX = () => {
    var project_id = this.props.params.id;
    var form_id = this.state.form_id;
    send_request(
      "project-service/project-session/download_form_submission_xlsx/" +
        project_id +
        "/" +
        form_id,
      {},
      "",
      "GET",
      {},
      "arraybuffer"
    )
      .then((response) => {
        const filename = "submissionData.xlsx";
        var blob = new Blob([response.data]);
        let url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      })
      .catch((err) => console.log(err));
  };

  generateCode = () => {
    const { embedWidth, embedHeight } = this.state;

    let link = `https://form.workflow86.com/form/${this.state.form_id}`;
    let iframeLink = `<iframe src="${link}" width="${embedWidth}" height="${embedHeight}" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`;

    this.setState({
      iframeLink,
    });
  };

  onImageLoaded = (image, type) => {
    if (type === "coverImage") {
      this.coverImageRef = image;
    } else if (type === "logo") {
      this.logoRef = image;
    }
  };

  onCropComplete = (crop, type) => {
    this.makeClientCrop(crop, type);
  };

  onCropChange = (crop, type) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    if (type === "coverImage") {
      crop.aspect = 7 / 1;
      this.setState({ coverImageCrop: crop });
    } else if (type === "logo") {
      crop.aspect = 1;
      this.setState({ logoCrop: crop });
    }
  };

  async makeClientCrop(crop, type) {
    if (type === "coverImage") {
      if (this.coverImageRef && crop.width && crop.height) {
        await this.getCroppedImg(this.coverImageRef, crop);
      }
    }
  }

  getCroppedImg(image, crop) {
    image.crossOrigin = "anonymous";
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    this.setState({
      coverImageCrop: {
        aspect: 7 / 1,
        scaleX: scaleX,
        scaleY: scaleY,
        width: crop.width,
        height: crop.height,
        x: crop.x,
        y: crop.y,
        naturalWidth: crop.width * scaleX,
        naturalHeight: crop.height * scaleY,
        naturalX: crop.x * scaleX,
        naturalY: crop.y * scaleY,
      },
    });
  }

  onDropRejected = (type) => {
    if (type === "coverImage") {
      this.setState({
        errorCoverImageMessage: "Maximum file upload size is 2MB",
      });
    } else if (type === "logo") {
      this.setState({
        errorLogoMessage: "Maximum file upload size is 2MB",
      });
    }
  };

  onLogoDrop(files) {
    this.previewIcon(files[0]);
    this.setState({
      uploadedLogo: files[0],
      errorLogoMessage: "",
    });
  }

  deleteCoverImage = async () => {
    const { uploadedCoverImage } = this.state;
    const data = [];
    data.push(uploadedCoverImage.id);
    let requestBody = {
      deleteList: data,
      project_id: this.props.params.id,
    };

    const json = await send_request(
      `project-service/project/file-library/deletes`,
      requestBody,
      "",
      "POST"
    ).catch((err) => {
      throw Error(err.message);
    });

    if (json && json.status == "200") {
      this.setState({
        uploadedCoverImage: "",
        coverImageSrc: "",
        coverImageCrop: {
          unit: "%",
          width: 30,
          aspect: 7 / 1,
        },
      });

      this.handleSubmit("published");
    }
  };

  setInnerPlaceholders = () => {
    //Search for inner form placeholders
    this.setState({
      innerFormPlaceholders: searchForInnerFormPlaceholders(
        FormBuilderContainer.pages
      ),
    });
  };

  updateComponent = async (
    discardBuildInstruction = false,
    discardEditInstructions = false
  ) => {
    const formId = this.props.params.form_id
      ? this.props.params.form_id
      : this.props.component_id;


    let eventData = {
      componentId: formId,
      instruction: this.state.instruction,
      discardBuildInstructions: discardBuildInstruction,
      discardEditInstructions: discardEditInstructions,
    };

    let saveEvent = createSaveEvent(
      "UPDATE_COMPONENT",
      ProjectStore.project_id,
      { x: null, y: 0.0, zoom: 0.0 },
      [],
      eventData
    );

    //send event
    await send_request_graphql_mutation(
      `project-service/graphql/project/save/${ProjectStore.project_id}`,
      SAVE_EVENT(saveEvent),
      "",
      "POST"
    )
      .then((response) => {
        if (response.data.saveEvent && response.data.saveEvent.updated) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setComponentInstruction = async (instruction) => {
    this.setState({ instruction: instruction });
  };

  removeForceStartTestParams = async () => {

    if (this.props.queryParams) {
      this.props.queryParams.delete("force_start");
      this.props.queryParams.delete("selectedSession");
      this.props.navigate({ search: this.props.queryParams.toString() });
    } else {
      const params = new URLSearchParams(window.location.search);
      params.delete("force_start");
      params.delete("selectedSession");
      this.props.navigate({ search: params.toString() });
    }

  };

  componentWillUnmount() {
  if (this.disposer) {
      this.disposer();
    }
  }

  handleExpand = () => {
    this.setState({ isFullScreen: true });
    this.props.handleExpand();
  };

  handleCollapse = () => {
    this.setState({ isFullScreen: false, openTestPanel: false });
    this.props.handleCollapse();
  };

  saveFormBuilder = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      this.handleSubmit("published").then(() => {
        if (this.state.saveStatus == 200) {
          this.props.showSuccess({}, this.props.SaveTrigger);
          this.props.onLastModifiedChanged(this.state.lastModified);
        }
        ProjectStore.savedComponent = true;
      });
    }
  );


  render() {
    const { coverImageSrc, coverImageCrop } = this.state;
    const routes = [
      {
        path: `/project/canvas/${this.props.params.id}`,
        breadcrumbName: "Workflow Canvas",
      },
      {
        breadcrumbName: "Form Builder",
      },
    ];

    return (
      <FormContext.Consumer>
        {({
          settings,
          formLink,
          formExport,
          formEmbed,
          componentSettings,
          styling,
        }) => {
          const [openSettings, setOpenSettings] = settings;
          const [openFormLink, setOpenFormLink] = formLink;
          const [openExport, setOpenExport] = formExport;
          const [openEmbed, setOpenEmbed] = formEmbed;
          const [
            openComponentSettings,
            setOpenComponentSettings,
          ] = componentSettings;
          const [openUploadDialog, setOpenUploadDialog] = styling;

          return (
            <Grid container>
              {this.state && !this.state.loading ? (
                <Grid item xs>
                  <Grid container item direction="column" xs={12}>
                    <ValidationErrors
                      open={this.state.errorDialog}
                      close={() => {
                        this.setState({ errorDialog: false, onBack: false });
                      }}
                      errors={this.state.errors}
                      save={() => {
                        if (this.state.onBack) {
                          let path = `/project/canvas/${this._project_id}`;
                          this.props.navigate(path);
                        }
                        this.setState({ errorDialog: false });
                        this.SaveTrigger["triggerValue"] =
                          this.SaveTrigger["triggerValue"] === 1 ? 0 : 1;
                      }}
                    />

                    <FormEmbedDialog
                      openEmbed={openEmbed}
                      setOpenEmbed={setOpenEmbed}
                      generateCode={this.generateCode}
                      iframeLink={this.state.iframeLink}
                      embedHeight={this.state.embedHeight}
                      embedWidth={this.state.embedWidth}
                      setEmbedHeight={(height) => {
                        this.setState({ embedHeight: height });
                      }}
                      setEmbedWidth={(width) => {
                        this.setState({ embedWidth: width });
                      }}
                    />

                    <FormComponentSettings
                      isForm={true}
                      openComponentSettings={openComponentSettings}
                      setOpenComponentSettings={setOpenComponentSettings}
                      exposeMeta={this.state.exposeMeta}
                      prefixLabel={this.state.prefixLabel}
                      formSubmittedDateFormat={
                        this.state.formSubmittedDateFormat
                      }
                      prefixLabelErrorMsg={this.state.prefixLabelErrorMsg}
                      formId={this.state.form_id}
                      setExposeMeta={(val) => {
                        this.setState({ exposeMeta: val });
                      }}
                      setPrefixLabel={(val) => {
                        this.setState({ prefixLabel: val });
                      }}
                      setPrefixLabelErrorMsg={(val) => {
                        this.setState({ prefixLabelErrorMsg: val });
                      }}
                      handleSubmit={this.handleSubmit}
                      setFormSubmittedDateFormat={(val) => {
                        this.setState({ formSubmittedDateFormat: val });
                      }}
                    />

                    <FormLinkDialog
                      openFormLink={openFormLink}
                      setOpenFormLink={setOpenFormLink}
                      formId={this.state.form_id}
                    />
                    <FormSettingsDialog
                      isForm={true}
                      openSettings={openSettings}
                      setOpenSettings={(val) => setOpenSettings(val)}
                      linkedComponent={this.state.linkedComponent}
                      setLinkedComponents={(val) =>
                        this.setState({ linkedComponents: val })
                      }
                      setLinkedComponent={(val) =>
                        this.setState({ linkedComponent: val })
                      }
                      relatedSequentialForm={this.state.relatedSequentialForm}
                      linkedComponents={this.state.linkedComponents}
                      submMsg={this.state.submMsg}
                      setSubmMsg={(val) => this.setState({ submMsg: val })}
                      canEditSubmission={this.state.canEditSubmission}
                      setCanEditSubmission={(val) =>
                        this.setState({ canEditSubmission: val })
                      }
                      canReadOnly={this.state.canReadOnly}
                      setCanReadOnly={(val) =>
                        this.setState({ canReadOnly: val })
                      }
                      canAddSubmission={this.state.canAddSubmission}
                      setCanAddSubmission={(val) =>
                        this.setState({ canAddSubmission: val })
                      }
                      canSaveSubmission={this.state.canSaveSubmission}
                      setCanSaveSubmission={(val) =>
                        this.setState({ canSaveSubmission: val })
                      }
                      autoSave={this.state.autoSave}
                      setAutoSave={(val) => this.setState({ autoSave: val })}
                      canAnnotateContent={this.state.canAnnotateContent}
                      setCanAnnotateContent={(val) =>
                        this.setState({ canAnnotateContent: val })
                      }
                      canSubmitSubmission={this.state.canSubmitSubmission}
                      setCanSubmitSubmission={(val) =>
                        this.setState({ canSubmitSubmission: val })
                      }
                      handleCancelSettings={() =>
                        this.handleCancelSettings(setOpenSettings)
                      }
                    />

                    <CustomDialog
                      size={
                        FormBuilderContainer.editTarget.type === 15
                          ? "xl"
                          : "md"
                      }
                      zIndex={true}
                      isOpen={FormBuilderContainer.onEdit}
                      key={FormBuilderContainer.editTarget.id}
                      onClose={() => {
                        FormBuilderContainer.onEdit = false;
                        FormBuilderContainer.reloadEditComponent();
                      }}
                      title={FormBuilderContainer.editTarget.text}
                      contents={
                        <ComponentConfig
                          availablePlaceholders={
                            this.state.innerFormPlaceholders
                          }
                          ref={this.Config}
                          key={FormBuilderContainer.editTarget.id}
                          handleKeyChanges={(error) => {
                            this.setState({ error });
                          }}
                          setInnerPlaceholders={this.setInnerPlaceholders}
                        />
                      }
                      buttons={
                        <>
                          <DiscardChangesButton disabled={this.state.error || FormBuilderContainer.onSave||this.state.isInAIDraft} />
                          <Button
                            disabled={
                              this.state.error || FormBuilderContainer.onSave
                            }
                            onClick={this.onDone}
                            variant={"contained"}
                            color={"primary"}
                          >
                            Save
                            {FormBuilderContainer.onSave && (
                              <CircularProgress
                                size={24}
                                className="buttonProgress"
                              />
                            )}
                          </Button>
                        </>
                      }
                    />
                    <FormStylingDialog
                      coverImageCrop={this.state.coverImageCrop}
                      coverImageSrc={this.state.coverImageSrc}
                      openUploadDialog={openUploadDialog}
                      setOpenUploadDialog={setOpenUploadDialog}
                      onImageLoaded={this.onImageLoaded}
                      onCropChange={this.onCropChange}
                      onCropComplete={this.onCropComplete}
                      onImageDrop={this.onImageDrop}
                      onDropRejected={this.onDropRejected}
                      uploadedCoverImage={this.state.uploadedCoverImage}
                      errorCoverImageMessage={this.state.errorCoverImageMessage}
                      uploadedLogo={this.state.uploadedLogo}
                      errorLogoMessage={this.state.errorLogoMessage}
                      loadingUpload={this.state.loadingUpload}
                      deleteCoverImage={this.deleteCoverImage}
                      onLogoDrop={(files) => this.onLogoDrop(files)}
                      setUploadedLogo={(value) =>
                        this.setState({ uploadedLogo: value })
                      }
                      setUploadIcon={(value) =>
                        this.setState({ uploadIcon: value })
                      }
                    />

                    <FormExportDialog
                      openExport={openExport}
                      setOpenExport={setOpenExport}
                      exportType={this.state.exportType}
                      setExportType={(val) => {
                        this.setState({ exportType: val.exportType });
                      }}
                      exportFormDataAsCsv={this.exportFormDataAsCsv}
                      exportFormDataAsXLSX={this.exportFormDataAsXLSX}
                    />
                    <Grid
                      container
                      xs={12}
                      direction={"row"}
                      spacing={2}
                      style={{ padding: "0px" }}
                    >
                      <Grid item xs={9}>
                        <Target
                          coverImage={coverImageSrc}
                          coverImageCrop={coverImageCrop}
                          linkedComponent={this.state.linkedComponent}
                          launchSettingDialog={() => {
                            setOpenSettings(true);
                          }}
                          setInnerPlaceholders={this.setInnerPlaceholders}
                          uploadIcon={this.state.uploadIcon}
                          formTitle={ProjectStore.ComponentName.value}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ComponentsSidebar />
                      </Grid>
                      <Snackbar
                        open={
                          ProjectStore.state.production &&
                          !ProjectStore.state.canvasView
                        }
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                      >
                        <Alert
                          icon={<></>}
                          variant={"filled"}
                          style={{ backgroundColor: "#303030" }}
                        >
                          🔒 This is a view of the production workflow. It
                          cannot be edited and changes will not be saved.
                        </Alert>
                      </Snackbar>
                      <Snackbar
                        open={ProjectStore.state.canvasView}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                      >
                        <Alert
                          icon={<></>}
                          variant={"filled"}
                          style={{ backgroundColor: "#303030" }}
                        >
                          🔒 You are viewing version{" "}
                          {ProjectStore.publishedVersion}. This workflow has
                          been published and cannot be edited.
                        </Alert>
                      </Snackbar>
                    </Grid>
                  </Grid>

                </Grid>
              ) : (
                <IndividualComponentLoader />
              )}
        
            </Grid>

          );
        }}
      </FormContext.Consumer>
    );

  }
}

export default withParams(inject("ProjectStore")(observer(FormBuilderPage)));
