import React, { Component } from "react";
import { Grid, TextField, InputAdornment, Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import { observer, inject } from "mobx-react";
import { decorate, observable, action, toJS } from "mobx";
import SearchIcon from "@mui/icons-material/Search";
import { send_request } from "../../utils/Request";
import FadeIn from "react-fade-in";
import DeleteDialog from "./DeleteDialog";
import ImportDialog from "./ImportDialog";
import MarketplaceCard from "./MarketplaceCard";
import PreviewStore from "../ProjectCanvas/PreviewStore";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import "./MarketplacePage.css";
import LinkDialog from "./LinkDialog";
import Tour from "reactour";
import { MarketplacePageTourSteps } from "../../utils/ProductTour";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import CategorySelect from "./CategorySelect";
import { withParams } from "../../Routes";
const styles = (theme) => ({});
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)({
  marginLeft: '8px',
});

const StyledHeader = styled('h3')({
  margin: '16px 24px 0 24px',
});

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: '8px',
  '& .MuiGrid-item': {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

class MarketplacePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importsv: false,
      request: false,
      importSuccess: false,
      tab: 0,
      allTemplates: [],
      dataImport: null,
      dataDelete: null,
      confirmDelete: false,
      loadImport: false,
      searchTemplates: [],
      searchPrivateTemplates: [],
      disableRemove: false,
      openLinkDialog: false,
      activeTemplate: {},
      loading: false,
      cloneProjectId: "",
      categorySelected: 0
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    ProjectStore.closeWelcomePageTour();
    ProjectStore.closeProjectPageTour();
    ProjectStore.closeCanvasPageTour();
    ProjectStore.closePortalPageTour();
    ProjectStore.closeCredentialPageTour();
    const allTemplates = await this.requestMarketplace();
    this.setState({
      allTemplates: allTemplates,
      searchTemplates: allTemplates,
      loading: false
    });

    if (this.props.PreviewStore.download) {
      this.setState({ dataImport: toJS(this.props.PreviewStore.template) });

      this.requestTemplateToProject();
    }
  }

  requestMarketplace = async () => {
    const json = await send_request(
      "project-service/template/getProjectTemplates",
      "",
      {}
    ).catch((err) => {
      let url = "/ ";

      window.open(url, "_self");
      throw Error(err.message);
    });

    if (json && json.data) return json.data;
    return [];
  };

  handleChange = (event, newValue) => {
    this.setState({ tab: newValue });
  };

  requestTemplateToProject = async () => {
    this.setState({ loadImport: true });
    const { dataImport } = this.state;
    if (!dataImport) return false;

    const requestData = {
      projectId: dataImport.projectId,
      version: dataImport.version
    };
    const { importSuccess, importsv } = this.state;
    const json = await send_request(
      "project-service/template/templatetoproject",
      requestData,
      "",
      "POST"
    ).catch((err) => {
      throw Error(err.message);
      this.setState({ loadImport: false });
    });

    if (json && json.status == 200) {
      this.setState({
        importSuccess: !importSuccess,
        importsv: !importsv,
        cloneProjectId: json.data,
        loadImport: false
      });
      const allTemplates = await this.requestMarketplace();

      this.setState({
        allTemplates: allTemplates,
        searchTemplates: allTemplates
      });

      PreviewStore.download = false;
      this.props.navigate(`/project/canvas/${this.state.cloneProjectId}`);
      
      return true;
    }
    return false;
  };

  requestDeleteTemplate = async () => {
    const { dataDelete } = this.state;
    if (!dataDelete) return false;

    const requestData = {
      projectId: dataDelete.projectId,
      version: dataDelete.version
    };
    this.setState({ disableRemove: true });
    const json = await send_request(
      "project-service/template/delete",
      requestData,
      "",
      "POST"
    ).catch((err) => {
      throw Error(err.message);
    });

    if (json && json.status == 200) {
      const allTemplates = await this.requestMarketplace();

      this.setState({
        allTemplates: allTemplates,
        searchTemplates: allTemplates,
        disableRemove: false
      });

      return true;
    }
    return false;
  };

  confirmDelete = async () => {
    const { confirmDelete } = this.state;
    const del = await this.requestDeleteTemplate();
    if (del) {
      const data = await this.requestMyTemplates();
      this.setState({
        confirmDelete: !confirmDelete
      });
    }
    this.componentDidMount();
  };

  handleCategoryChange = (category) => {
    this.setState({ categorySelected: category });

    // Searches all categories
    if (category === 0) {
      this.setState({ allTemplates: this.state.searchTemplates });
      return;
    }

    const allTemplates = this.state.searchTemplates.filter(
      (item) => item.categories && item.categories.includes(category)
    );

    this.setState({ allTemplates });
  };

  handleSearch = (e) => {
    e.preventDefault();
    const { searchTemplates } = this.state;
    const word = e.target.value.toLowerCase();

    searchTemplates.map((i) =>
      i.name === null ? (i.name = "Untitled") : (i.name = i.name)
    );
    const allTemplates = this.state.searchTemplates.filter(
      (item) =>
        (item.name.toLowerCase().includes(word) ||
          item.author.toLowerCase().includes(word)) &&
        (this.state.categorySelected !== 0
          ? item.categories &&
            item.categories.includes(this.state.categorySelected)
          : true)
    );

    this.setState({
      allTemplates: allTemplates
    });
  };

  render() {
    const {
      importsv,
      request,
      importSuccess,
      tab,
      allTemplates,
      confirmDelete
    } = this.state;
    if (!this.state.loading) {
      return (
        <Grid container direction={"column"}>
          <Tour
            steps={MarketplacePageTourSteps}
            isOpen={ProjectStore.isMarketplacePageTour}
            onRequestClose={ProjectStore.closeMarketplacePageTour}
          />
          <StyledHeader>Workflow templates</StyledHeader>
          <Grid container direction="row" xs={12} className="templateSection">
            <CategorySelect
              changeCategory={this.handleCategoryChange}
              currentCategory={this.state.categorySelected}
              categories={ProjectStore.state.workflowCategories}
            />
            <div className="template-tabs">
              <div
                data-tut="reactour__marketplace__search"
                className="template-title-search"
              >
                <StyledTextField
                  id="outlined-start-adornment"
                  fullWidth
                  className="search-text-field"
                  placeholder="Search for a template"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  onChange={this.handleSearch}
                />
              </div>
              <StyledGrid container spacing={3}>
                {allTemplates &&
                  allTemplates.map((template, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <FadeIn>
                        <MarketplaceCard template={template} />
                      </FadeIn>
                    </Grid>
                  ))}
              </StyledGrid>
            </div>
          </Grid>
          <DeleteDialog
            disableRemove={this.state.disableRemove}
            confirmDelete={confirmDelete}
            delete={() => {
              this.confirmDelete();
            }}
            close={() => {
              this.setState({ confirmDelete: !confirmDelete });
            }}
          />
          <LinkDialog
            open={this.state.openLinkDialog}
            id={this.state.activeTemplate.projectId}
            handleClose={() => {
              this.setState({ openLinkDialog: false });
            }}
          />
          <ImportDialog
            importSuccess={importSuccess || this.state.loadImport}
            dataImport={this.state.dataImport}
            importsv={importsv}
            loadImport={this.state.loadImport}
            handleCancel={() => this.setState({ importsv: !importsv })}
            importTemplateToProject={() => {
              this.requestTemplateToProject();
            }}
            close={() => {
              this.setState({ importsv: !importsv });
            }}
            close2={() => {
              this.setState({ importSuccess: !importSuccess });
            }}
            goToProjects={() => {
              this.setState({ importSuccess: !importSuccess });
              PreviewStore.download = false;
              this.props.navigate(
                "/project/canvas/" + this.state.cloneProjectId
              );
            }}
            handleDone={() => {
              this.setState({ importSuccess: !importSuccess });
              PreviewStore.download = false;
            }}
          />
        </Grid>
      );
    } else {
      return showLoadingScreen();
    }
  }
}

export default withParams(
  withStyles(styles)(
    inject("PreviewStore")(
      observer(
        decorate(MarketplacePage, {
          downloadTemplate: action,
          download: observable,
          template: observable
        })
      )
    )
  )
);