import React, { Component } from "react";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import { CircularProgress, Grid, Switch, TextField } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { toJS } from "mobx";
import HandleJsonFunction from "../../Component/HandleJsonFunction";
import { send_component_save_request, send_request } from "../../../utils/Request";
import { styled } from "@mui/system";
import WarningBox from "../../pages/CommonStyledComponents/WarningBox";
import { Select, MenuItem } from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import CustomizeResponse from "./CustomizeResponse";
import { T } from "antd/lib/upload/utils";
import WebhookImportStore from "../WebhookImportStore";
import { getCurrentTime } from "../../../utils/getCurrentTime";

const WarningGridItem = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

class UploadSample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      errorMessage: "",
      status: this.props.status,
      devCustomResponse:
        WebhookImportStore.data.dev && WebhookImportStore.data.dev.response
          ? WebhookImportStore.data.dev.response
          : false,
      devCustomStatus:
        WebhookImportStore.data.dev && WebhookImportStore.data.dev.status
          ? WebhookImportStore.data.dev.status
          : 200,
      devCustomDataType:
        WebhookImportStore.data.dev && WebhookImportStore.data.dev.dataType
          ? WebhookImportStore.data.dev.dataType
          : "JSON",
      devCustomHeaders:
        WebhookImportStore.data.dev && WebhookImportStore.data.dev.headers
          ? WebhookImportStore.data.dev.headers
          : [{ key: "key", value: "value" }],
      devCustomBody:
        WebhookImportStore.data.dev && WebhookImportStore.data.dev.body
          ? WebhookImportStore.data.dev.body
          : '{"key": "value"}',

      sampleReceived: false,
      requireSave: false,
    };
  }

  componentDidMount = () => {
    if (this.props.componentStore.data.type === "send_a_sample_json") {
      this.hanldeSampling(this.props.project_id, this.props.component_id);
    }
  };

  getSample = async (component_id, version) => {
    this.setState({ loading: true, error: false });
    let found = false;
    let error = false;
    let count = 0;
    //poll for sample
    while (!error && !found && count < 30) {
      setTimeout(async () => {
        await send_request(
          "project-service/project/component/query/" +
            component_id +
            "/at-version/" +
            version,
          "",
          {}
        )
          .then((response) => {
            if (response && response.data) {
              const { components } = response.data;
              if (components) {
                if (components[0].componentData.sample) {
                  this.setState({ sampleReceived: true });
                  let sampleWebhook = components[0].componentData.sample
                    ? components[0].componentData.sample
                    : {};
                  sampleWebhook["headers"] =
                    components[0].componentData.headers;
                  if (this.isJsonString(JSON.stringify(sampleWebhook))) {
                    this.props.componentStore.setField(
                      JSON.stringify(sampleWebhook),
                      "",
                      "sample"
                    );
                    this.setState({ loading: false });
                    found = true;
                    this.props.onChangeState(2, 2);
                  } else {
                    error = true;
                    this.setState({
                      error: true,
                      loading: false,
                    });
                  }
                } else {
                  error = true;
                  this.setState({
                    error: true,
                    errorMessage:
                      "Sample data could not be found. Please try again by sending another sample.",
                    loading: false,
                  });
                }
              }
            }
          })
          .catch((err) => {
            error = true;
            this.setState({
              error: true,
              errorMessage:
                "Sample data could not be found. Please try again by sending another sample.",
              loading: false,
            });
          });
      }, 3000);
      count++;
    }
  };

  hanldeSampling = async (projectId, componentId) => {
    send_request(
      `project-service/project/set-sampling-status/${projectId}/${componentId}`,
      "",
      "",
      "PATCH"
    )
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({
            status: "SAMPLING",
          });
          this.setState({ requireSave: false, loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          error: true,
          errorMessage:
            "Sample data could not be found. Please try again by sending another sample.",
          loading: false,
        });
      });
  };

  handleCustomResponseSwitch = () => {
    WebhookImportStore.data.dev = {
      response: !this.state.devCustomResponse,
    };
    this.setState({
      devCustomResponse: !this.state.devCustomResponse,
      requireSave: true,
    });
  };

  handleSaveComponentData = async () => {
    this.setState({ loading: true });
    WebhookImportStore.data.dev = {
      response: this.state.devCustomResponse,
      status: this.state.devCustomStatus,
      dataType: this.state.devCustomDataType,
      headers: this.state.devCustomHeaders,
      body: this.state.devCustomBody,
    };

    let lastModified = getCurrentTime();
    let dataSave = toJS(WebhookImportStore).data;

    let data = {
      componentData: {
        data: dataSave,
        name: toJS(this.props.ComponentName),
        lastModified: lastModified,
      },
      status:"SAMPLING",
      componentId: this.props.component_id,
      type: WebhookImportStore.template.type,
    };

    await send_component_save_request("component-service/webhook/data", data, "", "POST", this.props.SaveTrigger)
      .then(async (res) => {
        this.hanldeSampling(this.props.project_id, this.props.component_id);

      })
      .catch((e) => console.log(e));
  };

  changeMethod = () => {
    const { method, component_id, isExp } = this.props;
    const { loading, error, errorMessage } = this.state;
    const { data, name, errorJsonMessage } = toJS(this.props.componentStore);

    switch (method) {
      case "send_a_sample_json":
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <span>
                Send a sample from your webhook source to the Sample Endpoint
                URL below and then click Find Sample
              </span>
            </Grid>

            <Grid item xs={12}>
              <TextField
                value={
                  "https://webhook.workflow86.com/webhook/catch-sample/" +
                  component_id
                }
                className={`key_padding ${isExp && "textFieldWebhook"}`}
                variant="outlined"
                disabled={true}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard
                        text={
                          "https://webhook.workflow86.com/webhook/catch-sample/" +
                          component_id
                        }
                      >
                        <FileCopyIcon className="custom-icon-btn" />
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Switch
                    checked={this.state.devCustomResponse}
                    onChange={this.handleCustomResponseSwitch}
                  />
                </Grid>
                <Grid item>
                  <p className="bold">Customize response (optional)</p>
                </Grid>
              </Grid>
              
              {this.state.devCustomResponse && (
                <CustomizeResponse
                  editStatus={(val) => {
                    this.setState({
                      devCustomStatus: val,
                      requireSave: true,
                    });
                  }}
                  editBody={(val) => {
                    this.setState({ devCustomBody: val, requireSave: true });
                  }}
                  editDataType={(val) => {
                    this.setState({
                      devCustomDataType: val,
                      requireSave: true,
                    });
                  }}
                  status={this.state.devCustomStatus}
                  dataType={this.state.devCustomDataType}
                  headers={this.state.devCustomHeaders}
                  body={this.state.devCustomBody}
                  addHeader={() => {
                    this.state.devCustomHeaders.push({
                      key: "key",
                      value: "value",
                    });
                    this.setState({
                      devCustomHeaders: this.state.devCustomHeaders,
                      requireSave: true,
                    });
                  }}
                  editHeader={(value, index, type) => {
                    this.state.devCustomHeaders[index][type] = value;
                    this.setState({
                      devCustomHeaders: this.state.devCustomHeaders,
                      requireSave: true,
                    });
                  }}
                  deleteHeader={(index) => {
                    this.state.devCustomHeaders.splice(index, 1);
                    this.setState({
                      devCustomHeaders: this.state.devCustomHeaders,
                      requireSave: true,
                    });
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <WarningBox
                text="Do not navigate away from this step until the sample has been captured"
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    onClick={() => this.props.onChangeState(0, 0)}
                    variant="outlined"
                    color="info"
                  >
                    Back
                  </Button>
                </Grid>
                
                {this.props.componentStore.data.samplePlaceholders[0].sampleData !== "" && (
                  <Grid item>
                    <Button
                      onClick={() => this.props.onChangeState(2, 2)}
                      variant="outlined"
                      color="info"
                    >
                      Skip
                    </Button>
                  </Grid>
                )}

                {this.state.requireSave && (
                  <Grid item>
                    <Button
                      onClick={this.handleSaveComponentData}
                      disabled={loading}
                      variant="contained"
                      color="primary"
                    >
                      Save changes
                      {loading && (
                        <CircularProgress size={24} className="buttonProgress" />
                      )}
                    </Button>
                  </Grid>
                )}

                {this.state.status === "SAMPLING" && !this.state.requireSave && (
                  <Grid item>
                    <Button
                      onClick={() => this.getSample(component_id, this.props.version)}
                      disabled={loading || this.state.requireSave}
                      variant="contained"
                      color="primary"
                    >
                      Find Sample
                      {loading && (
                        <CircularProgress size={24} className="buttonProgress" />
                      )}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {loading && (
              <Grid item xs={12}>
                <span>🔍 Searching for sample data...</span>
              </Grid>
            )}

            {error && (
              <Grid item xs={12}>
                <p className="err-text">
                  <ErrorIcon className="iconDiscard" />
                  {errorMessage}
                </p>
              </Grid>
            )}
          </Grid>
        );
      case "upload_sample_json":
        return (
          <Grid
            item
            xs={12}
            className="textAlignLeft"
            style={{ padding: 0 }}
          >
            <span>
              Copy and paste the sample JSON below and then click upload sample{" "}
            </span>
            <br />
            <TextField
              id="outlined-textarea"
              placeholder="Copy and paste sample JSON here"
              multiline
              defaultValue={""}
              value={data.json}
              onChange={(e) =>
                this.props.componentStore.onChangeField(
                  e.target.value,
                  "",
                  "json"
                )
              }
              className="json-textField-webhook"
              margin="normal"
              variant="outlined"
            />
            {error ? (
              <p className="err-text-left">
                <ErrorIcon className="iconDiscard" />
                {errorJsonMessage ? errorJsonMessage : errorMessage}
              </p>
            ) : (
              errorJsonMessage && (
                <p className="err-text-left">
                  <ErrorIcon className="iconDiscard" />
                  {errorJsonMessage ? errorJsonMessage : ""}
                </p>
              )
            )}
            <Grid item xs={12} className="dialogContentLeft">
              <Grid item xs={12}>
                <Button
                  onClick={() => this.props.onChangeState(0, 0)}
                  className="btnSpace"
                  variant={"outlined"}
                  color={"info"}
                >
                  {" "}
                  Back
                </Button>
                {this.props.componentStore.data.samplePlaceholders[0]
                  .sampleData !== "" && (
                  <Button
                    onClick={() => this.props.onChangeState(2, 2)}
                    className="btnSpace"
                    variant={"outlined"}
                    color={"info"}
                  >
                    {" "}
                    Skip
                  </Button>
                )}
                <Button
                  onClick={() => this.handleJson()}
                  variant={"contained"}
                  color={"primary"}
                >
                  {" "}
                  Upload Sample
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
    }
  };

  handleJson = () => {
    const { data, name } = toJS(this.props.componentStore);

    if (this.isJsonString(data.json)) {
      this.props.componentStore.setErrorMessage("");
      this.props.onChangeState(2, 2);
    } else {
      this.setState({ error: true });
    }
  };

  isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (err) {
      this.setState({ errorMessage: "Error with sample: " + err.message });
      return false;
    }
    return true;
  };

  render() {
    return this.changeMethod();
  }
}

export default UploadSample;
