import React from 'react';
import Grid from "@mui/material/Grid";
import { DashboardCard } from './CommonComponents';

import "../stylesheet/dashboardPage.css";

import { Link } from "react-router-dom";
import "../Achievement/AchievementStyle.css";
import AchievementCard from "../Achievement/AchievementCard";
import AchievementCardCompleted from '../Achievement/AchievementCardCompleted';

function AchievementsCard(props) {
  const { allAchievementCompleted, achievements, achievementsAll } = props;

  let achievementsCardComponent = (
    <DashboardCard container>
      <Grid className="achieve-header">Achievements</Grid>
      <Grid className="achieve-title">
        <Link
          to="/achievements"
          style={{ textDecoration: "none" }}
          target="_blank"
        >
          Complete tasks to unlock Workflow86 upgrades
        </Link>
      </Grid>

      {allAchievementCompleted ? (
        <AchievementCardCompleted />
      ) : (
        achievements.map((achievement) => {
          if (
            achievementsAll.filter(
              (a) => a.achievementId === achievement.id
            ).length > 0
          ) {
            return <AchievementCard key={achievement.id} data={achievement} />;
          }
          return null;
        })
      )}
    </DashboardCard>
  );

  return achievementsCardComponent;
}

export default AchievementsCard;
