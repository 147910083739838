import axios from "axios";
import config from "../config";
import { fetchAuthSession, signOut } from '@aws-amplify/auth';

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (
      err.response !== undefined &&
      err.response.status == 401 &&
      err.response.data["authorisationUrl"] !== undefined
    ) {
      
    }
    
    throw err;
  }
);

export async function send_request_graphql(path, query) {
  const url = `${config.API_ADDRESS}/${path}`;
  let header = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  try {
    const { tokens } = await fetchAuthSession();
    header["Authorization"] = tokens.idToken.toString();

    const request = await axios(url, {
      headers: header,
      method: "POST",
      data: { query: query },
    })
      .then(
        (res) => {
          return res;
        },
        (rej) => {
          throw rej;
        }
      )
      .catch(async (error) => {
        throw error;
      });
    return request;
  } catch (err) {
    console.log(err);
    if (
      err === "No current user" ||
      (err.code && err.code === "NotAuthorizedException")
    ) {
      //In case no user found exception is thrown
      await signOut();
      console.log("graceful sign out on UI");
    }
  }
}

export async function send_request_prebuilt_graphql(path, query) {
  const url = `${config.API_ADDRESS}/${path}`;
  let header = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  try {
    const { tokens } = await fetchAuthSession();
    header["Authorization"] = tokens.idToken.toString();

    const request = await axios(url, {
      headers: header,
      method: "POST",
      data: { query: query.query, variables: query.variables },
    })
      .then(
        (res) => {
          return res;
        },
        (rej) => {
          throw rej;
          // return rej;
        }
      )
      .catch(async (error) => {
        throw error;
      });
    return request;
  } catch (err) {
    console.log(err);
    if (
      err === "No current user" ||
      (err.code && err.code === "NotAuthorizedException")
    ) {
      //In case no user found exception is thrown
      await signOut();
      console.log("graceful sign out on UI");
    }
    throw err;
  }
}

export async function send_request_graphql_mutation(path, query) {
  const url = `${config.API_ADDRESS}/${path}`;
  let header = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  try {
    const { tokens } = await fetchAuthSession();
    header["Authorization"] = tokens.idToken.toString();

    const request = await axios(url, {
      headers: header,
      method: "POST",
      data: { query: query.query, variables: query.variables },
    })
      .then(
        (res) => {
          return res;
        },
        (rej) => {
          throw rej;
          // return rej;
        }
      )
      .catch(async (error) => {
        throw error;
      });
    return request;
  } catch (err) {
    console.log(err);
    if (
      err === "No current user" ||
      (err.code && err.code === "NotAuthorizedException")
    ) {
      //In case no user found exception is thrown
      await signOut();
      console.log("graceful sign out on UI");
      throw err;
    }
    throw err;

  }
}

export async function send_request_templates(
  path,
  method,
  header = { Accept: "application/json", "Content-Type": "application/json" }
) {
  const url = `${config.TEMPLATES_API_ADDRESS}/${path}`;
  const request = await axios(url, {
    method: method,
    headers: header,
  })
    .then(
      (res) => {
        return res;
      },
      (rej) => {
        throw rej;
        // return rej;
      }
    )
    .catch(async (error) => {
      throw error;
    });
  return request;
}

export async function send_request(
  path,
  bodydata,
  querydata,
  method,
  header = { Accept: "application/json", "Content-Type": "application/json" },
  responseType,
  onUploadProgress = null
) {
  let url = `${config.API_ADDRESS}/${path}`;

  if (querydata) {
    url = url + "?" + serializeData(querydata);
  }
  try {
    const { tokens } = await fetchAuthSession();
    header["Authorization"] = tokens.idToken.toString();
    const request = await axios(url, {
      method: method,
      data: bodydata,
      headers: header,
      responseType: responseType,
      onUploadProgress,
      timeout: 28000,
    })
      .then(
        (res) => {
          return res;
        },
        (rej) => {
          throw rej;
        }
      )
      .catch(async (error) => {
        throw error;
      });
    return request;
  } catch (err) {
    if (
      err === "No current user" ||
      (err.code && err.code === "NotAuthorizedException")
    ) {
      //In case no user found exception is thrown
      await signOut();
      window.location.pathname = "/";
    }
    return err;
  }
}

/**
 * Function used for performing the component save query
 * for individual component templates
 * 
 * Here, you are able to attach additional data to the payload
 * in one location
 */
export const send_component_save_request = async (
  path,
  bodydata,
  querydata,
  method,
  saveTrigger
) => {
  let data = null; // Init data object

  // If we're given the bodydata, copy the properties over
  if (bodydata) {
    data = {
      ...bodydata,
    }
  }

  // Handle data manipulation here
  if (saveTrigger) {

    if (saveTrigger.sessionId) {
      data["sessionId"] = saveTrigger.sessionId; // Add the session id to the data map
      delete saveTrigger["sessionId"]; // Remove it from the save trigger
    }
  }

  return send_request(path, data, querydata, method);
}

export async function send_request_without_auth(
  path,
  bodydata,
  querydata,
  method,
  header = { Accept: "application/json", "Content-Type": "application/json" },
  responseType
) {
  let url = `${config.API_ADDRESS}/${path}`;

  if (querydata) {
    url = url + "?" + serializeData(querydata);
  }
  const request = await axios(url, {
    method: method,
    data: bodydata,
    headers: header,
    responseType: responseType,
  })
    .then(
      (res) => {
        return res;
      },
      (rej) => {
        console.log(rej);
        throw rej;
        // return rej;
      }
    )
    .catch((error) => {
      throw error;
    });
  return request;
}

export async function send_external_request(
  path,
  bodydata,
  querydata,
  method,
  header = { Accept: "application/json", "Content-Type": "application/json" }
) {
  let url = `${path}`;

  if (querydata) {
    url = url + serializeData(querydata);
  }

  const request = axios(url, {
    method: method,
    data: bodydata,
    headers: header,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
  return request;
}

function serializeData(formBodyObj) {
  const requestBody = Object.keys(formBodyObj)
    .map((key) => {
      return (
        encodeURIComponent(key) + "=" + encodeURIComponent(formBodyObj[key])
      );
    })
    .join("&");

  return requestBody;
}
