import React, { useEffect, useState } from "react";

// Material UI
import { Grid, styled } from "@mui/material";

const Box = styled(Grid)({
    border: "1px solid rgba(255, 214, 0, 1)",
    backgroundColor: "rgba(251, 246, 190, 1)",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 0px",
    gap: "16px",
    marginTop: "16px",
});

const PricingText = styled("span")({
    fontSize: "24px",
});

function SavingsBox({ prices, tier, newUserQuantity, newEndUserQuantity }) {
    const [savings, setSavings] = useState(null);

    useEffect(() => {
        let { user, userAnnual, endUser, endUserAnnual } = prices; // Grab prices

        // Calculate each
        let userPriceMonthly = calcAdminEditorPrice(user) * 12;
        let userPriceAnnually = calcAdminEditorPrice(userAnnual);
        if (userPriceMonthly === 0 || userPriceAnnually === 0) userPriceMonthly, userPriceAnnually = 0;
        
        let endUserPriceMonthly = calcEndUserPrice(endUser) * 12;
        let endUserPriceAnnually = calcEndUserPrice(endUserAnnual);
        if (endUserPriceMonthly === 0 || endUserPriceAnnually === 0) endUserPriceMonthly, endUserPriceAnnually = 0;
        
        // Calculate amount saved from annual vs monthly
        let userAmountSaved = userPriceMonthly - userPriceAnnually + (endUserPriceMonthly - endUserPriceAnnually);

        // Calculate with plan
        let calc = calculateSavings(userAmountSaved);
        setSavings(calc);
    }, [newUserQuantity, newEndUserQuantity]);

    const calculateSavings = (userAmountSaved) => {
        // Calculate the savings for each plan
        let saved = 0;

        switch (tier) {
            case "STARTER_ANNUAL":
                saved = prices.starter * 12 - prices.starterAnnual;
                break;
            case "PRO_ANNUAL":
                saved = prices.pro * 12 - prices.proAnnual;
                break;
            case "PRO_PLUS_ANNUAL":
                saved = prices.proPlus * 12 - prices.proPlusAnnual;
                break;
            default:
                break;
        }

        return parseInt(saved) + parseInt(userAmountSaved); // Return the plan amount saved + the user amount saved
    };

    const calcAdminEditorPrice = (userPrice) => {
        let runningUserQuantity = newUserQuantity;
        let currentTierLimit = 0;

        let calcPrice = 0;

        for (let a = 0; a < userPrice.length && runningUserQuantity > 0; a++) {
            if (userPrice[a].upTo) {
                //there is a limit for the tier
                currentTierLimit = userPrice[a].upTo - currentTierLimit;

                let b = runningUserQuantity < currentTierLimit ? runningUserQuantity : currentTierLimit;

                calcPrice += b * (userPrice[a].unitAmount / 100);
                runningUserQuantity -= b;
            }

            //no limit, most probably the last one
            else calcPrice += runningUserQuantity * (userPrice[a].unitAmount / 100);
        }
        return calcPrice;
    };

    const calcEndUserPrice = (endUserPrice) => {
        let runningEndUserQuantity = newEndUserQuantity;
        let currentTierLimit = 0;

        let calcPrice = 0;

        for (let a = 0; a < endUserPrice.length && runningEndUserQuantity > 0; a++) {
            if (endUserPrice[a].upTo) {
                //there is a limit for the tier
                currentTierLimit = endUserPrice[a].upTo - currentTierLimit;

                let b = runningEndUserQuantity < currentTierLimit ? runningEndUserQuantity : currentTierLimit;

                calcPrice += b * (endUserPrice[a].unitAmount / 100);

                runningEndUserQuantity -= b;
            } else calcPrice += runningEndUserQuantity * (endUserPrice[a].unitAmount / 100);
        }
        return calcPrice;
    };

    const getSavingsBox = () => {
        return (
            <Box container>
                <Grid item xs={12}>
                    💰 Save
                </Grid>
                <Grid item xs={12}>
                    <PricingText>${savings}</PricingText>
                </Grid>
                <Grid item xs={12}>
                    on the annual plan vs monthly plan
                </Grid>
            </Box>
        );
    };

    return savings && getSavingsBox();
}

export default SavingsBox;
