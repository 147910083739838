import React from "react";
import { Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FormPredertermineStore from "../FormConditionStores/FormPredertermineStore";
import { Logic, SelectKey, SelectFuc, SelectValue } from "./ItemComponents";
import FormMentionEditor from "../../../Component/FormMentionEditor";
import SequentialFormContainer from '../../SequentialFormContainer';
import useQuestionData from "../../../../utils/FormUtil";
function ItemCondition(props) {
  const { indexCondition, indexStatement, condition, ConditionList } = props;
  

  const isFromDBOrList = useQuestionData(condition.key, SequentialFormContainer);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {indexCondition > 0 ? (
          <Grid sm={2} item>
            <Logic
              value={condition.logic}
              onChange={(event) => {
                FormPredertermineStore.setCondition(
                  event,
                  indexStatement,
                  indexCondition,
                  "logic"
                );
              }}
            />
          </Grid>
        ) : (
          <Grid
            sm={1}
            item
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: 600
            }}
          >
            {" "}
            {indexStatement > 0 ? "ELSE IF" : "IF"}
          </Grid>
        )}

        <Grid sm={indexCondition > 0 ? 3 : 4} item>
          <SelectKey
            value={condition.key}
            onChange={(event) => {
              FormPredertermineStore.setCondition(
                event,
                indexStatement,
                indexCondition,
                "key"
              );
            }}
          />
        </Grid>
        <Grid sm={3} item>
          {ConditionList ? (
            <SelectFuc
              HasSelected={ConditionList.HasSelected}
              value={condition.function}
              onChange={(event) => {
                FormPredertermineStore.setCondition(
                  event,
                  indexStatement,
                  indexCondition,
                  "function"
                );
              }}
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid sm={indexCondition > 0 ? 3 : indexStatement > 0 ? 3 : 4} item>
          {ConditionList ? (
            (!ConditionList.HasSelected || isFromDBOrList) ? (
              <FormMentionEditor
                style={{
                  width: "100%",
                  marginBottom: 8,
                  verticalAlign: "top",
                  minHeight: "16px"
                }}
                assignTask={true}
                contentBlock={false}
                variant="outlined"
                placeholder="Insert value"
                fullWidth
                className="key_padding"
                text={condition.value}
                hideScrollBar={true}
                onFocus={(e) => {}}
                onChange={(value) => {
                  FormPredertermineStore.setCondition(
                    value,
                    indexStatement,
                    indexCondition,
                    "value"
                  );
                }}
                {...(props.availablePlaceholders != null && {
                  availablePlaceholders: props.availablePlaceholders
                })}
              />
            ) : (
              <SelectValue
                data={ConditionList.dataAnswer}
                value={condition.value}
                onChange={(event) => {
                  FormPredertermineStore.setCondition(
                    event,
                    indexStatement,
                    indexCondition,
                    "value"
                  );
                }}
              />
            )
          ) : (
            ""
          )}
        </Grid>
        {indexCondition > 0 ? (
          <Grid
            sm={1}
            item
            style={{
              display: "flex",
              alignItems: "center",
              color: "rgb(116, 115, 115)"
            }}
          >
            <DeleteIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                FormPredertermineStore.removeCondition(
                  indexStatement,
                  indexCondition
                )
              }
            />
          </Grid>
        ) : indexStatement > 0 ? (
          <Grid
            sm={1}
            item
            style={{
              display: "flex",
              alignItems: "center",
              color: "rgb(116, 115, 115)"
            }}
          >
            <DeleteIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                FormPredertermineStore.removeStatement(indexStatement)
              }
            />
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </React.Fragment>
  );
}

export default ItemCondition;
