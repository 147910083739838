import React, { Component } from "react";
import PropTypes from "prop-types";
import { fade, withStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import { autorun, toJS, reaction, trace } from "mobx";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { ComponentRegistry } from "../ProjectCanvas/ComponentRegistry";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { decorate, observable, action } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Tabs from "@mui/material/Tabs";
import { send_request } from "../../utils/Request";
import Tab from "@mui/material/Tab";
import SequentialFormContainer from "./SequentialFormContainer";

import PlaceholderContainer from "vardogyir-ui/PlaceholderContainer";
import DropZone from "vardogyir-ui/DropZone";
import DragComponent from "./DragComponent";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import { showLoadingScreen } from "../../utils/showLoadingScreen";

const styles = (theme) => ({
  drawer: {},
  drawerPaper: {},
  toolbar: theme.mixins.toolbar,
});

class ComponentsSidebar extends Component {
  constructor(props) {
    super(props);
    this.SelectedPlaceholder = {};
    this._node_id = this.props.nodeId;
    this._project_id = this.props.projectId || undefined;
    this._component_id = this.props.componentId || undefined;

    this.state = {
      placeholders: [],
      oldPlaceholders: [],
      activeTab: 0,
      components: [
        {
          type: 7,
          text: "Calendar",
        },
        {
          type: 5,
          text: "Checkbox",
        },
        {
          type: 11,
          text: "Content Block",
        },
        {
          type: 10,
          text: "Dropdown",
        },
        {
          type: 16,
          text: "Embed video",
        },
        {
          type: 13,
          text: "E-Signature",
        },
        {
          type: 14,
          text: "File preview",
        },
        {
          type: 12,
          text: "List",
        },
        {
          type: 2,
          text: "Long Text",
        },
        {
          type: 3,
          text: "Map",
        },
        {
          type: 1,
          text: "Number Input",
        },
        {
          type: 8,
          text: "Page Break",
        },
        {
          type: 4,
          text: "Radio Button",
        },
        {
          type: 19,
          text: "Repeatable Section"
        },
        {
          type: 17,
          text: "Rich Text Editor",
        },
        {
          type: 9,
          text: "Short Text",
        },
        {
          type: 15,
          text: "Table",
        },
        {
          type: 6,
          text: "Upload File",
        },
      ],
      loading: false,
    };
  }

  setupPlaceholders = (_callback) => {
    this.setState({ loading: true });
    const current_components = this.props.ProjectStore.getCurrentComponents(
      this._node_id
    );
    const placeholder_promises = current_components.map(async (pre_com) => {
      const { type, component_id } = pre_com;
      // const { discoveryEndpoint } = ComponentRegistry.filter(
      //   (ele) => ele.name == type
      // )[0];
      const url = `component-service/placeholder/${this._project_id}/${component_id}`;
      const res = await send_request(url, "", "", "get").catch((err) =>
        console.error(err)
      );
      if (res && res.data) {
        return res.data;
      } else {
        return null;
      }
    });

    Promise.all(placeholder_promises).then((query_placeholders) => {
      let merge_placeholder = [];
      query_placeholders.map((qp) => {
        if (qp) {
          if (qp.data) {
            let result = this.analysisPlaceholder(qp.data);
            if (result) merge_placeholder.push(result.placeholders);
          } else {
            merge_placeholder.push(qp.placeholders);
          }
        }
      });
      let placeholders = [
        {
          placeholders: merge_placeholder.flat(),
        },
      ];
      this.setState({
        placeholders: placeholders,
        oldPlaceholders: placeholders,
        loading: false,
      });
      _callback();
    });
  };
  componentDidMount() {
    this.setState({
      placeholders: SequentialFormContainer.placeholders,
      oldPlaceholders: SequentialFormContainer.placeholders,
      loading: false,
    });
  }
  onPlaceholderSelected = (placeholderUpdateEvent) => {
    SequentialFormContainer.SelectedPlaceholder = placeholderUpdateEvent;
  };
  analysisPlaceholder = (query) => {
    let data = [];
    query.forEach((ele) => {
      if (ele.key) {
        data.push({
          key: ele.key,
          type: "text",
          length: 1,
        });
      }
    });
    if (data.length > 0) {
      return {
        placeholders: data,
      };
    } else {
      return null;
    }
  };
  switchTab = (id) => {
    if (id == "first") this.setState({ activeTab: 0 });
    if (id == "second") this.setState({ activeTab: 1 });
  };

  handleSearch = (e) => {
    const word = e.target.value.toLowerCase();
    const placeholders = this.state.oldPlaceholders
      .flatMap((n) => n.placeholders)
      .filter((item) => item.key.toLowerCase().includes(word));
    this.setState({
      placeholders: [
        {
          placeholders,
        },
      ],
    });
  };

  render() {
    const { placeholders, loading } = this.state;
    const { classes, isFormSection } = this.props;
    const getComponentBar = () => {
      return (
        <div
          style={{
            overflowY: "scroll",
            height: "max-content",
            maxHeight: "90vh",
            borderRadius: "8px",
            padding: "8px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            {this.state.components.map((n) => {
              return <DragComponent item={n} />;
            })}
          </div>
        </div>
      );
    };

    const getSidebar = () => {
      return (
        <div
          style={{
            position: "sticky",
            top: "16px",
            font: "inherit",
            width: "min-content",
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 8px",
            backgroundColor: "#ffffff",
            padding: "16px",
            borderRadius: "6px",
          }}
        >
          {!isFormSection ? (
            <>
              <Tabs
                value={this.state.activeTab}
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab
                  id={"first"}
                  onClick={() => {
                    this.switchTab("first");
                  }}
                  label={"Form Components"}
                />
              </Tabs>
              {getComponentBar()}
            </>
          ) : (
            getComponentBar()
          )}
        </div>
      );
    };
    try {
      if (!loading) {
        return getSidebar();
      } else return showLoadingScreen();
    } catch (e) {
      return <div></div>;
    }
  }
}

ComponentsSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  inject("ProjectStore")(
    observer(
      decorate(ComponentsSidebar, {
        SelectedPlaceholder: observable,
        SaveTrigger: observable,
        onPlaceholderSelected: action
      })
    )
  )
);