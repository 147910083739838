// Part of the sign up flow
// Displays a message informing the user that an email verification link has been sent.
// Provides buttons to open Gmail or Outlook inboxes and a resend button with a loading indicator. 

import React from 'react';
import { Button, CircularProgress, Paper, Typography, Box, styled } from '@mui/material';

const InputArea = styled(Paper)({
  padding: '0px',
  borderRadius: '4px',
  boxShadow: 'none',
});

const InputDescription = styled(Typography)({
  marginBottom: '16px',
  color: '#000',
});

const EmailClients = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  marginBottom: '1rem',
});

const ResendButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
});

const StyledButtonIcon = styled('img')({
  width: '16px !important',
  height: '16px !important',
  minWidth: '16px !important',
  display: 'block',
});

const StyledButton = styled(Button)({
  whiteSpace: 'nowrap',
  width: 'auto',
});

const SignupSuccess = ({ email, loading, handleResend }) => (
  <InputArea elevation={3}>
    <InputDescription variant="body2">
      A link to verify your email address has been sent to {email}. If you do not receive this email, please check your junk and spam folders. Click the Resend Email button to resend the email.
    </InputDescription>

    <EmailClients>
      <StyledButton
        variant="outlined"
        color="primary"
        startIcon={<StyledButtonIcon src="/svgs/googleLogo.svg" alt="google" />}
        onClick={() => window.open('https://mail.google.com/mail/u/0/#inbox', '_blank')}
      >
        Open your Gmail inbox
      </StyledButton>
      <StyledButton
        variant="outlined"
        color="primary"
        startIcon={<StyledButtonIcon src="/svgs/microsoftIcon.svg" alt="microsoft" />}
        onClick={() => window.open('https://outlook.live.com/owa/?nlp=1', '_blank')}
      >
        Open your Outlook inbox
      </StyledButton>
    </EmailClients>

    <ResendButtonContainer>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => window.location.href = '/'}
      >
        Back
      </Button>
      <Button
        variant="outlined"
        type="submit"
        onClick={handleResend}
        disabled={loading}
      >
        Resend Verification
        {loading && <CircularProgress size={24} className="buttonProgress" />}
      </Button>
    </ResendButtonContainer>
  </InputArea>
);

export default SignupSuccess;
