import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import "../WebhookImport/WebhookImport.css";
import Paper from "@mui/material/Paper";
import { send_component_save_request, send_request } from "../../utils/Request";
import { observer, inject } from "mobx-react";
import { autorun, toJS, reaction, trace } from "mobx";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import TriggerFromZapierStore from "./TriggerFromZapierStore";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import ChooseApp from "./component/ChooseApp";
import SetupAction from "./component/SetupAction";
import SkipTest from "./component/SkipTest";
import SendSampleFromZapier from "./component/SendSampleFromZapier";
import SampleReceived from "./component/SampleReceived";
import SetUpPlaceholders from "./component/SetUpPlaceholders";
import SetEndpointUrl from "./component/SetEndpointURL";
import SetToken from "./component/SetToken";
import StepButton from "@mui/material/StepButton";
import Summary from "./component/Summary";
import projectStore from "../ProjectCanvas/ProjectStore";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
class TriggerFromZapierTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholders: [],
      checkKey: false,
      data: {},
      token: "",
      index: 0,
      checkRoutingKey: false,
      loading: false,
      tab: 0,
      open: false,
      error: false,
      placeholdersJson: [],
      generateOpen: false,
      regenerateOpen: false,
      showPassword: false,
      setShowPassword: false,
      loadingCircle: false,
      hasToken: false,
      deleteLoadingCircle: false,
      checkKeyUpload: false,
      indexError: [],
      indexError2: [],
      activeStep: 0,
      webhookStep: 0,
      value: null,
      method: null,
      status: null
    };
  }

  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({ loading: true });
      send_request(
        `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
        "",
        {}
      )
        .then(response => {
          if (response && response.data) {
            const { components } = response.data;
            if (components) {
              TriggerFromZapierStore.setComponentData(components[0]);
              this.props.onComponentDescriptionChanged(components[0].description);
              this.props.onComponentNameChanged(TriggerFromZapierStore.name);
              this.props.onLastModifiedChanged(
                TriggerFromZapierStore.lastModified
              );
              this.setState({
                status: components[0].status
              });
              if (
                components[0].componentData &&
                components[0].componentData.data
              ) {
                if (!components[0].componentData.data.samplePlaceholders) {
                  TriggerFromZapierStore.data.samplePlaceholders = [];
                  for (
                    let i = 0;
                    i < components[0].componentData.data.placeholders.length;
                    i++
                  ) {
                    components[0].componentData.data.placeholders[
                      i
                    ].checked = true;
                    TriggerFromZapierStore.addJsonData(
                      components[0].componentData.data.placeholders[i]
                    );
                  }
                }
                if (components[0].componentData.token) {
                  this.setState({
                    hasToken: true
                  });
                }
                this.setState({
                  placeholders:
                    components[0].componentData.data.samplePlaceholders,
                  method: components[0].componentData.data.type
                });
                if (
                  components[0].componentData.token &&
                  components[0].componentData.data.json != "" &&
                  components[0].componentData.data.samplePlaceholders != []
                ) {
                  this.setState({
                    activeStep: 7,
                    webhookStep: 7
                  });
                }
                this.setState({ loading: false });
              } else {
                let lp = [];
                lp.push({
                  index: 0,
                  placeholders: [],
                  key: TriggerFromZapierStore.keyAuto(),
                  incomingKey: ""
                });
                this.setState({ placeholders: lp, loading: false });
              }
            }
          }
          this.props.setChildTemplateLoaded(true);
        })
        .catch(err => {
          throw Error(err.message);
        });
    }
  }

  setWebhookName = autorun(() => {
    TriggerFromZapierStore.setName(this.props.ComponentName);
  });

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveWebhookImport();
  }

  saveWebhookImport = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      const { placeholderError } = toJS(TriggerFromZapierStore);
      if (this.state.error || placeholderError.length > 0) {
        this.props.showError();
        return;
      }

      let lastModified = getCurrentTime();
      let dataComponent = toJS(TriggerFromZapierStore).data;
      TriggerFromZapierStore.data.placeholders = [];
      for (let i = 0; i < dataComponent.samplePlaceholders.length; i++) {
        if (dataComponent.samplePlaceholders[i].checked == true) {
          TriggerFromZapierStore.data.placeholders.push(
            dataComponent.samplePlaceholders[i]
          );
        }
      }
      let dataSave = toJS(TriggerFromZapierStore).data;
      let data = {
        componentData: {
          data: dataSave,
          name: toJS(this.props.ComponentName),
          lastModified: lastModified
        },
        componentId: this.props.component_id,
        type: TriggerFromZapierStore.template.type,
        description: this.props.ComponentDescription.value
      };
      
      send_component_save_request(
        "component-service/trigger_from_zapier/data",
        data,
        "",
        "POST",
        this.props.SaveTrigger
      )
        .then(response => {
          this.props.onLastModifiedChanged(lastModified);
          this.props.showSuccess(data, this.props.SaveTrigger);
          // this.componentDidMount();
          projectStore.savedComponent = true;
        })
        .catch(err => {
          throw err;
        });
    }
  );

  handleRoutingKeyChange = (event, placeholder, index) => {
    TriggerFromZapierStore.onChangeField(
      event.target.value,
      placeholder,
      "content",
      index
    );
  };

  handleJsonBodyChange = value => {
    TriggerFromZapierStore.onChangeField(value, "", "json");
  };

  handleKeyChange = (event, placeholder) => {
    WebhookImportStore.onChangeField(event.target.value, placeholder, "key");
  };

  handleKeyChanges = error => {
    this.setState({ error });
  };

  onChangeState = (webhookStep, activeStep) => {
    this.setState({
      webhookStep,
      activeStep
    });
  };

  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <ChooseApp
            componentStore={TriggerFromZapierStore}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)
            }
            method={method => this.setState({ method })}
            isExpanded={this.props.isExpanded}
          />
        );
      case 1:
        return (
          <SetupAction
            componentStore={TriggerFromZapierStore}
            status={this.state.status}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)
            }
            changeTokenStatus={status => this.setState({ hasToken: status })}
            hasToken={this.state.hasToken}
            project_id={this.props.project_id}
            component_id={this.props.component_id}
            method={this.state.method}
            isExpanded={this.props.isExpanded}
          />
        );
      case 2:
        return (
          <SendSampleFromZapier
            componentStore={TriggerFromZapierStore}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)
            }
            project_id={this.props.project_id}
            component_id={this.props.component_id}
            version={this.props.version}
            isExpanded={this.props.isExpanded}
          />
        );
      case 3:
        return (
          <SampleReceived
            componentStore={TriggerFromZapierStore}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)
            }
            handleKeyChanges={error => this.setState({ error })}
            method={this.state.method}
            component_id={this.props.component_id}
            isExpanded={this.props.isExpanded}
          />
        );
      case 4:
        return (
          <SetUpPlaceholders
            componentStore={TriggerFromZapierStore}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)
            }
            handleKeyChanges={error => this.setState({ error })}
            method={this.state.method}
            stepStatus={"setPlaceholders"}
            isExpanded={this.props.isExpanded}
          />
        );
      case 5:
        return (
          <SetEndpointUrl
            componentStore={TriggerFromZapierStore}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)
            }
            component_id={this.props.component_id}
            isExpanded={this.props.isExpanded}
          />
        );
      case 6:
        return (
          <SkipTest
            componentStore={TriggerFromZapierStore}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)
            }
            component_id={this.props.component_id}
            isExpanded={this.props.isExpanded}
          />
        );
      case 7:
        return (
          <Summary
            componentStore={TriggerFromZapierStore}
            onChangeState={(webhookStep, activeStep) =>
              this.onChangeState(webhookStep, activeStep)
            }
            changeTokenStatus={status => this.setState({ hasToken: status })}
            name={toJS(this.props.ComponentName)}
            component_id={this.props.component_id}
            stepStatus={"summary"}
            hasToken={this.state.hasToken}
            isExpanded={this.props.isExpanded}
          />
        );
      default:
        return "Unknown step";
    }
  };

  handleOnClickStep = step => {
    this.setState({
      webhookStep: step,
      activeStep: step
    });
  };

  render() {
    const { data, name } = toJS(TriggerFromZapierStore);
    const { activeStep, webhookStep, method } = this.state;
    const steps = [
      "Choose app",
      "Set up action",
      "Test action in Zapier",
      "Sample received",
      "Set up placeholders",
      "Set endpoint URL",
      "Skip test",
      "Summary"
    ];

    const { tab } = this.state;
    if (this.state && !this.state.loading) {
      return (
        <Grid container xs={12} className="componentGrid">
          <Paper className="componentPaperWebhook">
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepButton
                      onClick={() => {
                        this.handleOnClickStep(index);
                      }}
                      {...labelProps}
                    >
                      {label}
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>

            <Grid container style={{ marginTop: this.props.isExpanded ? 40 : 0, padding: "0 24px"}}>
              {this.getStepContent(webhookStep)}
            </Grid>
          </Paper>
        </Grid>
      );
    } else return <IndividualComponentLoader />;
  }
}
export default inject(
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(TriggerFromZapierTemplate));
