import React, { useEffect, useState } from "react";

// MUI
import { styled } from "@mui/system";
import { Alert, Grid, Skeleton, Box } from "@mui/material";

// Icons
import { ErrorRounded } from "@mui/icons-material";

// Util
import config from "../../config";
import { Link } from "react-router-dom";
import { send_request } from "../../utils/Request";
import NonFormTriggerSkeleton from "./NonFormTriggers/NonFormTriggerSkeleton";

// Styled components
const StyledAlert = styled(Alert)({
    color: "black",
    backgroundColor: "#FFEACB",
    border: "1px solid #FFB74D",
});

const StyledErrorIcon = styled(ErrorRounded)({
    color: "#FFB74D",
});

const StyledLink = styled(Link)({
    underline: "none",
    color: "#2196F3",
});

const StylediFrame = styled("iframe")({
    border: 'none',
    width: '100%',
    height: '100%',
    minHeight: '800px', // Adjust the height as needed
});

const BrowserWrapper = styled(Box)({
    border: '1px solid #e0e0e0',
    padding: '0px !important',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
});

const BrowserBar = styled(Box)({
    backgroundColor: '#f5f5f5',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
});

const TestIframeRender = (props) => {

    // Initial call
    useEffect(async () => {
        await getTests();
    }, []);

    useEffect(async () => {
        if (!props.refresh) return; // If not refreshing, return

        props.setTests([]);
        // Handle refresh logic
        await getTests();

        props.setRefresh(false);
        props.setLoadingInputPHs(false);
    }, [props.refresh])

    const getTests = async () => {
        return await send_request(`project-service/test/get_tests/${props.projectId}`, "", "", "GET").then(async (res) => {
            // Creates a test if there is none
            if (res.data.length === 0) {
                await send_request(
                    `project-service/test/create_test/${props.projectId}`,
                    {
                        name: "Test",
                    },
                    "",
                    "POST"
                ).then((res) => {
                    props.setTests([res.data]);
                });
            } else {
                props.setTests(res.data);
            }
        });
    };

    const renderComponent = (componentId, type, componentData = null, projectId, version) => {
        let src = config.FORM.FORM_FRONT_END + `form/form_test/` + componentId + `/${props.tests[0].testId}`;

        if (props.tests.length > 0 && props.tests[0].testSessions !== null) {
            const testSessions = props.tests[0].testSessions;
            const sessionId = testSessions[componentId];
            if (sessionId !== undefined) {
                src += `/${sessionId}`;
            }
        }

        let name = "Untitled";

        if (componentData) {
            name = componentData.label;
        }

        src += "?isLatestDraft=true";
        
        switch (type) {
            case "form":
            case "bulk_assessment":
                return (
                    <BrowserWrapper>
                        <BrowserBar>
                            <div style={{ display: 'flex', gap: '8px' }}>
                                <div style={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: '#ff5f57' }}></div>
                                <div style={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: '#ffbd2e' }}></div>
                                <div style={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: '#28c940' }}></div>
                            </div>
                        </BrowserBar>
                        <StylediFrame 
                            src={type === "form" ? src : `${config.FORM.FORM_FRONT_END}/bulk/bulk_test/${componentId}/${props.tests[0].testId}?test=true`}
                            onLoad={props.loadingInputPHs ? props.setLoadingInputPHs(false) : null}
                        />
                    </BrowserWrapper>
                );
            case "trigger_from_zapier":
            case "trigger_from_a_workflow":
            case "schedule_start":
            case "webhook":
                if (props.loadingInputPHs) props.setLoadingInputPHs(false);
                return (
                    <NonFormTriggerSkeleton
                        componentType={type}
                        componentId={componentId}
                        componentName={name}
                        projectId={projectId}
                        projectVersion={version}
                        iconName={type === "trigger_from_zapier" ? "zapier-icon" : null}
                        testId={props.tests[0].testId}
                        testValues={props.tests[0].testValues !== null && props.tests[0].testValues !== undefined ? props.tests[0].testValues[componentId] : []}
                    />
                );
        }
    };

    return (
        <div style={{ padding: props.expanded ? "16px 0px 0px 0px" : "0px" }}>
            <Grid container direction={"column"} spacing={1}>
                {!props.isTestPane && (
                    <Grid item>
                        <StyledAlert icon={<StyledErrorIcon />}>
                            After submitting a test trigger, go to the{" "}
                            <StyledLink to={`/project/logs/${props.projectId}?test=true`} target="_blank">
                                test session logs
                            </StyledLink>{" "}
                            to track the test session
                        </StyledAlert>
                    </Grid>
                )}
                <Grid item>{props.loadingInputPHs && <Skeleton variant="rectangular" width="100%" height={250} />}</Grid>
                <Grid item>
                    {(!props.loadingInputPHs && props.tests.length > 0) && renderComponent(props.componentId, props.expanded ? props.compData.type : props.compData.data.type, props.compData.data, props.projectId, props.version)}
                </Grid>
            </Grid>
        </div>
    );
};

export default TestIframeRender;
