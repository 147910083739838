import React from "react";
import { timezones } from "../../../utils/timezones";
import { Grid, MenuItem, Select } from "@mui/material";
class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.preview === true) {
      return (
        <Grid container item direction={"row"} xs={12} justifyContent={"start"}>
          <img
            style={{
              width: "260px",
              height: "36px",
              border: "solid 1px #868686",
              borderRadius: "8px",
            }}
            src={"/images/calendar.jpg"}
          />
        </Grid>
      );
    } else if (this.props.preview == false) {
      return (
        <Grid>
          <Grid container>
            <p>Export in the following date format</p>
            <p className={"extra-margin"}>Timezone</p>
          </Grid>
          <Select
            onChange={(e) => this.props.handleChangeOutput(e)}
            className={"select-list"}
            variant={"outlined"}
            value={this.props.outputFormat}
            defaultValue={"dd LLLL YYYY"}
          >
            <MenuItem value={"dd LLLL YYYY"}>
              dd Month yyyy [01 February 2021]
            </MenuItem>
            <MenuItem value={"dd LLLL YYYY kk:mm"}>
              dd Month yyyy hh:mm [01 February 2021 14:01]
            </MenuItem>
            <MenuItem value={"dd/MM/YY"}>dd/mm/yy [01/02/21]</MenuItem>
            <MenuItem value={"dd/MM/YY kk:mm"}>
              dd/mm/yy hh:mm [01/02/21 14:01]
            </MenuItem>
            <MenuItem value={"dd/MM/YYYY"}>dd/mm/yyyy [01/02/2021]</MenuItem>
            <MenuItem value={"dd/MM/YYYY kk:mm"}>
              dd/mm/yyyy hh:mm [01/02/2021 14:01]
            </MenuItem>
            <MenuItem value={"dd-MM-YYYY"}>dd-mm-yyyy [01-02-2021]</MenuItem>
            <MenuItem value={"dd-MM-YYYY kk:mm"}>
              dd-mm-yyyy hh:mm [01-02-2021 14:01]
            </MenuItem>
            <MenuItem value={"MM/dd/YYYY"}>mm/dd/yyyy [02/01/2021]</MenuItem>
            <MenuItem value={"MM/dd/YYYY kk:mm"}>
              mm/dd/yyyy hh:mm [02/01/2021 14:01]
            </MenuItem>
            <MenuItem value={"YYYY-MM-dd"}>yyyy-mm-dd [2021-02-01]</MenuItem>
            <MenuItem value={"YYYY-MM-dd kk:mm"}>
              yyyy-mm-dd hh:mm [2021-02-01 14:01]
            </MenuItem>
            <MenuItem value={"Iso-8601"}>
              ISO 8601 [2021-02-01T14:01:00±Timezone]
            </MenuItem>
            <MenuItem value={"Unix"}>Unix [1612148400]</MenuItem>
          </Select>
          <Select
            onChange={(e) => this.props.handleChangeTimezone(e)}
            className={"select-list extra-margin"}
            variant={"outlined"}
            value={this.props.timezone ? this.props.timezone : "UTC"}
            defaultValue={"UTC"}
          >
            <MenuItem value="" disabled>
              {" "}
              Select timezone
            </MenuItem>
            {timezones.length > 0 &&
              timezones.map((item, index) => (
                <MenuItem value={item.offset} key={index}>
                  {" "}
                  {item.name} {item.offset}{" "}
                </MenuItem>
              ))}
          </Select>
        </Grid>
      );
    }
  }
}

export default Calendar;
