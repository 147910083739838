import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Custom
import ChipInput from "../../ChipInput/ChipInput";
import { getColourFromString } from "../../../utils/ColourNameGenerator";

// MUI
import { styled } from "@mui/system";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const MyChip = styled(Chip)(({ colour }) => ({
    border: `1px solid ${colour.border}`,
    backgroundColor: colour.bg,
    boxSizing: "border-box",
    borderRadius: "4px",
    marginRight: "6px",
}));

function ListChip({ availablePlaceholders, answer_list, handleReplaceAnswerList }) {
    const [currList, setCurrList] = useState([]);

    useEffect(() => {
        // Get the first array values that aren't empty and set into the state
        let parsedList = answer_list
            .map((ans) => {
                return ans[0];
            })
            .filter((ans) => ans != "");
        setCurrList(parsedList);
    }, []);

    // Convert from a single array to a 2d array
    // Being the data structure of the choice questions
    const convertToChoiceStructure = (arr) => {
        return arr.map((ans) => {
            return [ans, ans];
        });
    };

    // Flattens an array of nested arrays that contains commas
    const flattenArray = (arr) => {
        let newArr = [];
        for (let val of arr) {
            if (val.includes(",")) {
                let splitVal = val.split(",");
                for (let splitV of splitVal) {
                    newArr.push(splitV.trim());
                }
            } else newArr.push(val.trim());
        }
        return newArr;
    };

    // Handles the on change for the chip input
    const handleChangePlaceholder = (chip) => {
        let key = chip.key;

        if (chip.isNotPlaceholder !== true) {
            key = "${" + key + "}";
        }

        let updatedList = [...currList, key];
        setCurrList(flattenArray(updatedList));
        handleReplaceAnswerList(convertToChoiceStructure(flattenArray(updatedList)));
    };

    // Handles the deleting for the chip input
    const handleDeletePlaceholder = (index) => {
        currList.splice(index, 1);
        setCurrList(currList);
        handleReplaceAnswerList(convertToChoiceStructure(currList));
    };

    return availablePlaceholders ? (
        <ChipInput
            inputValue={currList}
            placeholders={availablePlaceholders}
            fullWidth={true}
            placeholder={"Type in values or insert a list placeholder"}
            onPlaceholderSelected={handleChangePlaceholder}
            onDelete={(index) => handleDeletePlaceholder(index)}
        />
    ) : (
        <Autocomplete
            multiple
            freeSolo
            fullWidth
            options={[]}
            value={currList}
            onChange={(event, newValue, reason) => {
                if (reason === "removeOption") {
                    handleReplaceAnswerList(convertToChoiceStructure(newValue));
                } else if (reason === "clear") {
                    handleReplaceAnswerList(convertToChoiceStructure(newValue));
                }
                setCurrList(flattenArray(newValue));
            }}
            onBlur={() => {
                // Convert to correct structure and set the list answer
                handleReplaceAnswerList(convertToChoiceStructure(currList));
            }}
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => <MyChip {...getTagProps({ index })} label={option} colour={getColourFromString(option)} />);
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Type in values" />}
        />
    );
}

ListChip.propTypes = {
    availablePlaceholders: PropTypes.element.isRequired,
    answer_list: PropTypes.array.isRequired,
    handleReplaceAnswerList: PropTypes.func.isRequired,
};

export default ListChip;
