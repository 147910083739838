import { Menu, MenuItem, MenuList, Paper } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { send_request } from '../../utils/Request';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    maxHeight: '900px',
    marginTop: '24px',
    width: '250px',
    position: "sticky",
    top: "16px"
  },
  heading: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '14px',
    paddingLeft: '16px'
  },

})

const CategorySelect = ({ changeCategory, currentCategory }) => {

  const classes = useStyles();
  const [categories, setCategories] = useState([])

  const handleMenuClick = (e) => {
    const { myValue } = e.currentTarget.dataset;
    changeCategory(parseInt(myValue));
  }

  useEffect(() => {
    
    const fetchCategories = async () => {
      const response = await send_request(
        'project-service/template/projectCategories',"",
        {},
        "GET"
      ).catch((err) => {
        console.log(err);
      });

      setCategories(response.data);
    }

    fetchCategories();

  }, [])

  return (
    <Paper className={classes.root}>
        <MenuList>
          <MenuItem
            style={0 === currentCategory ? { color: '#2196F3', fontWeight: 'bold' } : {}}
            data-my-value={0}
            onClick={handleMenuClick}
            selected={0 === currentCategory}
            data-tut="reactour__marketplace__categories"
          >
              All templates
          </MenuItem>
          {categories.map(c => (
            <MenuItem
              style={c.categoryId === currentCategory ? { color: '#2196F3', fontWeight: 'bold' } : {}}
              data-my-value={c.categoryId}
              onClick={handleMenuClick}
              selected={c.categoryId === currentCategory}
            >
              {c.displayName}
            </MenuItem>
          ))}
        </MenuList>
      </Paper>
  )
}

export default CategorySelect
