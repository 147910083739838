import React, {Component} from 'react';
import Grid from "@mui/material/Grid";

class AchievementCardCompleted extends Component {
  render() {
    return (
      <Grid item xs={12} className={"all-achieve-complete"}>
        <Grid container spacing={1}>

          <Grid item xs={12} className="txt-achieve-title">
            🙌 You have completed all available achievements
          </Grid>

          <Grid item xs={12} className="txt-achieve-required">
            Nice work! You have completed all available achievements. When new achievements get added, you will see them here. You can also check the Achievements page for updates as well.
          </Grid>

          <Grid item xs={12}>
            <a href="/achievements" target="_blank" className="txt-achieve-link">
              Go to Achievements Page
            </a>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default AchievementCardCompleted;