import React from "react";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import { Grid, TextField, MenuItem, Button, Select } from "@mui/material";
import FormBuilderContainer from "../../FormBuilderContainer";

function Logic(props) {
  return (
    <Select
      style={{
        width: "100%",
        backgroundColor: "#F8F8F8",
        borderRadius: "4px",
        height: "36px",
        color: "#868686",
      }}
      name="selectType"
      disableUnderline
      variant="standard"
      labelId="demo-simple-select-outlined-label"
      className="dropdown-mat selectStandard"
      value={props.value}
      onChange={(event) => {
        props.onChange(event.target.value);
      }}
    >
      <MenuItem value={"OR"}> OR </MenuItem>
      <MenuItem value={"AND"}> AND </MenuItem>
    </Select>
  );
}

function SelectKey(props) {
  const data = toJS(FormBuilderContainer.dataQuestion);
  return (
    <Select
      style={{
        width: "100%",
        borderRadius: "4px",
        height: "36px",
        color: "#868686",
      }}
      labelId="demo-simple-select-outlined-label"
      className="dropdown-mat selectCondition"
      displayEmpty
      name="selectQuestion"
      value={props.value}
      onChange={(event) => {
        props.onChange(event.target.value);
      }}
      disableUnderline
      variant="outlined"
    >
      <MenuItem value="" disabled>
        {" "}
        Select question{" "}
      </MenuItem>
      {data.length > 0 &&
        data.map((item, index) => {
          if (item.type != 8) {
            return (
              <MenuItem value={item.config.key} key={index}>
                {" "}
                {item.config.key}{" "}
              </MenuItem>
            );
          }
        })}
    </Select>
  );
}

function SelectFuc(props) {
  console.log(props);
  return (
    <React.Fragment>
      {props.HasSelected ? (
        props.isCheckbox ? (
          <Select
            style={{
              width: "100%",
              backgroundColor: "#F8F8F8",
              borderRadius: "4px",
              height: "36px",
              color: "#868686",
            }}
            name="selectFunc"
            displayEmpty
            value={props.value}
            onChange={(event) => {
              props.onChange(event.target.value);
            }}
            disableUnderline
            labelId="demo-simple-select-outlined-label"
            className="dropdown-mat selectStandard"
            variant="standard"
          >
            <MenuItem value={"HASSELECTED"}> Has selected </MenuItem>
            <MenuItem value={"HASNOTSELECTED"}> Has not selected </MenuItem>
            <MenuItem value={"CONTAIN"}> Contains </MenuItem>
            <MenuItem value={"NOTCONTAIN"}> Does not contain </MenuItem>
          </Select>
        ) : (
          <Select
            style={{
              width: "100%",
              backgroundColor: "#F8F8F8",
              borderRadius: "4px",
              height: "36px",
              color: "#868686",
            }}
            name="selectFunc"
            displayEmpty
            value={props.value}
            onChange={(event) => {
              props.onChange(event.target.value);
            }}
            disableUnderline
            labelId="demo-simple-select-outlined-label"
            className="dropdown-mat selectStandard"
            variant="standard"
          >
            <MenuItem value={"HASSELECTED"}> Has selected </MenuItem>
            <MenuItem value={"HASNOTSELECTED"}> Has not selected </MenuItem>
          </Select>
        )
      ) : (
        <Select
          // native
          style={{
            width: "100%",
            backgroundColor: "#F8F8F8",
            borderRadius: "4px",
            height: "36px",
            color: "#868686",
          }}
          name="selectFunc"
          displayEmpty
          value={props.value}
          onChange={(event) => {
            props.onChange(event.target.value);
          }}
          disableUnderline
          labelId="demo-simple-select-outlined-label"
          className="dropdown-mat selectStandard"
          variant="standard"
        >
          <MenuItem value={"CONTAIN"}> contains </MenuItem>
          <MenuItem value={"NOTCONTAIN"}> does not contain </MenuItem>
          <MenuItem value={"MATCHES"} > matches </MenuItem>
          <MenuItem value={"NOTMATCH"} > does not match </MenuItem>
          <MenuItem value={"EQUAL"}> equals </MenuItem>
          <MenuItem value={"NOTEQUAL"}> does not equal </MenuItem>
          <MenuItem value={"GREATERTHAN"}> greater than </MenuItem>
          <MenuItem value={"GREATERTHANOREQUAL"} > greater than or equals to </MenuItem>
          <MenuItem value={"LESSTHAN"}> less than </MenuItem>
          <MenuItem value={"LESSTHANOREQUAL"} > less than or equals to </MenuItem>
          <MenuItem value={"NOTEMPTY"} > is not empty </MenuItem>
          <MenuItem value={"EMPTY"} > is empty </MenuItem>
        </Select>
      )}
    </React.Fragment>
  );
}

function SelectValue(props) {
  return (
    <Select
      style={{
        width: "100%",
        borderRadius: "4px",
        height: "36px",
        color: "#868686",
      }}
      labelId="demo-simple-select-outlined-label"
      className="dropdown-mat selectCondition"
      displayEmpty
      name="selectQuestion"
      value={props.value}
      onChange={(event) => {
        props.onChange(event.target.value);
      }}
      disableUnderline
      variant="outlined"
    >
      <MenuItem value="" disabled>
        {" "}
        Select answer{" "}
      </MenuItem>
      {props.data.length > 0 &&
        props.data.map((item, index) => (
          <MenuItem value={item[0]} key={index}>
            {" "}
            {item[0]}{" "}
          </MenuItem>
        ))}
    </Select>
  );
}

export { Logic, SelectKey, SelectFuc, SelectValue };
