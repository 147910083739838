import React, { useEffect, useState } from "react";
//material
import { Button, Grid, TextField, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
//icons
import {
  Cached,
  Description,
  OpenInFull,
  Save,
  Close,
  AutoAwesome,
  CloseFullscreen,
  ExpandMore as ExpandMoreIcon,
  AutoFixHigh
} from "@mui/icons-material";
//prop types
import PropTypes from "prop-types";
//css
import styles from "./ComponentPaneStyle";
import { withStyles } from "@mui/styles";
//utils
import { format } from "../../../utils/Date";
//store to manage state
import ProjectStore from "../../ProjectCanvas/ProjectStore";
//skeleton
import ComponentPaneHeaderSkeleton from "./ComponentPaneHeaderSkeleton";
import ScienceIcon from "@mui/icons-material/Science";
import { isAIEnabled } from "../../../utils/AIUtil";
import { StyledErrorIcon } from "../../TriggerFromAWorkflow/components/StyledComponents";
import DiscardChangesButton from "./DiscardChangesButton";
import "./ComponentPaneHeader.css";

const ComponentPaneHeader = props => {
  // ================================================= State ==========================================================

  const [templateLoading, setTemplateLoading] = useState(true);

  // ================================================= CSS ==========================================================
  
  useEffect(() => {
    setTemplateLoading(props.templateLoaded);
  }, [props.templateLoaded]);
  
  const classes = props.classes;

  const getLastUpdated = () => {
    let updated = props.lastUpdated
      ? " " + format(props.lastUpdated, ProjectStore.state.timezone, "relative")
      : null;

    if (!updated) return; // If no last updated, don't show anything

    return (
      <Grid item>
        <Button
          className={classes.textButton}
          size={"small"}
          startIcon={<Cached />}
          disabled={templateLoading}
        >
          Updated: {updated}
        </Button>
      </Grid>
    );
  };

  if (!props.loading)
    return (
      <Grid className={classes.header}>
        <Grid container item direction={"row"} spacing={1}>

          {!props.isExpanded
            ? <Grid item>
                <Button
                  className={classes.textButton}
                  size={"small"}
                  startIcon={<OpenInFull />}
                  disabled={props.isInAIDraft || templateLoading}
                  onClick={() => props.handleExpand()}
                >
                  Expand
                </Button>
              </Grid>
            : <Grid item>
                <Button
                  className={classes.textButton}
                  size={"small"}
                  startIcon={<CloseFullscreen />}
                  onClick={() => {
                    props.handleCollapse();
                  }}
                  disabled={templateLoading}
                >
                  Collapse
                </Button>
              </Grid>}

          <Grid item>
            <Button
              className={classes.textButton}
              size={"small"}
              startIcon={<Save />}
              disabled={ProjectStore.state.production || props.isInAIDraft || templateLoading}
              onClick={() => {
                props.save();
              }}
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                background: "rgba(255, 88, 0, 1)",
                color: "#FFF",
                paddingRight: "10px"
              }}
              className={classes.textButton}
              size={"small"}
              startIcon={<ScienceIcon />}
              disabled={ProjectStore.state.production || props.isInAIDraft || templateLoading}
              onClick={() => {
                // Set open for test
                if (props.setTestFromCompPanel)
                  props.setTestFromCompPanel(true);
                if (props.setOpenTestPanel)
                  props.setOpenTestPanel(props.componentId);
              
              }}
            >
              Test
            </Button>
          </Grid>
          <Grid item>
            <div className="ai-button-wrapper">
              <div className="ai-button-border"></div>
              <div className="ai-button-border-glow"></div>
              <Button
                className={`${classes.textButton} ai-button`}
                size={"small"}
                startIcon={<AutoAwesome />}
                disabled={ProjectStore.state.production || props.isInAIDraft || templateLoading}
                onClick={() => {
                  props.setAiComponentBuilderOpen(
                    !props.aiComponentBuilderOpen
                  );
                  if (props.setTestFromCompPanel)
                    props.setTestFromCompPanel(false);
                }}
              >
                AI
              </Button>
            </div>
          </Grid>
          <Grid item>
            <Button
              className={classes.textButton}
              size={"small"}
              startIcon={<Description />}
              onClick={() => {
                let component_type = props.type
                  .toLowerCase()
                  .replace(/\s/g, "_");
                ProjectStore.handleDocsRedirect(component_type);
              }}          
              disabled={templateLoading}
            >
              Docs
            </Button>
          </Grid>
          {props.additionalButtons}
          {getLastUpdated()}
          <Grid item>
            <DiscardChangesButton disabled={templateLoading} />
          </Grid>
          <Grid item>
            <Button
              className={classes.textButton}
              size={"small"}
              startIcon={<Close />}
              onClick={() => props.handleClose()}
              disabled={templateLoading}
            >
              Close
            </Button>
          </Grid>
        </Grid>
        <TextField
          value={props.name}
          fullWidth={true}
          inputProps={{
            style: { fontWeight: 300, fontSize: "18px" }
          }}
          variant={"standard"}
          onChange={event => {
            if (!props.production) props.updateName(event.target.value);
          }}
          disabled={templateLoading}
        />
        <Grid item xs={12} sx={{ paddingTop: '8px' }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="description-content"
              id="description-header"
            >
              <span>Description</span>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                value={props.componentDescription}
                onChange={event => {
                  if (!props.production) props.updateComponentDescription(event.target.value);
                }}
                fullWidth
                multiline
                maxRows={6}
                variant={"outlined"}
                disabled={templateLoading}
              />
              {props.instructions &&
                Object.keys(props.instructions).map(instructionKey => {
                  if (props.instructions[instructionKey]) {
                    let label = instructionKey.substring(
                      0,
                      instructionKey.lastIndexOf("Instruction")
                    );
                    return (
                      <Grid
                        key={instructionKey}
                        item
                        container
                        xs={12}
                        className={classes.instructionInfo}
                        direction={"column"}
                        marginTop={2}
                      >
                        <Grid
                          item
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                          }}
                        >
                          <StyledErrorIcon />
                          <b>
                            Incomplete component {label}
                          </b>
                        </Grid>
                        <Grid item>
                          <p className={"s-text"}>
                            The AI did not complete {label}ing this component to
                            the proposed configuration below. Click Finish
                            setting up to complete the {label}, or discard the
                            proposal if it is no longer required.
                          </p>
                        </Grid>
                        <Grid item>
                          <TextField
                            InputProps={{
                              readOnly: true,
                              disableUnderline: true
                            }}
                            variant={"standard"}
                            value={props.instructions[instructionKey]}
                            fullWidth
                            multiline
                            maxRows={6}
                            disabled={templateLoading}
                          />
                        </Grid>
                        <Grid item direction={"row"} container>
                          <Button
                            variant={"outlined"}
                            sx={{
                              textTransform: "none",
                              border: "1px solid #944BA8"
                            }}
                            onClick={() => {
                              props.setShowBuildPrompt(!props.showBuildPrompt);
                              props.setAiComponentBuilderOpen(true);
                              label === "build"
                                ? props.setSidebarState("BUILD")
                                : props.setSidebarState("EDIT");
                            }}
                            startIcon={
                              <img
                                className={classes.icon}
                                src="/svgs/instructionIcon.svg"
                              />
                            }
                            disabled={templateLoading}
                          >
                            Finish setting up
                          </Button>
                          <Button
                            variant={"string"}
                            sx={{ textTransform: "none" }}
                            onClick={() => {
                              label === "build"
                                ? props.saveBuildInstruction(
                                    true,
                                    false,
                                    instructionKey
                                  )
                                : props.saveBuildInstruction(
                                    false,
                                    true,
                                    instructionKey
                                  );
                            }}
                            disabled={templateLoading}
                          >
                            <Close fontSize={"small"} color={"info"} />
                            Discard
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  } else return null;
                })}
              
              {/* Add the new smaller "Generate with AI" button */}
              <Grid container justifyContent="flex-end" sx={{ marginTop: '16px' }}>
                <Button
                  variant="contained"
                  startIcon={<AutoFixHigh />}
                  disabled={true}
                  size="small"
                  sx={{ textTransform: 'none' }}
                >
                  Generate with AI
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    );
  else {
    return <ComponentPaneHeaderSkeleton />;
  }
};

export default withStyles(styles)(ComponentPaneHeader);

ComponentPaneHeader.propTypes = {
  classes: PropTypes.object.isRequired
};