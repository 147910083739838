import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Grid,
  Tooltip,
  Button,
  TextField,
  Chip,
  Skeleton,
  Box,
  IconButton,
} from "@mui/material";
import "./Task.css";
import TaskChip from "./TaskChip";
import { send_request } from "../../utils/Request";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import JoditEditor from "jodit-pro-react";
import { format } from "../../utils/Date";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import UserDisplayName from "../User/components/UserDisplayName";
import ChipInput from "../ChipInput/ChipInput";
import { Autocomplete } from "@mui/material";
import TextEditor from "../TextEditor/TextEditor";
import { useNavigate, useParams } from "react-router";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDayjs";
import TaskOpens from "./TaskOpens";
import { Archive, Assignment, Close, Edit, OpenInFull, OpenInNew, ExpandMore, ExpandLess, Person, Email } from "@mui/icons-material";
import { styled } from "@mui/system";
import { getComponentSessionWithSessionIdAndComponentId } from "../../utils/Requests/Projects";
import { generateTimeTracking } from "./Util";
import dayjs from "dayjs";
import WarningBox from "../pages/CommonStyledComponents/WarningBox";
import TaskWorkflowName from "./TaskWorkflowName";

var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const CONFIG = {
  license: "CC7FX-1D761-AUOP5-JG0GX",
  readonly: true,
  toolbar: false,
  disablePlugins: [
    "keyboard",
    "google-search",
    "fullsize",
    "about",
    "classSpan",
    "google-maps",
    "export-docs",
  ],
  askBeforePasteHTML: true,
  defaultActionOnPaste: "insert_only_text",
  uploader: {
    insertImageAsBase64URI: true,
  },
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
};

const styles = () => ({
  root: {
    "& .jodit-container": {
      border: "none !important",
      minHeight: "unset!important",
    },
    "& .jodit-wysiwyg": {
      padding: "0px!important",
      minHeight: "unset!important",
    },
    "& .jodit-workplace": {
      padding: "0px!important",
      minHeight: "unset!important",
    },
    "& a": {
      color: "-webkit-link",
    },
    width: "100%",
    padding: "0",
    display: "flex",
    flexDirection: "column"
  },
});

const AppBarButton = styled(Button)({
  textTransform: "none",
  color: "rgba(134, 134, 134, 1)",
  fontSize: "14px",
  marginRight: "1rem"
})

const StyledATag = styled("a")({
  "&:hover": {
    textDecoration: "underline !important"
  },
  color: "black",
  marginBottom: "8px",
  fontSize: "1.2em",
  textDecoration: "none",
});

const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const AssignedOnSpan = styled('span')({
  fontSize: '0.9em',
  color: 'rgba(134, 134, 134, 1)',
});

const StyledPaper = styled(Paper)({
  width: "100%",
  padding: "1rem",
  borderRadius: "8px",
  background: "#FFF",
});

const ExpandButton = styled(Button)({
  minWidth: 'auto',
  padding: '6px'
});

const SectionTitle = styled('p')({
  fontSize: 'small',
  margin: 0,
});

const ContentParagraph = styled('p')({
  marginTop: '4px',
  marginBottom: '8px',
});

const TaskDetails = (props) => {
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [assignedEmail, setAssignedEmail] = useState([]);
  const [taskSubject, setTaskSubject] = useState("");
  const [assignedUser, setAssignedUser] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [taskDescription, setTaskDescription] = useState("");
  const [expandDetails, setExpandDetails] = useState(false);
  const [timeTracking, setTimeTracking] = useState(null);
  const [workflowName, setWorkflowName] = useState(null);
  
  const navigate = useNavigate();
  const { expanded } = props;

  const openSessionLog = (task) => {
    window.open(
      config.APP_URL +
      "/project/logs/"
      +
      task.projectId +
      "/" +
      task.sessionId,
      "_blank"
    );
  };
  


  const openTask = async (task) => {
    let url =
      "project-service/project-session/get_form_session/" +
      task.componentId +
      "/" +
      task.sessionId;

    if (task.threadId) {
      //task is in thread
      url = url + "/" + task.threadId;
    }

    if(task.componentSessionId) {
      url = url + "?componentSessionId="+task.componentSessionId;
    }

    const session = await send_request(url, "", "", "GET")
      .then((response) => {
        if (response.data) {
          window.open(
            `https://form.workflow86.com/form/${task.componentId}/${response.data}`,
            "_blank"
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const expandTask = () => {
    props.expandTask();
  }

  const editTask = (task) => {
    navigate('/task/' + task.taskId + "?edit=true");
  };

  const saveTask = (task) => {
    setLoading(true);

    task.overDue = false;
    if (task.dueDate) {
      const nowDate = new Date();
      const nowDataMillis = nowDate.getTime();

      const dueDate = new Date(task.dueDate);
      const dueDateMillis = dueDate.getTime();

      if (nowDataMillis > dueDateMillis) {
        task.overDue = true;
      }
    }

    task.taskSubject = taskSubject;
    task.assignedEmail = assignedEmail;

    task.assignedUser = [];

    assignedUser.map((user) => {
      task.assignedUser.push(user.userName);
    });

    task.taskDescription = taskDescription;

    send_request("task/task/update_task_detail", task, null, "POST").then(
      () => {
        setEditMode(false);
        setLoading(false);
        setTask(task);
      }
    );
  };

  const openDetails = (task) => {
    //open individual task page
    window.open(
      `https://app.workflow86.com/project/canvas/${task.projectId}/sequential_form/${task.componentId}`,
      "_blank"
    );
  };

  const handleChangeEmail = (chip) => {
    let key = chip.key;
    if (chip.isNotPlaceholder !== true) {
      key = "${" + key + "}";
    }
    const updatedEmail = [...assignedEmail, key];
    setAssignedEmail(updatedEmail);
  };

  const handleDeleteEmail = (index) => {
    if (assignedEmail) {
      assignedEmail.splice(index, 1);
      setAssignedEmail(assignedEmail);
    }
  };

  const handleSetUsers = (event, inputValue, reason) => {
    if (reason == "clear") {
      setAssignedUser([]);
    } else {
      setAssignedUser(inputValue);
    }
  };

  const handleChangeDescription = (html) => {
    setTaskDescription(html);
  };

  const handleTaskDueDateChange = (value) => {
    const newTask = { ...task };
    newTask.dueDate = value;
    setTask(newTask);
  };

  useEffect(() => {
    const { id, task } = props;
    const getTask = async () => {

      if (task !== null && task !== undefined) {
        setTask(task);

        if (task.assignedEmail === null) {
          setAssignedEmail([]);
        } else {
          setAssignedEmail(task.assignedEmail);
        }

        setTaskSubject(task.taskSubject);
        setLoading(false);

        const res = await getComponentSessionWithSessionIdAndComponentId(task.sessionId, task.componentId);
        const componentSession = res.data;
          
        if (componentSession) {
          const componentTimeTracking = generateTimeTracking(componentSession);
          setTimeTracking(componentTimeTracking);
        }


        return;
      }

      if (!id) return; // If id is null, return

      const json = await send_request(`task/task/get_task/${id}`)
        .then(async (response) => {
          let task = response.data;

          const res = await getComponentSessionWithSessionIdAndComponentId(task.sessionId, task.componentId); 
          const componentSession = res.data;
              
          if (componentSession) {
            const componentTimeTracking = generateTimeTracking(componentSession);
            setTimeTracking(componentTimeTracking);
          }

          let componentIds = [];
          componentIds.push(task.componentId);

          const taskInfo = await send_request(
            "project-service/project/get_project_info",
            componentIds,
            "",
            "POST"
          )
            .then((res) => {
              const taskExtras = res.data;
              if (taskExtras[0] && task.componentId == taskExtras[0].componentId) {
                task.componentName = taskExtras[0].componentName;
                task.projectName = taskExtras[0].projectName;
                task.projectId = taskExtras[0].projectId;
              }
              
              task["overDue"] = false;
              if (task.dueDate) {
                const nowDate = new Date();
                const nowDataMillis = nowDate.getTime();

                const dueDate = new Date(task.dueDate);
                const dueDateMillis = dueDate.getTime();

                if (nowDataMillis > dueDateMillis){
                  task.overDue = true
                }
              }

              var currentTask = task;
              setTask(currentTask);

              if (task.assignedEmail === null) {
                setAssignedEmail([]);
              } else {
                setAssignedEmail(task.assignedEmail);
              }

              setTaskSubject(task.taskSubject);
              setLoading(false);
              return task;
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTask();
  }, [props.id]);

  const renderWorkflowName = (task) => {
    const { projectName, projectId } = task;

    if (!projectId || projectId === undefined) {
      return "Deleted workflow"
    }

    if (projectName && projectName !== undefined) {
      if (projectName.length !== 0) {
        return projectName;
      } else {
        return projectId;
      }
    } else {
      return projectId;
    }
  }

  const renderColorStatus = (status) => {
    let color;
    switch (status) {
      case "FAIL":
        color = "#55A77A26";
        break;
      case "SUCCESS":
      case "SUCCESS_END":
        color = "#55A77A26";
        break;
      case "INPROGRESS":
        color = "#FFF1DD";
        break;
      case "WAITING":
        color = "#FFF1DD";
        break;
      case "TERMINATED":
        color = "#55A77A26";
        break;
      case "PAUSED":
        color = "#FFF1DD";
        break;
    }

    return color
  }

  const renderTimeTracking = (timeTracking, status) => {
    let timeTrackingInDuration = dayjs.duration(timeTracking);

    return (
      <p style={{
        background: renderColorStatus(status),
        padding: 8,
        fontSize: 14,
        borderRadius: 4,
        width: "fit-content"
      }}>
        {timeTrackingInDuration.asDays().toFixed() > 0 && (
          <>
            <span style={{fontSize:24}}>
              {timeTrackingInDuration.asDays().toFixed()}
            </span>
            <span> days </span>
          </>
        )}
        {timeTrackingInDuration.hours() > 0 && (
          <>
            <span style={{fontSize:24}}>
              {timeTrackingInDuration.hours()}
            </span>
            <span> hours </span>
          </>
        )}
        {timeTrackingInDuration.minutes() > 0 && (
          <>
            <span style={{fontSize:24}}>
              {timeTrackingInDuration.minutes()}
            </span>
            <span> minutes </span>
          </>
        )}
        {timeTrackingInDuration.seconds() > 0 && (
          <>
            <span style={{fontSize:24}}>
              {timeTrackingInDuration.seconds()}
            </span>
            <span> seconds </span>
          </>
        )}             
      </p>
    )
  }

  const { classes, appBarButtons } = props;
  if (task != null && !loading) {
    return (
        <Box
          className={classes.root} 
        >
          {
            !expanded && 
            <Box
              className="appBarButtons"
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "flex-end"
              }}
            >
              {appBarButtons}
            </Box>
          }
          <Box
            sx={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <TaskChip
              overDue={task.overDue}
              value={task.status}
              extras={task}
            />
            <h4 style={{ marginLeft: '8px', lineHeight: '16px' }}>{task.isExternal && "[External] "} {task.taskSubject}</h4>
          </Box>
          {
            expanded && 
            <Box
              className="appBarButtons"
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                marginBottom: "1rem"
              }}
            >
              {appBarButtons}
            </Box>
          }
          <StyledPaper>
            <Grid container item direction={"column"} xs={12}>
              <StyledBox>
                {!task.isExternal &&
                  <StyledATag
                    href={`/project/canvas/${task.projectId}`}
                    target="_blank"
                  >
                    {renderWorkflowName(task)}
                  </StyledATag>
                }
                <assignedOnSpan>
                  {format(task.assignedOn, ProjectStore.state.timezone, "timeline")}
                </assignedOnSpan>
              </StyledBox>
              {task.isExternal && 
                <>
                  <Grid container marginTop={2}>
                    <Grid item xs={12}>
                      <WarningBox 
                        width="full-width"
                        vertical="flex-start"
                        horizontal="flex-start"
                        text={
                          <Grid container rowSpacing={1}>
                            <Grid item xs={12}><b>This is an external task</b></Grid>
                            <Grid item xs={12}>This task was assigned to you from an external Workflow86 account. Learn more <a href="https://docs.workflow86.com/docs/components/assign_task#external-tasks" target="_blank" style={{ textDecoration: "none", color: "rgba(33, 150, 243, 1)"}}>here</a>.</Grid>
                          </Grid>}
                      />
                    </Grid>
                  </Grid>
                  <Grid container marginBottom={2} marginTop={2}>
                    <Grid item xs={12}>
                      <SectionTitle>From</SectionTitle>
                    </Grid>
                    <Grid item xs={12}>
                      <TaskWorkflowName returnChip task={task} />
                    </Grid>
                  </Grid>
                </>
              }
              <Grid
                container
                item
                xs={12}
                direction={"row"}
                style={{ paddingBottom: "8px" }}
              >
                {editMode == false && task.assignedUser != null
                  ? task.assignedUser.map((assigned, id) => {
                      return (
                        <UserDisplayName
                          username={assigned}
                          isTaskExternal={task.isExternal}
                          taskId={task.taskId}
                          icon={<Person fontSize="small" />}
                        />
                      );
                    })
                  : ""}
                {editMode == false && task.assignedEmail != null
                  ? task.assignedEmail.map((assigned, id) => {
                      return (
                        <UserDisplayName
                          userType="EMAIL"
                          username={assigned}
                          isTaskExternal={task.isExternal}
                          taskId={task.taskId}
                          icon={<Email fontSize="small" />}
                        />
                      );
                    })
                  : ""}
                {
                  task.assignedEmail === null && task.assignedUser === null
                  && "-"
                }
                {editMode == true ? (
                  <>
                    <Autocomplete
                      options={allUsers}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                          return (
                            <Chip
                              color="default"
                              label={option.displayName}
                              {...getTagProps({ index })}
                            />
                          );
                        })
                      }
                      fullWidth
                      size={"small"}
                      multiple
                      freeSolo={false}
                      openOnFocus={true}
                      getOptionLabel={(option) => {
                        return option.displayName;
                      }}
                      variant={"outlined"}
                      value={assignedUser}
                      onChange={(event, inputValue, reason) =>
                        handleSetUsers(event, inputValue, reason)
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            placeholder={"Select users"}
                            size={"small"}
                            variant="outlined"
                            fullWidth
                          />
                        );
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                container
                item
                xs={12}
                direction={"row"}
                style={{ paddingBottom: "8px" }}
              >
                {editMode == true && (
                  <>
                    <ChipInput
                      inputValue={assignedEmail}
                      fullWidth={true}
                      placeholder={"Insert emails "}
                      onPlaceholderSelected={handleChangeEmail}
                      onDelete={(index) => {
                        // Deletes email
                        handleDeleteEmail(index);
                      }}
                    />
                  </>
                )}
              </Grid> 
              {
                !expandDetails && 
                <Tooltip title="Expand details" placement="top">
                  <ExpandButton onClick={() => setExpandDetails(true)}>
                    <ExpandMore />
                  </ExpandButton>
                </Tooltip>
              }
              { expandDetails && (
                <>
                <SectionTitle>Description</SectionTitle>
                {editMode == false && task.taskDescription != null && task.taskDescription.length !== 0 ? (
                  <span style={editMode == true ? { display: "none" } : {}}>
                    <JoditEditor
                      className={"taskDescription"}
                      value={task.taskDescription}
                      config={CONFIG}
                    />
                  </span>
                ) : (
                  "-"
                )}
                <SectionTitle>Task subject</SectionTitle>
                {editMode == false && <ContentParagraph>{taskSubject}</ContentParagraph>}
                {editMode == true && (
                  <>
                    <TextField
                      variant="outlined"
                      value={taskSubject}
                      onChange={(event) => {
                        setTaskSubject(event.target.value);
                      }}
                      style={{ paddingBottom: "8px" }}
                    />
                  </>
                )}
                {editMode == true && (
                  <div style={{ paddingBottom: "8px" }}>
                    <TextEditor
                      editorFocus={() => {}}
                      onChange={handleChangeDescription}
                      disableTooltip
                      contentStyles={{
                        border: "1px solid rgba(0, 0, 0, 0.16)",
                        boxShadow: "none",
                        padding: "8px",
                        minWidth: "100%",
                      }}
                      html={taskDescription}
                    />
                  </div>
                )}
                {!task.isExternal &&
                  <>
                    <SectionTitle>Component Name</SectionTitle>
                    <p className={"bold"}>
                      {task.componentName ? task.componentName : "-"}
                    </p>
                  </>
                }
                <SectionTitle>Due Date</SectionTitle>
                <p className={task.overDue && task.status !== "DONE" ? "txt-overdue" : "-"}>
                  {!editMode ? (
                    task.dueDate != null ? (
                      format(task.dueDate, ProjectStore.state.timezone) +
                      " (Due " +
                      format(
                        task.dueDate,
                        ProjectStore.state.timezone,
                        "relative"
                      ) +
                      ")"
                    ) : (
                      <p>-</p>
                    )
                  ) : (
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DateTimePicker
                        disabled={!editMode}
                        value={task.dueDate}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={handleTaskDueDateChange}
                      />
                    </LocalizationProvider>
                  )}
                </p>

                {!task.isExternal && <TaskOpens taskId={task.taskId} />}

                <SectionTitle>Date Assigned</SectionTitle>
                {task.assignedOn != null ? (
                  <Tooltip
                    title={format(task.assignedOn, ProjectStore.state.timezone)}
                    placement="bottom-start"
                  >
                    <p>
                      {format(
                        task.assignedOn,
                        ProjectStore.state.timezone,
                        "relative"
                      )}
                    </p>
                  </Tooltip>
                ) : (
                  <p>-</p>
                )}

                <SectionTitle>Date Completed</SectionTitle>
                {task.completedDate != null ? (
                  <Tooltip
                    title={format(
                      task.completedDate,
                      ProjectStore.state.timezone
                    )}
                    placement="bottom-start"
                  >
                    <p>
                      {format(
                        task.completedDate,
                        ProjectStore.state.timezone,
                        "relative"
                      )}
                    </p>
                  </Tooltip>
                ) : (
                  <p>NA</p>
                )}
                <SectionTitle>Time to complete</SectionTitle>
                {timeTracking ? 
                  renderTimeTracking(timeTracking.timeTracking, timeTracking.status) 
                  : "-"}
                <SectionTitle>Task ID</SectionTitle>
                <p>{task.taskId}</p>
                <Tooltip title="Collapse details" placement="top">
                  <ExpandButton onClick={() => setExpandDetails(false)}>
                    <ExpandLess />
                  </ExpandButton>
                </Tooltip>
                </>
              )
              }
            </Grid>
          </StyledPaper>
        </Box>
    );
  } else return (
    <>
      <Skeleton width="100%" height={"5vh"} />
      <Skeleton width="100%" height={"10vh"} />
    </>
  );
};

export default withStyles(styles)(TaskDetails);
