import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox } from "@mui/material";

// Styled components
export const Container = styled(Grid)({
  width: "100%",
  padding: "24px",
  backgroundColor: "white",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px"
});

export const Row = styled(Grid)({
  marginBottom: "8px"
});

export const RowItem = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start"
});

export const Header = styled("h1")({
  fontSize: "14px",
  lineHeight: "19px",
  color: "#000"
});

export const InputLabel = styled("div")({
  fontSize: "14px",
  lineHeight: "19px",
  color: "#000",
  margin: "18px 0px 8px 0px"
});

export const PlaceholderBox = styled("div")({
  width: "100%",
  height: "100%",
  backgroundColor: "#FCFCFC",
  border: "1px solid rgba(0, 0, 0, 0.36)",
  borderRadius: "4px",
  padding: "10px 16px",
  paddingLeft: "30px",
  width: "425px",
  overflow: "hidden",
  fontSize: "14px",
  lineHeight: "16px"
});

export const Dropdown = styled(Autocomplete)({
  width: "650px",
  backgroundColor: "#fff !important"
});

export const StyledCheckBox = styled(Checkbox)({
  padding: "0px",
  marginRight: "32px"
});

export const InfoLabel = styled("span")({
  margin: "8px",
  fontSize: "12px"
});

export const BoldText = styled("span")({
  fontWeight: "bold"
});

export const StyledOpenInNewIcon = styled(OpenInNewIcon)({
  width: "15px",
  height: "15px",
  marginRight: "4px"
});

export const OpenWorkflowText = styled("span")({
  fontSize: "14px",
  lineHeight: "19px",
  color: "#343434",
  display: "flex",
  alignItems: "center",
  marginTop: "16px"
});

export const Text = styled("span")({
  fontSize: "14px",
  lineHeight: "19px"
});

export const BlueText = styled("span")({
  fontSize: "14px",
  color: "#2196f3",
  margin: "0px 3px",
  "&:hover": {
    color: "rgba(33, 150, 243, 0.75)"
  },
  display: "flex",
  alignItems: "center"
});
