
//TODO this needs clean up at some point
const awsconfig = {
  USAGE_SERVICE_ADDRESS: "",
  REGION: process.env.REGION || "ap-southeast-2",
  USER_POOL_ID: process.env.USER_POOL_ID || "ap-southeast-2_4pkuaVYZY",
  APP_CLIENT_ID: process.env.APP_CLIENT_ID || "7k5h7tnrnkdcg78d9ofpt1opij",
  DOMAIN: process.env.DOMAIN || "workflow86.com",
  ISS: ""
};

const aws_config = {
  Auth: {
    Cognito: {
      userPoolId: awsconfig.USER_POOL_ID,
      userPoolClientId: awsconfig.APP_CLIENT_ID,
      signUpVerificationMethod: 'code',
      loginWith: {
        oauth: {
          domain:  'authenticateme.app.workflow86.com',
          scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: ['https://app.workflow86.com'],
          redirectSignOut: ['https://app.workflow86.com'],
          responseType: 'code',
        },
        username: true,
        email: true,
        phone: true,
      },
    },
    region: awsconfig.REGION,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  oauth: {
    domain: 'authenticateme.app.workflow86.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn:'https://app.workflow86.com',
    redirectSignOut:'https://app.workflow86.com',
    responseType: 'code',
  },
  gg_client_id: "541992205730-eaa8et59jir07ttls4vqgci5hpe5hdr5.apps.googleusercontent.com",
  cookieStorage: {
    domain: window.location.host.includes("localhost")
      ? "localhost"
      : awsconfig.DOMAIN,
    path: "/",
    expires: 1,
    secure: false
  }
};

// Keeping additional properties for reference, but not including in the main config
const additionalConfig = {
  mandatorySignId: true,
  cookieStorage: {
    domain: window.location.host.includes("localhost")
      ? "localhost"
      : awsconfig.DOMAIN,
    path: "/",
    expires: 1,
    secure: false
  },
  aws_cognito_region: "ap-southeast-2",
  aws_user_pools_id:  "ap-southeast-2_4pkuaVYZY",
  aws_user_pools_web_client_id: "7k5h7tnrnkdcg78d9ofpt1opij",
  gg_client_id: "541992205730-eaa8et59jir07ttls4vqgci5hpe5hdr5.apps.googleusercontent.com"
};

export default aws_config;

