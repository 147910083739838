import {send_request, send_request_prebuilt_graphql} from "../../../../../../utils/Request";
import {RETRIEVE_EDITOR_SESSION, RETRIEVE_SESSION} from "../../AIBuilderQueries";
import {FINISH_SETTING_UP_MESSAGE, getProposalMessage} from "../../components/Chat/StaticMessages/Assistant";
import {ERROR_MESSAGE} from "../../components/Chat/StaticMessages/Error";
import {REFUSAL_ERROR_MESSAGE} from "../../components/Chat/StaticMessages/REFUSAL_ERROR_MESSAGE";
import {
  determineInitialMessage,
  determineInsertLoadingMessageBasedOnState,
  determineSuccessMessage,
  determineUserClickedInsertMessage
} from "../../components/Chat/ChatStateManager";
import ReactJson from "react-json-view";

export const convertEventsAndSyncState = async fns => {
  const { events, setMessages, chatState } = fns;

  if (!events) return [];

  let messages = [determineInitialMessage(chatState)];

  for (let i = 0; i < events.length; i++) {
    let event = events[i];
    if (!event) continue;

    let nextEvent = events[i + 1]; // Grab next event

    try {
      let translatedEvent = await handleEventMappingToFunction(
        event,
        nextEvent,
        fns
      );
      if (!translatedEvent) continue;
      if (Array.isArray(translatedEvent)) {
        // If translatedEvents is an array
        for (const transEvent of translatedEvent) {
          if (transEvent) {
            messages.push(transEvent);
          }
        }
      } else {
        // If translatedEvents is a single event
        messages.push(translatedEvent);
      }
    } catch (e) {
      console.log(e);
    }
  }

  setMessages(messages); // Set messages

  // Sync the state from the messages
  await syncChatState(fns);
};

// Handles mapping the event type to the given functionality
const handleEventMappingToFunction = async (event, nextEvent, fns) => {
  const {
    setUserPrompt,
    setPrebuildProposal,
    setOpenPrebuildPreview,
    setMermaidJSContent,
    chatState
  } = fns;

  switch (event.aiEventType) {
    // user
    case "USER_SENT_INITIAL_PREBUILD_MESSAGE":
      return getUserSentInitialPrebuildMessage(setUserPrompt, event);
    case "USER_ANSWERED_PREBUILD_QUESTION":
      return getUserAnsweredPrebuildQuestion(event);
    case "USER_SENT_FEEDBACK_TO_PREBUILD_PROPOSAL":
      return getUserSentFeedbackToProposal(event);
    case "USER_CLICKED_INSERT_WORKFLOW_ONTO_CANVAS":
      return getUserClickedInsertWorkflowOntoCanvas(event, chatState);
    case "USER_SENT_ADDITIONAL_MESSAGE_TO_BUILD_WORKFLOW":
      return getUserSentAdditionalMessageToBuild(event);
    case "USER_CLICKED_BUILD_ALL_COMPONENTS":
      return getUserClickedBuildAllComponents(event,fns.chatState);
    case "USER_CLICKED_CANCEL_PREBUILD_REQUEST":
      return getUserClickedCancelPrebuildRequest(event);
    case "USER_CLICKED_CANCEL_BUILD_REQUEST":
      return getUserClickedCancelBuildRequest(event);

    // ai
    case "AI_SENT_PREBUILD_QUESTION_TO_USER":
      return getPrebuildQuestionMessage(event, nextEvent);
    case "AI_SENT_PREBUILD_PROPOSAL_TO_USER":
      return await getPrebuildProposalMessage(
        event,
        setPrebuildProposal,
        setOpenPrebuildPreview,
        setMermaidJSContent,
        chatState
      );

    // w86
    case "W86_BUILD_STREAMING_COMPLETED":
      return await getBuildStreamingCompleted(event,fns.chatState);
    /*case "W86_PREBUILD_STREAMING_COMPLETED":
      return await  getBuildStreamingCompleted(event,fns.chatState);*/
    case "W86_WORKFLOW_INSTRUCTION_MESSAGE_SENT":
      return getWorkflowInstructionMessage(event);
    case "W86_INSERTING_WORKFLOW_ONTO_CANVAS":
      return getInsertingWorkflowOntoCanvas(event, chatState);
    case "W86_INSERTED_WORKFLOW_ONTO_CANVAS_SUCCESSFULLY":
      return getInsertedWorkflowOntoCanvasSuccessfully(event, chatState);
    case "W86_COMPONENT_BUILD_INSTRUCTION_MESSAGE_SENT":
      return getComponentBuildInstructionMessage(event);
    case "W86_BUILD_WITH_ADDITIONAL_MESSAGE_STREAMING_COMPLETED":
      return await getAdditionalMessageBuildCompleted(event);
    case "W86_BUILD_ALL_COMPONENTS_COMPLETED":
    case "W86_EDIT_ALL_COMPONENTS_COMPLETED":
      return await getBuildAllComponentsCompleted(event);
    case "W86_PREBUILD_REQUEST_CANCELLED_MESSAGE_SENT":
      return getPrebuildRequestCancelledMessage(event);
    case "W86_BUILD_REQUEST_CANCELLED_MESSAGE_SENT":
      return getBuildRequestCancelledMessage(event);
    case "W86_BUILD_STREAMING_CANCELLED":
      return await getBuildStreamingCancelled(event);
    case "W86_ERROR_MESSAGE_SENT":
      return getErrorMessageSent(event);
    case "W86_BUILD_AI_DATABASES_COMPLETED":
      return getDataBaseCreatesMessage(event);
    case "W86_START_A_TEST_RUN_MESSAGE_SENT":
      return getStartATestRunMessageSent(event);
    case "W86_PERFORMING_AI_WORKFLOW_EDITS":
      return getPerformingAIEditMessage(event);
    default:
      break;
  }
};

// ============ USER ============ //
const getUserSentInitialPrebuildMessage = (setUserPrompt, event) => {
  /**
     * We need to extract both the rawValue & displayValue
     * This is done as we occasionally send a message in the chat that is different
     * from the actual value
     * i.e: 
     * {
     *     displayValue: "Translate these notes into a workflow"
     *     rawValue: "...the ai converted workflow structure..."
     * }
     * 
     * This will do displayValue === rawValue for legacy chat sessions
     */
  const { rawValue, displayValue } = extractRawAndDisplayValue(event.content);
  setUserPrompt(rawValue); // Set the user prompt here to the rawValue
  return { role: "user", content: displayValue }; // Set the message to the display value
};

// Returns the rawValue and displayValue from a given initial prebuild message
const extractRawAndDisplayValue = content => {
  try {
    if (!content.rawValue || !content.displayValue)
      throw new Error("No rawValue or displayValue");
    return {
      rawValue: content.rawValue,
      displayValue: content.displayValue
    };
  } catch (e) {
    // Default to both being content
    return {
      rawValue: content,
      displayValue: content
    };
  }
};

const getUserAnsweredPrebuildQuestion = event => {
  if (!event.content) return;
  return { role: "user", content: event.content.questionComment };
};

const getUserAcceptedPrebuildProposal = event => {
  return { role: "user", content: "Accept proposal" };
};

const getUserSentFeedbackToProposal = event => {
  return { role: "user", content: event.content };
};

const getUserClickedInsertWorkflowOntoCanvas = (event, chatState) => {
  return determineUserClickedInsertMessage(chatState);
};

const getUserSentAdditionalMessageToBuild = event => {
  return { role: "user", content: event.content };
};

const getUserClickedBuildAllComponents = (event, chatState) => {
  if (chatState === "WORKFLOW_EDITOR") return { role: "user", content: "Finish setting up for me" };
  else return { role: "user", content: event.content };
};

const getUserClickedCancelPrebuildRequest = event => {
  return { role: "user", content: event.content };
};

const getUserClickedCancelBuildRequest = event => {
  return { role: "user", content: event.content };
};

// ============ AI ============ //
const getPrebuildQuestionMessage = (event, nextEvent) => {
  let data = JSON.parse(event.content);
  if (!data) return;

  // Fetch answers if previously provided
  let historyAnswers = null;
  if (
    nextEvent &&
    nextEvent.aiEventType === "USER_ANSWERED_PREBUILD_QUESTION" &&
    nextEvent.content
  ) {
    historyAnswers = nextEvent.content.questionAnswers;
  }

  return {
    role: "questionAnswerMessage",
    content: data,
    historyAnswers: historyAnswers
  };
};

const getPrebuildProposalMessage = async (
  event,
  setPrebuildProposal,
  setOpenPrebuildPreview,
  setMermaidJSContent,
  chatState
) => {
  let data = JSON.parse(event.content);

  if (chatState === "WORKFLOW_EDITOR") setPrebuildProposal(data);
  else setPrebuildProposal(data.proposal);
  
  let historyMermaidJS = data.proposalMermaidJS;

  if (!historyMermaidJS) {
    let url = `project-service/project-ai/assistant/retrieve_linked_events/${event.eventId}`;
    const json = await send_request(url, null, null, "GET");

    if (json && json.data) {
      const linkedEvent = json.data.find(
        item =>
          item.aiEventType === "W86_PROPOSAL_DIAGRAM_SUCCESSFULLY_GENERATED"
      );

      if (!linkedEvent || !linkedEvent.content) historyMermaidJS = "error";
      else historyMermaidJS = linkedEvent.content.proposalMermaidJS;

      if (!data) return;

      switch (chatState) {
        case "STRATEGY":
        case "DISCOVERY": {
            return {
              role: "streamingMessage",
              showAnimation: false,
              sidebarState: chatState,
              loadingHistory: data
            };
        }
        case "WORKFLOW_EDITOR": {
          let arr=[];

          let res = await getTranslatedAIEditSession(event)
          if (res && res.data && res.data.retrieveTranslatedAIEditSession) {
            arr.push({
              role: "streamingMessage",
              showAnimation: false,
              loadingHistory:  res.data.retrieveTranslatedAIEditSession,
            });
          }
          return arr;
        }

        default: {
          return getProposalMessage(
            data,
            null,
            setOpenPrebuildPreview,
            setMermaidJSContent,
            null,
            historyMermaidJS,
            null,
            null
          );
        }
      }
    }
  }
};

// ============ W86 ============ //
const getBuildStreamingCompleted = async (event, chatState) => {
};

const getPerformingAIEditMessage = (event) => {
  //setting for edit stages to be used later
  let arr = [];

  arr.push( { role: "editChangesProgressMessage", loadingHistory: event.content });
  arr.push(FINISH_SETTING_UP_MESSAGE);

  return arr;
}

const getAdditionalMessageBuildCompleted = async event => {
  let eventId = event.eventId; // We can use this to fetch the streaming event

  let url = `project-service/project-ai/assistant/retrieve_segment_with_event_id/${eventId}`;
  const json = await send_request(url, null, null, "GET");

  if (json && json.data) {
    return {
      role: "streamingMessage",
      showAnimation: false,
      loadingHistory: json.data
    };
  }
};

const getWorkflowInstructionMessage = event => {
  return { role: "instruction", content: "" };
};

const getInsertingWorkflowOntoCanvas = (event, chatState) => {
  return determineInsertLoadingMessageBasedOnState(chatState, false);
};

const getInsertedWorkflowOntoCanvasSuccessfully = (event, chatState) => {
  return determineSuccessMessage(chatState);
};

const getComponentBuildInstructionMessage = () => {
  return { role: "componentBuildInstruction", content: "" };
};
const getDataBaseCreatesMessage = event => {
  return { role: "dataBasesCreated", content: event.content };
};

const getStartATestRunMessageSent = () => {
  return { role: "testRunInstructionMessage", content: "" };
};

const getBuildAllComponentsCompleted = async event => {
  if (!event.content) return;

  return {
    role: "componentBuildStatus",
    content: event.content,
    timeout: 1000
  };
};

const getPrebuildRequestCancelledMessage = event => {
  return { role: "assistant", content: event.content };
};

const getBuildRequestCancelledMessage = event => {
  return { role: "assistant", content: event.content };
};

const getBuildStreamingCancelled = async event => {
  let eventId = event.eventId; // We can use this to fetch the streaming event

  let url = `project-service/project-ai/assistant/retrieve_segment_with_event_id/${eventId}`;
  const json = await send_request(url, null, null, "GET");

  if (json && json.data) {
    let info = json.data;
    info["wasCancelled"] = true;
    return {
      role: "streamingMessage",
      showAnimation: false,
      loadingHistory: info
    };
  }
};

const getErrorMessageSent = event => {
  if (!event) return;
  let error = event.content;

  const type = error.errorType;

  if (error && type === "GPT_REFUSE_EXCEPTION")
    return REFUSAL_ERROR_MESSAGE(error.content);
  else if (error && type !== "INTERNAL" && error.errorMessage) {
    return {
      role: "error",
      content: "❌ " + error.errorMessage
    };
  } else return ERROR_MESSAGE;
};

const getTranslatedAIEditSession = async(lastEvent)=> {
  let url = `project-service/graphql/project/${lastEvent.projectId}`;
  return await send_request_prebuilt_graphql(
      url,
      RETRIEVE_EDITOR_SESSION(lastEvent.sessionId)
  );
}

const  setComponentsAndEditStage = async (events, setEditChanges, setCompForBuilding, setCompForEditing, setApplyingEditStages) =>{
  const lastEvent = events[events.length - 1];

  let res = await getTranslatedAIEditSession(lastEvent);

  //required for showing all the required stages
  if (res && res.data && res.data.retrieveTranslatedAIEditSession) {
    await setEditChanges(res.data.retrieveTranslatedAIEditSession);
    let editChanges = res.data.retrieveTranslatedAIEditSession;
    if (editChanges && editChanges.editChangesDTO) {
      const changes = editChanges.editChangesDTO;
      setCompForBuilding(changes.componentsToAdd);
      setCompForEditing(changes.componentsToUpdate);

    }
  }
  let isCompleted = true;
  if(lastEvent.content && lastEvent.content.editStages)
  {
    await setApplyingEditStages({
      ...lastEvent.content,
      isCompleted
    });
  } else{
    for(let editIndex in events)
    {
      if(events[editIndex].aiEventType==="W86_PERFORMING_AI_WORKFLOW_EDITS"){
        await setApplyingEditStages({
          ...events[editIndex].content,
          isCompleted
        })
        break;
      }
    }

    // we need to find the event with correct content to set edit stages

  }

}

// ============= SYCHRONISING STATE =============== //
export const syncChatState = async fns => {
  const {
    events,
    draftVersion,
    setComponentInfoForNodeHeader,
    setIsPrebuild,
    setNewComponents,
    setNewDiagram,
    setCompForBuilding,
    setShowInstruction,
    setShowBuildButtons,
    setShowConclude,
    setStreamingStatus,
    setShowUserPrompt,
    setPrebuildProposal,
    setPrebuildQuestions,
    setPrebuildQuestionAnswers,
    setCancelled,
    setCancelBuild,
    setUserPrompt,
    parseWorkflowAndDisplayDraft,
    generate,
    setAiDatabases,
    setSessionTraceId,
    setSidebarState,
    parseAndDisplayEditChanges,
    beginPollingForEditProgress,
    addApplyingEditsMessage,
    setApplyingEditStages,
    setEditChanges,
    setHideCancel,
    setHasAppliedEdit,
    setCompForEditing,
    chatState
  } = fns; // De-structure required functions and info


  const lastEvent = events[events.length - 1];

  if(!lastEvent) return;
  let url = `project-service/project-ai/assistant/retrieve_session_state/${lastEvent.sessionId}/${lastEvent.threadId}`;

  const eventSource = chatState === "WORKFLOW" ? "WORKFLOW_BUILDER" : "WORKFLOW_EDITOR";
  const json = await send_request(url, null,  { aiEventSource: eventSource } , "GET");

  let currentState = "GENERIC_ERROR";
  let info = {};

  if (json && json.data) {
    let res = json.data;

    if (res.chatSessionState) currentState = res.chatSessionState; // set state if given
    if (res.info) info = res.info;
  }

  const traceId = info.sessionTraceId;
  setSessionTraceId(traceId); // Set trace id

  switch (currentState) {
    case "WAITING_FOR_USER_TO_ANSWER_QUESTION":
      setIsPrebuild(true);
      setNewComponents([]);
      setCompForBuilding([]);
      setNewDiagram([]);
      setShowInstruction(false);
      setShowBuildButtons(false);
      setShowConclude(false);
      setStreamingStatus("");
      setShowUserPrompt(false);
      setCancelled(false);
      setCancelBuild(false);
      setUserPrompt("");

      // pre-build
      setPrebuildProposal("");

      const content = info.content ? info.content : lastEvent.content;
      setPrebuildQuestions(content);

      try {
        let data = JSON.parse(content);
        let answers = Array.from({ length: data.questions.length }, () => "");
        setPrebuildQuestionAnswers(answers);
      } catch (e) {}

      break;

    case "WAITING_FOR_USER_TO_ACCEPT_PROPOSAL":
      if (chatState === "WORKFLOW_EDITOR") {

        const res = await getTranslatedAIEditSession(lastEvent);
        if (res && res.data && res.data.retrieveTranslatedAIEditSession) {

          await parseAndDisplayEditChanges(
              res.data.retrieveTranslatedAIEditSession,
              null,
              true
          );

          setIsPrebuild(true);
          setShowInstruction(true);
          setShowBuildButtons(true);
          setShowConclude(false);
          setShowUserPrompt(false);
          setStreamingStatus("");
          setCancelled(false);
          setCancelBuild(false);
          setPrebuildProposal(info.content ? info.content : lastEvent.content);
          setHideCancel(false);
        }
      }else{
        setIsPrebuild(true);
        setNewComponents([]);
        setCompForBuilding([]);
        setNewDiagram([]);
        setShowInstruction(false);
        setShowBuildButtons(false);
        setShowConclude(false);
        setStreamingStatus("");
        setShowUserPrompt(false);
        setCancelled(false);
        setCancelBuild(false);
        setUserPrompt("");

        setPrebuildProposal(info.content ? info.content : lastEvent.content);
        setPrebuildQuestions("");
      }

      break;

    case "USER_CANCELLED_PREBUILD_REQUEST":
      setIsPrebuild(true);
      setNewComponents([]);
      setCompForBuilding([]);
      setNewDiagram([]);
      setShowInstruction(false);
      setShowBuildButtons(false);
      setShowConclude(false);
      setStreamingStatus("");
      setShowUserPrompt(false);
      setCancelled(true);
      setCancelBuild(true);

      setPrebuildProposal("");
      setPrebuildQuestions("");
      setUserPrompt("");

      break;

    case "WAITING_FOR_USER_TO_INSERT_DRAFT_WORKFLOW":
      setIsPrebuild(false);
      // If here, we need to build the draft AI workflow
      // We also need to set the "newComponents" state accordingly

      // Grab the components from info
      // If not set, try to fetch via endpoint and set from there as a fall-back
      let comps = info.components;
      if (!comps || comps.length <= 0) {
        let url = `project-service/graphql/project/${lastEvent.projectId}`;
        const res = await send_request_prebuilt_graphql(
          url,
          RETRIEVE_SESSION(lastEvent.sessionId)
        );
        if (res && res.data && res.data.retrieveSession) {
          comps = res.data.retrieveSession.components;
          setAiDatabases(res.data.retrieveSession.aiDatabases);
        }
      } else {
        let db = info.aiDatabases;
        if (db && db.length >= 0) {
          setAiDatabases(db);
        }
      }

      let workflow = {
        components: comps
      };

      setCompForBuilding([]);
      setShowBuildButtons(false);
      setShowConclude(false);
      setStreamingStatus("COMPLETED");

      setCancelled(false);
      setCancelBuild(false);

      await parseWorkflowAndDisplayDraft(workflow, null, true);

      break;

    case "USER_CANCELLED_BUILDING_WORKFLOW":
      setIsPrebuild(false);

      setNewComponents([]);
      setCompForBuilding([]);
      setNewDiagram([]);

      setShowInstruction(true);
      setShowBuildButtons(false);
      setShowConclude(false);
      setStreamingStatus("");
      setShowUserPrompt(true);
      setCancelled(true);
      setCancelBuild(true);

      setPrebuildProposal("");
      setPrebuildQuestions("");

      break;

    case "WAITING_FOR_USER_TO_BUILD_ALL_COMPONENTS":
      setIsPrebuild(false);
      setAiDatabases(info.aiDatabases);

      setCompForBuilding(info.compForBuilding);
      setNewComponents([]);
      setNewDiagram([]);

      setShowInstruction(true);
      setShowBuildButtons(true);
      setShowConclude(false);
      setShowUserPrompt(false);

      setStreamingStatus("COMPLETED");

      setCancelled(false);
      setCancelBuild(false);

      break;

    case "WAITING_FOR_USER_TO_CONCLUDE_SESSION":
      if (chatState === "WORKFLOW_EDITOR") {
        await setComponentsAndEditStage(events, setEditChanges, setCompForBuilding, setCompForEditing, setApplyingEditStages);
      } else{
        setCompForBuilding(info.compForBuilding);
      }

      setIsPrebuild(false);
      setHasAppliedEdit(true);

      setNewComponents([]);
      setNewDiagram([]);

      setShowInstruction(true);
      setShowBuildButtons(false);
      setShowConclude(true);
      setStreamingStatus("COMPLETED");
      setShowUserPrompt(false);

      setCancelled(false);
      setCancelBuild(false);

      let nodeHeaderInfo = info.componentNodeInfo;

      // If not given from backend, try to re-construct on the front-end similar to the buildAllComponents func
      if (!nodeHeaderInfo || nodeHeaderInfo.length <= 0) {
        let componentInfo = [];
        for (let component in info.compForBuilding) {
          let userPromptData = component.instructions
            ? component.instructions.buildInstruction
            : component.description
              ? component.description
              : component.instruction;
          let componentInfoMap = {
            componentId: component.componentId,
            componentType: component.type,
            generatedTitle: component.generatedTitle,
            userPrompt: userPromptData, // fallback to description or instruction in case instructions are not found
            status: "AI_BUILD_STARTING"
          };
          componentInfo.push(componentInfoMap);
        }

        for (let component of componentInfo) {
          // If the component has been built aready, skip it
          if (
            component.status === "AI_BUILD_GENERATED" ||
            component.status === "AI_BUILD_ERROR"
          )
            continue;

          const url = `project-service/project/get_component_status/${component.componentId}/${draftVersion}`;
          const response = await send_request(url);

          if (response) component["status"] = response.data; // Set status accordingly
        }

        // Set the node header info to this
        nodeHeaderInfo = componentInfo;
      }

      if (!nodeHeaderInfo) nodeHeaderInfo = [];

      setComponentInfoForNodeHeader(nodeHeaderInfo);

      break;

    case "USER_CANCELLED_BUILD_ALL_COMPONENTS":
      break;

    case "WAITING_FOR_REDIRECT_AND_BUILD_AFTER_ONBOARDING":
      setUserPrompt(lastEvent.content);
      setIsPrebuild(true);
      setNewComponents([]);
      setCompForBuilding([]);
      setNewDiagram([]);
      setShowInstruction(false);
      setShowBuildButtons(false);
      setShowConclude(false);
      setStreamingStatus("");
      setShowUserPrompt(false);
      setCancelled(false);
      setCancelBuild(false);
      setPrebuildProposal("");
      setPrebuildQuestions("");

      generate(
        null,
        info.sessionId,
        info.threadId,
        traceId,
        false,
        false,
        lastEvent.content
      );
      break;

    case "WAITING_FOR_REDIRECT_AND_BUILD_AFTER_TRANSLATING_NOTES":
      const rawValue = JSON.stringify(lastEvent.content);

      setUserPrompt(rawValue);
      setIsPrebuild(true);
      setNewComponents([]);
      setCompForBuilding([]);
      setNewDiagram([]);
      setShowInstruction(false);
      setShowBuildButtons(false);
      setShowConclude(false);
      setStreamingStatus("");
      setShowUserPrompt(false);
      setCancelled(false);
      setCancelBuild(false);
      setPrebuildProposal("");
      setPrebuildQuestions("");
      setSidebarState("WORKFLOW");

      const displayValue = "Translate these notes into a workflow";
      generate(
        null,
        info.sessionId,
        info.threadId,
        traceId,
        false,
        false,
        rawValue,
        displayValue
      );

      break;

    case "WAITING_FOR_USER_TO_FINISH_SETTING_UP_EDIT_CHANGES":
      await setComponentsAndEditStage(events, setEditChanges, setCompForBuilding, setCompForEditing, setApplyingEditStages);
        setIsPrebuild(false);
        setShowInstruction(true);
        setShowBuildButtons(true);
        setShowConclude(false);
        setShowUserPrompt(false);
        setStreamingStatus("COMPLETED");
        setCancelled(false);
        setCancelBuild(false);
        setPrebuildProposal(null);
        setHideCancel(false);
        setHasAppliedEdit(false);

      break;

    default:
      // GENERIC ERROR STATE HERE
      setNewComponents([]);
      setCompForBuilding([]);
      setNewDiagram([]);

      setShowInstruction(true);
      setShowBuildButtons(false);
      setShowConclude(false);
      setStreamingStatus("");
      setShowUserPrompt(true);
      setCancelled(true);
      setCancelBuild(true);

      setPrebuildProposal("");
      setPrebuildQuestions("");
      setUserPrompt("");

      break;
  }

};
