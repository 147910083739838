import React from "react";

export const WelcomePageTourPrivateSteps = [
  {
    selector: '[data-tut="reactour__useful__links"]',
    content: () => (
      <>
        <p className={"bold"}>🙂 Useful links</p>
        <p>
          Here you will find some useful links to some important parts of the
          platform.
        </p>
      </>
    )
  },
  {
    selector: '[data-tut="reactour__portal"]',
    content: () => (
      <>
        <p className={"bold"}>🚪 Find a workflow portal</p>
        <p>
          Navigate to the Portals page to view the workflow portals you have
          access to.
        </p>
      </>
    )
  }
];

export const WelcomePageTourSteps = [
  // {
  //   selector: '[data-tut="reactour__freeTrialCountdown"]',
  //   content: () => (
  //     <>
  //       <p className={"bold"}>🚀 You are currently on the Free Trial</p>
  //       <p>
  //         You are currently on the Free Trial plan, which gives you <b>14 days</b> to try out all the powerful features that Workflow86 has to offer. 
  //       </p>
  //     </>
  //   )
  // },
  // {
  //   selector: '[data-tut="reactour__freeTrialCountdown"]',
  //   content: () => (
  //     <>
  //       <p className={"bold"}>🔼 Extend your Free Trial</p>
  //       <p>
  //         You can extend your Free Trial to <b>28 days</b> and get an extra <b>50 actions</b>{" "}
  //         by running your first worflow session.{" "}
  //         More details 👉<a href="https://docs.workflow86.com/docs/first_workflow/" target="_blank" style={{ textDecoration: "none", color: "rgba(33, 150, 243, 1)" }}>here</a>
  //       </p>
  //     </>
  //   )
  // },
  {
    selector: '[data-tut="reactour__home"]',
    content: () => (
      <>
        <p className={"bold"}>🏠 Home page</p>
        <p>
          You are on the home page, which acts as your dashboard, and displays
          information and data across all workflows in your organisation.
        </p>
      </>
    )
  },
  {
    selector: '[data-tut="reactour__project"]',
    content: () => (
      <>
        <p className={"bold"}>📚 Workflow Library</p>
        <p>
          Here is your workflow library. You will find any workflows you create
          or import here.
        </p>
      </>
    )
  },
  {
    selector: '[data-tut="reactour__databases"]',
    content: () => (
      <>
        <p className={"bold"}>🗃 Databases</p>
        <p>
          Here you will find the built-in spreadsheet-like databases that can be
          used stand alone and integrated into your workflows
        </p>
      </>
    )
  },
  {
    selector: '[data-tut="reactour__mytasks"]',
    content: () => (
      <>
        <p className={"bold"}>🗃 Built-in task management</p>
        <p>
          Here you will find any tasks assigned to you via the Assign Task
          component.
        </p>
      </>
    )
  },
  {
    selector: '[data-tut="reactour__marketplace"]',
    content: () => (
      <>
        <p className={"bold"}>🛒 Marketplace</p>
        <p>
          This is the marketplace. This is where you can find ready-made
          workflows which you can download and use straight away or customise
          them to your own requirements.
        </p>
      </>
    )
  },
  {
    selector: '[data-tut="reactour__project"]',
    content: () => (
      <>
        <p className={"bold"}>👈 Click here to continue the tour</p>
        <p>
          Click on the workflow library icon in the menu to continue the tour.
        </p>
      </>
    )
  }
];

export const ProjectPageTourSteps = [
  {
    selector: '[data-tut="reactour__new__workflow"]',
    content: () => (
      <>
        <p className={"bold"}>⭐ Create a new workflow </p>
        <p>You can create a new workflow from scratch by clicking this</p>
      </>
    )
  },
  {
    selector: '[data-tut="reactour__import__template"]',
    content: () => (
      <>
        <p className={"bold"}>📥 Import a template</p>
        <p>
          You can also import a workflow by clicking here. This will navigate
          you to the marketplace.
        </p>
      </>
    )
  },
  {
    selector: '[data-tut="reactour__new__workflow"]',
    content: () => (
      <>
        <p className={"bold"}>👉 Let’s create a new workflow</p>
        <p>
          Click here to create a workflow and introduced to the workflow canvas
        </p>
      </>
    )
  }
];

export const CanvasPageTourSteps = [
  {
    selector: '[data-tut="reactour__readme__dialog"]',
    content: () => {
      return (
        <>
          <p className={"bold"}>👀 Workflow Read Me</p>
          <p>
            Every workflow has a Read Me where you can store information and
            instructions on the workflow. This dialog will appear every time
            someone enters the canvas unless you click “Do not show again”.
          </p>
        </>
      );
    }
  },
  {
    selector: '[data-tut="reactour__canvas"]',
    content: () => {
      return (
        <>
          <p className={"bold"}>🎨 A canvas for your workflow masterpiece</p>
          <p>
            This is the Workflow Canvas. This is where you will build your
            workflows by dragging, dropping and connecting components together.
          </p>
        </>
      );
    }
  },
  {
    selector: '[data-tut="reactour__canvas__notes"]',
    content: () => {
      return (
        <>
          <p className={"bold"}>📝 Document and map your processes</p>
          <p>
            Document, map and brainstorm your processes before building using our notes feature. This allows you to place notes onto the canvas and connect them together to create process maps. You can also use these notes to document your workflows, and leave instructions for other users on your team.
          </p>
        </>
      );
    }
  },
  {
    selector: '[data-tut="reactour__canvas__newb"]',
    content: () => {
      return (
        <>
          <p className={"bold"}>😌 Building a workflow, made easy</p>
          <p>
            The New and Easy Workflow Builder (NEWB) is a guided wizard that
            helps you create simple workflows step by step. If you are a
            beginner, this is the perfect place to start.
          </p>
        </>
      );
    }
  },
  {
    selector: '[data-tut="reactour__canvas__open_components"]',
    content: "Click here to open the components menu 👉"
  },
  {
    selector: '[data-tut="reactour__canvas__sidebar"]',
    content: () => {
      return (
        <>
          <p className={"bold"}>🧰 Your toolbox of components</p>
          <p>
            Components are the building blocks of a workflow. To add a component
            to your workflow, drag and drop it onto the canvas. You can also
            minimize this menu at any time.
          </p>
        </>
      );
    }
  },
  {
    selector: '[data-tut="reactour__canvas__test"]',
    content: () => {
      return (
        <>
          <p className={"bold"}>🧪 Test before you run</p>
          <p>
            You can perform a test run of your workflow before publishing it. 
          </p>
        </>
      );
    }
  },
  {
    selector: '[data-tut="reactour__canvas__publish"]',
    content: () => {
      return (
        <>
          <p className={"bold"}> 🚩 Remember to publish before you run</p>
          <p>
            Before you can run a workflow, it needs to be published. Publishing
            a workflow means the workflow goes live and can be started and run.
          </p>
        </>
      );
    }
  },

  {
    selector: '[data-tut="reactour__canvas__status"]',
    content: () => {
      return (
        <>
          <p className={"bold"}> ⏲ Published and draft version tracking </p>
          <p>
            All workflows have version tracking. When you publish a workflow,
            that workflow version becomes the published version. Any changes
            will then be tracked to a new draft version. When that draft version
            is published, it becomes the published version and a new draft
            version is created.
          </p>
        </>
      );
    }
  },
  {
    selector: '[data-tut="reactour__canvas__save"]',
    content: () => {
      return (
        <>
          <p className={"bold"}> ✅ Changes are auto-saved</p>

          <p>
            Any changes made to the canvas are autosaved whenever a new action
            is taken. For example, when you drop in a new component, draw a
            connection and so on. However, you can manually save the canvas at
            any time by pressing this save button.
          </p>
        </>
      );
    }
  },
  {
    selector: '[data-tut="reactour__canvas__startworkflow"]',
    content: () => {
      return (
        <>
          <p className={"bold"}> 🚦 Starting a workflow</p>
          <p>
            Once a workflow has been published, you will see this button turn
            green. You can then press it to start the workflow.
          </p>
        </>
      );
    }
  },
  {
    selector: '[data-tut="reactour__canvas__project__access"]',
    content: () => {
      return (
        <>
          <p className={"bold"}> 🔒 Control who can run your workflow </p>
          <p>
            Here are the access controls which controls who can start and run a
            workflow. All user-facing components of a workflow (such as the
            forms) can be controlled individually, and set to either public or
            restricted to certain users only.
          </p>
        </>
      );
    }
  },
  {
    selector: '[data-tut="reactour__canvas__files"]',
    content: () => {
      return (
        <>
          <p className={"bold"}> 🗃 Files are stored here</p>
          <p>
            Any files generated during a workflow (such as documents) or files
            uploaded during a workflow, will be stored here.
          </p>
        </>
      );
    }
  },
  {
    selector: '[data-tut="reactour__canvas__logs"]',
    content: () => {
      return (
        <>
          <p className={"bold"}> 📄 Session logs and run history </p>
          <p>
            Whenever a workflow is run, all of its details are logged here. The
            workflow logs are particularly useful for tracking the progress of a
            workflow, and troubleshooting errors.
          </p>
        </>
      );
    }
  },
  {
    selector: '[data-tut="reactour__canvas__version"]',
    content: () => {
      return (
        <>
          <p className={"bold"}> ⌚ Turn back time</p>
          <p>
            The past 5 versions of a workflow are stored. You can rollback a
            workflow to a previous version here.
          </p>
        </>
      );
    }
  },
  {
    selector: '[data-tut="reactour__marketplace"]',
    content: () => {
      return (
        <>
          <p className={"bold"}> 🚩 Grab a head start via the marketplace</p>
          <p>
            To give you a head start, let’s go to the marketplace and import a
            pre-built workflow which you can get up and running right now
          </p>
        </>
      );
    }
  }
  // ...
];

export const MarketplacePageTourSteps = [
  {
    selector: "marketplace",
    content: () => (
      <>
        <p className={"bold"}>🔥 Find pre-made workflows here</p>
        <p>
          This is the marketplace. This is where you can find ready-made
          templates to download, as well as manage any templates you have
          shared.
        </p>
      </>
    )
  },
  {
    selector: '[data-tut="reactour__marketplace__categories"]',
    content: () => (
      <>
        <p className={"bold"}>📚 Find a use case</p>
        <p>
          Find a use case From administration to Sales, just click on a category
          to find templates for a particular use case.
        </p>
      </>
    )
  },
  {
    selector: '[data-tut="reactour__marketplace__download"]',
    content: () => (
      <>
        <p className={"bold"}>👇 Just click to download</p>
        <p>
          To download a template, simply click on it and follow the dialog
          prompts.
        </p>
      </>
    )
  },
  {
    selector: '[data-tut="reactour__marketplace__search"]',
    content: () => (
      <>
        <p className={"bold"}>🔍 Find your onboarding workflow</p>
        <p>
          Let’s get you started by downloading a pre-built onboarding workflow.
          Search for “First workflow” and then click on it to download the
          template.
        </p>
      </>
    )
  }
  // ...
];

export const RepositoryPageTourSteps = [
  {
    content: () => (
      <>
        <p className={"bold"}>✅ Manage your templates here</p>
        <p>
          Here you will find all templates you have deployed to either the
          public or private marketplace, as well as any templates made shareable
          via link.
        </p>
      </>
    )
  },
  {
    selector: '[data-tut="reactour__marketplace__REMOVE"]',
    content: () => (
      <>
        <p className={"bold"}>❌ Remove a template</p>
        <p>
          Click Remove to remove a template from the marketplace and deactivate
          the template link.
        </p>
      </>
    )
  }
];

export const PortalPageTourSteps = [
  {
    selector: '[data-tut="reactour__portal__page"]',
    content: () => (
      <>
        <p className={"bold"}>🚪 Portals are the entry points to a workflow</p>
        <p>
          Welcome to the Portals page. A portal is an entry point to a workflow
          and the action that starts a workflow. To start a workflow, simply
          click on its portal card.
        </p>
      </>
    )
  },
  {
    selector: '[data-tut="reactour__portal__search"]',
    content: "You can search for portals here"
  },
  {
    selector: '[data-tut="reactour__portal__help"]',
    content: () => (
      <>
        <p className={"bold"}>🚩 Contact your Admin if something is wrong</p>
        <p>
          If you have any issues with a portal, or cannot find a portal you
          think you should have access to, contact an Administrator in your
          organisation.
        </p>
      </>
    )
  }
  // ...
];

export const CredentialPageTourSteps = [
  {
    selector: '[data-tut="reactour__credential__page"]',
    content: () => (
      <>
        <p className={"bold"}>🔐 Securely store your passwords and API keys</p>
        <p>
          The credential store allows you to securely store secrets and
          credentials such as passwords and API keys. Once stored here, you can
          access them as variables inside any of your workflows.
        </p>
        <p>
          🚩 Please note that this is a premium feature and requires a paid plan
          to use
        </p>
      </>
    )
  }
];
