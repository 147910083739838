import React, { useContext, useEffect, useState } from "react";
//mui components
import { Grid } from "@mui/material";
//custom components
import Chat from "./components/Chat/Chat";
//utils
import {
  send_request,
  send_request_graphql_mutation,
  send_request_prebuilt_graphql,
} from "../../../../utils/Request";
import projectStore from "../../../ProjectCanvas/ProjectStore";
import {
  CHECK_BUILD_AND_ERROR,
  GENERATE,
  GENERATE_COMPONENT,
  GENERATE_COMPONENT_VIA_EDIT,
  GENERATE_WITH_ADDITIONAL_MESSAGE,
  HANDLE_WORKFLOW_PREBUILD,
  RETRIEVE_EDITOR_SESSION,
  RETRIEVE_PREBUILD_SESSION,
  RETRIEVE_SESSION,
} from "./AIBuilderQueries";
import { SAVE_EVENT } from "../CanvasQueries";
//styling
import { withStyles } from "@mui/styles";
import AIBuilderStyles from "./AIBuilderStyles";
//static messages
import { BASIC_LOADING_MESSAGE } from "./components/Chat/StaticMessages/Loading";
import { ERROR_MESSAGE } from "./components/Chat/StaticMessages/Error";
//layouting
import dagre from "dagre";
import {
  applyLayout,
  moveCollidingComponents,
  applyEditLayout,
} from "./util/TranslateUtil";
import { CanvasContext } from "../WorkflowCanvas";
import { convertEventsAndSyncState } from "./util/EventTranslation/WorkflowBuilderEventTranslate";
import { useLocation, useNavigate } from "react-router";
import { REFUSAL_ERROR_MESSAGE } from "./components/Chat/StaticMessages/REFUSAL_ERROR_MESSAGE";
import {
  extractNodes,
  extractEdges,
  getElements,
} from "../../../../utils/CanvasUtil";
import { getChatHistoryLoader } from "./util/AIChatHistoryUtil";
import AIBuilderSidebarPropTypes from "./components/Chat/PropTypes/AIBuilderSidebarPropTypes";
import {
  runChatStateInitialiser,
  determineInsertLoadingMessageBasedOnState,
  determineSaveEventBasedOnState,
  determineProceedToComponentBuild,
  determineUserClickedInsertMessage,
  finiliseDiagramInsert,
  extractNewNotes,
  determinePrebuildFlow,
  determineAIEventSource,
  findSidebarStateWithNotesBuilderEvent,
  determineBuildAllMessage,
} from "./components/Chat/ChatStateManager";
import { cleanArray } from "./util/NoteDiagramUtil";
import ReactJson from "react-json-view";
import {
  checkIfApplyingEditCompleted,
  filterAIEditCanvasAfterTranslation,
} from "./util/Edit/EditUtil";
import { toJS } from "mobx";
import { handleUploadFile } from "../../../../utils/FileUploadUtil";
import { ROLES } from "../AIBuilder/components/Chat/MessageRole";

const uuidv4 = require("uuid/v4");

const styles = AIBuilderStyles;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

let timeouts = [];

const AIBuilderSidebar = (props) => {
  //----------------------------------------------------------------------------------------------
  // State
  //----------------------------------------------------------------------------------------------
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionTraceId, setSessionTraceId] = useState(null);
  const [isPrebuild, setIsPrebuild] = useState(true);
  const [userPrompt, setUserPrompt] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hideCancel, setHideCancel] = useState(false);
  const [aiWorkflowName, setAiWorkflowName] = useState(
    projectStore.project_name
  );

  // editor
  const [editChanges, setEditChanges] = useState(null);
  const [applyingEditStages, setApplyingEditStages] = useState([]);

  const [compForEditing, setCompForEditing] = useState([]);
  const [componentsToEdit, setComponentsToEdit] = useState([]);
  const [editComponentInfo, setEditComponentInfo] = useState(null);
  const [hasAppliedEdit, setHasAppliedEdit] = useState(false);
  const [transitionFromBuildToEdit, setTransitionFromBuildToEdit] = useState(
    false
  );

  // builder
  const [compForBuilding, setCompForBuilding] = useState([]);
  const [newComponents, setNewComponents] = useState([]);

  const [startTime, setStartTime] = useState(null);
  const [newDiagram, setNewDiagram] = useState([]);
  const [completedTime, setCompletedTime] = useState(null);
  const [cancelled, setCancelled] = useState(false);
  const [reset, setReset] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);
  const [aiDatabases, setAiDatabases] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [threadId, setThreadId] = useState(null);
  const [parentThreadId, setParentThreadId] = useState(null);
  const [showBuildButtons, setShowBuildButtons] = useState(false);
  const [showConclude, setShowConclude] = useState(false);
  const [streamingStatus, setStreamingStatus] = useState("");
  const [showUserPrompt, setShowUserPrompt] = useState(false);
  const [cancelBuild, setCancelBuild] = useState(false);
  const [error, setError] = useState(null);

  // Prebuild
  const [prebuildProposal, setPrebuildProposal] = useState("");
  const [prebuildQuestions, setPrebuildQuestions] = useState("");
  const [prebuildQuestionAnswers, setPrebuildQuestionAnswers] = useState([]);
  const [viewportOnGenerate, setViewportOnGenerate] = useState(null);
  const [additionalMessagePrompt, setAdditionalMessagePrompt] = useState("");
  const { prebuildPreview, mermaidJS } = useContext(CanvasContext);
  const [openPrebuildPreview, setOpenPrebuildPreview] = prebuildPreview;
  const [mermaidJSContent, setMermaidJSContent] = mermaidJS;

  // Chat
  const [chatHistoryLoading, setChatHistoryLoading] = useState(false);
  const { isCanvasLocked } = useContext(CanvasContext);
  const { componentInfoHeader } = useContext(CanvasContext);
  const [canvasLocked, setCanvasLocked] = isCanvasLocked
    ? isCanvasLocked
    : useState(false);
  const [
    componentInfoForNodeHeader,
    setComponentInfoForNodeHeader,
  ] = componentInfoHeader ? componentInfoHeader : useState([]);
  const [buildComponentInfo, setBuildComponentInfo] = useState(null);

  const [header, setHeader] = useState("Start a new AI session");
  const [selectedNotes, setSelectedNotes] = useState([]);
  const navigate = useNavigate();
  const search = useLocation().search;
  const [historyEvents, setHistoryEvents] = useState([]);

  let sidebarState = props.sidebarState;
  let setSidebarState = props.setSidebarState;

  const [skipToBuildAndEditAll, setSkipToBuildAndEditAll] = useState(false);
  const [skipToBuild, setSkipToBuild] = useState(false);

  //----------------------------------------------------------------------------------------------
  // USE EFFECTS
  //---------------------------------------------------------------------------------------------
  // Used for transitioning from insert/save directly to build & edit all
  useEffect(() => {
    if (!skipToBuildAndEditAll) return;

    // Check if there are components to build/edit
    if (
      (compForBuilding && compForBuilding.length > 0) ||
      (compForEditing && compForEditing.length > 0)
    ) {
      buildAllComponents(true); // Proceed to building/editing all
    } else {
      // No components to build/edit so unlock the canvas
      setCanvasLocked(false);
      setLoading(false);
    }

    setSkipToBuildAndEditAll(false);
  }, [skipToBuildAndEditAll]);

  //
  useEffect(async () => {
    if (!skipToBuild) return;
    if (isPrebuild && prebuildProposal) {
      await generate(
        prebuildProposal,
        sessionId,
        threadId,
        sessionTraceId
      ).then(() => {
        setLoading(true);
        setSkipToBuild(false);
      });
    }
  }, [skipToBuild]);

  //Initialise sidebar state according to the type of chat required
  useEffect(() => {
    if (transitionFromBuildToEdit) return; // If transitions, don't return here

    runChatStateInitialiser(
      sidebarState,
      setHeader,
      chatHistoryLoading,
      setMessages,
      props,
      setCanvasLocked
    );
  }, [sidebarState]);

  //Trach changes to workflow name
  useEffect(() => {
    if (projectStore.project_name === null && aiWorkflowName !== null) {
      props.setWorkflowName(aiWorkflowName);
    }
  }, [aiWorkflowName]);

  // Dependency for changing url
  useEffect(() => {
    if (!sessionId) return;

    const params = new URLSearchParams();
    params.append("chatSession", sessionId);
    params.append("threadId", threadId);

    navigate({ search: params.toString() });
  }, [sessionId]);
  useEffect(async () => {
    if (!props.callDiscard) return;

    discardDiagram();
    props.setCallDiscard(false);
  }, [props.callDiscard]);

  // For handling loading of history session
  useEffect(async () => {
    if (!props.selectedHistorySession) return;
    if (props.selectedHistorySession.isReset) return;
    if (loading) return;

    discardDiagram();
    setTimeout(() => {
      setChatHistoryLoading(true);
    }, 0);

    let { sessionId, threadId } = props.selectedHistorySession;

    setSessionId(sessionId);
    setThreadId(threadId);

    let url = `project-service/project-ai/assistant/retrieve_ordered_events_by_sessionId_and_threadId/${sessionId}/${threadId}`;
    const json = await send_request(
      url,
      null,
      { aiEventSource: determineAIEventSource([sidebarState]) },
      "GET"
    );

    if (json && json.data) {
      let chatState = findSidebarStateWithNotesBuilderEvent(json.data);
      setHistoryEvents(json.data);
      setSidebarState(chatState);

      //based on the type of session that is
      let fns = {
        events: json.data,
        draftVersion: props.draftVersion,
        setMessages,
        setComponentInfoForNodeHeader,
        setIsPrebuild,
        setNewComponents,
        setNewDiagram,
        setCompForBuilding,
        setShowInstruction,
        setShowBuildButtons,
        setShowConclude,
        setStreamingStatus,
        setShowUserPrompt,
        setPrebuildProposal,
        setPrebuildQuestions,
        setPrebuildQuestionAnswers,
        setCancelled,
        setCancelBuild,
        setUserPrompt,
        parseWorkflowAndDisplayDraft,
        generate,
        setAiDatabases,
        setOpenPrebuildPreview,
        setMermaidJSContent,
        setSessionTraceId,
        setSidebarState,
        chatState,
        parseAndDisplayEditChanges,
        beginPollingForEditProgress,
        addApplyingEditsMessage,
        setApplyingEditStages,
        setEditChanges,
        setHideCancel,
        setHasAppliedEdit,
        setCompForEditing,
      };

      await convertEventsAndSyncState(fns);
    }

    // Need the set timeout here to prevent react batching the set states
    setTimeout(() => {
      setChatHistoryLoading(false);
    }, 0);
  }, [props.selectedHistorySession]);

  useEffect(() => {
    if (!openPrebuildPreview) return;
    setOpenPrebuildPreview(false);
  }, [messages]);

  useEffect(async () => {
    if (!cancelBuild) return;

    let componentIdsToRemove = [];
    for (let component in compForBuilding) {
      componentIdsToRemove.push(compForBuilding[component].componentId);
    }

    let editIdsToRemove = [];
    if (compForEditing) {
      for (let component of compForEditing) {
        editIdsToRemove.push(component.componentId);
      }
    }

    await removeComponentData(componentIdsToRemove, editIdsToRemove, false);
  }, [cancelBuild]);

  // Check if the chunk loading has completed
  useEffect(async () => {
    if (
      !streamingStatus ||
      streamingStatus !== "COMPLETED" ||
      chatHistoryLoading ||
      prebuildQuestions
    )
      return; // If not completed, return

    const isEditor = sidebarState === "WORKFLOW_EDITOR";

    // If we get here, we know that the session streaming has completed
    // Make a request to retrieve the final session
    const url = `project-service/graphql/project/${props.projectId}`;
    const query = isEditor
      ? RETRIEVE_EDITOR_SESSION(sessionId)
      : RETRIEVE_SESSION(sessionId);
    const res = await send_request_prebuilt_graphql(url, query);

    // If we retrieve the session, we have to parse the workflow and display the draft
    if (
      isEditor &&
      res &&
      res.data &&
      res.data.retrieveTranslatedAIEditSession
    ) {
      parseAndDisplayEditChanges(
        res.data.retrieveTranslatedAIEditSession,
        messages,
        false
      );
      setLoading(false);
    } else if (res && res.data && res.data.retrieveSession) {

      const data = res.data.retrieveSession;

      // Handle refusal
      if (data.action && data.action === "refuse") {
        let errMessage = REFUSAL_ERROR_MESSAGE(projectStore.state.userName);
        showError(messages, errMessage);
        setLoading(false);
      } else if (res && res.data && res.data.retrieveSession) {

        const data = res.data.retrieveSession;

        // Handle refusal
        if (data.action && data.action === "refuse") {
          let errMessage = REFUSAL_ERROR_MESSAGE(projectStore.state.userName);
          showError(messages, errMessage);
          setLoading(false);
          setCanvasLocked(false);
          return;
        }

        // This is the default (if no error)
        setAiDatabases(data.aiDatabases);

        const isNotesBuilder = sidebarState === "STRATEGY" || sidebarState === "DISCOVERY";

        if (!historyEvents.find(item => item.aiEventType === "W86_INSERTED_WORKFLOW_ONTO_CANVAS_SUCCESSFULLY")) {

          // If it's the notes builder
          if (isNotesBuilder) {
            setUserPrompt(""); // Clear user prompt

            /**
             * If no prebuild proposal set when we get to here, this means the streaming
             * has completed before the handlePrebuild function has completed.
             * So we need to temporarily set it to show the insert + save button
             */
            if (!prebuildProposal) setPrebuildProposal(data);
          }

          parseWorkflowAndDisplayDraft(data, messages, false);
        }
        if (!isNotesBuilder) setLoading(false);
      } else {
        // Handle error
        showError(messages, ERROR_MESSAGE);
        setCanvasLocked(false);
        return;
      }

      // This is the default (if no error)
      setAiDatabases(data.aiDatabases);

      const isNotesBuilder = sidebarState === "STRATEGY" || sidebarState === "DISCOVERY";

      if (!historyEvents.find(item => item.aiEventType === "W86_INSERTED_WORKFLOW_ONTO_CANVAS_SUCCESSFULLY")) {

        // If it's the notes builder
        if (isNotesBuilder) {
          setUserPrompt(""); // Clear user prompt

          /**
           * If no prebuild proposal set when we get to here, this means the streaming
           * has completed before the handlePrebuild function has completed.
           * So we need to temporarily set it to show the insert + save button
           */
          if (!prebuildProposal) setPrebuildProposal(res.data.retrieveSession);
        }

        parseWorkflowAndDisplayDraft(data, messages, false);
      }
      if (!isNotesBuilder) setLoading(false);
    } else {
      // Handle error
      showError(messages, ERROR_MESSAGE);
      setCanvasLocked(false);
      setLoading(false);
    }
  }, [streamingStatus]);

  useEffect(() => {
    if (chatHistoryLoading) return;
    // handleDynamicStartMessage();
  }, []);

  const handleDynamicStartMessage = () => {
    if (chatHistoryLoading) return;

    const message = {
      role: "initialMessage",
      content: "",
    };

    setMessages([message]);
    setCanvasLocked(false);
  };

  useEffect(() => {
    if (!error || !error.hasError || chatHistoryLoading) return; // If no error, return

    clearAllTimeouts(); // Clear all timeouts

    let errMessage = ERROR_MESSAGE;

    if (error && error.errorType !== "INTERNAL" && error.errorMessage) {
      errMessage = {
        role: "error",
        content: "❌ " + error.errorMessage,
      };
    }

    showError(messages, errMessage);
    setLoading(false);
    setCanvasLocked(false);
  }, [error]);

  /**
   * This useEffect is responsible for handling the transition from the workflow builder
   * to a new workflow editor session.
   *
   * We set the "transitionFromBuildToEdit" state to true which will then trigger this useEffect.
   * We then call the generate function with the additional message prompt
   * as the user input
   */
  useEffect(() => {
    if (!transitionFromBuildToEdit) return;

    generate(
      null,
      null,
      null,
      null,
      null,
      false,
      additionalMessagePrompt,
      null
    );
    setTransitionFromBuildToEdit(false); // Set back to false after completed
  }, [transitionFromBuildToEdit]);
  useEffect(() => {
    if (props.triggerCloseInChild) {
      handleClose();
      props.setTriggerCloseInChild(false);
    }
  }, [props.triggerCloseInChild]);
  //----------------------------------------------------------------------------------------------
  // User input handling
  //----------------------------------------------------------------------------------------------

  //handle closing sidebar
  const handleClose = (shouldNavigate) => {
    discardDiagram();
    setCanvasLocked(false);
    props.close();
    props.setSelectedHistorySession(null);

    if (window.location.href.includes("?chatSession=")) {
      if (!shouldNavigate) navigate(`/project/canvas/${props.projectId}`);
    }
  };

  const restartComponentBuild = async (compId, content, pollNo, isEdit) => {
    // Find the component that we want to re-build/re-edit
    let componentToRebuild = content.filter(
      (item) => item.componentId === compId
    );

    // Set the initial status accordingly
    componentToRebuild[0].status = !isEdit
      ? "AI_BUILD_STARTED"
      : "AI_EDIT_STARTED";

    setShowConclude(false);

    let arr = messages;
    arr.push(BASIC_LOADING_MESSAGE);
    setMessages((prevMessages) => [...arr]);

    setStartTime(new Date().getTime());

    // Construct new builder/editor dto
    let aiComponentBuilderDto = {
      sessionId: sessionId,
      sessionTraceId: sessionTraceId,
      projectId: props.projectId,
      componentInfos: componentToRebuild,
      saveComponentData: true,
      retryComponent: true,
      threadId: threadId,
      parentThreadId: parentThreadId,
    };

    // Set to build query by default
    // If it's an edit, use the edit endpont
    let query = GENERATE_COMPONENT(aiComponentBuilderDto);
    if (isEdit) query = GENERATE_COMPONENT_VIA_EDIT(aiComponentBuilderDto);

    const URL = `project-service/graphql/project/${props.projectId}`;
    await send_request_prebuilt_graphql(URL, query)
      .then(async (response) => {
        // else we poll for answer
        setCanvasLocked(true); // Lock the canvas

        return await pollForBuildingAll(
          isEdit ? [] : content,
          isEdit ? content : [],
          pollNo,
          true
        );
      })
      .catch(async (e) => {
        console.log(e);
        // if this request times out we still need to poll
        return await pollForBuildingAll(
          isEdit ? [] : content,
          isEdit ? content : [],
          pollNo,
          true
        );
      });
  };

  //----------------------------------------------------------------------------------------------
  // Interacting with project service and ai builder, Polling
  //----------------------------------------------------------------------------------------------

  //submit user input to the ai builder service
  const generate = async (
    proposal,
    givenSessionId,
    givenThreadId,
    givenSessionTraceId,
    showProposalMessage,
    isRegenerated,
    rawPrompt,
    displayPrompt
  ) => {
    // upload files first, get image urls and then do the generate
    setIsLoading(true);
    let url = await handleUploadFile(files);
    setIsLoading(false);

    setCanvasLocked(true); // Lock the canvas

    setStartTime(new Date().getTime()); //start timer

    let isNewSession = false;

    // Generate a new session id
    let generatedSessionId = givenSessionId ? givenSessionId : uuidv4();
    let generatedThreadId = givenThreadId ? givenThreadId : uuidv4();

    let generatedSessionTraceId = givenSessionTraceId
      ? givenSessionTraceId
      : uuidv4();

    // This means it's a new session being created
    if (sessionId !== generatedSessionId) {
      setSessionId(generatedSessionId);
      setThreadId(generatedThreadId);

      setSessionTraceId(generatedSessionTraceId);
      isNewSession = true;
    }

    const prompt = rawPrompt ? rawPrompt : userPrompt;

    //create ai builder input for the project service backend
    let aiBuilderDto = {
      userPrompt: prompt,
      projectId: props.projectId,
      sessionId: generatedSessionId,
      threadId: generatedThreadId,
      sessionTraceId: generatedSessionTraceId,
      builderType: sidebarState === "INITIAL" ? "WORKFLOW" : sidebarState,
      imageFileUrl: url,
    };

    // Add display value if it exists
    if (displayPrompt) aiBuilderDto["optionalDisplayValue"] = displayPrompt;

    setLoading(true); // Set loading to true

    // If we are given a proposal, begin building the workflow
    // Else, we want to make a request to pre-build
    if (proposal) buildAIWorkflow(proposal, aiBuilderDto, showProposalMessage);
    else
      handlePrebuildRequest(
        aiBuilderDto,
        generatedSessionId,
        generatedThreadId,
        generatedSessionTraceId,
        isRegenerated,
        isNewSession,
        prompt,
        displayPrompt
      );
  };

  const buildAIWorkflow = async (
    proposal,
    aiBuilderDto,
    showProposalMessage
  ) => {
    // Set the proposal userPrompt to JUST the proposal (exclude everything else)
    try {
      // Check if it's already an object
      if (proposal && typeof proposal === "object")
        proposal = proposal.proposal;
      else proposal = JSON.parse(proposal).proposal;
    } catch (e) {
      console.log("proposal is not a json, use as it is");
    }

    aiBuilderDto["userPrompt"] = proposal; // Set the user prompt in the dto to the proposal

    setIsPrebuild(false); // We are finished with the pre-build phase

    let arr = messages;
    // Send accept proposal request
    aiBuilderDto["sendAcceptedProposalEvent"] = !showProposalMessage;

    arr.push({
      role: "streamingMessage",
      showAnimation: true,
      sidebarState: sidebarState,
    });
    setMessages((prevMessages) => [...arr]);

    // send off user input and trigger system+user prompt
    try {
      let url = `project-service/graphql/project/${props.projectId}`;
      await send_request_prebuilt_graphql(url, GENERATE(aiBuilderDto))
        .then(() => {
          setUserPrompt("");
        })
        .catch((e) => {
          setUserPrompt("");
        });
    } catch (e) {}
  };

  const handlePrebuildRequest = async (
    aiBuilderDto,
    generatedSessionId,
    generatedThreadId,
    generatedSessionTraceId,
    isRegenerated,
    isNewSession,
    prompt,
    displayPrompt
  ) => {
    let newEdit = false;
    if (sidebarState === "WORKFLOW_EDITOR" && hasAppliedEdit) {
      // Handle additional messages after applying edit here
      handleEditorAdditionalMessage();
      aiBuilderDto["additionalMessagePrompt"] = prompt;
      newEdit = true;
    }

    setIsPrebuild(true);
    setStreamingStatus("");

    let arr = messages;
    if (arr[arr.length - 1] && arr[arr.length - 1].role === "suggestions")
      arr.pop();

    if (arr.length <= 0) {
      // Add start message if missing
      arr.push({
        role: "dynamicMessage",
        showTimer: false,
        content: "",
      });

      //to handle onboarding
      setSidebarState("WORKFLOW");
    }

    // If it is being regenerated, show regenerate message
    if (isRegenerated) {
      arr.push({ role: ROLES.USER, showTimer: false, content: "Regenerate" });
      aiBuilderDto.userPrompt = "REGENERATE";
    } else {
      let promptVal = prompt;

      if (prebuildQuestions) {
        // We are answering a pre-build question
        // So grab all the prebuild question answers
        let newVal = "";
        for (let ans of prebuildQuestionAnswers) {
          if (!ans) continue; // skip empty
          newVal += `${ans}. `;
        }
        promptVal = (newVal + prompt).trim(); // Set prompt val
        aiBuilderDto["userPrompt"] = promptVal; // Update the user prompt sent
      }
      let uploadedFiles = files;
      arr.push({ role: ROLES.IMAGE, content: uploadedFiles });
      setFiles([]);

      if (displayPrompt || promptVal) {
        arr.push({
          role: ROLES.USER,
          content: displayPrompt ? displayPrompt : promptVal,
        });
      }
    }

    arr.push(BASIC_LOADING_MESSAGE);
    setMessages((prevMessages) => [...arr]);

    if (prebuildQuestions) {
      aiBuilderDto["prebuildQuestions"] = prebuildQuestions;
      aiBuilderDto["prebuildQuestionAnswers"] = prebuildQuestionAnswers;
      setPrebuildQuestions("");
    } else if (prebuildProposal) {
      aiBuilderDto["prebuildProposal"] = prebuildProposal;
    }

    try {
      let url = `project-service/graphql/project/${props.projectId}`;
      await send_request_prebuilt_graphql(
        url,
        HANDLE_WORKFLOW_PREBUILD(aiBuilderDto)
      );

      await wait(2000); // Wait 2 seconds and then poll for the pre-build result
      setPrebuildQuestionAnswers([]); // Set back to empty

      if (
        sidebarState === "DISCOVERY" ||
        sidebarState === "STRATEGY" ||
        sidebarState === "WORKFLOW_EDITOR"
      ) {
        //show streaming message if we are handling discovery or process
        handleStreamingMessage();

        // For the workflow editor, clear previous draft
        if (sidebarState === "WORKFLOW_EDITOR" && !newEdit)
          discardDiagram(true);
      }

      pollForPrebuildResult(
        generatedSessionId,
        generatedThreadId,
        generatedSessionTraceId,
        0
      );
    } catch (e) {
      console.log(e);
    }
  };

  const pollForPrebuildResult = async (
    generatedSessionId,
    generatedThreadId,
    generatedSessionTraceId,
    polled
  ) => {
    // If no result has been returned for more than 20 polling attempts,
    // Show error & return
    const pollMax = 60;
    if (polled > pollMax) {
      showError(messages, ERROR_MESSAGE);
      setLoading(false);
      return;
    }

    polled = polled + 1; // Increase polling count

    try {
      // Send request to check build and status
      const response = await send_request_prebuilt_graphql(
        `project-service/graphql/project/${props.projectId}`,
        CHECK_BUILD_AND_ERROR(generatedSessionId, polled, pollMax)
      );

      if ((!response || !response.data) && polled > 5) {
        // If response.data is null, show an error
        showError(messages, ERROR_MESSAGE);
        setLoading(false);

        return;
      }

      let res = response.data.checkBuildAndErrorStatus;

      // If no res yet or the build hasnt finished and there are no errors
      // Set a timeout and continue polling
      if (!res || (!res.checkBuild && !res.checkError)) {
        let timeout = setTimeout(() => {
          pollForPrebuildResult(
            generatedSessionId,
            generatedThreadId,
            generatedSessionTraceId,
            polled
          );
        }, 5000);
        timeouts.push(timeout);
        return;
      }

      // Check if we have an error
      // If so, set the error and return
      if (res.checkError) {
        setError(res.aiError);
        return;
      }

      // If here, we know that there have been no errors and the session has ended
      // So handle the pre-build action logic
      const prebuildRes = await send_request_prebuilt_graphql(
        `project-service/graphql/project/${props.projectId}`,
        RETRIEVE_PREBUILD_SESSION(generatedSessionId, generatedThreadId)
      );

      if (
        prebuildRes &&
        prebuildRes.data &&
        prebuildRes.data.retrievePrebuildSession
      )
        handlePrebuildAction(
          prebuildRes.data.retrievePrebuildSession,
          generatedSessionId,
          generatedThreadId,
          generatedSessionTraceId
        );
    } catch (err) {
      console.log(err);
      let timeout = setTimeout(() => {
        pollForPrebuildResult(
          generatedSessionId,
          generatedThreadId,
          generatedSessionTraceId,
          polled
        );
      }, 5000);
      timeouts.push(timeout);
    }
  };

  //there has already been a proposal so now we poll
  const handleStreamingMessage = async () => {
    const updatedMessages = [...messages];
    // Remove the loading message
    updatedMessages.pop();
    // Add the streaming message
    updatedMessages.push({
      role: "streamingMessage",
      showAnimation: true,
      sidebarState,
    });
    setMessages(updatedMessages);
  };

  // Handles the prebuild action returned
  const handlePrebuildAction = async (
    data,
    generatedSessionId,
    generatedThreadId,
    generatedSessionTraceId
  ) => {
    if (data.prebuildActionType === "ERROR") {
      // Handle error in future
      return;
    }

    const isNotesBuilder =
      sidebarState === "STRATEGY" || sidebarState === "DISCOVERY";

    // If it's the editor/strategy/discovery and it's a proposal, return
    // This is because we are already streaming the proposal at this point
    // And the result here is no longer required
    if (
      (sidebarState === "WORKFLOW_EDITOR" || isNotesBuilder) &&
      data.prebuildActionType === "PROPOSE"
    ) {
      if (sidebarState === "WORKFLOW_EDITOR")
        setPrebuildProposal(data.originalGPTResponse); // Set pre-build proposal
      return;
    }

    let arr = messages; // Grab current messages
    arr.pop(); // Remove loading

    if (data.prebuildActionType === "QUESTION") {
      // display questions
      setPrebuildProposal(""); // Remove proposal if we get another question

      // Initialise the prebuildQuestionAnswers structure
      let prebuildQA = [];

      for (let question of data.questions) {
        prebuildQA.push("");
      }

      setPrebuildQuestionAnswers(prebuildQA);

      arr.push({
        role: "questionAnswerMessage",
        content: data,
      });

      setPrebuildQuestions(data.originalGPTResponse);
      setMessages((prevMessages) => [...arr]);
      setLoading(false);
    } else if (data.prebuildActionType === "PROPOSE") {
      // Create dto to generate diagram
      let diagramDTO = {
        projectId: props.projectId,
        sessionId: generatedSessionId,
        threadId: generatedThreadId,
        sessionTraceId: generatedSessionTraceId,
        prebuildProposal: data.proposal ? data.proposal + "" : "",
        linkedEventId: data.linkedEventId,
      };

      setPrebuildProposal(data.originalGPTResponse); // Set pre-build proposal

      determinePrebuildFlow(
        sidebarState,
        arr,
        data,
        setLoading,
        setOpenPrebuildPreview,
        setMermaidJSContent,
        diagramDTO,
        messages,
        setMessages
      );

      setMessages((prevMessages) => [...arr]);
      if (data.action !== "explain") setSkipToBuild(true);

      setLoading(false);
    } else if (data.prebuildActionType === "REFUSAL") {
      let errMessage = REFUSAL_ERROR_MESSAGE(projectStore.state.userName);

      const errData = {
        errorType: "GPT_REFUSE_EXCEPTION",
        content: projectStore.state.userName,
      };

      showError(messages, errMessage);
      setLoading(false);
    } else setLoading(false);

    // Unlock canvas / set loading to fales
    setCanvasLocked(false);
    setUserPrompt(""); // Set to empty for next user answer
  };

  const removeComponentData = async (buildIds, editIds, isReset) => {
    if (!buildIds) buildIds = [];
    if (!editIds) editIds = [];

    if (buildIds.length <= 0 && editIds.length <= 0) return;

    const removeURL = `project-service/project/updateComponentStatusForAI/${isReset}`;
    const body = {
      buildComponentIds: buildIds,
      editComponentIds: editIds,
    };

    return await send_request(removeURL, body, null, "DELETE");
  };

  const pollForBuildingAll = async (
    componentInfo,
    editInfo,
    polled,
    isReset = false
  ) => {
    if (isReset) {
      if (
        polled >= 60 ||
        !polled // it can be undefined if polling starts from a historic session
      )
        // if it is reset and polling has completed, poll for 5 more sessions
        polled = 15;
    }

    if (polled <= 60) {
      polled = polled + 1;
      try {
        let allCompleted = true;

        // 1. Construct build & edit dto
        const body = {
          sessionId: sessionId,
          buildComponentIds: componentInfo
            ? componentInfo.map((item) => {
                return item.componentId;
              })
            : [],
          editComponentIds: editInfo
            ? editInfo.map((item) => {
                return item.componentId;
              })
            : [],
        };

        // 2. Make request
        const URL = `project-service/project-ai/assistant/get_build_and_edit_all_info/${props.projectId}/${props.draftVersion}`;
        const response = await send_request(URL, body, null, "POST");

        /** Handle BUILD ALL here **/
        const buildMap = response.data.build;
        let newComponentInfo = [...componentInfo];

        for (let component of newComponentInfo) {
          if (
            component &&
            component.status !== "AI_BUILD_GENERATED" &&
            component.status !== "AI_BUILD_ERROR" &&
            component.status !== "AI_BUILD_CANCELLED"
          ) {
            const resultComponent = buildMap[component.componentId];
            if (resultComponent) {
              component["status"] = resultComponent.status;
              component["error"] = resultComponent.error;
              component["description"] = resultComponent.description;

              if (
                resultComponent.status !== "AI_BUILD_GENERATED" &&
                resultComponent.status !== "AI_BUILD_ERROR" &&
                component.status !== "AI_BUILD_CANCELLED"
              ) {
                allCompleted = false;
              }
            }
          }
        }

        setBuildComponentInfo(newComponentInfo);

        // /** Handle EDIT ALL here **/
        const editMap = response.data.edit;
        let finalEditInfo = [];

        if (editInfo && editMap && editInfo.length > 0) {
          let newEditComponentInfo = [...editInfo];

          const completedStatus = new Set([
            "AI_EDIT_GENERATED",
            "AI_EDIT_ERROR",
            "AI_EDIT_CANCELLED",
          ]);

          for (let idx = 0; idx < newEditComponentInfo.length; idx++) {
            const component = newEditComponentInfo[idx];
            const compStatus = component.status;
            if (completedStatus.has(compStatus)) continue; // If completed, skip

            const resultComponent = editMap[component.componentId];
            if (resultComponent) {
              // Set the status & error fields
              component["status"] = resultComponent.status;
              component["error"] = resultComponent.error;
              component["description"] = resultComponent.description;

              // If not completed, set all completed to false
              if (
                resultComponent.status !== "AI_EDIT_GENERATED" &&
                resultComponent.status !== "AI_EDIT_ERROR" &&
                component.status !== "AI_EDIT_CANCELLED"
              ) {
                allCompleted = false;
              }
            }
          }

          if (Object.keys(buildMap).length !== 0) {
            finalEditInfo = newEditComponentInfo;
            setEditComponentInfo(newEditComponentInfo); // Set the updated info
          }
        }

        setComponentInfoForNodeHeader([...componentInfo, ...finalEditInfo]);

        // If not completed, continue to poll
        // Else, we want to conclude
        if (!allCompleted) {
          setTimeout(() => {
            pollForBuildingAll(newComponentInfo, editInfo, polled);
          }, 5000);
        } else {
          if (props.editComponent != null) {
            //meanng component is open and we need to reload it
            props.setPaneSkeleton(false);
          }
          const isEditor = sidebarState === "WORKFLOW_EDITOR";
          if (isEditor) {
            // refetch components again to get appropriate state on canvas
            setTimeout(() => {
              applyEditChangesToCanvas(true);
            }, 500); // adjust delay as needed
          }
          // Set latest state
          setShowConclude(true);
          setIsPrebuild(false);
          setShowBuildButtons(false);
          setShowInstruction(true);

          let latestChat = await updateChatMessages(
            messages,
            null,
            null,
            null,
            false
          );

          // Remove loading message
          if (latestChat[latestChat.length - 1].role === "loading")
            latestChat.pop();

          if (!isEditor)
            latestChat.push({ role: "testRunInstructionMessage", content: "" });

          setMessages((prevMessages) => [...latestChat]);
          setComponentInfoForNodeHeader([...componentInfo, ...editInfo]);
          setCanvasLocked(false); // Un Lock the canvas
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setTimeout(() => {
          pollForBuildingAll(componentInfo, editInfo, polled);
        }, 5000);
      }
    } else {
      // Handle error here
      // Build
      for (const component of componentInfo) {
        if (
          component.status !== "AI_BUILD_GENERATED" &&
          component.status !== "AI_BUILD_ERROR"
        ) {
          component["status"] = "AI_BUILD_ERROR";
        }
      }

      // Edit
      if (editInfo) {
        for (let component of editInfo) {
          if (
            component.status !== "AI_EDIT_GENERATED" &&
            component.status !== "AI_EDIT_ERROR"
          ) {
            component["status"] = "AI_EDIT_ERROR";
          }
        }
      }

      setBuildComponentInfo(componentInfo);
      setEditComponentInfo(editInfo);

      setComponentInfoForNodeHeader([...componentInfo, ...editInfo]);
      setShowConclude(true);

      const latestChat = updateChatMessages(messages, null, null, null, false);
      setMessages((prevMessages) => [...latestChat]);

      setCanvasLocked(false); // Un Lock the canvas

      //   showError(currentChat);
      setLoading(false);
    }
  };

  //----------------------------------------------------------------------------------------------
  // Reading diagram
  //----------------------------------------------------------------------------------------------
  const parseAndDisplayEditChanges = async (
    editChanges,
    currentChat,
    isLoadingFromHistory
  ) => {
    // Set loading/saving/mask to false
    setLoading(false);
    props.setSaving(true);

    if (!isLoadingFromHistory && editChanges.aiBuildingError) {
      // If there was an error, show error
      let error = {
        role: "error",
        content: editChanges.aiBuildingError,
      };

      let arr = messages;
      arr.push(error);
      setMessages((prevMessages) => [...arr]);

      setIsPrebuild(false);
      handleEditsErrorState();
      return;
    }

    setShowUserPrompt(true);
    setUserPrompt("");

    // We need to set this temporarily
    // To show the confirm and apply changes button for the editor
    if (!prebuildProposal) setPrebuildProposal(editChanges);

    let arr = messages;

    // Set the newComponents and componentsToEdit here
    if (editChanges && editChanges.editChangesDTO) {
      const changes = editChanges.editChangesDTO;
      setNewComponents(changes.componentsToAdd);
      setComponentsToEdit(changes.componentsToUpdate);

      if (!isLoadingFromHistory) {
        arr.pop();

        //reload streaming message in the chat
        arr.push({
          role: "streamingMessage",
          showAnimation: false,
          sidebarState: sidebarState,
          loadingHistory: editChanges,
        });
      }

      const allNodesAndEdges = [...props.nodes, ...props.edges];

      // Re-construct current canvas
      const currentCanvas = filterAIEditCanvasAfterTranslation(
        allNodesAndEdges,
        changes
      );

      // Apply the graphing algorithm here
      const res = applyEditLayout(currentCanvas, [], []);

      // Add the existing components that we want to add the positions for
      editChanges.editChangesDTO.updatedComponentPositions = extractNodes(
        res
      ).map((item) => {
        return {
          componentId: item.id,
          viewData: toJS(item.position),
        };
      });

      setEditChanges(editChanges);

      props.setNodes(extractNodes(res));
      props.setEdges(extractEdges(res));
    }

    if(!isLoadingFromHistory)
    setMessages([...arr]);

    // Set completed time and canvas locked to false
    const finishedTime = new Date().getTime();
    setCompletedTime(finishedTime);
    setCanvasLocked(false);
  };

  //this function takes care of creating ai builder draft and adding it to the canvas
  const parseWorkflowAndDisplayDraft = async (
    workflow,
    currentChat,
    isLoadingFromHistory
  ) => {
    const isNotesBuilder =
      sidebarState === "STRATEGY" || sidebarState === "DISCOVERY";

    if (!isNotesBuilder) setLoading(false);
    props.setSaving(true);

    if (
      !isLoadingFromHistory &&
      workflow.aiBuildingError &&
      sidebarState != "STRATEGY" &&
      sidebarState != "DISCOVERY" &&
      sidebarState != "WORKFLOW_EDITOR"
    ) {
      // If there was an error, show error
      let error = {
        role: "error",
        content: workflow.aiBuildingError,
      };
      showError(currentChat, error);
      setCanvasLocked(false);
      return;
    }

    // Update chat messages
    if (!isLoadingFromHistory) {
      if (sidebarState !== "STRATEGY" && sidebarState !== "DISCOVERY") {
        let latestChat = updateChatMessages(
          currentChat,
          workflow.explanation,
          workflow.aiDatabases,
          workflow.aiSolution,
          true
        );
        setMessages((prevMessages) => [...latestChat]);
      }
    }

    if (!isLoadingFromHistory) {
      let arr = messages;
      arr.pop();

      //reload streaming message in the chat
      arr.push({
        role: "streamingMessage",
        showAnimation: false,
        sidebarState: sidebarState,
        loadingHistory: workflow,
      });

      setMessages((prevMessages) => [...arr]);
    }

    // Read diagram
    let diagram = projectStore.readDiagram(workflow.components, true);

    if (workflow.notes) diagram = [...diagram, ...cleanArray(workflow.notes)];

    if(workflow.name !== null)
      setAiWorkflowName(workflow.name);
    setNewComponents(workflow.components);
    if (sidebarState == "WORKFLOW") setShowInstruction(true);
    setShowUserPrompt(true);

    let rfiObj = props.reactFlowInstance.toObject();

    let viewport = {
      x: rfiObj.viewport.x.toFixed(5),
      y: rfiObj.viewport.y.toFixed(5),
      zoom: rfiObj.viewport.zoom.toFixed(5),
    };

    const newItems = applyLayout(
      diagram,
      viewportOnGenerate ? viewportOnGenerate : viewport
    );
    setNewDiagram(newItems);

    let generatedNotes = workflow.notes ? cleanArray(workflow.notes) : [];
    // Update canvas
    const allNodesAndEdges = [
      ...props.nodes,
      ...props.edges,
      ...generatedNotes,
    ];

    setSelectedNotes(generatedNotes);

    const currentCanvas = allNodesAndEdges.filter(
      (c) => c.type === "link" || c.data.isTemp == null
    );

    const finalDiagram = moveCollidingComponents(newItems, currentCanvas);

    // Grab the canvas items that are not AI
    let canvasWithoutAI = [];
    for (let canvasItem of allNodesAndEdges) {
      if (
        !canvasItem ||
        canvasItem.isTemp ||
        (canvasItem.data && canvasItem.data.isTemp)
      )
        continue;
      canvasWithoutAI.push(canvasItem);
    }

    // Update the canvas
    const canvasWithAIAndFinalDiagram = [...canvasWithoutAI, ...finalDiagram];


    props.setNodes(extractNodes(canvasWithAIAndFinalDiagram));
    props.setEdges(extractEdges(canvasWithAIAndFinalDiagram));

    // Count how long it took the request to finish
    const finishedTime = new Date().getTime();
    setCompletedTime(finishedTime);

    setCanvasLocked(false);
    if (isNotesBuilder) setLoading(false);
  };

  const updateChatMessages = (
    currentChat,
    explanation,
    databases,
    aiSolution,
    showInstruction
  ) => {
    currentChat = currentChat.map((message) => {
      if (message.role === "loading") {
        return {
          ...message,
          showAnimation: false,
        };
      }
      return message;
    });

    let updatedMessages = [...currentChat];
    if (showInstruction && sidebarState == "WORKFLOW") {
      updatedMessages.push({ role: "instruction", content: "" });
    }

    return updatedMessages;
  };

  //----------------------------------------------------------------------------------------------
  // Handling User Interactions in the Chat
  //----------------------------------------------------------------------------------------------

  //restart ai builder interaction
  const restart = (stateMessageTransition) => {
    setStreamingStatus("");
    setShowUserPrompt(false);
    setShowConclude(false);
    setOpenPrebuildPreview(false);
    setPrebuildQuestionAnswers([]);
    setSessionId("");
    setThreadId("");
    setSessionTraceId(null);
    setHasAppliedEdit(false);
    props.setSelectedHistorySession(null); // Set any selected history session to null

    // Clear query params
    if (window.location.href.includes("?chatSession=")) {
      navigate(`/project/canvas/${props.projectId}`);
    }

    setIsPrebuild(true);
    setPrebuildProposal("");

    //get rid of the current sidebar state
    discardDiagram();

    //ok here the first cancelled is going to get rid of polling of a cancelled request
    setCancelled(true);
    //the second cancelled needs to be wrapped into timeout to break out of React batch update
    //and allow to issue another request straight away
    //removing this line will result in new session always ending with an error
    setTimeout(() => {
      setCancelled(false); // Start new request
    }, 0);

    setCanvasLocked(false);
    setSessionId(null);
    setUserPrompt("");
    setNewComponents([]);
    setComponentsToEdit([]);
    setSelectedNotes([]);
    setAiWorkflowName(projectStore.project_name);
    setShowInstruction(false);
    setShowBuildButtons(false);

    if (!stateMessageTransition) {
      // Default behaviour
      handleDynamicStartMessage();
      props.setState("INITIAL");
    } else {
      // Handle custom transitions here
      handleCustomStateTransition(stateMessageTransition);
    }

    // Edit
    setHideCancel(false);
    setBuildComponentInfo(null);
    setEditComponentInfo([]);
    setCompForEditing([]);
    setComponentsToEdit([]);
    setApplyingEditStages([]);
    setComponentInfoForNodeHeader([]);
  };

  const handleCustomStateTransition = (stateMessageTransition) => {
    if (!stateMessageTransition) return;

    switch (stateMessageTransition) {
      case "WORKFLOW_EDITOR":
        // Handle transitioning to workflow editor message
        setSidebarState("WORKFLOW_EDITOR");

        runChatStateInitialiser(
          "WORKFLOW_EDITOR",
          setHeader,
          chatHistoryLoading,
          setMessages,
          props,
          setCanvasLocked
        );
        break;

      default:
        break;
    }
  };

  const buildAllComponents = async (skipUserMessage) => {
    const isEditor = sidebarState === "WORKFLOW_EDITOR";

    setShowBuildButtons(false);
    setLoading(true);

    let arr = messages;

    if (!skipUserMessage) arr.push(determineBuildAllMessage(sidebarState));

    setMessages((prevMessages) => [...arr]);

    let componentInfo = [];
    for (let component in compForBuilding) {
      if (
        !(
          compForBuilding[component].type === "conditional_workflow" &&
          compForBuilding[component].generatedTitle === null
        )
      ) {
        let compBuilder = compForBuilding[component].instructions
          ? compForBuilding[component].instructions.buildInstruction
          : compForBuilding[component].description;
        let componentInfoMap = {
          componentId: compForBuilding[component].componentId,
          componentType: compForBuilding[component].type,
          generatedTitle: compForBuilding[component].generatedTitle,
          userPrompt: compBuilder,
          status: "AI_BUILD_STARTING",
        };

        componentInfo.push(componentInfoMap);
      }
    }

    let aiComponentBuilderDto = {
      sessionId: sessionId,
      sessionTraceId: sessionTraceId,
      projectId: props.projectId,
      componentInfos: componentInfo,
      saveComponentData: true,
      retryComponent: false,
      threadId: threadId,
      parentThreadId: parentThreadId,
    };

    if (componentInfo.length > 0) {
      arr.push({
        role: "componentBuildStatus",
        content: componentInfo,
        timeout: 1000,
        isEditor: false,
        title: isEditor ? "Building new components" : "",
      });
    }

    // Handle edit here if it's the workflow editor
    let editInfo = [];
    if (isEditor && compForEditing && compForEditing.length > 0) {
      // Set component edit info
      for (let idx = 0; idx < compForEditing.length; idx++) {
        const component = compForEditing[idx];
        const type = component.type;
        if (!type || type === "conditional_workflow") continue;

        const infoMap = {
          componentId: component.componentId,
          componentType: type,
          generatedTitle: component.generatedTitle,
          userPrompt: component.editInstructions,
          status: "AI_EDIT_STARTED",
          isEdit: true,
        };

        editInfo.push(infoMap);
      }

      arr.push({
        role: "componentBuildStatus",
        content: editInfo,
        timeout: 1000,
        isEditor: true,
        title: "Updating existing components",
      });
    }

    arr.push(BASIC_LOADING_MESSAGE);
    setMessages((prevMessages) => [...arr]); // Set messages

    setBuildComponentInfo(componentInfo);
    setEditComponentInfo(editInfo);

    setComponentInfoForNodeHeader([...componentInfo, ...editInfo]);
    setStartTime(new Date().getTime());

    await proceedToBuildAndEditAll(
      isEditor,
      componentInfo,
      editInfo,
      aiComponentBuilderDto
    );
  };

  const proceedToBuildAndEditAll = async (
    isEditor,
    buildInfo,
    editInfo,
    dto
  ) => {
    if (props.editComponent != null) props.setPaneSkeleton(true);

    const URL = `project-service/graphql/project/${props.projectId}`;

    const buildAllRequest = send_request_prebuilt_graphql(
      URL,
      GENERATE_COMPONENT(dto)
    );

    let requests = [];

    if (buildInfo && buildInfo.length > 0) {
      requests.push(buildAllRequest);
    }

    if (isEditor && editInfo && editInfo.length > 0) {
      const editDTO = {
        ...dto,
        componentInfos: editInfo.map((info) => {
          delete info.isEdit;
          return info;
        }), // Set edit info
      };

      const editAllRequest = send_request_prebuilt_graphql(
        URL,
        GENERATE_COMPONENT_VIA_EDIT(editDTO)
      );
      requests.push(editAllRequest); // Add the edit request to all of the requests
    }

    await Promise.allSettled(requests); // Send requests

    // After both have returned, we can lock the canvas & begin polling
    setCanvasLocked(true); // Lock the canvas
    await wait(2000);
    return await pollForBuildingAll(buildInfo, editInfo, 0);
  };

  const rebuildComponents = async () => {
    let buildIdsToRemove = [];
    for (let component in compForBuilding) {
      buildIdsToRemove.push(compForBuilding[component].componentId);
    }

    let editIdsToRemove = [];
    if (compForEditing) {
      for (let component of compForEditing) {
        editIdsToRemove.push(component.componentId);
      }
    }

    await removeComponentData(buildIdsToRemove, editIdsToRemove, true).then(
      () => {
        buildAllComponents();
      }
    );
  };

  //insert a new workflow to the canvas
  const insert = async () => {
    setLoading(true);
    setNewDiagram([]);
    setShowUserPrompt(false);
    setShowConclude(false);

    let arr = messages;
    arr.push(determineUserClickedInsertMessage(sidebarState));

    const loadingMessage = determineInsertLoadingMessageBasedOnState(
      sidebarState,
      true
    );
    if (loadingMessage) arr.push(loadingMessage);

    setMessages(prevMessages => [...arr]);

    //disable canvas
    props.setSaving(true);

    //get meta information about the diagram
    let rfiObj = props.reactFlowInstance.toObject();

    let viewport = {
      x: rfiObj.viewport.x.toFixed(5),
      y: rfiObj.viewport.y.toFixed(5),
      zoom: rfiObj.viewport.zoom.toFixed(5),
    };

    setCompForBuilding(newComponents);
    setCompForEditing(componentsToEdit.filter((item) => !item.editConnectionsOnly));

    //create event  data to save a segment of the diagram
    let saveEvent = determineSaveEventBasedOnState(
      sidebarState,
      props.projectId,
      getElements(props.reactFlowInstance),
      sessionId,
      threadId,
      newComponents,
      viewport,
      editChanges,
      projectStore.state.userName
    );

    setNewComponents([]);
    setComponentsToEdit([]);
    setSelectedNotes(extractNewNotes(getElements(props.reactFlowInstance)));

    const isEditor = sidebarState === "WORKFLOW_EDITOR";
    const isNotesSession = sidebarState === "STRATEGY" || sidebarState === "DISCOVERY";

    if (isEditor) addApplyingEditsMessage(); // Add the applying edits message

    //send save event
    await send_request_graphql_mutation(
      `project-service/graphql/project/${props.projectId}`,
      SAVE_EVENT(saveEvent)
    )
      .then(async (response) => {
        //if response is not empty
        if (response) {
          // If we are on the workflow editor, we need to move to polling
          if (isEditor) {
            beginPollingForEditProgress();
            return;
          }

          // Set loading back to false
          setLoading(false);

          // Else, proceed with normal logic

          //remove ai builder banner on the components
          let withoutAI = [...props.nodes, ...props.edges];

          withoutAI.map((item) => {
            if (item.data && item.data.isTemp) delete item.data.isTemp;
            if (item.data && item.data.instructions)
              delete item.data.instructions;
            if (item.isTemp) delete item.isTemp;
          });

          //update nodes/edges to remove ai draft  banners
          props.setNodes(extractNodes(withoutAI));
          props.setEdges(extractEdges(withoutAI));

          //turn off saving state
          props.setSaving(false);
          finiliseDiagramInsert(
            sidebarState,
            arr,
            setShowConclude,
            setPrebuildQuestions,
            setPrebuildProposal,
            setIsPrebuild,
            setShowBuildButtons
          );

          if (aiDatabases && aiDatabases.length > 0) {
            arr.push({
              role: "loading",
              showAnimation: true,
              sidebarState: sidebarState,
            });
            setMessages((prevMessages) => [...arr]);

            await createAIDatabases(aiDatabases).then((databasesCreated) => {
              if (databasesCreated) {
                arr.pop();
                arr.push({ role: "dataBasesCreated", content: aiDatabases });
              }
            });
          }
          determineProceedToComponentBuild(
            sidebarState,
            arr,
            projectStore,
            newComponents
          );
          setShowBuildButtons(true);
          setMessages((prevMessages) => [...arr]);
          props.setSaving(false);

          // If workflow builder, we want to go directly to build/edit all
          if (sidebarState === "WORKFLOW") {
            setSkipToBuildAndEditAll(true);
          }
        } else {
          // No response, throw an error to be caught below
          throw new Error("No response returned for save event!");
        }
      })
      .catch((e) => {
        //failed insertion
        //TODO handle error state
        console.log(e);
        setLoading(false);

        // Error state for editor
        if (isEditor) {
          handleEditsErrorState();

          let arr = messages;
          arr.pop(); // Remove applying edits message and add error message
          arr.push({
            role: "error",
            content: "❌  Error applying edit changes",
          });

          setMessages((prevMessages) => [...arr]);
          return;
        }
      });

    //if we fire this on the editor or notes session changes might get overwritten
    if (!isEditor && !isNotesSession) {
      await props.handleSaveProjectName(aiWorkflowName);
    }
  };

  const addApplyingEditsMessage = () => {
    // Set loading parameters
    setCanvasLocked(true); // Lock the canvas
    setShowBuildButtons(false);
    setLoading(true);
    setIsPrebuild(false);
    setHideCancel(true);

    // Add messages
    let arr = messages;
    arr.push({ role: "editChangesProgressMessage" });
    setMessages((prevMessages) => [...arr]);
  };

  const handleEditsErrorState = () => {
    setShowBuildButtons(false);
    setShowInstruction(true);
    setShowUserPrompt(false);
    setCanvasLocked(false);
    setHideCancel(false);
    setLoading(false);
  };

  const beginPollingForEditProgress = async () => {
    // Wait 2 seconds and then begin polling
    await wait(2000);
    await pollForApplyingEdit(0, false);
  };

  const pollForApplyingEdit = async (polled, isReset = false) => {
    // Condition for polling when retrieving history
    if (isReset && (polled >= 20 || !polled)) polled = 15;

    const pollMax = 20;
    if (polled > pollMax) {
      // There was some type of error
      showError(messages, ERROR_MESSAGE);
      setLoading(false);
      setHideCancel(false);
      return;
    }

    polled += 1; // Increment the polling counter

    // Else, we continue polling here
    try {
      // Make request
      const url = `project-service/project-ai/assistant/retrieve_edit_stage/${sessionId}/${threadId}?currentPoll=${polled}&pollMax=${pollMax}`;
      const res = await send_request(url, null, null, "GET");

      let isCompleted = false;
      let hasError = false;

      if (res && res.data) {
        // Set is completed and hasError
        isCompleted = checkIfApplyingEditCompleted(res.data);
        hasError = res.data.hasError;

        // Set the applying edit stages
        setApplyingEditStages({
          ...res.data,
          isCompleted,
        });
      }

      if (hasError) {
        // We've encountered an error
        handleEditsErrorState();
        return;
      }

      // Else if not completed, continue polling
      if (!isCompleted) {
        let timeout = setTimeout(() => {
          pollForApplyingEdit(polled, isReset);
        }, 5000);
        timeouts.push(timeout);
      } else {
        /**
         * Handle completion of applying edit changes here
         */

        // Apply the changes to the canvas
        await applyEditChangesToCanvas();

        // Update the messages
        // let arr = messages;
        // arr.push(FINISH_SETTING_UP_MESSAGE);
        // setMessages(prevMessages => [...arr]);

        // Update state accordingly
        setEditChanges(null);
        setShowConclude(true);
        setShowBuildButtons(false);
        setShowInstruction(false);
        setShowUserPrompt(false);
        setIsPrebuild(true);
        setPrebuildProposal(null);
        setHideCancel(false);
        setHasAppliedEdit(true);

        // Proceed to build/edit all
        setSkipToBuildAndEditAll(true);
      }
    } catch (err) {
      console.log(err);
      let timeout = setTimeout(() => {
        pollForApplyingEdit(polled, isReset);
      }, 5000);
      timeouts.push(timeout);
    }
  };

  // Function for skipping the questions by sending a skip message
  const skipQuestions = () => {
    const skipMessage = "Skip all questions and make any required assumptions";
    generate(
      null,
      sessionId,
      threadId,
      sessionTraceId,
      null,
      false,
      skipMessage
    );
  };

  const applyEditChangesToCanvas = async (editCompleted = false) => {
    if (!editCompleted && (!editChanges || !editChanges.editChangesDTO)) return;
    await props.getLatestVersion(props.projectId); // Fetch latest version
    await props.refetchComponents();
  };

  const undoEditChanges = async () => {
    console.log("Undo changes!");
  };

  const retryApplyingEdit = async () => {
    setCanvasLocked(true); // Lock the canvas
    setLoading(true);

    let rfiObj = props.reactFlowInstance.toObject();

    let viewport = {
      x: rfiObj.viewport.x.toFixed(5),
      y: rfiObj.viewport.y.toFixed(5),
      zoom: rfiObj.viewport.zoom.toFixed(5),
    };

    let saveEvent = determineSaveEventBasedOnState(
      sidebarState,
      props.projectId,
      getElements(props.reactFlowInstance),
      sessionId,
      threadId,
      newComponents,
      viewport,
      editChanges,
      projectStore.state.userName
    );

    addApplyingEditsMessage(); // Add the applying edits message

    //send save event
    const save_URL = `project-service/graphql/project/${props.projectId}`;
    await send_request_graphql_mutation(save_URL, SAVE_EVENT(saveEvent))
      .then(async (response) => {
        //if response is not empty
        if (!response) throw new Error("No response returned for save event!");

        // If we are on the workflow editor, we need to move to polling
        beginPollingForEditProgress();
      })
      .catch((e) => {
        console.log(e);

        handleEditsErrorState();
        let arr = messages;
        arr.pop(); // Remove applying edits message and add error message
        arr.push({
          role: "error",
          content: "❌  Error applying edit changes",
        });

        setMessages((prevMessages) => [...arr]);
      });
  };

  // Sends request to create the AI databases
  const createAIDatabases = async (dbs) => {
    if (!dbs || dbs.length <= 0) return;

    let url = `database/create_databases_for_ai/${props.projectId}/${props.draftVersion}?sessionId=${sessionId}`;
    const json = await send_request(url, dbs, null, "POST");

    if (json && json.status == 200) {
      return true;
    } else {
      return false;
    }
  };

  //regenerate ai builder response by resubmitting
  const regenerate = () => {
    resetState();

    setNewComponents([]);
    setComponentsToEdit([]);
    setSelectedNotes([]);
    setAiWorkflowName(projectStore.project_name);

    setShowInstruction(false);
    regeneratePrebuild();
  };

  const resetState = (isGeneratingAdditional) => {
    setStreamingStatus("");
    setShowUserPrompt(false);
    setShowConclude(false);
    setAdditionalMessagePrompt("");

    // Set the isRegenerating state
    setReset(true);

    // Reset the cancelled state
    setCancelled(false);
    setShowBuildButtons(false);
    setEditChanges(null);

    // Discard the current diagram
    if (!isGeneratingAdditional) discardDiagram();
  };

  const handleEditorAdditionalMessage = () => {
    setStreamingStatus("");
    setShowUserPrompt(false);
    setShowConclude(false);
    setCancelled(false);
    setShowBuildButtons(false);
    setEditChanges(null);
    setHideCancel(false);

    setBuildComponentInfo(null);
    setEditComponentInfo(null);
    setCompForBuilding([]);
    setCompForEditing([]);
    setComponentsToEdit([]);
    setApplyingEditStages([]);
    setComponentInfoForNodeHeader([]);
    setShowInstruction(false);
    setHasAppliedEdit(false); // set back to false
  };

  const clearAllTimeouts = () => {
    for (let i = 0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }
    timeouts = [];
  };

  //cancel current request
  const cancel = async () => {
    clearAllTimeouts(); // Clear all timeouts

    setCancelled(true);
    setCancelBuild(true);

    let arr = messages;
    // since we are setting thw message here it renders the sidebar and resets cancelled to false,
    // therefore the buildComponent is not getting its latest status

    let isStreaming =
      arr[arr.length - 1] && arr[arr.length - 1].role === "streamingMessage";

    if (!isStreaming) {
      arr.pop(); // If not streaming message, remove loading animation
      setStreamingStatus(""); // Set streaming message to empty
      discardDiagram();
    } else {
      // It is streaming so set to cancelled
      setStreamingStatus("CANCELLED");
    }

    arr.push({ role: ROLES.USER, content: "Cancel request" });
    arr.push({ role: "assistant", content: "❌ Request cancelled" });
    setMessages((prevMessages) => [...arr]);

    setShowUserPrompt(true);
    setShowConclude(false);

    if (isPrebuild) setPrebuildProposal("");
    else {
      setNewComponents([]);
      setComponentsToEdit([]);
      setSelectedNotes([]);
      if (sidebarState == "WORKFLOW") setShowInstruction(true);
      setAiWorkflowName(projectStore.project_name);
    }

    setCanvasLocked(false);
    setUserPrompt("");
    setShowBuildButtons(false);

    setLoading(false);

    // Send cancel chat session request and events
    await cancelChatSession();
  };

  //----------------------------------------------------------------------------------------------
  // Utils for Chat Interaction
  //----------------------------------------------------------------------------------------------

  //get rid of the draft diagram
  const discardDiagram = (skipIfCheck) => {
    if (
      skipIfCheck ||
      newDiagram.length > 0 ||
      (editChanges && editChanges.editChangesDTO)
    ) {
      //remove all the temporary components from the diagram
      const allNodesAndEdges = [...props.nodes, ...props.edges];

      // Remove any editStatus set to any given components
      let canvasWithRemovedEditStatus = allNodesAndEdges.map((component) => {
        if (component && component.data && component.data.editStatus) {
          delete component.data.editStatus;
        }
        return component;
      });

      // Remove any isTemp == true
      let newCanvas = canvasWithRemovedEditStatus.filter((c) => {
        return (
          (c.type === "link" && !c.isTemp) ||
          (c.type !== "link" && c.data && !c.data.isTemp)
        );
      });

      // If edit changes given, we need to remove any proposal edit instructions
      if (
        editChanges &&
        editChanges.editChangesDTO &&
        editChanges.editChangesDTO.componentsToUpdate
      ) {
        const compsToUpdate = editChanges.editChangesDTO.componentsToUpdate;

        // Construct compToUpdateIdSet
        const compIdToUpdate = new Set();
        for (let i = 0; i < compsToUpdate.length; i++) {
          if (!compsToUpdate[i] || !compsToUpdate[i].componentId) continue;
          compIdToUpdate.add(compsToUpdate[i].componentId);
        }

        newCanvas = newCanvas.map((item) => {
          if (
            item &&
            item.data &&
            item.data.instructions &&
            compIdToUpdate.has(item.id)
          ) {
            return {
              ...item,
              data: {
                ...item.data,
                instructions: {
                  ...item.data.instructions,
                  editInstruction: null,
                },
              },
            };
          }
          return item;
        });
      }

      // Set to [] to fix batching issue
      props.setNodes([]);
      props.setEdges([]);
      props.setNodes(extractNodes(newCanvas));
      props.setEdges(extractEdges(newCanvas));
    }
  };

  const cancelChatSession = async () => {
    let url = `project-service/project-ai/assistant/cancel/${sessionId}/${threadId}`;
    const json = await send_request(url, null, null, null);

    if (json && json.data && json.data.sessionId === sessionId) return true;
    else return false;
  };

  //add an error to the chat
  const showError = (currentChat, errorMessage) => {
    currentChat = currentChat.map((message) => {
      if (message.role === "loading") {
        return {
          ...message,
          showAnimation: false,
        };
      }
      return message;
    });
    if (
      !currentChat.some((m) => m.role === "instruction" || m.role === "success")
    ) {
      //add error message to the messages array
      let arr = currentChat;
      if (arr) {
        arr.push(errorMessage);
        setMessages((prevMessages) => [...arr]);
      }
    }
  };

  const regeneratePrebuild = () => {
    generate(null, sessionId, threadId, sessionTraceId, false, true);
  };

  // Handles additional message to gpt
  const generateWithAdditional = async () => {
    /**
     * If the user has either
     * 1. inserted + saved onto canvas OR
     * 2. built all components
     *
     * We want the additional message to transition into an editor session
     */
    setIsLoading(true);
    let url = await handleUploadFile(files);
    setIsLoading(false);

    if (!newDiagram || newDiagram.length === 0) {
      // Resatrt the chat and transition it to the workflow editor
      restart("WORKFLOW_EDITOR");
      setTransitionFromBuildToEdit(true);
      return;
    }

    // Set loading to true and lock the canvas
    setLoading(true);
    setCanvasLocked(true);
    setStartTime(new Date().getTime()); // Set timer start
    setStreamingStatus("");
    let arr = messages;
    let uploadedFiles = files;
    arr.push({ role: ROLES.IMAGE, content: uploadedFiles });
    setFiles([]);
    if (additionalMessagePrompt && additionalMessagePrompt.length > 0) {
      arr.push({ role: ROLES.USER, content: additionalMessagePrompt });
    }
    setMessages((prevMessages) => [...arr]);
    resetState(true); // Reset state
    // Create ai builder dto
    let aiBuilderDto = {
      additionalMessagePrompt: additionalMessagePrompt,
      sessionId: sessionId,
      threadId: threadId,
      sessionTraceId: sessionTraceId,
      parentThreadId: parentThreadId,
      builderType: sidebarState,
      imageFileUrl: url,
    };

    if (prebuildProposal) {
      aiBuilderDto["prebuildProposal"] = prebuildProposal;
    }
    // Send request to generate with the additional user message
    try {
      let url = `project-service/graphql/project/${props.projectId}`;
      send_request_prebuilt_graphql(
        url,
        GENERATE_WITH_ADDITIONAL_MESSAGE(aiBuilderDto)
      ).catch((e) => {});
    } catch (e) {
      console.log(e);
    }

    // Wait 2 seconds and then begin stremaing messages from new workflow being built
    await wait(2000);
    arr.push({
      role: "streamingMessage",
      showAnimation: true,
      sidebarState: sidebarState,
    });

    setMessages((prevMessages) => [...arr]);
    setShowInstruction(false);
  };

  const wait = (ms) =>
    new Promise((res) => {
      let timeout = setTimeout(res, ms);
      timeouts.push(timeout); // Add to list of timeouts
      return timeout;
    });
  const buildComponentsIndividually = () => {
    handleClose();
  };
  const conclude = () => {
    handleClose();
  };

  const convertIntoWorkflow = async () => {
    if (!selectedNotes || selectedNotes.length <= 0) {
      console.log("No selected notes to convert into workflow! \n");
      return;
    }

    setLoading(true);
    setCanvasLocked("Collecting notes for AI"); // Set to locked

    let startNoteId = selectedNotes.find((note) => note.type === "note").id;

    const dto = {
      startNoteId: startNoteId,
      projectId: projectStore.project_id,
    };

    // Send request to create AI session for the notes
    const url = `project-service/project-ai/assistant/create_ai_session_for_notes`;
    const json = await send_request(url, dto, null, "POST");

    setLoading(false);
    setCanvasLocked(false);
    setMessages([]);
    setSidebarState("WORKFLOW");
    props.setState("WORKFLOW_BUILDER");

    if (json && json.data) {
      props.setSelectedHistorySession({
        sessionId: json.data.chatSessionId,
        threadId: json.data.threadId,
      });
    }
  };

  //----------------------------------------------------------------------------------------------
  // unmount
  //----------------------------------------------------------------------------------------------
  useEffect(() => {
    return async () => {
      setTimeout(() => {
        discardDiagram();
      }, 100); // adjust delay as needed

      setOpenPrebuildPreview(false);
      props.setSelectedHistorySession(null);
    };
  }, []);

  //----------------------------------------------------------------------------------------------
  // JSX
  //----------------------------------------------------------------------------------------------

  //takes care of rendering interactive components inside the sidebar
  const renderSidebar = () => {
    return (
      <Grid container className={props.classes.fullChat} direction="column">
        <Grid item>
          <Grid
            container
            direction={"column"}
            alignItems={messages.length > 0 ? "center" : "flex-start"}
            className={messages.length > 0 ? props.classes.chatHeader : ""}
          >
            <span>
              <b>{header}</b>
            </span>
            {sessionId && (
              <span
                className={props.classes.sessionId}
                onClick={() => navigator.clipboard.writeText(sessionId)}
                style={{ cursor: "pointer" }}
              >
                {sessionId}
              </span>
            )}
          </Grid>
        </Grid>
        <Grid item xs>
          {chatHistoryLoading ? (
            getChatHistoryLoader()
          ) : (
            <Chat
              hideCancel={hideCancel}
              projectId={props.projectId}
              editChanges={editChanges}
              draftVersion={props.draftVersion}
              // if user has applied edit, we want the new edit session code to be started, which is handled in generateWithAdditional code, triggered by this flag
              showAdditionalPrompt={
                sidebarState !== "WORKFLOW_EDITOR" || hasAppliedEdit
              }
              reset={reset}
              classes={props.classes}
              messages={messages}
              restartComponentBuild={(id, content, pollNo, isEdit) => {
                restartComponentBuild(id, content, pollNo, isEdit);
              }}
              setWorkflowName={setAiWorkflowName}
              restart={() => {
                restart();
              }}
              buildAllComponents={() => {
                buildAllComponents();
              }}
              regenerate={() => {
                regenerate();
              }}
              insert={() => {
                insert();
              }}
              rebuild={() => {
                rebuildComponents();
              }}
              conclude={() => conclude()}
              buildComponentsIndividually={() => {
                buildComponentsIndividually();
              }}
              loading={loading}
              startTime={startTime}
              completedTime={completedTime}
              cancel={() => {
                cancel();
              }}
              userPrompt={userPrompt}
              updateUserPrompt={(val) => {
                setUserPrompt(val);
              }}
              showBuildButtons={showBuildButtons}
              showConclude={showConclude}
              generate={() => {
                generate(null, sessionId, threadId, sessionTraceId);
              }}
              canInsert={
                newComponents && newComponents.length <= 0 ? true : false
              }
              showInstruction={showInstruction}
              showUserPrompt={showUserPrompt}
              generateWithAdditional={generateWithAdditional}
              additionalMessagePrompt={additionalMessagePrompt}
              setAdditionalMessagePrompt={setAdditionalMessagePrompt}
              prebuildProposal={prebuildProposal}
              handleBuildWithProposal={(proposal) => {
                generate(proposal, sessionId, threadId, sessionTraceId);
              }}
              regeneratePrebuild={() => {
                regeneratePrebuild();
              }}
              openSessionHistory={props.openSessionHistory}
              prebuildQuestionAnswers={prebuildQuestionAnswers}
              isPrebuild={isPrebuild}
              chatHistoryLoading={chatHistoryLoading}
              isLoadedFromHistory={
                props.selectedHistorySession != null &&
                Object.keys(props.selectedHistorySession).length > 0
              }
              compForBuilding={compForBuilding}
              compForEditing={compForEditing}
              buttonText={{
                insert: {
                  message: "Start build",
                },
                restart: {
                  message: "New chat",
                },
                regenerate: {
                  message: "Regenerate",
                },
                editAndRegenerate: {
                  message: "Edit",
                },
                buildAllComponents: {
                  message:
                    sidebarState !== "WORKFLOW_EDITOR"
                      ? "Build components"
                      : "Finish setting up for me",
                },
                buildComponentsIndividually: {
                  message: "End chat",
                },
                conclude: {
                  message: "End chat",
                },
                rebuild: {
                  message: "Rebuild components",
                },
              }}
              sessionId={sessionId}
              streamingStatus={streamingStatus}
              setStreamingStatus={setStreamingStatus}
              viewportOnGenerate={viewportOnGenerate}
              setViewportOnGenerate={setViewportOnGenerate}
              setNewComponents={setNewComponents}
              setNewDiagram={setNewDiagram}
              setError={setError}
              setPrebuildQuestionAnswers={setPrebuildQuestionAnswers}
              buildComponentInfo={buildComponentInfo}
              editComponentInfo={editComponentInfo}
              applyingEditStages={applyingEditStages}
              setApplyingEditStages={setApplyingEditStages}
              undoEditChanges={undoEditChanges}
              retryApplyingEdit={retryApplyingEdit}
              setSidebarState={setSidebarState}
              sidebarState={sidebarState}
              convertIntoWorkflow={convertIntoWorkflow}
              prebuildQuestions={prebuildQuestions}
              skipQuestions={skipQuestions}
              focusObject={{
                rfInstance: props.reactFlowInstance,
                openSessionHistory: props.openSessionHistory,
                targetZoom: 1.0,
              }}
              setUserPrompt={setUserPrompt}
              hideButtons={false}
              files={files}
              setFiles={setFiles}
              isLoading={isLoading}
              nodes={props.nodes}
              generateWithQuesAns={() => {
                generate(
                  null,
                  sessionId,
                  threadId,
                  sessionTraceId,
                  true,
                  false,
                  null,
                  null
                );
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  return renderSidebar();
};

AIBuilderSidebar.propTypes = AIBuilderSidebarPropTypes;
export default withStyles(styles)(AIBuilderSidebar);
