import uuid from "uuid";
import React from "react";
import "../css/draggable.css";
import {
  send_request,
  send_request_templates,
  send_request_without_auth
} from "../../../utils/Request";
import "../css/canvasPreview.css";
import { getCurrentUser } from 'aws-amplify/auth';
import Button from "@mui/material/Button";
import { format } from "../../../utils/Date";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import Canvas from "../../pages/Canvas/Canvas";
import { Navigate } from "react-router-dom";
import ProjectStore from "../../ProjectCanvas/ProjectStore";
import { withStyles } from "@mui/styles";
import { Paper, Grid, Link, Tooltip, TextField, Skeleton } from "@mui/material";
import Share from "../../ProjectCanvas/Share";
import Config from "../../../config";
import { withParams } from "../../../Routes";
import MarketplaceNavbar from "../../../components/Marketplace/MarketplaceTemplateNavBar";
import { showLoadingScreen } from "../../../utils/showLoadingScreen";
import { CanvasContext } from "../../pages/Canvas/WorkflowCanvas";
import { styled } from "@mui/system";
import { extractNodes, extractEdges } from "../../../utils/CanvasUtil";

const StyledTopTitle = styled("div")({
  position: "absolute",
  margin: "24px 0px 0px 24px",
  padding: "8px 16px",
  fontSize: "18px",
  zIndex: 1000,
  border: "1px solid rgba(191, 191, 191, 1)",
  borderRadius: "8px",
  boxShadow: "4px 4px 0px rgba(0, 0, 0, 0.25)",
  background: "#FFF"
});

const BottomContainer = styled(Grid)({
  border: "1px solid rgba(191, 191, 191, 1)",
  boxShadow: "4px 4px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  padding: "0px 16px 32px 16px"
});

const StyledSkeleton = styled(Skeleton)(({ width, height, margin, position }) => ({
  borderRadius: "4px",
  background: "#E8E8E8",
  width: width,
  height: height,
  margin: margin,
  zIndex: 1000,
  position: position
}));

const styles = (theme) => ({
  paper: {
    border: "none",
    minHeight: theme.spacing.unit * 90,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  canvas: {
    position: "relative",
    width: "100%",
    height: "93vh",
    minHeight: "480px"
  }
});

export const downloadTemplate = () => {};
class CanvasPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      template: {},
      download: false,
      categoryMapping: {},
      loadedAPI: false,
      nodes: [],
      edges: [],
    };
    this.reactFlowWrapper = React.createRef(null);
  }

  componentWillMount() {
    this.props.PreviewStore.clear();
  }

  async componentDidMount() {
    const project_id = window.location.pathname.split("/")[2];
    let loggedIn = true;

    try {
      await getCurrentUser();
    } catch (e) {
      loggedIn = false;
    }

    let data = {
      loggedIn: loggedIn,
      projectId: project_id
    };

    const response = await send_request_templates("projectCategories", "GET");

    const categoryMapping = {};

    if (response)
      response.data.forEach(({ categoryId, displayName }) => {
        categoryMapping[categoryId] = displayName;
      });

    this.setState({ categoryMapping });
    if (!loggedIn) {
      send_request_without_auth(
        "project-service/public-preview/",
        data,
        "",
        "POST"
      )
        .then((workflow) => {
          this.props.PreviewStore.setWorkflow(workflow.data);
          this.setState({ template: workflow.data });
        })
        .then(() => {
          if (this.state.template.notes) {
            this.setState({
              nodes: [
                ...this.props.PreviewStore.nodes,
                ...extractNodes(this.state.template.notes)
              ],
              edges: [
                ...this.props.PreviewStore.edges,
                ...extractEdges(this.state.template.notes)
              ],
            });
          } else {
            this.setState({
              nodes: [...this.props.PreviewStore.nodes],
              edges: [...this.props.PreviewStore.edges],
            });
          }
        })
        .catch((e) => {
          window.location.href = "/ ";
          console.log(e);
        });
    } else {
      send_request(`project-service/preview/getPreview`, data, "", "POST")
        .then((workflow) => {
          this.props.PreviewStore.setWorkflow(workflow.data);
          this.setState({ template: workflow.data });
        })
        .then(() => {
          if (this.state.template.notes) {
            this.setState({
              nodes: [
                ...this.props.PreviewStore.nodes,
                ...extractNodes(this.state.template.notes)
              ],
              edges: [
                ...this.props.PreviewStore.edges,
                ...extractEdges(this.state.template.notes)
              ],
            });
          } else {
            this.setState({
              nodes: [...this.props.PreviewStore.nodes],
              edges: [...this.props.PreviewStore.edges],
            });
          }
        })
        .catch((e) => {
          window.location.href = "/ ";
          console.log(e);
        });
    }
    this.setState({ loadedAPI: true });
  }

  handleDownload = () => {
    this.props.PreviewStore.download = true;
    this.props.PreviewStore.template = this.state.template;
    this.setState({ download: true });
    this.props.navigate("/project");
  };

  renderCategories = (categories, categoryMapping) => {
    if (categories === undefined || categories === null) {
      return;
    }

    return categories.map((category) => (
      <div
        style={{
          padding: "10px 12px",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: "24px",
          fontSize: "12px",
          marginRight: "0.5rem",
          marginTop: "0.5rem"
        }}
      >
        {categoryMapping[category]}
      </div>
    ));
  };

  getBottomContainer = () => {
    return <BottomContainer container columnSpacing={3}>
      <Grid item xs={12} lg={6}>
        <h5 style={{ width: "100%", marginBottom: "0" }}>
          {this.state.template.name}
        </h5>
        <p style={{ color: "black", width: "100%" }}>
          <span>By {this.state.template.author}</span> | Published
          <Tooltip
            title={format(
              this.state.template.lastModified,
              ProjectStore.state.timezone
            )}
            placement="bottom-end"
          >
            <span>
              {" " +
                format(
                  this.state.template.lastModified,
                  ProjectStore.state.timezone,
                  "relative"
                )}{" "}
            </span>
          </Tooltip>
          |{" "}
          <span>{this.state.template.downloads + " "} downloads</span>
        </p>
        <div
          style={{
            display: "flex",
            marginBottom: "1rem",
            flexWrap: "wrap",
            width: "100%"
          }}
        >
          {this.renderCategories(
            this.state.template.categories,
            this.state.categoryMapping
          )}
        </div>
        <p
          variant={"standard"}
          disabled={true}
          InputProps={{ disableUnderline: true }}
          multiline={6}
          style={{
            color: "black",
            marginTop: "0",
            width: "100%"
          }}
        >
          {this.state.template.description}
        </p>
        {
          (this.state.template.downloadable === null || this.state.template.downloadable) &&
          <Grid container>
            <Grid item>
              <Button
                onClick={this.handleDownload}
                variant={"contained"}
                color={"primary"}
              >
                Download Template
              </Button>
            </Grid>
          </Grid>
        }
      </Grid>
      <Grid item xs={12} lg={6}>
        <Share
          url={`${Config.APP_URL}/template/${this.state.template.projectId}`}
          template={true}
        />
      </Grid>
    </BottomContainer>
  }

  render() {
    if (window.drift != undefined) {
      window.drift.hide();
    }

    const { classes } = this.props;
    return (
      <CanvasContext.Provider
        value={{
          value: [[], () => {}],
          modeInfo: ["TEMPLATE", () => {}],
        }}
      >
        <div className={classes.paper}>
          {this.state.loadedAPI ? (
            <>
              {this.state.template.name ? 
                  <StyledTopTitle>
                    {this.state.template.name}
                  </StyledTopTitle> 
                  : <StyledSkeleton variant="rectangular" position="absolute" margin="24px 0px 0px 24px" width="300px" height="40px" />
                }
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                container
                item
                direction={"column"}
                alignItems={"center"}
              >
                <Grid
                  xs={12}
                  container
                  item
                  alignItems={"stretch"}
                  direction={"column"}
                >
                  <div className={classes.canvas} ref={this.reactFlowWrapper}>
                    <Canvas
                      mode={"TEMPLATE"}
                      isTemplate={true}

                      nodes={this.state.nodes}
                      setNodes={() => {}}
                      edges={this.state.edges}
                      setEdges={() => {}}

                      reactFlowWrapper={this.reactFlowWrapper}
                    />
                  </div>
                </Grid>
                <div style={{ marginBottom: "16px", padding: "0px 24px 24px 48px", width: "100%"}}>
                  {Object.keys(this.state.template).length > 0 ? 
                    this.getBottomContainer() : 
                    <Grid container columnSpacing={2}>
                      <Grid item xs={6}>
                        <StyledSkeleton variant="rectangular" width="100%" height="250px" margin="16px 0px 0px 0px" />
                      </Grid>
                      <Grid item xs={6}>
                        <StyledSkeleton variant="rectangular" width="100%" height="250px" margin="16px 0px 0px 0px" />
                      </Grid>
                    </Grid>}
                </div>
              </Grid>
            </>
          ) : (
            showLoadingScreen()
          )}
        </div>
      </CanvasContext.Provider>
    );
  }
}

export default withParams(
  withStyles(styles)(inject("PreviewStore")(observer(CanvasPreview)))
);
