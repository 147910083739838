import { matchPath } from "react-router";

const setPageTitle = (location) => {
    let path = location.pathname;
    let title = "All-in-one process documentation, automation and management";

    let match = checkPathWithIds(path);
    if (match) path = match.pathnameBase;

    switch (path) {
        case "/":
            title = "Home";
            break;
        case "/project":
            title = "Workflow Library";
            break;
        case "/portal":
            title = "Forms";
            break;
        case "/activity":
            title = "Activity Logs";
            break;
        case "/credential_store":
            title = "Credential Store";
            break;
        case "/marketplace":
            title = "Templates";
            break;
        case "/task":
            title = "My Tasks";
            break;
        case "/user_management":
            title = "User Management";
            break;
        case "/databases":
            title = "Databases";
            break;
        case "/dashboards":
            title = "Dashboards";
            break;
        case "/repository":
            title = "Shared Workflows";
            break;
        case "/achievements":
            title = "Achievements";
            break;
        case "/profile":
            title = "Profile";
            break;
        case "/organization":
            title = "Organisation Settings";
            break;
        case "/set_plan":
            title = "Plans";
            break;
        case "/project/file_library":
            title = "File Library";
            break;
        case "/project/readme":
            title = "Read Me";
            break;
        case "/project/logs":
            title = "Session Logs";
            break;
        case "/project/version_history":
            title = "Version History";
            break;
        case "/project_data":
            title = "Workflow data";
            break;
        case "/project/logs/progress_view":
            title = "Progress View";
            break;
        case "/project/folder":
            title = "Workflow Library";
            break;
        default:
            break;
    }

    document.title = `${title} | Workflow86`;
};

const checkPathWithIds = (path) => {
    if (matchPath("/project/file_library/*", path)) return matchPath("/project/file_library/*", path);
    else if (matchPath("/project/readme/*", path)) return matchPath("/project/readme/*", path);
    else if (matchPath("/project/logs/progress_view/*", path)) return matchPath("/project/logs/progress_view/*", path);
    else if (matchPath("/project/logs/*", path)) return matchPath("/project/logs/*", path);
    else if (matchPath("/project/version_history/*", path)) return matchPath("/project/version_history/*", path);
    else if (matchPath("/project_data/*", path)) return matchPath("/project_data/*", path);
    else if (matchPath("/project/folder/*", path)) return matchPath("/project/folder/*", path);
};

export default setPageTitle;
