import React from "react";
//mui
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
//styles
import { withStyles } from "@mui/styles";
import "../../../util/chatMessageAnimations.css";

const styles = () => ({
  instruction: {
    maxWidth: "100%!important",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    width: "fit-content",
    padding: "0px 16px 0px 16px",
    size: "14px"
  }
});

const ComponentInstruction = ({ classes }) => {
  return (
    <Grid className={`${classes.instruction} message-bubble`}>
      <p>
        I have rendered a preview of my proposed configuration for this
        component.{" "}
      </p>
      <p>
        {" "}Click <b>Configure and save component</b> to accept the proposed
        configuration and save it into the component.{" "}
      </p>
      <p>
        {" "}Note that accepting the proposed configuration will{" "}
        <u>overwrite</u> any existing configuration and content inside of the
        component.
      </p>
    </Grid>
  );
};

ComponentInstruction.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ComponentInstruction);
