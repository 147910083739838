import React, { Component } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import Cards from "./Cards";
import SequentialFormContainer from "./SequentialFormContainer";
import { createTheme } from "@mui/material/styles";
import {
  withStyles,
} from "@mui/styles";
import TextField  from "vardogyir-ui/TextField";
import CoverImage from "../FormBuilder/CoverImage";
import {PlaylistAddCheckRounded, DirectionsRounded} from "@mui/icons-material";
import Toolbar from "@mui/material/Toolbar";
import {Grid} from "@mui/material";

const update = require("immutability-helper");

const styles = (theme) => ({
  textroot: {
    width: "100%",
    height: "100px",
    margin: "auto",
  },
  pagetitle: {
    fontWeight: 300,
    fontSize: "3.3rem",
  },
  pageDescription: {
    fontSize: "1rem",
  },
  textField: {
    width: 600,
    margin: "auto",
    position: "relative",
  }
});
class Validation extends Component {
  state = {
    pageTitle: "",
    pageDescription: "",
  };
  handleInput = (type, event) => {
    SequentialFormContainer.pageTitles[0][type] = event.target.value;
  };

  deleteItem = (id) => {
    SequentialFormContainer.pages = SequentialFormContainer.pages.filter(item => item.id !== id);
  };

  moveCard = (dragIndex, hoverIndex) => {
    let newcards = SequentialFormContainer.pages;

    // dragCard is card we are dragging
    let dragCard = newcards[dragIndex];

    // removing this dragCard from array
    newcards.splice(dragIndex, 1);

    // insert dragCard at hover position
    newcards.splice(hoverIndex, 0, dragCard);

    SequentialFormContainer.pages = newcards;
  };
  editItem = (id) => {
    console.log(id)
    console.log(SequentialFormContainer.onEdit)
    SequentialFormContainer.editComponent(id);
    SequentialFormContainer.onEdit = true;
  };
  copyItem = (id) => {
    SequentialFormContainer.copyItem(id);
  };

  render() {
    const theme = createTheme({
      palette: {
        primary: { 500: "#2196F3" },
      },
      typography: { useNextVariants: true },
    });
    const data = toJS(SequentialFormContainer.pageTitles);
    const { classes, coverImage, coverImageCrop, linkedComponent, launchSettingDialog,uploadIcon,formTitle } = this.props;
    return (
      <>
      <div className="form-component-validation-sform">
        <Toolbar variant="dense" style={{ minHeight: "45px !important", borderBottom: "1px solid #C4C4C4"} } xs={12}>
          <Grid
              container
              direction="row"
              alignItems="center"
          >
            <Grid item >
              <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-start"
              >
                <Grid
                    item
                    style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid style={{ width: "auto", height: "32px" }}>
                    {
                      uploadIcon == "" ? (
                          <img
                              alt="logo"
                              style={{
                                width: "auto",
                                height: "100%",
                                borderRadius: 8,
                              }}
                              src={"/images/default-image-form.png"}
                          />
                      ) : (
                          <img
                              alt="logo"
                              style={{
                                width: "auto",
                                height: "100%",
                                borderRadius: 8,
                              }}
                              src={uploadIcon}
                          />
                      )}
                  </Grid>

                </Grid>
              </Grid>

            </Grid>
            <Grid
                item
                style={{ display: "flex", alignItems: "center" ,marginLeft:"8px"}}
            >
              {formTitle}
            </Grid>
          </Grid>
        </Toolbar>

        {(coverImage !== undefined && coverImage !== "") ? (
          <div className="form-cover-image">
            <CoverImage coverImage={coverImage} coverImageCrop={coverImageCrop} />
          </div>
        ): ""}
        <div className="appIntro">
          <div style={{ textAlign: "center" }} className="cardContainer">
            {SequentialFormContainer.pages.length == 0 && (
              <p style={{ marginTop: "30vh" }}>Drop Area</p>
            )}
            {SequentialFormContainer.pages.map((card, i) => {
              if (i == 0) {
                return (
                  <div>
                    <div
                      className="cardPageTitle"
                    >
                      <TextField
                        fontSize={24}
                        Theme={"blue"}
                        value={data[0].page_title}
                        className={classes.textroot}
                        InputProps={{
                          className: classes.pagetitle,
                        }}
                        label={"Page Title"}
                        onChange={this.handleInput.bind(this, "page_title")}
                      />
                    </div>
                    <Cards
                      key={card.id}
                      index={i}
                      id={card.id}
                      type={card.type}
                      text={card.text}
                      editItem={this.editItem}
                      copyItem={this.copyItem}
                      deleteItem={this.deleteItem}
                      moveCard={this.moveCard}
                    />
                  </div>
                );
              } else {
                return (
                  <Cards
                    key={card.id}
                    index={i}
                    id={card.id}
                    type={card.type}
                    text={card.text}
                    editItem={this.editItem}
                    copyItem={this.copyItem}
                    deleteItem={this.deleteItem}
                    moveCard={this.moveCard}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>

      <div 
        style={{
                borderRadius: "8px",
                margin: "16px 0",
                wordWrap: "break-word",
                overflow: "hidden",
                backgroundColor: "#ffffff",
                boxShadow: "0 4px 8px rgb(0 0 0 / 25%)",
                display:"flex",
                justifyContent:"center",
                padding:"8px",
                cursor:"pointer"}}
        onClick={() => {launchSettingDialog();} }
      >
        
        {(!linkedComponent) ? (<><PlaylistAddCheckRounded/> &nbsp; Display submission page when submitted</>): 
        (<> <DirectionsRounded/> &nbsp; Navigate to next page when submitted  </>) }
        
      </div>
      </>
    );
  }
}

export default withStyles(styles)(observer(Validation));
