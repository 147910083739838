import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import { MenuItem, Select, TextField } from "@mui/material";
import DateAdapter from "@mui/lab/AdapterDayjs";
import { LocalizationProvider, TimePicker, DatePicker } from "@mui/lab";
import { timezones } from "../../utils/timezones";
import TextFieldMUI from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import { autorun, reaction, toJS } from "mobx";
import scheduleStartStore from "./ScheduleStartStore";
import checkKey from "../../utils/CheckKey";
import Icon from "@mui/material/Icon";
import { inject, observer } from "mobx-react";
import { send_component_save_request, send_request } from "../../utils/Request";
import { Auth } from "aws-amplify";
import ErrorIcon from "@mui/icons-material/Error";
import ButtonGroup from "vardogyir-ui/ButtonGroup";
import "./ScheduleStartStyle.css";
import { getCurrentTime } from "../../utils/getCurrentTime";
import DocumentStore from "../DocumentEditor/DocumentStore";
import ValidationErrors from "../pages/ComponentPageDialogs/ValidationErrors";
import projectStore from "../ProjectCanvas/ProjectStore";
import replacePlaceholderValue from "../../utils/ReplacePlaceholderValue";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import W86PlaceholderTextField from "../W86Components/W86PlaceholderTextField";
import { getTime, setMonth } from "../../utils/TimeUtil";

class ScheduleStartTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkKey: false,
      errorMessage: "",
      loading: false,
      user: null,
      errorDateTime: "",
      errorStopDateTime: "",
      errors: [],
      errorDialog: false,
      description:null,
      status:null
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });

    const json = await send_request(`authz-service/user-info`, "", {});

    if (json && json.data) {
      const {
        email,
        roleAdministrator,
        roleDisabled,
        roleEditor,
        roleOwner,
        rolePrivateViewer,
        rolePublicViewer,
        userName,
        client,
        timezone
      } = json.data;
      let permission = null;

      if (roleDisabled) {
        permission = "Disabled";
      } else if (roleOwner) permission="Owner";
      else if (roleAdministrator) permission = "Administrator";
      else if (roleEditor) permission = "Editor";
      else if (rolePrivateViewer) permission = "PrivateViewer";
      else if (rolePublicViewer) permission = "PublicViewer";

      const user = {
        email: email,
        username: userName,
        permission: permission,
        orangisation: client.clientName,
        timezone: timezone != null ? timezone : "Australia/Hobart"
      };
      scheduleStartStore.data.timezone = timezone;
      scheduleStartStore.data.stopRunning.timezone = timezone;
      this.setState({ user });
      this.props.setChildTemplateLoaded(true);
    }

    if (this.props.component_id !== undefined) {
      const url = `project-service/project/component/${this.props.component_id}`;
      const json = await send_request(url, "", {}).catch((err) => {
        throw Error(err.message);
      });
      if (json && json.data) {
        this.setState({ loading: false });
        const components = json.data;
        if (components) {
          scheduleStartStore.setComponentData(components);
          this.props.onComponentNameChanged(scheduleStartStore.name);
          this.props.onComponentDescriptionChanged(components.description);
          this.props.onLastModifiedChanged(scheduleStartStore.lastModified);
        }
      }

      // Check if aiComponentBuilder is given and if so, set the draft data
      if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data) {
        this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
        this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
        scheduleStartStore.setTemplateData(this.props.aiComponentBuilderData.data);
        this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
        this.setState({ status: "AI_BUILD_GENERATED" });
      }

      this.props.setChildTemplateLoaded(true);
    }
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveScheduleStart();
  }

  saveScheduleStart = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      let dataSave = toJS(scheduleStartStore).data;

      let timeNow = Date.now();
      const startTime = Date.parse(dataSave.date + " " + dataSave.time);
      const stopTime = Date.parse(
        dataSave.stopRunning.date + " " + dataSave.stopRunning.time
      );

      let lastModified = getCurrentTime();
      let data = {
        componentData: {
          data: dataSave,
          name: toJS(this.props.ComponentName),
          lastModified: lastModified
        },
        componentId: this.props.component_id,
        type: scheduleStartStore.template.type,
        description: this.props.ComponentDescription.value,
        status: this.state.status
      };
      send_component_save_request("component-service/schedule-start/data", data, "", "POST", this.props.SaveTrigger)
        .then((response) => {
          this.props.onLastModifiedChanged(lastModified);
          this.props.showSuccess(data, this.props.SaveTrigger);
          // this.componentDidMount();
          projectStore.savedComponent = true;
        })
        .catch((err) => {
          throw err;
        });
    }
  );



  handleKeyChanges = (event) => {
    const { data } = toJS(scheduleStartStore);
    const value = checkKey(event, data);
    this.setState({ checkKey: value[0], errorMessage: value[1] });
  };

  handleChangeTimezone = (timezone) => {
    this.state.user.timezone = timezone;
    this.setState({ user: this.state.user });
    scheduleStartStore.data.timezone = timezone;
  };

  handleChangeStopRunningTimezone = (timezone) => {
    scheduleStartStore.data.stopRunning.timezone = timezone;
  };

  setMonth = (value) => {
    switch (value) {
      case 1:
        return "01";
      case 2:
        return "02";
      case 3:
        return "03";
      case 4:
        return "04";
      case 5:
        return "05";
      case 6:
        return "06";
      case 7:
        return "07";
      case 8:
        return "08";
      case 9:
        return "09";
      case 10:
        return "10";
      case 11:
        return "11";
      case 12:
        return "12";
    }
  };

  getTime = (time) => {
    let DateTime = new Date(time);
    const hour =
      DateTime.getHours() >= 10
        ? DateTime.getHours()
        : "0" + DateTime.getHours();
    const minutes =
      DateTime.getMinutes() >= 10
        ? DateTime.getMinutes()
        : "0" + DateTime.getMinutes();
    const fullTimeNow = hour + ":" + minutes;
    return fullTimeNow;
  };

  setDateTime = (value, type, status) => {
    const DateTime = new Date(value);
    const date = DateTime.getDate();
    let month = DateTime.getMonth() + 1;
    const year = DateTime.getFullYear();
    this.setState({
      errorDateTime: "",
      errorStopDateTime: ""
    });

    if (type == "time") {
      if (status == "start") {
        scheduleStartStore.setContent(getTime(value), "time");
      } else if (status == "stop") {
        scheduleStartStore.setStopContent(getTime(value), "time");
      }
    } else if (type == "date") {
      month = setMonth(month);
      const fullDate = year + "-" + month + "-" + date;
      if (status == "start") {
        scheduleStartStore.setContent(fullDate, "date");
      } else if (status == "stop") {
        scheduleStartStore.setStopContent(fullDate, "date");
      }
    }
  };

  selectDays = (key) => {
    scheduleStartStore.setContent(key, "selectDays");
  };
  selectDayType = () => {
    const { data } = toJS(scheduleStartStore);
    switch (data.startWorkflow) {
      case "specific_date_time":
        return (
          <Grid container spacing={2} style={{ marginBottom: 16 }} display={"flex"} alignItems={"center"}>
            <Grid item className="schedule-start-label">
              At this date
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={DateAdapter}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      style={{ margin: 0, width: "100%" }}
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      value={data.date}
                      onChange={(value) =>
                        this.setDateTime(value, "date", "start")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </LocalizationProvider>
            </Grid>
          </Grid>
        );
      case "particular_date_time":
        return (
          <Grid container spacing={2} style={{ marginBottom: 20 }} display={"flex"} alignItems={"center"}>
            <Grid item className="schedule-start-label">
              Select day
            </Grid>
            <Grid item>
              <ButtonGroup
                onClickButton={(key) => {
                  this.selectDays(key);
                }}
                size="small"
                data={data.selectDays}
              />
            </Grid>
          </Grid>
        );
      case "certain_interval":
        return (
          <Grid container spacing={2} style={{ marginBottom: 16 }} display={"flex"} alignItems={"center"}>
            <Grid item className="schedule-start-label">
              Starting on
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={DateAdapter}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      style={{ margin: 0, width: "100%" }}
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      value={data.date}
                      onChange={(value) =>
                        this.setDateTime(value, "date", "start")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      renderInput={params => <TextField {...params} /> }
                    />
                  </Grid>
                </LocalizationProvider>
            </Grid>
          </Grid>
        );
    }
  };

  handleKeyChange = (e) => {
    const placeholderValue = replacePlaceholderValue(e.target.value);
    scheduleStartStore.setContent(placeholderValue, "key");

    const { data } = toJS(scheduleStartStore);
    const value = checkKey(placeholderValue, data);
    this.setState({ checkKey: value[0], errorMessage: value[1] });
  };

  render() {
    const { data } = toJS(scheduleStartStore);
    const { loading } = this.state;
    const { isExpanded } = this.props;
    
    return (
      <React.Fragment>
        {!loading ? (
          <div className="item-wrapper" style={{ width: "100%" }}>
            <Grid container spacing={2} style={{ marginBottom: 16 }} display={"flex"} alignItems={"center"}>
              <Grid
                item
                style={{ flexBasis: "unset" }}
                className="schedule-start-label"
              >
                Placeholder
              </Grid>
              <Grid item>
                <W86PlaceholderTextField
                  value={data.key}
                  error={this.state.checkKey ? true : false}
                  helperText={this.state.checkKey ? this.state.errorMessage : null}
                  placeholderText="Enter placeholder for schedule start"
                  handleChange={this.handleKeyChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginBottom: 16 }} display={"flex"} alignItems={"center"}>
              <Grid item className="schedule-start-label">
                Start this workflow
              </Grid>
              <Grid item>
                <Select
                  name="selectType"
                  disableUnderline
                  variant="standard"
                  labelId="demo-simple-select-outlined-label"
                  className="schedule-start-select"
                  displayEmpty
                  value={data.startWorkflow}
                  onChange={(e) => {
                    scheduleStartStore.setContent(e.target.value, "workflow");
                    this.setDateTime(data.time, "time");
                  }}
                >
                  <MenuItem value="specific_date_time">
                    {" "}
                    at a specific date/time{" "}
                  </MenuItem>
                  <MenuItem value="particular_date_time">
                    {" "}
                    on a particular day/time of the week{" "}
                  </MenuItem>
                  <MenuItem value="certain_interval">
                    {" "}
                    at a certain interval of time{" "}
                  </MenuItem>
                </Select>
                {/* <Grid item xs={8} sm={8} md={6} lg={4} xl={4}>
                  <Select
                    name="selectType"
                    disableUnderline
                    variant="standard"
                    labelId="demo-simple-select-outlined-label"
                    className="dropdown-mat selectStandard item-select"
                  
                    displayEmpty
                    value={data.startWorkflow}
                    onChange={(e) => {
                      scheduleStartStore.setContent(e.target.value, "workflow");
                      this.setDateTime(data.time, "time");
                    }}
                  >
                    <MenuItem value="specific_date_time">
                      {" "}
                      at a specific date/time{" "}
                    </MenuItem>
                    <MenuItem value="particular_date_time">
                      {" "}
                      on a particular day/time of the week{" "}
                    </MenuItem>
                    <MenuItem value="certain_interval">
                      {" "}
                      at a certain interval of time{" "}
                    </MenuItem>
                  </Select>
                </Grid> */}
              </Grid>
            </Grid>
            <ValidationErrors
              open={this.state.errorDialog}
              close={() => {
                this.setState({ errorDialog: false, errors: [] });
              }}
              errors={this.state.errors}
            />
            {(data.startWorkflow == "specific_date_time" ||
              data.startWorkflow == "certain_interval") &&
              this.state.errorDateTime && (
                <Grid container spacing={2} style={{ marginBottom: 16 }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="item-label text-normal"
                  >
                    <div style={{ background: "#F7E5E9", padding: 8 }}>
                      <p
                        className="err-text errorTextFile"
                        style={{ margin: 0 }}
                      >
                        <ErrorIcon className="iconDiscard" />
                        <span>{this.state.errorDateTime}</span>
                      </p>
                    </div>
                  </Grid>
                </Grid>
              )}

            {this.selectDayType()}

            <Grid container spacing={2} style={{ marginBottom: 16 }} display={"flex"} alignItems={"center"}>
              <Grid item className="schedule-start-label">
                At this time
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <TimePicker
                      style={{ width: "100%" }}
                      margin="normal"
                      id="time-picker"
                      label="Time picker"
                      value={new Date("2014-08-18T" + data.time)}
                      onChange={(value) =>
                        this.setDateTime(value, "time", "start")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change time"
                      }}
                      renderInput={params => <TextField {...params} /> }
                    />
                  </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid container spacing={2} display={"flex"} alignItems={"center"}>
              <Grid item className="schedule-start-label">
                Timezone
              </Grid>
              <Grid item sm={isExpanded ? 4 : 6} xl={isExpanded ? 3 : 6}>
                <Autocomplete
                    options={timezones}
                    autoComplete={true}
                    value={data.timezone}
                    size={"small"}
                    onChange={(event, inputValue) => {
                      if (inputValue != null)
                        this.handleChangeTimezone(inputValue.name);
                    }}
                    renderInput={(params) => {
                      return (
                        <TextFieldMUI
                          {...params}
                          variant="outlined"
                          value={data.timezone}
                        />
                      );
                    }}
                    getOptionLabel={(option) => {
                      if (typeof option == "object")
                        return option.name + " " + option.offset;
                      return option;
                    }}
                    isOptionEqualToValue={(option, value) => {
                      if (option != null) {
                        if (option.name == value) return true;
                        else return false;
                      }
                    }}
                    id={"autocomplete"}
                    variant={"outlined"}
                    color={"info"}
                  />
              </Grid>
            </Grid>

            {data.startWorkflow == "certain_interval" && (
              <Grid spacing={2} style={{ marginTop: 16 }}>
                <Grid container spacing={2} style={{ marginBottom: 16 }} display={"flex"} alignItems={"center"}>
                  <Grid item className="schedule-start-label">
                    Repeat this workflow every
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2} display={"flex"} alignItems={"center"}>
                      <Grid item>
                        <TextField
                          variant="outlined"
                          placeholder="Insert number of times"
                          fullWidth
                          className="key_padding"
                          type={"number"}
                          value={data.repeat.interval}
                          onChange={(e) =>
                            scheduleStartStore.setRepeat(
                              e.target.value,
                              "interval"
                            )
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Select
                          name="selectType"
                          disableUnderline
                          variant="standard"
                          labelId="demo-simple-select-outlined-label"
                          className="schedule-start-select"
                          displayEmpty
                          value={data.repeat.type}
                          onChange={(e) =>
                            scheduleStartStore.setRepeat(e.target.value, "type")
                          }
                        >
                          <MenuItem value="day"> day(s) </MenuItem>
                          <MenuItem value="businessDays"> business day(s) </MenuItem>
                          <MenuItem value="hour"> hour(s) </MenuItem>
                          <MenuItem value="minute"> minute(s) </MenuItem>
                          <MenuItem value="month"> month(s) </MenuItem>
                          <MenuItem value="week"> week(s) </MenuItem>
                        </Select>
                      </Grid>
                      <Grid
                        item
                        className="schedule-start-label-smaller"
                      >
                        after the starting date and time
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: 16 }} display={"flex"} alignItems={"center"}>
                  <Grid item className="schedule-start-label">
                    Stop running the workflow
                  </Grid>
                  <Grid item>
                    <Select
                      name="selectType"
                      disableUnderline
                      variant="standard"
                      labelId="demo-simple-select-outlined-label"
                      className="schedule-start-select"
                      displayEmpty
                      value={data.stopRunning.type}
                      onChange={(e) =>
                        scheduleStartStore.setStopRunning(
                          e.target.value,
                          "type"
                        )
                      }
                    >
                      <MenuItem value="after_a_certain_number_of_repeats">
                        {" "}
                        after a certain number of repeats{" "}
                      </MenuItem>
                      <MenuItem value="once_a_date_time_is_reached">
                        {" "}
                        once a date/time is reached{" "}
                      </MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                {data.stopRunning.type ==
                  "after_a_certain_number_of_repeats" && (
                  <Grid container spacing={2} style={{ marginBottom: 16 }} display={"flex"} alignItems={"center"}>
                    <Grid item className="item-label text-normal">
                      <TextField
                        variant="outlined"
                        placeholder="Insert number of times"
                        fullWidth
                        className="key_padding"
                        type={"number"}
                        value={data.stopRunning.times}
                        onChange={(e) =>
                          scheduleStartStore.setStopRunning(
                            e.target.value,
                            "times"
                          )
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      alignItems={"center"}
                      style={{ display: "flex" }}
                      className="schedule-start-label-smaller"
                    >
                      number of times
                    </Grid>
                  </Grid>
                )}
                {data.stopRunning.type == "once_a_date_time_is_reached" &&
                  this.state.errorStopDateTime && (
                    <Grid container spacing={2} style={{ marginBottom: 16 }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="item-label text-normal"
                      >
                        <div style={{ background: "#F7E5E9", padding: 8 }}>
                          <p
                            className="err-text errorTextFile"
                            style={{ margin: 0 }}
                          >
                            <ErrorIcon className="iconDiscard" />
                            <span>{this.state.errorStopDateTime}</span>
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                {data.stopRunning.type == "once_a_date_time_is_reached" && (
                  <Grid spacing={2}>
                    <Grid container spacing={2} style={{ marginBottom: 16 }} display={"flex"} alignItems={"center"}>
                      <Grid item className="schedule-start-label">
                        At this date
                      </Grid>
                      <Grid item>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <Grid
                            container
                            justifyContent="space-around"
                            className="DatePickerContainer"
                          >
                            <DatePicker
                              renderInput={params => <TextField {...params} /> }
                              style={{ margin: 0, width: "100%" }}
                              disableToolbar
                              variant="inline"
                              format="dd.MM.yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              value={data.stopRunning.date}
                              onChange={(value) =>
                                this.setDateTime(value, "date", "stop")
                              }
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </Grid>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginBottom: 16 }} display={"flex"} alignItems={"center"}>
                      <Grid item className="schedule-start-label">
                        At this time
                      </Grid>
                      <Grid item>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <TimePicker
                            style={{ width: "100%" }}
                            margin="normal"
                            id="time-picker"
                            label="Time picker"
                            value={
                              new Date("2014-08-18T" + data.stopRunning.time)
                            }
                            onChange={(value) =>
                              this.setDateTime(value, "time", "stop")
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change time"
                            }}
                            renderInput={params => <TextField {...params} /> }
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} display={"flex"} alignItems={"center"}>
                      <Grid item className="schedule-start-label">
                        Timezone
                      </Grid>
                      <Grid item xs={isExpanded ? 2 : 6}>
                        <Autocomplete
                          options={timezones}
                          autoComplete={true}
                          value={data.stopRunning.timezone}
                          size={"small"}
                          onChange={(event, inputValue) => {
                            if (inputValue != null)
                              this.handleChangeStopRunningTimezone(
                                inputValue.name
                              );
                          }}
                          renderInput={(params) => {
                            return (
                              <TextFieldMUI
                                {...params}
                                variant="outlined"
                                value={data.stopRunning.timezone}
                              />
                            );
                          }}
                          getOptionLabel={(option) => {
                            if (typeof option == "object")
                              return option.name + " " + option.offset;
                            return option;
                          }}
                          isOptionEqualToValue={(option, value) => {
                            if (option != null) {
                              if (option.name == value) return true;
                              else return false;
                            }
                          }}
                          id={"autocomplete"}
                          variant={"outlined"}
                          color={"info"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        ) : <IndividualComponentLoader />}
      </React.Fragment>
    );
  }
}

export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(ScheduleStartTemplate));
