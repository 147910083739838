import React from "react";
import { Grid } from "@mui/material";

export const SUCCESS_MESSAGE = {
  role: "success",
  content: (
    <Grid container rowGap={1}>
      <Grid item xs={11}>
        <span>Workflow has been inserted and saved to the canvas</span>
      </Grid>
      <Grid item xs={11}>
        <span>Starting configuration of all components...</span>
      </Grid>
    </Grid>
  ),
};

export const SUCCESS_MESSAGE_COMPONENT = {
  role: "success",
  content: "Component has been successfully configured",
};
