import React, {Component} from 'react';
import Grid from "@mui/material/Grid";
import {Link} from "react-router-dom";

class AchievementCard extends Component {
  render() {
    const {data} = this.props
    return (
      <Grid item xs={12} className={data.complete ? "achieve-complete" : "achieve-uncomplete"}>
        <Grid container spacing={1}>
          {data.complete ?
            (
              <Grid item xs={12} className="txt-achieve-complete">
                🏆 Achievement earned
              </Grid>
            ) : ""
          }

          <Grid item xs={12} className="txt-achieve-title">
            {data.title}
          </Grid>

          <Grid item xs={12} className="txt-achieve-required">
            {data.required}
          </Grid>

          {
            data.tasks &&
              data.tasks.map(task => (
                <Grid item xs={12}>
                  <a href={task.link} target="_blank" className="txt-achieve-link">
                    {task.name}
                  </a>
                </Grid>
              ))
          }

          <Grid item xs={12} className="txt-achieve-bold">
            Reward
          </Grid>

          <Grid item xs={12} className="txt-achieve-reward">
            {data.reward}
          </Grid>

        </Grid>
      </Grid>
    );
  }
}

export default AchievementCard;