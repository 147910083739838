import React, { Component } from "react";
import { Card, CardContent, Typography, Grid, CircularProgress } from "@mui/material";
import { format } from "../../utils/Date";
import "./MarketplacePage.css";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import Tooltip from "@mui/material/Tooltip";
import { Emoji } from "emoji-mart";
import projectStore from "../ProjectCanvas/ProjectStore";
import PortalModal from '../Portal/PortalModal';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
  },
}));

const CircularProgressContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
});

const FullScreenIFrame = styled('iframe')({
  width: '100%',
  height: '100%',
  border: 'none',
});

const CardContentWrapper = styled(CardContent)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const EmojiWrapper = styled('div')({
  paddingBottom: '16px',
});

const CategoriesTypography = styled(Typography)({
  marginTop: '12px',
});

class MarketplaceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      iframeLoading: true,
    };
  }

  truncateDescription = (description) => {

    if (!description) {
      return;
    }

    if (description.length > 100) {
      return description.substring(0, 100) + '...';
    } else {
      return description;
    }
  }

  renderEmoji = (imagePath) => {
    // Checks if image path is not an emoji
    if (!imagePath || imagePath.charAt(0) !== ':') {
      return ":twisted_rightwards_arrows:"
    }
    else {
      return imagePath;
    }
  }

  renderCategories = (categories) => {
    let categoriesString = ""

    if (!categories) {
      return "";
    }
    
    categories.forEach((c, idx) => {
      if (idx === categories.length - 1) {
        categoriesString += " " + projectStore.state.workflowCategoriesMapping[c]
        return;
      }
      categoriesString += projectStore.state.workflowCategoriesMapping[c] + " | "
    })

    return categoriesString;
  };

  handleCardClick = () => {
    // Pre-fetch the iframe content
    const iframe = document.createElement('iframe');
    iframe.src = `/template/${this.props.template.projectId}`;
    
    // Open the modal immediately
    this.setState({ modalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  handleIframeLoad = () => {
    this.setState({ iframeLoading: false });
  };

  render() {
    const { template } = this.props;
    const { modalOpen, iframeLoading } = this.state;

    return (
      <>
        <StyledCard
          className="cardFrameStyle marketplace cursor-pointer"
          onClick={this.handleCardClick}
          data-tut="reactour__marketplace__download"
        >
          <CardContentWrapper>
            <Grid container direction="column" style={{ height: '100%' }}>
              <Grid
                container
                item
                className="textArea"
                direction="column"
                style={{ flexGrow: 1 }}
              >
                <EmojiWrapper>
                  <Emoji
                    size={"2.5em"}
                    emoji={this.renderEmoji(template.imagePath)}
                    set="twitter"
                  />
                </EmojiWrapper>
                <Tooltip title={template.name} placement="bottom-start">
                  <Typography variant="div" className="textHeader">
                    {template.name}
                  </Typography>
                </Tooltip>
                <Tooltip title={template.author} placement="bottom-start">
                  <Typography component="p" className="textAuthor">
                    By {template.author}
                  </Typography>
                </Tooltip>
                <Tooltip title={template.description}>
                  <Typography component="p" className="textDescription">
                    {this.truncateDescription(template.description)}
                  </Typography>
                </Tooltip>
                <Tooltip title={this.renderCategories(template.categories)}>
                  <CategoriesTypography
                    component="p"
                    className="textAuthor"
                  >
                    {this.renderCategories(template.categories)}
                  </CategoriesTypography>
                </Tooltip>
                {template.isPublic && !template.isHidden ? (
                  <Tooltip
                    title={
                      template.isPublic && !template.isHidden
                        ? format(template.lastModified, ProjectStore.state.timezone)
                        : template.lastModified
                    }
                    placement="bottom-start"
                  >
                    <Typography component="p" className="lastUpdated">
                      Last updated {format(template.lastModified, null, "relative")}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography component="p" className="textAuthor">
                    <Grid container direction={"row"} xs={12}>
                      Last updated{" "}
                      <div className={"datespacing"}>{template.lastModified}</div>
                    </Grid>
                  </Typography>
                )}
                <Typography component="p" className="textDownloads">
                  {template.downloads} downloads
                </Typography>
              </Grid>
            </Grid>
          </CardContentWrapper>
        </StyledCard>
        <PortalModal
          open={modalOpen}
          onClose={this.handleCloseModal}
          url={`/template/${template.projectId}`}
        >
          {iframeLoading && (
            <CircularProgressContainer>
              <CircularProgress />
            </CircularProgressContainer>
          )}
          <FullScreenIFrame
            src={`/template/${template.projectId}`}
            onLoad={this.handleIframeLoad}
          />
        </PortalModal>
      </>
    );
  }
}


export default MarketplaceCard;