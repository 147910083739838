import React, { useState, useEffect } from "react";

// MUI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Radio, RadioGroup, FormControlLabel, FormLabel, Slide } from "@mui/material";

import RenameSourceAndReferences from "./RenameSourceAndReferences";
import FixBrokenPlaceholders from "./FixBrokenPlaceholders";
import { DropdownLabel } from "./RenameStyledComponents";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function RenamePlaceholdersDialog({ isOpen, closeDialog, projectId, openBrokenPHRename, setOpenBrokenPHRename }) {
    const [isInitialSelection, setIsInitialSelection] = useState(true);
    const [renameOption, setRenameOption] = useState("");

    useEffect(() => {
        if (!openBrokenPHRename) return;

        // Need to open broken PH replace if given
        setRenameOption("broken_references");
        setIsInitialSelection(false);

        return () => {
            // Clean up
            setIsInitialSelection(true);
            setRenameOption("");
            setOpenBrokenPHRename(false);
        };
    }, []);

    const getStartButtons = () => {
        return (
            <>
                <Button variant="outlined" onClick={closeDialog}>
                    Close
                </Button>
                <Button variant="contained" onClick={() => setIsInitialSelection(false)}>
                    Next
                </Button>
            </>
        );
    };

    const getSelectDropdown = () => {
        const descriptions = {
            source_and_references: "This option allows you to rename an existing placeholder and automatically update all references to it throughout your project. Use this when you want to update all references to one placeholder to another, or to rename a placeholder from one name to another.",
            broken_references: "This option helps you fix broken placeholder references by pointing them to an existing valid placeholder. Use this when you have references to placeholders that no longer exist.",
        };

        return (
            <>
                <DialogTitle>Rename and Fix Placeholders</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <RadioGroup
                            value={renameOption}
                            onChange={(e) => setRenameOption(e.target.value)}
                        >
                            <FormControlLabel 
                                value="source_and_references"
                                control={<Radio />}
                                label="Update placeholder name to a new name"
                            />
                            <FormControlLabel 
                                value="broken_references"
                                control={<Radio />}
                                label="Update broken placeholders to another placeholder"
                            />
                        </RadioGroup>
                        {renameOption && (
                            <DropdownLabel sx={{ mt: 2 }}>
                                {descriptions[renameOption]}
                            </DropdownLabel>
                        )}
                    </FormControl>
                </DialogContent>
                <DialogActions>{getStartButtons()}</DialogActions>
            </>
        );
    };

    const getRelevantOption = () => {
        const handleBack = () => setIsInitialSelection(true);
        
        if (renameOption === "source_and_references") 
            return <RenameSourceAndReferences projectId={projectId} closeDialog={closeDialog} onBack={handleBack} />;
        else if (renameOption === "broken_references") 
            return <FixBrokenPlaceholders projectId={projectId} closeDialog={closeDialog} onBack={handleBack} />;
    };

    return (
        <Dialog fullWidth maxWidth="md" open={isOpen ? isOpen : false} TransitionComponent={Transition} onClose={closeDialog} disableEnforceFocus={true}>
            {isInitialSelection ? getSelectDropdown() : getRelevantOption()}
        </Dialog>
    );
}

export default RenamePlaceholdersDialog;
