import React, { useState } from "react";

// MUI
import { Grid, MenuItem, Select } from "@mui/material";

import { timezones } from "../../../../../utils/timezones";

function DateTimeRegion({ col, classes, handleChangeDtFormat }) {
  const [outputFormat, setOutputFormat] = useState(col.dtFormat.outputFormat);
  const [timezone, setTimezone] = useState(col.dtFormat.timezone);

  const handleOnBlurOutputFormat = () => {
    if (outputFormat === col.dtFormat.outputFormat) return;
    handleChangeDtFormat(col.id, outputFormat, true);
  };

  const handleOnBlurTimezone = () => {
    if (timezone === col.dtFormat.timezone) return;
    handleChangeDtFormat(col.id, timezone, false);
  };

  return (
    col && (
      <Grid item>
        <Grid item xs={12}>
          <Select
            onChange={e => setTimezone(e.target.value)}
            onBlur={handleOnBlurTimezone}
            className={classes.selectList}
            variant={"outlined"}
            value={timezone}
            defaultValue={"GMT+10"}
            size="small"
          >
            <MenuItem value="" disabled>
              {" "}
              Select timezone
            </MenuItem>
            {timezones.length > 0 &&
              timezones.map((item, index) => (
                <MenuItem value={item.offset} key={index}>
                  {" "}
                  {item.name} {item.offset}{" "}
                </MenuItem>
              ))}
          </Select>
        </Grid>
      </Grid>
    )
  );
}

export default DateTimeRegion;
