export const JsDemoCode = `// Welcome to the Run Code component 
// There are 3 basic steps to using this component:

// Step 1: write the code you want to run 
// here we are creating a new variable called helloWorld 
// and assigning it the value of "Hello world"
const helloWorld = "Hello world, I run custom code in JavaScript!"; 

// Step 2: Return the output of your function in an object
// To set the output from this component, we map a value from
// the code to a key. Here we are mapping hello_world to output1
return {
  output1: helloWorld
}

// Step 3: to pass output1 as a placeholder to the next component, 
// we set these up via the Output Placeholder field below`

export const PythonDemoCode = `# Welcome to the Run Code component
# There are 3 basic steps to using this component:

# Step 1: write the code you want to run
# here we are creating a new variable called hello_world
# and assigning it the value of "Hello world"
hello_world = "Hello world, I run custom code in Python!"

# Step 2: Return the output of your function in an object
# To set the output from this component, we map a value from
# the code to a key in the output_json object. Here we are
# mapping hello_world to the key output1.
output_json = {
    "output1": hello_world
}
return output_json

# Step 3: to pass output1 as a placeholder to the next component,
# we set these up via the Output Placeholder field below`