import React, { useState } from "react";
import PropTypes from "prop-types";

import { PlaceholderTextField } from "./StyledComponents";
import W86PlaceholderTextField from "../../W86Components/W86PlaceholderTextField";

function RenderPlaceholderTextField({
  currVal,
  index,
  handleOnTextFieldBlur,
  error
}) {
  
  const [stateVal, setStateVal] = useState(currVal);

  return (
    <W86PlaceholderTextField
      size="normal"
      value={stateVal}
      error={error.idx === index && error.hasError}
      helperText={error.idx === index && error.hasError ? error.message : ""}
      placeholderText="Enter exported placeholder value"
      handleChange={(e)=> {
        let val = e.target.value.replace(/[^\w]/gi, "_");
        setStateVal(val)
      }}
      onBlur={() => handleOnTextFieldBlur(stateVal, index)}
    />
  );
}

RenderPlaceholderTextField.propTypes = {
  currVal: PropTypes.string,
  index: PropTypes.number,
  handleOnTextFieldBlur: PropTypes.func,
  error: PropTypes.object
};

export default RenderPlaceholderTextField;
