import React, {Component} from "react";
import {reaction, toJS, autorun} from "mobx";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { styled } from '@mui/material/styles';
import APIResReqStore from "./APIResReqStore";
import "./APIResReqStyle.css";
import {inject, observer} from "mobx-react";
import {getCurrentTime} from "../../utils/getCurrentTime";
import {send_component_save_request, send_request} from "../../utils/Request";
import SetUpRequest from "./Components/SetUpRequest";
import SetUpPlaceholders from "./Components/SetUpPlaceholders";
import Finish from "./Components/Finish";
import SetUpSample from "./Components/SetUpSample";
import ProjectStore from "../ProjectCanvas/ProjectStore";
import {addCompleter} from 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/webpack-resolver';
import {IndividualComponentLoader} from "../../utils/ComponentLoadingSkeleton";
import CurlTemplatesSetup from "./Components/CurlTemplates/CurlTemplatesSetup";

const CurlSetupPaper = styled(Paper)({
  paddingBottom: '16px',
  marginBottom: '16px',
});

class APIResReqTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadData: [],
      generateData: [],
      tab: 0,
      selectedGenerate: [],
      show: false,
      placeholdersJson: [],
      checkKey: false,
      checkRoutingKey: false,
      data: {},
      token: "",
      index: 0,
      placeholder: [],
      placeholders: [],
      loading: false,
      boolState: false,
      focus: null,
      loadingCircle: false,
      loadingButton: false,
      activeStep: 0,
      apiStep: 0,
      method: null,
      status: null,
      error: false,
      requestKeyPlaceholders: {},
      requestValuePlaceholders: {},
      initialLoad:true,
    };
    APIResReqStore.initialiseRequestEmpty();
    APIResReqStore.initialiseSampleEmpty();
    APIResReqStore.initialiseResponseEmpty();
  }

  downloadMultiple = (e) => {
    e.preventDefault();
  };

  componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({loading: true});
      send_request(
        `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
        "",
        {}
      )
        .then((response) => {
          if (response && response.data) {
            const {components} = response.data;
            if (components) {
              APIResReqStore.setRequestTemplate(components[0]);
              APIResReqStore.setSampleTemplate(components[0]);
              APIResReqStore.setResponseTemplate(components[0]);
              APIResReqStore.setCurlTemplateData(components[0]);
              this.props.onComponentNameChanged(APIResReqStore.name);
              this.props.onComponentDescriptionChanged(components[0].description);
              this.props.onLastModifiedChanged(APIResReqStore.lastModified);
              if (components[0].componentData) {
                if (components[0].componentData.responseData) {
                  if (
                    !components[0].componentData.responseData.samplePlaceholders && components[0].componentData.responseData.placeholders
                  ) {
                    APIResReqStore.responseData.samplePlaceholders = [];
                    for (
                      let i = 0;
                      i <
                      components[0].componentData.responseData.placeholders
                        .length;
                      i++
                    ) {
                      components[0].componentData.responseData.placeholders[
                        i
                        ].checked = true;
                      APIResReqStore.addJsonData(
                        components[0].componentData.responseData.placeholders[i]
                      );
                    }
                  }
                  this.setState({
                    placeholders:
                    components[0].componentData.responseData.samplePlaceholders,
                  });
                  if (
                    components[0].componentData.responseData.json != "" &&
                    components[0].componentData.responseData.samplePlaceholders !=
                    []
                  ) {
                    this.setState({
                      activeStep: 3,
                      apiStep: 3,
                    });
                  }
                  this.setState({loading: false});
                  this.props.setChildTemplateLoaded(true);
                }
              } else {
                let lp = [];
                lp.push({
                  index: 0,
                  placeholders: [],
                  key: APIResReqStore.keyAuto(),
                  incomingKey: "",
                });
                this.setState({placeholders: lp, loading: false});
                this.props.setChildTemplateLoaded(true);
              }
            }
          }
          
          if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData["requestData"] !== undefined && this.props.aiComponentBuilderData["requestData"] !== '') {
            APIResReqStore.updateRequestData(this.props.aiComponentBuilderData["requestData"])
          }
          if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData["sampleData"] !== undefined && this.props.aiComponentBuilderData["sampleData"] !== '') {
            APIResReqStore.updateSampleData(this.props.aiComponentBuilderData["sampleData"])
          }
          if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData["name"]) this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
          if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData["explanation"]) this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);

          this.props.setChildTemplateLoaded(true);
          this.setState({loading: false});
        })
        .catch((err) => {
          throw Error(err.message);
        });

      const placeholderParameters = [];
      if (this.props
        && this.props.availablePlaceholders
        && this.props.availablePlaceholders.length === 1
        && this.props.availablePlaceholders[0]
        && this.props.availablePlaceholders[0].placeholders
        && this.props.availablePlaceholders[0].placeholders.length > 0
      ) {
        for (let i = 0; i < this.props.availablePlaceholders[0].placeholders.length; i++) {
          const completeKey = "${" + this.props.availablePlaceholders[0].placeholders[i].key + "}";
          const newCompleteItem = {
            name: completeKey,
            value: completeKey,
            caption: completeKey,
            meta: 'local',
            score: 1000
          };
          placeholderParameters.push(newCompleteItem)
        }
      }
      addCompleter({
        getCompletions: function (editor, session, pos, prefix, callback) {
          callback(null, placeholderParameters);
        },
      });
    }
  }

  setRequestName = autorun(() => {
    APIResReqStore.setRequestName(this.props.ComponentName);
  });

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveAPIResReq();
    this.reactToPlaceHolder();
  }

  saveAPIResReq = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {

      const {placeholderError} = toJS(APIResReqStore);
      if (this.state.error || placeholderError.length > 0) {
        this.props.showError();
        return;
      }

      let dataComponent = toJS(APIResReqStore).responseData;
      APIResReqStore.responseData.placeholders = [];
      if(dataComponent.samplePlaceholders)
      {
        for (let i = 0; i < dataComponent.samplePlaceholders.length; i++) {
          if (dataComponent.samplePlaceholders[i].checked == true) {
            APIResReqStore.responseData.placeholders.push(
                dataComponent.samplePlaceholders[i]
            );
          }
        }
      }
      const lastModified = getCurrentTime();
      APIResReqStore.setLastModified(lastModified);
      const requestTemplate = toJS(APIResReqStore.requestTemplate);
      const sampleTemplate = toJS(APIResReqStore.sampleTemplate);
      const responseTemplate = toJS(APIResReqStore.responseTemplate);
      const curlTemplateData = toJS(APIResReqStore.curlTemplateData)
      const data = {
        componentData: {
          name: toJS(this.props.ComponentName),
          requestData: requestTemplate.requestData,
          sampleData: sampleTemplate.sampleData,
          responseData: responseTemplate.responseData,
          lastModified: lastModified,
          data: {
            curlTemplateData: curlTemplateData,
          },

        },
        componentId: requestTemplate.componentId
          ? requestTemplate.componentId
          : this.props.component_id,
        description: this.props.ComponentDescription.value
      };

      send_component_save_request(
        `component-service/apirequestandresponse/data`,
        data,
        "",
        "POST",
        this.props.SaveTrigger
      )
        .then((response) => {
          if (response && response.status === 200) {
            this.props.showSuccess(data, this.props.SaveTrigger);
            this.props.onLastModifiedChanged(lastModified);
            ProjectStore.savedComponent = true;
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (placeholder["selected"] === undefined) {
        return;
      }
      const {style, key} = placeholder["selected"];
      this.insertPlaceholder(style.default, key, style, this.state.focus);
    }
  );

  insertPlaceholder = (label, text, style, focus) => {
    // Handles key-value headers
    if (typeof focus === "object" && focus !== null) {
      const {headerType, index, step} = focus;
      const placeholder = "${" + text + "}"
      if (headerType === "key") {
        // We want to check if we are inserting placeholders into
        // the set up request step or set up sample step
        if (step === "request") {
          APIResReqStore.requestData.headers[index].header_name += placeholder;
          const newKeysPlaceholders = {...this.state.requestKeyPlaceholders};
          if (!(index in newKeysPlaceholders)) {
            newKeysPlaceholders[index] = [{text}];
          } else {
            const indexedArray = [...newKeysPlaceholders[index]]
            indexedArray.push({text});
            newKeysPlaceholders[index] = indexedArray;
          }

          this.setState({requestKeyPlaceholders: newKeysPlaceholders});
        }
      } else {
        if (step === "request") {
          APIResReqStore.requestData.headers[index].header_value += placeholder;
          const newValuePlaceholders = {...this.state.requestValuePlaceholders};
          if (!(index in newValuePlaceholders)) {
            newValuePlaceholders[index] = [{text}];
          } else {
            const indexedArray = [...newValuePlaceholders[index]]
            indexedArray.push({text});
            newValuePlaceholders[index] = indexedArray;
          }

          this.setState({requestValuePlaceholders: newValuePlaceholders});
        }

      }

      return;
    }

    if (focus === "editor") {
      this.setState((prevState) => ({
        placeholders: [
          ...prevState.placeholders,
          {label, text, style, focus},
        ],
      }));
    } else if (focus == "body") {
      text = `@[${text}](${text})`;
      const {data} = toJS(APIResReqStore.template);
      text = data.body + text;
      APIResReqStore.setApiReqBody(text);
    } else if (focus === "endpoint") {
      let {endpointPlaceholders} = APIResReqStore;
      endpointPlaceholders.push({label, text, style});

      APIResReqStore.setEndpointPlaceholders(endpointPlaceholders);
    }
  };

  insertHeaders = () => {
    APIResReqStore.addCustomHeaders();
  };

  handleAddPlaceholders = () => {
    APIResReqStore.addRow();
  };

  handleRoutingKeyChange = (event, placeholder) => {
    APIResReqStore.onChangeField(event.target.value, placeholder, "content");
  };

  handleJsonBodyChange = (value, type) => {
    APIResReqStore.onChangeField(value, "", type);
  };

  iterate = (obj) => {
    var walked = [];
    var stack = [{obj: obj, stack: ""}];
    let result = {};
    while (stack.length > 0) {
      var item = stack.pop();
      var obj = item.obj;
      for (var property in obj) {
        if (obj.hasOwnProperty(property)) {
          if (typeof obj[property] == "object") {
            var alreadyFound = false;
            for (var i = 0; i < walked.length; i++) {
              if (walked[i] === obj[property]) {
                alreadyFound = true;
                break;
              }
            }
            if (!alreadyFound) {
              walked.push(obj[property]);
              if (Number.isInteger(parseInt(property))) {
                stack.push({obj: obj[property], stack: item.stack});
              } else {
                stack.push({
                  obj: obj[property],
                  stack: (item.stack ? item.stack + "." : "") + property,
                });
              }
            }
          } else {
            if (!result[item.stack + "." + property]) {
              result[item.stack + "." + property] = [];
            }
            result[item.stack + "." + property].push(obj[property]);
          }
        }
      }
    }
    console.log(result);
    return result;
  };

  handleJson = (key, value) => {
    for (let j = 0; j < value.length; j++) {
      if (Array.isArray(value[j])) {
        for (let x = 0; x < value[j].length; x++) {
          value.push(value[j][x]);
          key.push(key[j] + "[" + x + "]");
        }
      } else if (typeof value[j] === "object" && value[j] !== null) {
        let keyData = Object.keys(value[j]);
        let valueData = Object.values(value[j]);
        for (let y = 0; y < valueData.length; y++) {
          value.push(valueData[y]);
          key.push(key[j] + "." + keyData[y]);
        }
      }
    }
  };

  handleAddPlaceholdersJson = () => {
    APIResReqStore.clearField();
    const {responseData} = toJS(APIResReqStore);
    const json = responseData.json;
    if (json != "") {
      let data = JSON.parse(json);
      let keyData = Object.keys(data);
      let valueData = Object.values(data);
      let keyTmp = [];
      for (let i = 0; i < keyData.length; i++) {
        keyTmp.push("." + keyData[i]);
      }

      this.handleJson(keyData, valueData);

      let result = this.iterate(data);
      keyData = keyData.concat(Object.keys(result));
      let valueTmpData = Object.values(result);
      valueData = valueData.concat(
        valueTmpData.map((v) => {
          return JSON.stringify(v);
        })
      );
    }
  };

  handleDeletePlaceholder = (placeholder, index, event) => {
    APIResReqStore.deleteRow(placeholder);
  };

  handleChange = (event, newValue) => {
    this.setState({tab: newValue});
  };

  handingExtensionFileName = (text) => {
    let arr = text.split(".");
    return arr[arr.length - 1];
  };

  getStepContent = (step, isExpanded) => {
    const {responseData, name, requestData} = toJS(APIResReqStore);
    switch (step) {
      case 0:
        return (
          <SetUpRequest
            placeholders={this.state.placeholders}
            keyPlaceholders={this.state.requestKeyPlaceholders}
            valuePlaceholders={this.state.requestValuePlaceholders}
            availablePlaceholders={this.props.availablePlaceholders}
            onChangeState={(apiStep, activeStep) =>
              this.onChangeState(apiStep, activeStep)
            }
            initialLoad={this.state.initialLoad}
            setInitialLoad={(state) => {this.setState({initialLoad: state})}}
            onFocus={(focus) => {
              this.setState({focus});
            }}
            setOnFocus={(state) => {
              this.setState({
                focus: state,
              });
            }}
            isExpanded={isExpanded}
          />
        );
      case 1:
        return (
          <SetUpSample
            availablePlaceholders={this.props.availablePlaceholders}
            onChangeState={(apiStep, activeStep) => {
                this.onChangeState(apiStep, activeStep);
            }}
            
            componentStore={APIResReqStore}
            project_id={this.props.project_id}
            component_id={this.props.component_id}
          />
        );
      case 2:
        return (
          <SetUpPlaceholders
            availablePlaceholders={this.props.availablePlaceholders}
            componentStore={APIResReqStore}
            onChangeState={(apiStep, activeStep) =>
              this.onChangeState(apiStep, activeStep)
            }
            handleKeyChanges={(error) => this.setState({error})}
            stepStatus={"setPlaceholders"}
          />
        );
      case 3:
        return (
          <Finish
            availablePlaceholders={this.props.availablePlaceholders}
            onChangeState={(apiStep, activeStep) =>
              this.onChangeState(apiStep, activeStep)
            }
            stepStatus={"finish"}
          />
        );
      default:
        return "Unknown step";
    }
  };

  onChangeState = (apiStep, activeStep) => {
    this.setState({
      apiStep: apiStep,
      activeStep: activeStep,
    });
  };

  handleOnClickStep = (step) => {
    this.setState({
      apiStep: step,
      activeStep: step,
    });
  };

  render() {
    const {activeStep, apiStep} = this.state;
    const {requestData} = toJS(APIResReqStore);

    const steps = [
      "Set up request",
      "Set up sample",
      "Set up placeholders",
      "Finish",
    ];

    const isCurlSetupMode = APIResReqStore.requestData.setupMode !== "CURL";
    if (this.state && !this.state.loading) {
      const shouldHide = !requestData?.skipSample;
      return (
        <Grid container>
          <Grid item xs={12}>
            <Paper className="componentPaper" hidden={requestData && requestData.skipSample}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepButton
                        onClick={() => {
                          this.handleOnClickStep(index);
                        }}
                        {...labelProps}
                      >
                        {label}
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>
              {this.getStepContent(activeStep, this.props.isExpanded)}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <CurlSetupPaper className="componentPaper" hidden={isCurlSetupMode}>
              <CurlTemplatesSetup
                availablePlaceholders={this.props.availablePlaceholders}
                setOnFocus={(state) => {
                  this.setState({
                    focus: state,
                  });
                }}
              />
            </CurlSetupPaper>
          </Grid>
          <Grid item xs={12}>
            <Paper className="componentPaper" hidden={shouldHide}>
              <SetUpRequest
                  placeholders={this.state.placeholders}
                  keyPlaceholders={this.state.requestKeyPlaceholders}
                  valuePlaceholders={this.state.requestValuePlaceholders}
                  availablePlaceholders={this.props.availablePlaceholders}
                  onFocus={(focus) => {
                    this.setState({focus});
                  }}
                  setOnFocus={(state) => {
                    this.setState({
                      focus: state,
                    });
                  }}
                  isExpanded={this.props.isExpanded}
              />
            </Paper>
          </Grid>
        </Grid>
      );
    } else return <IndividualComponentLoader/>;
  }
}

export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(APIResReqTemplate));
