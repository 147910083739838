import React from "react";
import { runLoading } from "../../../utils/showLoadingScreen";
import ConditionalWorkflowTemplate from "../../ConditionalWorkflow/ConditionalWorkflowTemplate";
import FormBuilderPage from "../../FormBuilder/FormBuilderPage";
import SequentialForm from "../../SequentialForm/SequentialForm";
import TriggerFromZapierTemplate from "../../TriggerFromZapier/TriggerFromZapierTemplate";
import ApiResReqTemplate from "../../APIRequestAndResponse/APIResReqTemplate";
import WebhookImportTemplate from "../../WebhookImport/WebhookImportTemplate";
import TriggerAZapTemplate from "../../TriggerAZap/TriggerAZapTemplate";
import TimeDelayTemplate from "../../TimeDelay/TimeDelayTemplate";
import SlackTemplate from "../../Slack/SlackTemplate";
import CreateDatabaseRecordTemplate from "../../CreateDatabaseRecord/CreateDatabaseRecordTemplate";
import UpdateDatabaseRecordTemplate from "../../UpdateDatabaseRecord/UpdateDatabaseRecordTemplate";
import KnowledgeTemplate from "../../Knowledge/KnowledgeTemplate";
import TriggerAnotherWorkflowTemplate from "../../TriggerAnotherWorkflow/TriggerAnotherWorkflowTemplate";
import MergeWorkflowTemplate from "../../MergeWorkflow/MergeWorkflowTemplate";
import ConditionalLogicTemplate from "../../ConditionalLogic/ConditionalLogicTemplate";
import ScheduleStartTemplate from "../../ScheduleStart/ScheduleStartTemplate";
import TriggerFromAWorkflowTemplate from "../../TriggerFromAWorkflow/TriggerFromAWorkflowTemplate";
import EmailWriter from "../../Email/EmailWriter";
import TransformTemplate from "../../ListTransform/TransformTemplate";
import ParseJsonTemplate from "../../ParseJSON/ParseJSONTemplate";
import LoopThroughListTemplate from "../../LoopThroughList/LoopThroughListTemplate";
import ApiExportTemplate from "../../ApiExport/ApiExportTemplate";
import CodeTemplate from "../../Code/CodeTemplate";
import CalculatorTemplate from "../../Calculator/CalculatorTemplate";
import QueryDatabaseTemplate from "../../QueryDatabase/QueryDatabaseTemplate";
import DocumentTemplate from "../../DocumentEditor/DocumentTemplate";
import BulkAssessmentTemplate from "../../BulkAssessment/BulkAssessmentTemplate";
import SessionVariablesTemplate from "../../SessionVariable/SessionVariablesTemplate";
import EmailTriggerTemplate from "../../EmailTrigger/EmailTriggerTemplate";
import AIAssistantTemplate from "../../AIAssistant/AIAssistantTemplate";
import ConditionalWorkflowPathTemplate from "../../ConditionalWorkflowPath/ConditionalWorkflowPathTemplate";
import BranchPathsTemplate from "../../BranchPaths/BranchPathsTemplate";
//this function detects the type of component by type
//and returns the template for the componentPane to render

//when adding a new component to be rendered in the pane you will have to add the template into here
export default function getTemplate(type) {
  switch (type) {
    case "conditional_workflow":
      return <ConditionalWorkflowTemplate isExpanded={false} />;
    case "email":
      return <EmailWriter />;
    case "merge_workflow":
      return <MergeWorkflowTemplate />;
    case "conditional_logic":
      return <ConditionalLogicTemplate isExpanded={false} />;
    case "schedule_start":
      return <ScheduleStartTemplate isExpanded={false} />;
    case "trigger_from_a_workflow":
      return <TriggerFromAWorkflowTemplate />;
    case "api_export":
      return <ApiExportTemplate />;
    case "api_request_and_response":
      return <ApiResReqTemplate isExpanded={false} />;
    case "calculator":
      return <CalculatorTemplate isExpanded={false} />;
    case "code":
      return <CodeTemplate />;
    case "advanced_code":
      return <CodeTemplate />;
    case "create_database_record":
      return <CreateDatabaseRecordTemplate />;
    case "knowledge_bank":
      return <KnowledgeTemplate />;
    case "document_editor":
      return <DocumentTemplate isExpanded={false} />;
    case "form":
      return <FormBuilderPage isExpanded={false} />;
    case "sequential_form":
      return <SequentialForm isExpanded={false} isFormSection={false} />;
    case "form_section":
      return <SequentialForm isExpanded={false} isFormSection={true} />;
    case "list_transform":
      return <TransformTemplate isExpanded={false} />;
    case "loop_through_list":
      return <LoopThroughListTemplate />;
    case "parse_json":
      return <ParseJsonTemplate />;
    case "branch_paths":
      return <BranchPathsTemplate />;
    case "data_bank":
    case "query_database_record":
      return <QueryDatabaseTemplate />;
    case "workflow_data":
    case "slack":
      return <SlackTemplate />;
    case "time_delay":
      return <TimeDelayTemplate />;
    case "trigger_a_zap":
      return <TriggerAZapTemplate isExpanded={false} />;
    case "trigger_from_zapier":
      return <TriggerFromZapierTemplate isExpanded={false} />;
    case "trigger_another_workflow":
      return <TriggerAnotherWorkflowTemplate />;
    case "update_database_record":
      return <UpdateDatabaseRecordTemplate />;
    case "bulk_assessment":
      return <BulkAssessmentTemplate isExpanded={false} />;
    case "webhook":
      return <WebhookImportTemplate isExpanded={false} />;
    case "session_variables":
      return <SessionVariablesTemplate />;
    case "trigger_via_email":
      return <EmailTriggerTemplate />;
    case "ai_assistant":
      return <AIAssistantTemplate isExpanded={false} />;
    case "conditional_workflow_path":
      return <ConditionalWorkflowPathTemplate isExpanded={false} />;
    default:
      return runLoading();
  }
}
