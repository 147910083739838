import React, { Component } from "react";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { withStyles } from "@mui/styles";
import styles from "./BulkAssessmentStyle";
import { inject, observer } from "mobx-react";
import InputAdornment from "@mui/material/InputAdornment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import BulkAssessmentStore from "./BulkAssessmentStore.js";
import { reaction, toJS } from "mobx";
import BulkExcelUpload from "./components/BulkExcelUpload";
import { getCurrentTime } from "../../utils/getCurrentTime";
import { send_component_save_request, send_request } from "../../utils/Request";
import BulkItemKey from "./components/BulkItemKey";
import Icon from "@mui/material/Icon";
import checkKey from "../../utils/CheckKey";
import projectStore from "../ProjectCanvas/ProjectStore";
import checkKeyUtil from "../../utils/CheckKeyUtil";
import replacePlaceholderValue from "../../utils/ReplacePlaceholderValue";
import config from "../../config";
import { styled } from "@mui/system";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import W86PlaceholderTextField from "../W86Components/W86PlaceholderTextField";

const InfoText = styled("span")({
  color: "#868686",
  lineHeight: "19px",
  fontSize: "14px",
});

const AddColumnText = styled("span")({
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  color: "#868686",
  "&:hover": {
    cursor: "pointer",
    color: "#2196F3 !important",
  },
});

const StyledAddIcon = styled(AddIcon)({
  marginRight: 2,
});

class BulkAssessmentTemplate extends Component {
  constructor(props) {
    super(props);
    this.placeholder = [];
    this.state = {
      focus: false,
      placeholders: [],
      open: false,
      error: false,
      checkKey: false,
      errorMessage: "",
      keyErrors: [],
      spreadsheetOption: null,
      loading: true,
      description:null,
      status:null
    };
  }

  async componentDidMount() {
    const { component_id } = this.props;
    if (component_id) {
      const url = `project-service/project/component/${component_id}`;
      const json = await send_request(url, "", {}).catch((err) => {
        throw Error(err.message);
      });

      if (json && json.data) {
        BulkAssessmentStore.setData(json.data);
        this.props.onComponentDescriptionChanged(json.data.description);
        this.props.onComponentNameChanged(BulkAssessmentStore.name);
        this.props.onLastModifiedChanged(BulkAssessmentStore.lastModified);
        this.setSpreadsheetOption(json.data);
      }

      if (this.props.aiComponentBuilderData && this.props.aiComponentBuilderData.data) {
        this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
        this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
        BulkAssessmentStore.setTemplateData(this.props.aiComponentBuilderData.data);
        this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
        this.setState({ status: "AI_BUILD_GENERATED" });
      }

      this.setState({ loading: false });
      this.props.setChildTemplateLoaded(true);
    }
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveBATemplate();
  }

  saveBATemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    async () => {
      const { data } = toJS(BulkAssessmentStore);
      let keyError = false;
      data.fields.map((value, index) => {
        const result = checkKeyUtil(
          value.keyName,
          index,
          data.fields,
          "keyName"
        );
        if (result[0]) {
          keyError = result[0];
        }
      });

      let lastModified = getCurrentTime();
      let { type } = BulkAssessmentStore.template;
      // format data
      let fields = data.fields.map((field) => {
        return {
          keyName: field.keyName,
          dataType: field.dataType,
        };
      });
      let requestData = {
        componentData: {
          data: {
            key: data.key,
            fields: fields,
            option: this.state.spreadsheetOption,
          },
          name: toJS(this.props.ComponentName),
          lastModified: lastModified,
        },
        componentId: this.props.component_id,
        type: type,
        status: this.state.status,
        description: this.props.ComponentDescription.value
      };
      console.log(requestData);
      console.log(toJS(data));

      //send data
      const json = await send_component_save_request(
        "component-service/bulk_assessment/data",
        requestData,
        "",
        "POST",
        this.props.SaveTrigger
      ).catch((err) => {
        throw Error(err.message);
      });

      if (json && json.status == 200) {
        this.props.onLastModifiedChanged(lastModified);
        this.props.showSuccess(requestData, this.props.SaveTrigger);
        projectStore.savedComponent = true;
      }
    }
  );

  handleCancel = () => {
    this.setState({ open: false });
  };

  setSpreadsheetOption = (data) => {
    try {
      let option = data.componentData.data.option;
      if (!option) option = "manually_enter_columns";

      this.setState({ spreadsheetOption: option });
    } catch (e) {
      this.setState({ spreadsheetOption: "upload_sample_spreadsheet" });
    }
  };

  handingExcel = (data) => {
    BulkAssessmentStore.addDataFromExcel(data);
    this.setState({ open: false });
  };

  handleKeyChange = (e) => {
    const placeholderValue = replacePlaceholderValue(e.target.value);
    BulkAssessmentStore.setContent(placeholderValue, "key");

    const { data } = toJS(BulkAssessmentStore);
    const value = checkKey(placeholderValue, data);

    this.setState({
      checkKey: value[0],
      errorMessage: value[1],
    });
  };

  setKeyErrors = (status, key) => {
    if (status) {
      if (!this.state.keyErrors.includes(key)) {
        this.state.keyErrors.push(key);
      }
    } else {
      if (this.state.keyErrors.includes(key)) {
        let index = this.state.keyErrors.findIndex((x) => x === key);
        this.state.keyErrors.splice(index, 1);
      }
    }
  };

  render() {
    const { data } = toJS(BulkAssessmentStore);
    const { uploadPortal, key, fields } = data;
    const { open } = this.state;
    const { classes, isExpanded } = this.props;

    if (!this.state.loading)
      return (
        <div style={{ width: "100%" }}>
          <div className="item-wrapper">
            <Grid container alignItems={"center"}>
              <Grid item marginRight={2}>
                Placeholder
              </Grid>
              <Grid item>
                <W86PlaceholderTextField
                  value={key}
                  error={this.state.checkKey ? true : false}
                  helperText={this.state.checkKey ? this.state.errorMessage : null}
                  placeholderText="Enter placeholder for calculator"
                  handleChange={this.handleKeyChange}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={{ marginTop: "16px", marginBottom: "24px" }}
            >
              <Grid item xs={12}>
                <InfoText>Link to the upload spreadsheet portal</InfoText>
              </Grid>
              <Grid
                container
                item
                direction="column"
                className="componentContents"
                {...(this.props.isExpanded && {
                  xs: 12,
                  sm: 10,
                  md: 10,
                  lg: 7,
                  xl: 7,
                })}
              >
                <TextField
                  value={
                    "https://form.workflow86.com/bulk/" +
                    this.props.component_id
                  }
                  className="key_padding "
                  variant="outlined"
                  disabled={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" onClick={this.copy}>
                        <CopyToClipboard
                          text={
                            "https://form.workflow86.com/bulk/" +
                            this.props.component_id
                          }
                        >
                          <FileCopyIcon className="custom-icon-btn" />
                        </CopyToClipboard>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                Map spreadsheet columns
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid
                    item
                    xs={isExpanded && 4}
                    marginRight={
                      this.state.spreadsheetOption ===
                      "upload_sample_spreadsheet"
                        ? 2
                        : 0
                    }
                  >
                    {this.state.spreadsheetOption ? (
                      <Select
                        name="selectType"
                        disableUnderline
                        variant="standard"
                        labelId="demo-simple-select-outlined-label"
                        className="dropdown-mat selectStandard item-select-bulk"
                        displayEmpty
                        fullWidth={isExpanded ? true : false}
                        value={this.state.spreadsheetOption}
                        onChange={(e) => {
                          this.setState({ spreadsheetOption: e.target.value });
                        }}
                      >
                        <MenuItem value="upload_sample_spreadsheet">
                          Upload sample spreadsheet
                        </MenuItem>
                        <MenuItem value="manually_enter_columns">
                          Manually enter columns
                        </MenuItem>
                      </Select>
                    ) : (
                      <Skeleton
                        height={35}
                        width={400}
                        variant={"rectangular"}
                        style={{ borderRadius: "4px", background: "#E8E8E8" }}
                      />
                    )}
                  </Grid>
                  {this.state.spreadsheetOption ===
                    "upload_sample_spreadsheet" && (
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => this.setState({ open: !open })}
                      >
                        Upload
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InfoText>
                  {this.state.spreadsheetOption === "upload_sample_spreadsheet"
                    ? "Upload a sample spreadsheet to automatically map the columns"
                    : "Manually enter each column in the the spreadsheet to be uploaded"}
                </InfoText>
              </Grid>
              {this.state.spreadsheetOption === "manually_enter_columns" ? (
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                  marginBottom={3}
                  onClick={() => BulkAssessmentStore.addComponentKeyData()}
                >
                  <AddColumnText>
                    <StyledAddIcon /> Add column
                  </AddColumnText>
                </Grid>
              ) : (
                <Grid item xs={12} marginBottom={1} />
              )}
            </Grid>
            <Grid spacing={1} direction={"column"} container>
              <Grid container item direction={"row"} xs={12} fontSize={"14px"}>
                <Grid item xs={8}>
                  Column name{" "}
                </Grid>
                <Grid item xs={3}>
                  Data type
                </Grid>
                <Grid item xs={1}>
                  {" "}
                </Grid>
              </Grid>
              {fields.map((component, index) => (
                <Grid item container xs={12}>
                  <BulkItemKey
                    key={component.id}
                    component={component}
                    index={index}
                  />
                </Grid>
              ))}
            </Grid>
          </div>

          <BulkExcelUpload
            open={open}
            handleCancel={this.handleCancel}
            handingExcel={this.handingExcel}
          />
        </div>
      );
    else return <IndividualComponentLoader />;
  }
}

export default withStyles(styles)(
  inject(
    "SelectedPlaceholder",
    "ComponentName",
    "ComponentDescription",
    "SaveTrigger"
  )(observer(BulkAssessmentTemplate))
);
