import React from "react";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ConditionalLogicStore from "../ConditionalLogicStore";
import ItemCondition from "./ItemCondition";
import MentionEditor from "../../TextEditor/MentionEditor";
import { toJS } from "mobx";

class ItemStatementCondition extends React.Component {

  // isNumber = (value) => {
  //   const { outputType } = this.props;
  //   let isNumber = (outputType == 'NUMBER' ? !Number.isInteger(Number(value)) : false);
  //   console.log(isNumber);
  //   if (isNumber) {
  //     ConditionalLogicStore.setError(isNumber);
  //   }
  //   return isNumber;
  // }

  state = {
    updateState: false,
  };

  componentDidMount() {
    const { condition } = this.props;
    const { operators } = condition;

    if (operators) {
      operators.map((con) => {
        if (con.conditionRandomKey === undefined && con.logic !== "undefined") {
          con["conditionRandomKey"] = Math.random();
        }
      });
    }
  }

  forceUpdateState = () => {
    let currentState = this.state.updateState;

    this.setState({ updateState: !currentState });
  };

  render() {
    let { positions, placeholders } = ConditionalLogicStore;
    const {
      indexStatement,
      condition,
      indexLogic,
      outputType,
      availablePlaceholders,
    } = this.props;

    const { operators } = condition;

    let elseArray = [];
    let thenArray = [];
    placeholders = toJS(placeholders);

    if (
      positions.indexLogic == indexLogic &&
      positions.indexStatement == indexStatement &&
      positions.indexCondition == null &&
      positions.type == "ELSE"
    ) {
      elseArray = placeholders;
    }
    if (
      positions.indexLogic == indexLogic &&
      positions.indexStatement == indexStatement &&
      positions.indexCondition == null &&
      positions.type == "THEN"
    ) {
      thenArray = placeholders;
    }

    return (
      <div className={"logic-statement"}>
        {operators && operators.length > 0 ? (
          <Grid
            container
            item
            direction={"column"}
            xs={12}
            className={this.props.classes.conditionFrame}
          >
            {operators &&
              operators.map((con, index) => (
                <ItemCondition
                  classes={this.props.classes}
                  isExpanded={this.props.isExpanded}
                  key={con.conditionRandomKey}
                  indexCondition={index}
                  indexStatement={indexStatement}
                  indexLogic={indexLogic}
                  condition={con}
                  statement={condition}
                  availablePlaceholders={availablePlaceholders}
                  forceUpdateState={this.forceUpdateState}
                  forceUpdateStatement={this.props.forceUpdateState}
                />
              ))}
            <Grid
              xs={12}
              container
              item
              className={`hover-add-button ${this.props.classes.spacing}`}
              onClick={() => {
                ConditionalLogicStore.addCondition(indexLogic, indexStatement);
                this.forceUpdateState();
              }}
            >
              <AddIcon /> Add AND/OR condition
            </Grid>
            <Grid container xs={12} direction={"row"} alignItems={"center"}>
              <Grid
                xs={this.props.isExpanded ? 1 : 12}
                item
                style={{
                  marginBottom: this.props.isExpanded ? "" : "16px",
                }}
              >
                <p className={!this.props.isExpanded && "m-0"}>Then</p>
              </Grid>
              <Grid xs={this.props.isExpanded ? 10 : 12} item>
                <MentionEditor
                  style={{ width: "100%" }}
                  availablePlaceholders={availablePlaceholders}
                  placeholders={thenArray}
                  text={condition.then.statement.content}
                  placeholder="Insert placeholders and/or text"
                  minHeight={100}
                  onChange={(value) => {
                    if (value !== condition.then.statement.content) {
                      ConditionalLogicStore.setThenContent(
                        value,
                        indexLogic,
                        indexStatement,
                        true
                      );
                    }
                  }}
                  onFocus={() => {
                    let positions = {
                      indexLogic,
                      indexStatement,
                      indexCondition: null,
                      type: "THEN",
                    };
                    ConditionalLogicStore.setPositions(positions);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div>
            <Grid
              container
              xs={12}
              className={`hover-add-button ${this.props.classes.spacing}`}
              onClick={() => {
                ConditionalLogicStore.addStatement(indexLogic);
                this.props.forceUpdateState();
              }}
            >
              <AddIcon /> Add ELSE IF statement
            </Grid>
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              xs={12}
              className={this.props.classes.conditionFrame}
            >
              <Grid
                xs={this.props.isExpanded ? 1 : 12}
                style={{
                  marginBottom: this.props.isExpanded ? "" : "16px",
                }}
                item
              >
                Else
              </Grid>

              <Grid xs={this.props.isExpanded ? 10 : 12} item>
                <MentionEditor
                  style={{ width: "100%" }}
                  availablePlaceholders={availablePlaceholders}
                  placeholders={elseArray}
                  text={condition.then.statement.content}
                  placeholder="Insert placeholders or/and text"
                  minHeight={100}
                  onChange={(value) => {
                    ConditionalLogicStore.setThenContent(
                      value,
                      indexLogic,
                      indexStatement,
                      true
                    );
                  }}
                  onFocus={() => {
                    let positions = {
                      indexLogic,
                      indexStatement,
                      indexCondition: null,
                      type: "ELSE",
                    };
                    ConditionalLogicStore.setPositions(positions);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

export default ItemStatementCondition;
