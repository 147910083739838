import Grid from "@mui/material/Grid";
import {InsertPhotoOutlined} from "@mui/icons-material";
import ChipInput from "../ChipInput/ChipInput";
import {toJS} from "mobx";
import AIAssistantTemplateStore from "./AIAssistantTemplateStore";
import React from "react";

const ImageInputComponent = (props) => {
    return (
        <Grid>
            <Grid>
                <Grid container alignItems={"center"} className={props.classes.gridItemPadding} > <InsertPhotoOutlined/>
                    <span>
                        <b> Image input</b>
                    </span>
                </Grid>
                <span>
                    Add an image URL for the AI to use when performing its job or task. The image can be .png, .jpg, .jpeg format
                </span>
            </Grid>
            <Grid item xs={12} className={props.classes.gridItemPadding}>
                <ChipInput
                    inputValue={toJS(props.aiAssistantData.imageContext)}
                    onPlaceholderSelected={props.onPlaceholderSelected}
                    placeholders={props.availablePlaceholders}
                    placeholder="Select placeholders"
                    fullWidth
                    onDelete={index => {
                        AIAssistantTemplateStore.deleteChip(index);
                    }}
            />
            </Grid>
        </Grid>
    )

}
export default ImageInputComponent;