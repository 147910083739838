import React, { Component } from "react";
import { Grid, MenuItem, Paper, Select, TextField } from "@mui/material";
import SelectProject from "../Component/SelectProject";
import { withStyles } from "@mui/styles";
import styles from "../WorkflowData/DataBankStyle";
import { inject, observer } from "mobx-react";
import updateDatabaseRecordStore from "./UpdateDatabaseRecordStore";
import { send_component_save_request, send_request } from "../../utils/Request";
import { reaction, toJS } from "mobx";
import "./UpdateDatabaseRecordStyle.css";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import ItemCell from "./ItemCell";
import { getCurrentTime } from "../../utils/getCurrentTime";
import Icon from "@mui/material/Icon";
import checkKey from "../../utils/CheckKey";
import ChipInput from "../ChipInput/ChipInput";
import { showLoadingScreen } from "../../utils/showLoadingScreen";
import projectStore from "../ProjectCanvas/ProjectStore";
import replacePlaceholderValue from "../../utils/ReplacePlaceholderValue";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import W86PlaceholderTextField from "../W86Components/W86PlaceholderTextField";
import ProjectStore from "../ProjectCanvas/ProjectStore";
class UpdateDatabaseRecordTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availablePlaceholders: [],
      availableCols: [],
      columns: [],
      databaseList: [],
      targetDatabaseId: null,
      checkKey: false,
      errorMessage: "",
      loadingScreen: false,
      isLoading: false,
      showWarning:false,
      description:null,
      status:null
    };
  }

  saveButton = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      let lastModified = getCurrentTime();
      let data = {
        componentData: {
          data: toJS(updateDatabaseRecordStore).data,
          name: toJS(this.props.ComponentName),
          lastModified: lastModified,
        },
        componentId: this.props.component_id,
        type: "update_database_record",
        description: this.props.ComponentDescription.value,
        status: this.state.status
      };
      send_component_save_request(
        "component-service/update_database_record/data",
        data,
        "",
        "POST",
        this.props.SaveTrigger
      )
        .then((response) => {
          this.props.onLastModifiedChanged(lastModified);
          this.props.showSuccess(data, this.props.SaveTrigger);
          projectStore.savedComponent = true;
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  componentDidMount() {
    console.log(this.props.component_id);
    if (this.props.component_id !== undefined) {
      this.setState({ loadingScreen: true });

      send_request(`database/get_databases`, "", {}, "GET").then((response) => {
        if (response.data) {
          updateDatabaseRecordStore.setDatabaseList(response.data);
          this.setState({ databaseList: response.data });
          send_request(
            `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
            "",
            {}
          ).then((response) => {
            if (
              response.data &&
              response.data.components &&
              response.data.components[0].componentData
            ) {
              this.props.onComponentNameChanged(
                response.data.components[0].componentData.name
              );
              this.props.onComponentDescriptionChanged(response.data.components[0].description);
              this.props.onLastModifiedChanged(
                response.data.components[0].componentData.lastModified
              );

              let queryData = response.data.components[0].componentData.data;
              if (!Array.isArray(queryData)) {
                //somehow it is not an array.
                queryData = [];
              }
              updateDatabaseRecordStore.setUpdateDatabaseRecordTemplate(
                response.data.components[0]
              );
              this.setState({
                loadingScreen: false,
              });
            } else {
              updateDatabaseRecordStore.setUpdateDatabaseRecordTemplate(
                response.data.components[0]
              );
              this.setState({ loadingScreen: false });
            }

            if(this.props.aiComponentBuilderData && this.props.aiComponentBuilderData["data"]!==undefined && this.props.aiComponentBuilderData["data"]!==''  )
            {
              this.props.onComponentNameChanged(this.props.aiComponentBuilderData["name"]);
              this.props.onComponentDescriptionChanged(this.props.aiComponentBuilderData["explanation"]);
              updateDatabaseRecordStore.setTargetDatabaseId(this.props.aiComponentBuilderData.data.targetDatabaseId)
              updateDatabaseRecordStore.setId(this.props.aiComponentBuilderData.data.id)
              ProjectStore.targetDataBaseId.set(this.props.aiComponentBuilderData.data.targetDatabaseId);
              updateDatabaseRecordStore.setData(this.props.aiComponentBuilderData.data.columns)
              this.setState({ description: this.props.aiComponentBuilderData["explanation"] });
              this.setState({ status: "AI_BUILD_GENERATED" });
            }
            this.props.setChildTemplateLoaded(true);
          });
        }
      });
    }
  }

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveButton();
    this.reactToPlaceHolder();
  }

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (placeholder["selected"] === undefined) {
        return;
      }
      const { style, key, isNotPlaceholder } = placeholder["selected"];

      this.insertPlaceholder(style.default, key, style, isNotPlaceholder);
    }
  );

  insertPlaceholder = (label, text, style, isNotPlaceholder) => {
    let { placeholders } = updateDatabaseRecordStore;
    placeholders.push({ label, text, style });
    updateDatabaseRecordStore.setPlaceholders(placeholders);
    if (isNotPlaceholder !== true) {
      text = "${" + text + "}";
    }

    if (updateDatabaseRecordStore.focus == "key") {
      updateDatabaseRecordStore.setId(text);
    }
  };

  updateTargetDatabase = (targetDatabaseId) => {
    if (targetDatabaseId != null) {
      send_request(
        `database/get_columns/${targetDatabaseId}`,
        "",
        {},
        "GET"
      ).then((response) => {
        if (response.data) {
          if (response.data.length > 0) {
            this.addColumns(response.data);
          } else {
            updateDatabaseRecordStore.clearData();
          }
        }
        this.setState({
          isLoading: false,
        });
      });
    } else {
      updateDatabaseRecordStore.clearData();
      this.setState({
        isLoading: false,
      });
    }
  };

  addColumns = (data, existingCellValues) => {
    let columns = [];

    let dataStore = toJS(updateDatabaseRecordStore).data;

    data.forEach((cell) => {
      const col = dataStore.columns.filter(
        (c) => c.columnName === cell.name || c.columnName === cell.columnName
      );

      let value = col.length > 0 ? col[0].content : cell.content;

      // Look for the value in the existingCellValues if given
      if (!value && existingCellValues) {
        for (let i = 0; i < existingCellValues.length; i++) {
          if (cell.columnId !== existingCellValues[i].columnId) continue;
          value = existingCellValues[i].content; // Else, set the value and break
          break;
        }
      }

      const checked = col.length > 0 ? col[0].checked : true;

      columns.push({
        columnId: cell.columnId,
        columnName: cell.columnName ? cell.columnName : cell.name,
        type: cell.type,
        content: value,
        checked: checked,
        orderNumber: cell.orderNumber,
      });
    });

    updateDatabaseRecordStore.clearData();

    columns.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1));
    updateDatabaseRecordStore.setData(columns);
  };

  handleKeyChange = (e) => {
    const placeholderValue = replacePlaceholderValue(e.target.value);
    updateDatabaseRecordStore.setPlaceholderKey(placeholderValue);

    const { data } = toJS(updateDatabaseRecordStore);
    const value = checkKey(placeholderValue, data);

    this.setState({
      checkKey: value[0],
      errorMessage: value[1],
    });
  };

  render() {
    const { component, availablePlaceholders } = this.props;
    let { data } = updateDatabaseRecordStore;
    let { targetDatabaseId, updateMethod, key, id, columns } = data;

    let selectAll = true;
    columns.forEach((cell) => {
      if (!cell.checked) {
        selectAll = false;
      }
    });
    if (!this.state.loadingScreen) {
      return (
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Paper className="query-item-paper defaultText">
              <Grid container spacing={2} style={{ marginBottom: 24 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  xl={2}
                  className="item-label-update-record text-normal"
                >
                  Placeholder{" "}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={10}
                  lg={10}
                  xl={10}
                  className="key-bulk-grid"
                >
                  <Grid item xs={12} sm={6} md={4} lg={5} xl={5}>
                    <W86PlaceholderTextField
                      value={key}
                      error={this.state.checkKey ? true : false}
                      helperText={this.state.checkKey ? this.state.errorMessage : null}
                      placeholderText="Enter placeholder for update database"
                      handleChange={this.handleKeyChange}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <SelectProject
                databaseList={this.state.databaseList}
                updateTargetProject={this.updateTargetDatabase}
                setTargetDatabaseId={(targetDatabaseId) => {
                  updateDatabaseRecordStore.setTargetDatabaseId(
                    targetDatabaseId
                  );
                  ProjectStore.targetDataBaseId.set(targetDatabaseId);
                }}
                targetDatabaseId={targetDatabaseId}
                component={component}
                isLoading={this.state.isLoading}
                setIsLoading={(isLoading) => {
                  this.setState({
                    isLoading,
                  });
                }}
              />
              <Grid container xs={12} style={{ margin: "16px 0" }}>
                <Grid
                  item
                  direction="column"
                  xs={12}
                  style={{ marginBottom: 8 }}
                >
                  Update method
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      lg={5}
                      xl={5}
                      style={{ paddingRight: 16 }}
                    >
                      <Select
                        style={{
                          width: "100%",
                          backgroundColor: "#F8F8F8",
                          borderRadius: "4px",
                          height: "36px",
                          color: "#868686",
                          marginRight: "24px",
                          marginBottom: 8,
                          verticalAlign: "top",
                        }}
                        name="selectType"
                        disableUnderline
                        variant="standard"
                        labelId="demo-simple-select-outlined-label"
                        className="selectStandard"
                        displayEmpty
                        onChange={(event) =>
                          updateDatabaseRecordStore.setUpdateMethod(
                            event.target.value
                          )
                        }
                        value={updateMethod}
                      >
                        <MenuItem value={"update_by_record_id"}>
                          {" "}
                          Update by Record ID{" "}
                        </MenuItem>
                        {/*<MenuItem value={"update_by_session_id"}>*/}
                        {/*  {" "}*/}
                        {/*  Update by Session ID*/}
                        {/*  {" "}*/}
                        {/*</MenuItem>*/}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={12}>
                <Grid
                  item
                  direction="column"
                  xs={12}
                >
                  {updateMethod === "update_by_record_id"
                    ? "Record ID "
                    : "Session ID "}
                  to update
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ paddingRight: 16 }}
                    >
                      <ChipInput
                        onPlaceholderSelected={this.props.onPlaceholderSelected}
                        style={{ marginTop: "8px" }}
                        inputValue={id ? [id] : []}
                        placeholders={availablePlaceholders}
                        placeholder="Type something or insert a placeholder"
                        fullWidth
                        onBeforeAdd={(chip) => {
                          if (chip.trim() != "") {
                            updateDatabaseRecordStore.setId(chip.trim());
                            return true;
                          }
                        }}
                        onDelete={() => updateDatabaseRecordStore.deleteId()}
                        onFocus={() =>
                          updateDatabaseRecordStore.setFocus("key", "key")
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {columns.length > 0 ? (
                <Grid container>
                  <FormControl
                    component="fieldset"
                    className="form-control-update"
                  >
                    <Grid item xs={12} className="query-item-content">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                        }
                        label={
                          <p className="query-item-content select-all">
                            Select columns to update
                          </p>
                        }
                        onChange={() => {
                          updateDatabaseRecordStore.selectAll();
                        }}
                        checked={selectAll ? true : false}
                      />
                    </Grid>
                    <FormGroup>
                      {columns.map((column, index) => (
                        <Grid style={{ display: "flex" }}>
                          <FormControlLabel
                          label={""}
                            control={
                              <Checkbox
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            }
                            spellCheck={true}
                            className="select-cell"
                            checked={column.checked ? true : false}
                            onChange={(event) => {
                              updateDatabaseRecordStore.setChecked(
                                event.target.checked,
                                index
                              );
                            }}
                            style={{ alignItems: "center" }}
                          />
                          <ItemCell
                            column={column}
                            columns={columns}
                            availablePlaceholders={availablePlaceholders}
                            index={index}
                          />
                        </Grid>
                      ))}
                    </FormGroup>


                  </FormControl>
                </Grid>
              ) : (
                ""
              )}
            </Paper>
          </Grid>
        </Grid>
      );
    } else {
      return <IndividualComponentLoader />;
    }
  }
}

export default withStyles(styles)(
  inject(
    "SelectedPlaceholder",
    "ComponentName",
    "ComponentDescription",
    "SaveTrigger"
  )(observer(UpdateDatabaseRecordTemplate))
);
