import React, { Component } from "react";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { CustomDialog } from "../Component/Dialog";
import "./MarketplacePage.css";

class DeleteDialog extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const confirmDelete = this.props.confirmDelete;

    return (
      <CustomDialog
        fullWidth={true}
        size={"sm"}
        isOpen={confirmDelete}
        title={"Remove from the Marketplace"}
        contents={
          <>
            <img src="/images/delete.jpg" className="dialog-image-size"/>
              <p className="s-text">
                Are you sure you want to remove this workflow template from the
                Marketplace? This will prevent users from importing it. However,
                any copies of this workflow already imported by an organisation
                cannot be removed.
              </p>
          </>
        }
        buttons={
          <>
            <Button
              onClick={this.props.delete}
              variant={"outlined"}
              color={"info"}
              disabled={this.props.disableRemove}
            >
              {this.props.disableRemove && (
                <CircularProgress size={24} className="buttonProgress" />
              )}
              REMOVE
            </Button>
            <Button
              onClick={this.props.close}
              variant={"contained"}
              color={"primary"}
              disabled={this.props.disableRemove}
            >
              {this.props.disableRemove && (
                <CircularProgress size={24} className="buttonProgress" />
              )}
              CANCEL
            </Button>
          </>
        }
      />
    );
  }
}

export default DeleteDialog;
