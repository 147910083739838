import React, {Component} from "react";
import { toJS } from "mobx";
import Button from "@mui/material/Button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Grid} from "@mui/material";
import "../APIResReqStyle.css";
import {FormControl} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import APIResReqStore from "../APIResReqStore";
import MentionEditor from "../../TextEditor/MentionEditor";
import AceEditor from "react-ace";
import Switch from "@mui/material/Switch";
import {validURL} from "../../../utils/CanvasUtil";
import ExclamationIcon from "../../Component/Icons/ExclamationIcon";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "ace-builds/src-noconflict/theme-idle_fingers";
import "ace-builds/src-noconflict/mode-html";
class SetUpRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boolState: false,
      placeholders: [],
      headerErrors: false,
      errors: false,
      headerErrorMessage: "",
      errorMessage: "",
      updateState: false,
    };
  }

  componentDidMount() {
    this.validateRequest();
  }

  handleOnChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      name: value
    });
    if (name === "method") {
      this.chooseRequest(value);
    }
    if (name === "dataType") {
      this.chooseDataType(value);
    }
  };

  chooseRequest = (requestType) => {
    APIResReqStore.setRequestType(requestType);
    APIResReqStore.setSampleRequestType(requestType);
  };

  chooseDataType = (dataType) => {
    APIResReqStore.setDataType(dataType);
    APIResReqStore.setSampleDataType(dataType);
  };

  insertHeaders = () => {
    APIResReqStore.addCustomHeaders();
    APIResReqStore.addSampleCustomHeaders();
    this.validateRequest();
  };


  setupRequestMode = (mode) => {
    APIResReqStore.setSetupRequestMode(mode);
  };

  setupSampleMode = (mode) => {
    APIResReqStore.setSetupSampleMode(mode);
  };

  forceUpdateState = () => {
    let currentState = this.state.updateState;
    this.setState({updateState: !currentState});
  };

  validateRequest = (isNavigating) => {
    this.setState({headerErrors: false, headerErrorMessage: "", errors: false, errorMessage:""});

    if (
      APIResReqStore.requestData &&
      APIResReqStore.requestData.headers &&
      APIResReqStore.requestData.headers.length > 0
    ) {
      APIResReqStore.requestData.headers.forEach((h) => {
        if (h.header_name == "" || h.header_value == "") {
          this.setState({
            headerErrors: true,
            headerErrorMessage:
              "Headers cannot be empty.",
          });
        }
      });
    }

    let hasPlaceholder = false;
    const {availablePlaceholders} = this.props;
    availablePlaceholders.forEach((availablePlaceholder) => {
      availablePlaceholder.placeholders.forEach((placeholder) => {
        if (
          APIResReqStore.requestData &&
          APIResReqStore.requestData.endpoint_url &&
          APIResReqStore.requestData.endpoint_url.includes(placeholder.key)
        ) {
          hasPlaceholder = true;
        }
      });
    });
    if (hasPlaceholder) {
      this.setState({errors: false, errorMessage: ""});

      (isNavigating || this.props.initialLoad) && this.props.onChangeState && this.props.onChangeState(1, 1);
      this.props.setInitialLoad && this.props.setInitialLoad(false);
    } else {
      if (validURL(APIResReqStore.requestData.endpoint_url)) {
        this.setState({errors: false, errorMessage: ""});
        (isNavigating || this.props.initialLoad )&& this.props.onChangeState && this.props.onChangeState(1, 1);
        this.props.setInitialLoad && this.props.setInitialLoad(false);
      } else {
        this.setState({
          errors: true,
          errorMessage:
            "Invalid or empty endpoint URL"
        });
      }
    }
    
  };

  handleCurl = () => {
    this.setState({errors: false, errorMessage: ""});
    this.props.onChangeState && this.props.onChangeState(1, 1);
  };

  render() {
    let {errors, errorMessage, boolState, headerErrors, headerErrorMessage} = this.state;

    let {endpointPlaceholders} = APIResReqStore;

    endpointPlaceholders = toJS(endpointPlaceholders);

    let keyArray = [];
    keyArray = endpointPlaceholders;

    return (
      <div>
        <Grid container xs={12} className="setup-mode-container">
          <Grid item style={{width: "100%"}}>
            <FormControl className="form-control-full">
              <Grid item xs={12} className="setup-mode-label">
                Configuration mode
              </Grid>
              <RadioGroup
                name="setupMode"
                value={APIResReqStore.requestData.setupMode}
                onChange={(event) => {
                  const value = event.target.value;
                  this.setupRequestMode(value);
                  this.setupSampleMode(value);
                  this.forceUpdate();
                }}
              >
                <FormControlLabel value="MANUAL" control={<Radio />} label="Manually set each field" />
                <FormControlLabel value="CURL" control={<Radio />} label="Write cURL" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <div hidden={APIResReqStore.requestData.setupMode !== "MANUAL"}>
          <Grid container className="api-editor-container">
            <Grid item style={{width: "100%"}}>
              <FormControl className="form-control-full">
                <Grid item xs={12} className="setup-mode-label">
                  Method
                </Grid>
                <Select
                  name="method"
                  labelId="demo-simple-select-outlined-label"
                  className="dropdown-mat"
                  value={APIResReqStore.requestData.request_type}
                  defaultValue={"POST"}
                  disableUnderline
                  variant="standard"
                  align="left"
                  onChange={this.handleOnChange}

                >
                  <MenuItem value={"POST"}>POST</MenuItem>
                  <MenuItem value={"GET"}>GET</MenuItem>
                  <MenuItem value={"PATCH"}>PATCH</MenuItem>
                  <MenuItem value={"PUT"}>PUT</MenuItem>
                  <MenuItem value={"DELETE"}>DELETE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{width: "100%"}}>
              <FormControl className="form-control-full">
                <Grid item xs={12} className="setup-mode-label">
                  Content Type
                </Grid>

                <Select
                  name="dataType"
                  labelId="demo-simple-select-outlined-label"
                  className="dropdown-mat"
                  defaultValue={"JSON"}
                  value={APIResReqStore.requestData.data_type}
                  disableUnderline
                  variant="standard"
                  align="left"
                  onChange={this.handleOnChange}
                >
                  <MenuItem value={"JSON"}>JSON</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <FormControl className="form-control-full">
              <Grid item xs={12} className="setup-mode-label">
                Endpoint URL
              </Grid>
              <Grid item xs={12} className="endpoint-input">
                <MentionEditor
                  availablePlaceholders={this.props.availablePlaceholders}
                  placeholders={keyArray}
                  text={APIResReqStore.requestData.endpoint_url}
                  placeholder="Insert API endpoint URL here"
                  onChange={(value) => {
                    APIResReqStore.setEndPointUrl(value);
                    APIResReqStore.setSampleEndPointUrl(value);
                    this.validateRequest();
                  }}
                  onFocus={() => {
                    this.props.setOnFocus("endpoint");
                  }}
                  error={errors ? true : false}
                  helperText={errors ? errorMessage : null}
                />
              </Grid>
            </FormControl>
            <FormControl className="form-control-full">
              <Grid item xs={12} className="bodyInput">
                Body
              </Grid>
              <Grid item xs={12} style={{width: "100%"}}>
                <div id="aceditor" className="ace-editor">
                  <AceEditor
                    mode="javascript"
                    theme="github"
                    width="100%"
                    height="100%"
                    showPrintMargin={false}
                    showGutter={true}
                    wrapEnabled={true}
                    highlightActiveLine={true}
                    value={APIResReqStore.requestData.body}
                    onChange={(html) => {
                      if (html !== APIResReqStore.requestData.body) {
                        APIResReqStore.setApiReqBody(html);
                        APIResReqStore.setSampleApiReqBody(html);
                      }
                    }}
                    fontSize={16}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2
                    }}
                    editorFocus={() => {
                      this.props.setOnFocus("editor");
                    }}/>
                </div>
              </Grid>
            </FormControl>
            {APIResReqStore.requestData.headers && APIResReqStore.requestData.headers.length ? (
              <FormControl className="form-control-full">
                <Grid item xs={12} className="custom-header-label">
                  Custom Header
                </Grid>
                <CustomHeader
                  headerErrors={this.state.headerErrors}
                  availablePlaceholders={this.props.availablePlaceholders}
                  onFocus={this.props.onFocus}
                  keyPlaceholders={this.props.keyPlaceholders}
                  valuePlaceholders={this.props.valuePlaceholders}
                  validateRequest={() => {
                    this.validateRequest()
                  }}
                />
              </FormControl>
              
            ) : (
              <Grid></Grid>
            )}
            <Grid item xs={12}>
            </Grid>
            <Grid item>
              <Button
                variant={"text"}
                className={"custom-icon-btn"}
                onClick={() => {
                  this.insertHeaders();
                  this.setState({boolState: !boolState});
                }}
              >
                <AddIcon/> Add Header
              </Button>
            </Grid>

            {!APIResReqStore.isSkipSample() && (
              <>
                <Grid item xs={12}>
                  <div className="sample-parse-label">
                    Sample and parse JSON
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Switch
                    checked={!APIResReqStore.isSkipSample()}
                    onChange={(value) => {
                      APIResReqStore.setSkipSample(!value.target.checked);
                      if(value.target.checked){
                        APIResReqStore.removeKeyInPlaceholder(
                          APIResReqStore.requestData.rawResponsePlaceholderName);
                      } else{
                        if(!!APIResReqStore.requestData.rawResponsePlaceholderName) {
                          APIResReqStore.addKeyInPlaceholder(
                            APIResReqStore.requestData.rawResponsePlaceholderName,
                            "text");
                        }
                      }
                      this.forceUpdateState();
                    }}
                    inputProps={{'aria-label': 'controlled'}}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} hidden={!APIResReqStore.isSkipSample()}>
              <div className="raw-response-info">
                The raw JSON response will be outputted as this placeholder
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className="endpoint-input"
              hidden={!APIResReqStore.isSkipSample()}
            >
              <MentionEditor
                placeholders={keyArray}
                text={APIResReqStore.requestData.rawResponsePlaceholderName}
                placeholder="Insert Raw Response Placeholder Name Here"
                onChange={(value) => {
                  APIResReqStore.removeKeyInPlaceholder(
                    APIResReqStore.requestData.rawResponsePlaceholderName
                  )
                  APIResReqStore.setRawResponsePlaceholder(value);
                  APIResReqStore.addKeyInPlaceholder(
                    APIResReqStore.requestData.rawResponsePlaceholderName,
                    "text"
                  )
                  this.forceUpdateState();
                }}
                onFocus={() => {
                  this.props.setOnFocus("rawResponsePlaceholderName");
                }}
              />
            </Grid>

            <Grid item xs={12} className="dialogContentRight apiNextButton" hidden={APIResReqStore.isSkipSample()}>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    this.validateRequest(true);
                  }}
                  variant={"contained"}
                  color={"primary"}
                >
                  {" "}
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div hidden={APIResReqStore.requestData.setupMode !== "CURL"} style={{width: "100%"}}>
          <Grid container className="api-editor-container">
            <Grid item xs={12} style={{width: "100%"}}>
              <FormControl className="form-control-full">
                <Grid item xs={12} className="setup-mode-label">
                  cURL
                </Grid>
                <Grid item xs={12} style={{width: "100%"}}>
                  <div id="aceditor" className="curl-editor">
                    <AceEditor
                      mode="javascript"
                      theme="github"
                      width="100%"
                      height="100%"
                      showPrintMargin={false}
                      showGutter={true}
                      wrapEnabled={true}
                      highlightActiveLine={true}
                      value={APIResReqStore.requestData.curl}
                      onChange={(value) => {
                        if (value) {
                          APIResReqStore.setRequestCurl(value);
                          APIResReqStore.setSampleCurl(value);
                          this.forceUpdateState()
                        }
                      }}
                      fontSize={16}
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2
                      }}
                      editorFocus={() => {
                        this.setState({focus: "editor"});
                      }}/>
                  </div>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <p></p>
            </Grid>
            {!APIResReqStore.isSkipSample() && (
              <>
                <Grid item xs={12}>
                  <div className="sample-parse-label">
                    Sample and parse JSON
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Switch
                    checked={!APIResReqStore.isSkipSample()}
                    onChange={(value) => {
                      APIResReqStore.setSkipSample(!value.target.checked);
                      if(value.target.checked){
                        APIResReqStore.removeKeyInPlaceholder(
                          APIResReqStore.requestData.rawResponsePlaceholderName);
                      } else{
                        if(!!APIResReqStore.requestData.rawResponsePlaceholderName) {
                          APIResReqStore.addKeyInPlaceholder(
                            APIResReqStore.requestData.rawResponsePlaceholderName,
                            "text");
                        }
                      }
                      this.forceUpdateState();
                    }}
                    inputProps={{'aria-label': 'controlled'}}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} hidden={!APIResReqStore.isSkipSample()}>
              <div className="raw-response-info">
                The entire raw response will be outputted as this placeholder
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className="endpoint-input"
              hidden={!APIResReqStore.isSkipSample()}
            >
              <MentionEditor
                placeholders={keyArray}
                text={APIResReqStore.requestData.rawResponsePlaceholderName}
                placeholder="Insert Raw Response Placeholder Name Here"
                onChange={(value) => {
                  APIResReqStore.removeKeyInPlaceholder(
                    APIResReqStore.requestData.rawResponsePlaceholderName
                  )
                  APIResReqStore.setRawResponsePlaceholder(value);
                  APIResReqStore.addKeyInPlaceholder(
                    APIResReqStore.requestData.rawResponsePlaceholderName,
                    "text"
                  )
                  this.forceUpdateState();
                }}
                onFocus={() => {
                  this.props.setOnFocus("rawResponsePlaceholderName");
                }}
              />
            </Grid>
            <Grid item xs={12} className="dialogContentRight apiNextButton" hidden={APIResReqStore.isSkipSample()}>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    this.handleCurl();
                  }}
                  variant={"contained"}
                  color={"primary"}
                >
                  {" "}
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const deleteHeaders = (headerId, sampleHeaderId, props) => {
  APIResReqStore.requestData.headers = APIResReqStore.requestData.headers.filter(
    (h) => h.header_id !== headerId
  );
  APIResReqStore.sampleData.headers = APIResReqStore.sampleData.headers.filter(
    (h) => h.header_id !== sampleHeaderId
  );
  props.validateRequest();
};

const CustomHeader = (props) => {
  return (
    <Grid>
      <Grid xs={12} container spacing={3} direction="row">
        <Grid item xs={5}>
          <p>Key</p>
        </Grid>
        <Grid item xs={5}>
          <p className="textRightLabelJson">Value</p>
        </Grid>
      </Grid>
      {APIResReqStore.requestData.headers.map((h, index) => (
        <Grid container direction="row">
          <Grid item xs={5}>
            <MentionEditor
              style={{
                color: "black"
              }}
              text={h.header_name}
              availablePlaceholders={props.availablePlaceholders}
              placeholders={props.keyPlaceholders[index]}
              placeholder="Key"
              variant="outlined"
              className="textfieldPaddingJson key_padding"
              onChange={(value) => {
                APIResReqStore.requestData.headers[index].header_name =
                  value;

                APIResReqStore.sampleData.headers[index].header_name =
                  value;

              }}
              error={
                h.header_name.trim() == "" && props.headerErrors ? true : false
              }
              InputProps={{
                endAdornment: h.header_name.trim() == "" &&
                  props.headerErrors && (
                    <ExclamationIcon/>
                  )
              }}
              onFocus={() => {
                props.onFocus({
                  headerType: "key",
                  index,
                  step: "request"
                })
              }}
              helperText={
                h.header_name.trim() == "" && props.headerErrors
                  ? "Key cannot be empty"
                  : null
              }
            />
          </Grid>
          <Grid item xs={5}>
            <MentionEditor
              style={{
                color: "black"
              }}
              text={h.header_value}
              availablePlaceholders={props.availablePlaceholders}
              placeholders={props.valuePlaceholders[index]}
              placeholder="Value"
              variant="outlined"
              className="textfieldPaddingJson key_padding"
              onChange={(value) => {
                APIResReqStore.requestData.headers[index].header_value = value;
                APIResReqStore.sampleData.headers[index].header_value = value;
                props.validateRequest();
              }}
              error={
                h.header_value.trim() == "" && props.headerErrors ? true : false
              }
              InputProps={{
                endAdornment: h.header_value.trim() == "" &&
                  props.headerErrors && (
                    <ExclamationIcon/>
                  )
              }}
              onFocus={() => {
                props.onFocus({
                  headerType: "value",
                  index,
                  step: "request"
                })
              }}
              helperText={
                h.header_value.trim() == "" && props.headerErrors
                  ? "Value cannot be empty"
                  : null
              }
            />
          </Grid>
          <Grid item xs={1}>
            <i
              onClick={(event) => {
                deleteHeaders(
                  h.header_id,
                  APIResReqStore.sampleData.headers.length > 0
                    ? APIResReqStore.sampleData.headers[index].header_id
                    : "",
                  props
                );
              }}
              className="material-icons custom-icon-btn"
              style={{marginTop: 8}}
            >
              close
            </i>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default SetUpRequest;
