import React, { useState, useEffect } from "react";

// MUI
import {Grid, MenuItem, Select, TextField, InputAdornment, styled, Button} from "@mui/material";
import { Search } from "@mui/icons-material";

// Util
import { ComponentGroups, ComponentFactory as CF } from "../../../ProjectCanvas/ComponentRegistry";
import ComponentFactory from "../../../ProjectCanvas/ComponentRegistry";
import GroupItem from "./GroupItem";
import projectStore from "../../../ProjectCanvas/ProjectStore";
import { StyledTray } from "./StyledTray";
import {isAIEnabled} from "../../../../utils/AIUtil";


function ComponentTray({ classes }) {
    // State values
    const [componentDisplay, setComponentDisplay] = useState("group_by_category");
    const [searchValue, setSearchValue] = useState("");
    const [displayData, setDisplayData] = useState([]);
    const [groupsDisplayData, setGroupsDisplayData] = useState(ComponentGroups);
    const [hasBanner, setHasBanner] = useState(projectStore.state.hasBanner);
    const [defaultComponents, setDefaultComponents] = useState([]); 

    useEffect(() => {
        const filteredData = ComponentFactory.filter((component) => {
            return component.name !== "conditional_workflow_path" && component.name !== "knowledge_bank";
        });
        setDisplayData(filteredData);
        setDefaultComponents(filteredData);
        }, []);

    useEffect(() => {
        setHasBanner(projectStore.state.hasBanner);
    }, [projectStore.state.hasBanner]);

    const SidebarItem = (component) => {
        const onDragStart = (event, component) => {
            event.dataTransfer.setData("application/reactflow", "component");
            event.dataTransfer.setData("type", component.component.name);
            event.dataTransfer.effectAllowed = "move";
        };
        return (
            <Grid draggable={true} onDragStart={(event) => onDragStart(event, component)} container direction={"row"} alignItems={"center"} wrap={"noWrap"} className={classes.canvasItem}>
                <Grid item>
                    <img className={classes.logo} src={component.component.sidebarLogo ? component.component.sidebarLogo : component.component.logo} />
                </Grid>
                <Grid xs={10} container item direction={"column"} >
                    <Grid container item alignItems={"center"} gap={1}>
                        <Grid item> <p className={`rmv-margin ${classes.title}`}>{component.component.displayName}</p></Grid>
                        {component.component.isExcluded && <Grid item>
                            <Button
                                size={"small"}
                                variant={"outlined"}
                                className="aiExcludedButton"
                            >
                                <span className={` ${classes.title} excluded-btn`} >
                                    AI excluded
                                </span>
                            </Button>
                        </Grid>}
                    </Grid>
                    <Grid item> <p className={`s-text rmv-margin canvas-description ${classes.description}`}>{component.component.description}</p></Grid>
                </Grid>
            </Grid>
        );
    };

    const getDisplayOption = () => {
        if (componentDisplay === "group_by_category") {
            return (
                <Grid container spacing={1} className={classes.container}>
                    {groupsDisplayData
                        .filter((group) => {
                                if(group.title === "Artificial Intelligence" ) {
                                    if(isAIEnabled(projectStore.state.clientId))                                    
                                        return true;
                                } else {
                                    return true;
                                }
                                return false;
                            }
                            
                        )    
                        .map((group) => {
                            return (
                                <Grid item xs={12} id={group.title}>
                                    <GroupItem group={group} SidebarItem={SidebarItem} components={CF} />
                                </Grid>
                            );
                    })}
                </Grid>
            );
        } else {
            // Default to show all (original)
            return (
                <>
                    <p className={`bold rmv-margin ${classes.left}`}>Triggers</p>
                    {Object.values(displayData)
                    .filter((component, id) => {
                        if(component.name === "ai_assistant" ) {
                            if(isAIEnabled(projectStore.state.clientId))                            
                                return true;
                        } else {
                            return true;
                        }
                        return false;
                    }
                        
                    ) 
                    .map((component, id) => {
                        if (component.isTrigger && !component.hidden) return <SidebarItem key={id} component={component} />;
                    })}
                    <p className={`bold rmv-margin ${classes.left}`}>Actions</p>
                    {Object.values(displayData)                    
                    .filter((component, id) => {
                        if(component.name === "ai_assistant" ) {
                            if(isAIEnabled(projectStore.state.clientId))                           
                                return true;
                        } else {
                            return true;
                        }
                        return false;
                    }
                        
                    ) 
                    .map((component, id) => {
                        if (!component.isTrigger && !component.hidden) return <SidebarItem key={id} component={component} draggable />;
                    })}
                </>
            );
        }
    };

    const handleSearchSwitch = (val) => {
        // Handle switching between group and show all for search
        let e = {
            target: {
                value: searchValue,
            },
        };

        handleSearch(e, val);
    };

    const handleSearch = (event, display) => {
        const word = event.target.value.toLowerCase();

        if (display === "group_by_category") {
            // Handle group searching

            const filteredGroups = ComponentGroups.map((group) => {
                let comps = group.components;
                const filteredComps = comps.filter((c) => {
                    if (CF[c].displayName.toLowerCase().startsWith(word) || CF[c].description.toLowerCase().includes(word)) {
                        return c;
                    } else return false;
                });

                if (filteredComps.length <= 0) return null;
                return {
                    ...group,
                    components: filteredComps,
                    openForSearch: word.length > 0 ? true : false,
                };
            }).filter((group) => group);

            setSearchValue(word);
            setGroupsDisplayData(filteredGroups);
        } else {
            // Handle no groups
            let searchComp = {};
            let usedData;

            const filteredComp = Object.keys(defaultComponents).filter((a, i) => {
                const comp = defaultComponents[a];
                const compName = comp.displayName.toLowerCase().trim();
                const compDescription = comp.description.toLowerCase().trim();
                if (compName.indexOf(word) !== -1) searchComp[a] = defaultComponents[a];
                if (compDescription.indexOf(word) !== -1) searchComp[a] = defaultComponents[a];
                return false;
            }).filter((a) => {
                const comp = defaultComponents[a];
                return comp.name !== "conditional_workflow_path" && comp.name !== "knowledge_bank";
            });

            if (word.trim() !== null) {
                usedData = searchComp;
            } else {
                usedData = defaultComponents;
            }
            setSearchValue(word);
            setDisplayData(usedData);
        }
    };

    return (
        <StyledTray hasBanner={hasBanner} data-tut="reactour__canvas__sidebar">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        className={classes.search}
                        placeholder={componentDisplay === "show_all" ? "Search for component" : "Search for component in a category"}
                        value={searchValue}
                        id="components-search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => handleSearch(e, componentDisplay)}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} className={classes.dropdownGrid}>
                    <Select
                        fullWidth
                        variant="outlined"
                        value={componentDisplay}
                        id="component-side-menu-selection"
                        className={classes.dropdown}
                        sx={{ boxShadow: "none", ".MuiOutlinedInput-notchedOutline": { border: 0 } }}
                        onChange={(event) => {
                            let val = event.target.value;
                            setComponentDisplay(val);
                            handleSearchSwitch(val);
                        }}
                    >
                        <MenuItem value={"group_by_category"}>Group by category</MenuItem>
                        <MenuItem value={"show_all"}>Show all components</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    {getDisplayOption()}
                </Grid>
            </Grid>
        </StyledTray>
    );
}

export default ComponentTray;
