export const INITIAL_LOADING_MESSAGE = {
  role: "loading",
  showAnimation: true,
  title: "Building...",
  content:
    "Just a moment while I build this for you. This can take some time depending on the complexity of the workflow. Do not close this panel in the meantime. ",
};

export const INSERT_LOADING_MESSAGE = {
  role: "loading",
  showAnimation: false,
  title:null,
  content:
    "Inserting workflow on the canvas...",
};

export const INITIAL_LOADING_MESSAGE_COMPONENT = {
  role: "loading",
  showAnimation: true,
};

export const INSERT_COMPONENT_LOADING_MESSAGE = {
  role: "loading",
  showAnimation: true,
  title:null,
  content:
    "Configuring component according to the proposal...",
};

export const BASIC_LOADING_MESSAGE = {
  role: "loading",
  showAnimation: true,
  title:null,content:null
};