import React, { Component } from "react";
import { send_component_save_request, send_request } from "../../utils/Request";
import { observer, inject } from "mobx-react";
import { withStyles } from "@mui/styles";
import { autorun, toJS, reaction, trace } from "mobx";
import { getCurrentTime } from "../../utils/getCurrentTime";
import CodeStore from "./CodeStore";
import styles from "./CodeStyle";
import projectStore from "../ProjectCanvas/ProjectStore";
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import AceEditor from "react-ace";
import { addCompleter } from "ace-builds/src-noconflict/ext-language_tools";
import PlaceholderItem from "./CodeComponent/PlaceholderItem";
import "../../fonts/JetBrainsMono-Regular.ttf";
import { CustomDialog } from "../Component/Dialog";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import KeyIcon from "@mui/icons-material/Key";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";


import { styled } from "@mui/system";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import {
  JsDemoCode,
  PythonDemoCode,
} from "./CodeComponent/AceEditorPlaceholder";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledAccordion = styled(Accordion)({
  boxShadow: "none",
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root": {
    padding: "0",
  },
  "& .MuiAccordionDetails-root": {
    padding: "8px 0",
  },
});

const AddCredential = styled("span")(({ color, hoverColor }) => ({
  fontSize: "14px",
  color: color ? color : "rgba(33, 150, 243, 1)",
  "&:hover": {
    cursor: "pointer",
    color: hoverColor ? hoverColor : "rgba(33, 150, 243, 0.85)",
  },
}));

const StyledInstructions = styled("div")({
  width: "100%",
  borderRadius: "6px",
  border: "1px solid #55A77A",
  backgroundColor: "#55A77A26",
});

const FreeUserWarning = styled("div")({
  gap: "8px",

  padding: "8px",
  borderRadius: "6px",
  border: "1px solid #FFB74D",
  backgroundColor: "#FFB74D26",
});

const StyledSecretPlaceholder = styled("span")({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const FREE_USER_TIERS = ["FREE", "FREE_TRIAL"];

const CREDENTIALS_BY_TYPE_URL = "credential-store/credential/user/type/";

class CodeTemplate extends Component {
  state = {
    error: false,
    loading: false,
    placeholders: [],
    availableSecrets: [],
    emptyLine: { type: "text", key: "" },
    codePlaceholders: [],
    aceLangStyle: "python",
    changeDemo: true,
    description: null,
    status: null,
    willChangeLanguage: false,
    changeLangValue: "",
    secrets: [],
    isSecretsVisible: false,
  };

  getNodeJSLangName = () => {
    return "NodeJS";
  };

  getPythonLangName = () => {
    return "Python";
  };


  freeUserTier = () => {
    const { userTier } = projectStore.state.tier;
    return FREE_USER_TIERS.includes(userTier);
  };

  async componentDidMount() {
    if (this.props.component_id !== undefined) {
      this.setState({ loading: true });

      try {
        const response = await send_request(
          "credential-store/credential/user/types/",
          JSON.stringify(["VARIABLE", "OAUTH2"]),
          {},
          "POST"
        );

        let secrets = [];

        if (response?.data) {
          secrets = response.data.flatMap(credential => 
            `\${${credential.data.placeholder}.SECRET}`
          );
        }

        this.setState({ availableSecrets: secrets });
      } catch (error) {
        console.error("Error fetching credentials:", error);
      }

      //get variable secret
       send_request(
        `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
        ""
      )
        .then((response) => {
          if (response && response.data) {
            const {components} = response.data;
            if (components && components[0]) {
              CodeStore.setCodeData(components[0], this.props.version);
              this.props.onComponentDescriptionChanged(
                components[0].description
              );
             this.props.onComponentNameChanged(CodeStore.name);
              this.props.onLastModifiedChanged(CodeStore.lastModified);

              this.setState({ loading: false });

              if (
                components[0].componentData &&
                components[0].componentData.data
              ) {
                if (components[0].componentData.data.placeholders) {
                  this.setState({
                    codePlaceholders:
                      components[0].componentData.data.placeholders,
                  });
                }

                if (components[0].componentData.data.codeLang) {
                  const codeLang = components[0].componentData.data.codeLang;
                  let editorStyle = "python";
                  if (codeLang.includes(this.getPythonLangName())) {
                    editorStyle = "python";
                  } else if (codeLang.includes(this.getNodeJSLangName())) {
                    editorStyle = "javascript";
                  }
                  this.setState({ aceLangStyle: editorStyle });
                }
              }
            }
          }
          if(!this.state.codePlaceholders || this.state.codePlaceholders.length === 0) {
            this.state.codePlaceholders = [];
            const newPlaceholder = {
              key: "",
              label: "",
              text: "",
              length: 1,
              style: {},
              type: "text",
            };
            this.state.codePlaceholders.push(newPlaceholder);
          }



          this.props.setChildTemplateLoaded(true);

          // Set ai component builder data if given
          if (
            this.props.aiComponentBuilderData &&
            this.props.aiComponentBuilderData.data
          ) {
            this.props.onComponentNameChanged(
              this.props.aiComponentBuilderData["name"]
            );
            this.props.onComponentDescriptionChanged(
              this.props.aiComponentBuilderData["explanation"]
            );
            CodeStore.setTemplateData(this.props.aiComponentBuilderData.data);
            this.setState({
              description: this.props.aiComponentBuilderData["explanation"],
            });
            this.setState({ status: "AI_BUILD_GENERATED" });
            this.setState({
              codePlaceholders: this.props.aiComponentBuilderData.data
                .placeholders,
            });
          }


          this.setState({ loading: false });
        })

    }

    const placeholderParameters = [];
    const { availablePlaceholders } = this.props;

    if (availablePlaceholders?.length === 1) {
      const placeholders = availablePlaceholders[0]?.placeholders || [];
      placeholders.forEach(({ key }) => {
        const completeKey = `\${${key}}`;
        placeholderParameters.push({
          name: completeKey,
          value: completeKey,
          caption: completeKey,
          meta: "local",
          score: 1000,
        });
      });
    }

    addCompleter({
      getCompletions: (editor, session, pos, prefix, callback) => {
        if (!this.freeUserTier() && CodeStore.isInternetEnabledInStore()) {
          this.state.availableSecrets.forEach((secret) => {
            const secretKey = secret;
            placeholderParameters.push({
              name: secretKey,
              value: secretKey,
              caption: secretKey,
              meta: "secret",
              score: 1000,
            });
          });
        }
        callback(null, placeholderParameters);
      },
    });
  }

  componentWillUnmount() {
    this.saveCodeTemplate();
    this.reactToPlaceHolder();

  }

  setCodeName = autorun(() => {
    CodeStore.setCodeName(this.props.ComponentName);
  });

  saveCodeTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    () => {
      if (this.state.error) {
        this.props.showError();
        return;
      }

      const lastModified = getCurrentTime();
      CodeStore.setLastModified(lastModified);
      const template = toJS(CodeStore.template);

      const data = {
        type: template.type,
        componentData: {
          name: this.props.ComponentName,
          data: template.data,
          lastModified: lastModified,
        },
        componentId: template.componentId
          ? template.componentId
          : this.props.component_id,
        description: this.props.ComponentDescription.value,
        status: this.state.status,
      };
      send_component_save_request(
        `component-service/code/data`,
        data,
        "",
        "POST",
        this.props.SaveTrigger
      )
        .then((response) => {
          if (response && response.status == 200) {
            this.props.showSuccess(data, this.props.SaveTrigger);
            this.props.onLastModifiedChanged(lastModified);
            projectStore.savedComponent = true;
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  );

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      if (!placeholder["selected"]) return;
      const { style, key } = placeholder["selected"];
      if (
        this.props.availablePlaceholders &&
        this.props.availablePlaceholders.length === 1 &&
        this.props.availablePlaceholders[0].placeholders
      ) {
        for (
          let i = 0;
          i < this.props.availablePlaceholders[0].placeholders.length;
          i++
        ) {
          if (key === this.props.availablePlaceholders[0].placeholders[i].key) {
            this.addPlaceholders(
              "${" + key + "}",
              key,
              style,
              this.props.availablePlaceholders[0].placeholders[i]
            );
            this.addChip("${" + key + "}", "parameters");
            return;
          }
        }
      }
    }
  );

  addPlaceholders = (label, text, style, placeholder) => {
    const key = placeholder.key;
    const length = placeholder.length;
    const type = placeholder.type;
    CodeStore.addPlaceholders({ label, text, style, key, length, type });
  };

  forceUpdateState = () => {
    let currentState = this.state.updateState;
    this.setState({ updateState: !currentState });
  };

  codeLangChange = (value) => {
    if (value.includes(this.getNodeJSLangName())) {
      if (this.state.changeDemo) {
        CodeStore.setCode(JsDemoCode, "code");
      }
      this.setState({ aceLangStyle: "javascript" });
    } else if (value.includes(this.getPythonLangName())) {
      if (this.state.changeDemo) {
        CodeStore.setCode(PythonDemoCode, "code");
      }
      this.setState({ aceLangStyle: "python" });
    }
    CodeStore.setCode(value, "codeLang");
  };

  addChip = (chip) => {
    if (chip && chip.trim() !== "") {
      CodeStore.setCode(chip, "parameters");
      return true;
    } else {
      return false;
    }
  };

  getCodeParameters = (parameters) => {
    return [
      {
        placeholders: parameters,
      },
    ];
  };

  updateCodePlaceholdersByValue = (value) => {
    const placeholders = this.state.codePlaceholders;
    const index = value.index;
    if (index >= 0) {
      if (index < placeholders.length) {
        switch (value.attribute) {
          case "type":
            placeholders[index].type = value.value;
            break;
          case "key":
            placeholders[index].key = value.value;
            break;
        }
      } else {
        const placeholder = {};
        switch (value.attribute) {
          case "type":
            placeholder.type = value.value;
            break;
          case "key":
            if (!placeholder.type) {
              placeholder.type = "text";
            }
            placeholder.key = value.value;
            break;
        }
        placeholders.push(placeholder);
      }
    }
    return placeholders;
  };

  deleteStateFromIndex = (index) => {
    const placeholders = this.state.codePlaceholders;
    if (placeholders.length > index && index >= 0) {
      const newPlaceholders = [...placeholders];
      newPlaceholders.splice(index, 1);
      return newPlaceholders;
    }
    return placeholders;
  }

  codeLangChange = (value) => {
    if(value.includes(this.getNodeJSLangName())){
      if(this.state.changeDemo){
        CodeStore.setCode(JsDemoCode, "code");
      }
      this.setState({aceLangStyle: "javascript"});
    } else if (value.includes(this.getPythonLangName())) {
      if(this.state.changeDemo){
        CodeStore.setCode(PythonDemoCode, "code");
      }
      this.setState({aceLangStyle: "python"});
    }
    CodeStore.setCode([], "libraries");
    CodeStore.setCode(value,"codeLang");
  };

  render() {
    const { data } = toJS(CodeStore);

    if (this.state && !this.state.loading) {
      return (
        <div>
          <div>
            <div className={this.props.classes.wrapCode}>
              <Grid container rowSpacing={1} alignItems="center">
                <Grid item xs={12}>
                  <div className={this.props.classes.label}>
                    Internet Access
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Switch
                    checked={CodeStore.isInternetEnabledInStore()}
                    onChange={(value) => {
                      CodeStore.setCode(
                        value.target.checked,
                        "internetEnabled"
                      );
                      this.forceUpdateState();
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={this.freeUserTier()}
                  />
                  <Grid item xs={12}>
                    <span>
                      Allow the Run Code to access the internet and make
                      requests
                    </span>
                  </Grid>
                </Grid>
                {this.freeUserTier() && (
                  <Grid item xs={12}>
                    <FreeUserWarning>
                      This is a premium feature available to paid plans only.{" "}
                      <span
                        onClick={() => {
                          window.open("/set_plan");
                        }}
                        className="linkStyle"
                      >
                        Upgrade now
                      </span>
                    </FreeUserWarning>
                  </Grid>
                )}
                {CodeStore.isInternetEnabledInStore() && !this.freeUserTier() && (
                  <Grid item xs={12}>
                    <StyledInstructions>
                      <ul>
                        <li>
                          Use{" "}
                          <b>
                            {data.codeLang === "NodeJS_16"
                              ? "axiosClient"
                              : "requestsClient"}
                          </b>{" "}
                          to securely make requests using secrets or credentials
                          from this component
                        </li>
                        <li>
                          Do not import{" "}
                          {data.codeLang === "NodeJS_16" ? "axios" : "requests"}{" "}
                          in your code. Click to copy to your clipboard.
                        </li>
                      </ul>
                    </StyledInstructions>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {CodeStore.isInternetEnabledInStore() && !this.freeUserTier() && (
                    <StyledAccordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="secrets-content"
                        id="secrets-header"
                      >
                        <div className={this.props.classes.label}>
                          Secrets and Credentials
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container item xs={12} rowGap={1}>
                          <Grid item xs={12} style={{ color: "#00000080" }}>
                            <div>
                              These secrets and credentials can be used in your
                              code.
                            </div>
                          </Grid>

                          {this.state.availableSecrets.map((secret, index) => (
                            <CopyToClipboard text={secret} key={index}>
                              <Tooltip title={secret} placement="top">
                                <Button
                                  size={"small"}
                                  variant={"outlined"}
                                  className="secretButton"
                                  style={{ maxWidth: "400px" }}
                                >
                                  <Grid
                                    container
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Grid item className="flex-center">
                                      <KeyIcon />
                                    </Grid>
                                    <Grid
                                      item
                                      className="flex-center"
                                      style={{
                                        flex: 1,
                                        minWidth: 0,
                                      }}
                                    >
                                      <StyledSecretPlaceholder>
                                        {secret}
                                      </StyledSecretPlaceholder>
                                    </Grid>
                                    <Grid item className="flex-center">
                                      <ContentCopyIcon />
                                    </Grid>
                                  </Grid>
                                </Button>
                              </Tooltip>
                            </CopyToClipboard>
                          ))}

                          <Grid item xs={12}>
                            <AddCredential
                              onClick={() => window.open("/credential_store")}
                            >
                              + Add another secret or credential
                            </AddCredential>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </StyledAccordion>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={this.props.classes.wrapCode}>
            <Grid container rowSpacing={3} alignItems="center">
              <Grid item xs={12}>
                <div className={this.props.classes.label}>Code</div>
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="codeLang"
                  labelId="demo-simple-select-outlined-label"
                  className="dropdown-mat"
                  value={data.codeLang}
                  defaultValue={"Python_3.9"}
                  disableUnderline
                  variant="standard"
                  align="left"
                  onChange={(value) => {
                    this.setState({
                      willChangeLanguage: true,
                      changeLangValue: value.target.value,
                    });
                  }}
                >
                  <MenuItem value={"NodeJS_16"}>NodeJS 16</MenuItem>
                  <MenuItem value={"Python_3_9"}>Python 3.9</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <div id="aceditor" style={{ width: "100%", height: "700px" }}>
                  <AceEditor
                    mode={this.state.aceLangStyle}
                    wrapEnabled={true}
                    theme="github"
                    width="100%"
                    height="100%"
                    onChange={(value) => {
                      CodeStore.setCode(value, "code");
                      CodeStore.setCode(this.props.version, "version");
                      this.setState({ changeDemo: false });
                    }}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={data.code}
                    fontSize={16}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2,
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={this.props.classes.wrapCode}>
            <Grid container rowSpacing={3} alignItems="center">
              <Grid item xs={12}>
                <div className={this.props.classes.label}>Outputs</div>
              </Grid>
              <Grid item xs={12}>
                <div className={this.props.classes.secondLevelLabel}>
                  Enter the key in the return statement to output the value of
                  that key as a placeholder
                </div>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  xs={12}
                  rowSpacing={2}
                  spacing={2}
                  alignItems={"center"}
                >
                  <Grid item xs={3}>
                    <div className={this.props.classes.label}>Type</div>
                  </Grid>
                  <Grid item xs={8}>
                    <div className={this.props.classes.label}>Key</div>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {this.state.codePlaceholders.map((placeholder, index) => (
                  <div style={{ width: "100%", height: "64px" }}>
                    <PlaceholderItem
                      index={index}
                      total={data.placeholders.length}
                      placeholder={placeholder}
                      onChange={(value) => {
                        CodeStore.setCode(value, "placeholders");
                        this.setState({
                          codePlaceholders: this.updateCodePlaceholdersByValue(
                            value
                          ),
                        });
                      }}
                      onDelete={(index) => {
                        CodeStore.deleteCodePlaceholder(index);
                        this.setState({
                          codePlaceholders: this.deleteStateFromIndex(index),
                        });
                      }}
                    />
                  </div>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="medium"
                  onClick={() => {
                    let indexNum = 0;
                    if (this.state.codePlaceholders) {
                      indexNum = this.state.codePlaceholders.length - 1;
                    }

                    const placeholderKey = {
                      attribute: "key",
                      index: indexNum + 1,
                      value: "",
                    };
                    CodeStore.setCode(placeholderKey, "placeholders");
                    this.setState({
                      codePlaceholders: this.updateCodePlaceholdersByValue(
                        placeholderKey
                      ),
                    });
                    const placeholderType = {
                      attribute: "type",
                      index: indexNum + 1,
                      value: "text",
                    };
                    CodeStore.setCode(placeholderType, "placeholders");
                    this.setState({
                      codePlaceholders: this.updateCodePlaceholdersByValue(
                        placeholderType
                      ),
                    });
                  }}
                >
                  <AddIcon /> Add Placeholder
                </Button>
              </Grid>
            </Grid>
          </div>

          <CustomDialog
            isOpen={this.state.willChangeLanguage}
            size={"sm"}
            title={"Confirm language change"}
            contents={
              <>
                <p className={"s-text"}>
                  Changing from one coding language to another will wipe any
                  code in the current selected language. If you want to keep the
                  current code, you should copy and paste it somewhere before
                  swapping languages (e.g. in a note on the canvas).
                </p>
                <p className={"s-text"}>Are you sure you want to continue?</p>
              </>
            }
            buttons={
              <>
                <Button
                  variant={"outlined"}
                  onClick={() => {
                    this.setState({ willChangeLanguage: false });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant={"contained"}
                  className={this.props.classes.loadMore}
                  onClick={() => {
                    this.setState({ willChangeLanguage: false });
                    this.codeLangChange(this.state.changeLangValue);
                  }}
                >
                  Continue
                </Button>
              </>
            }
          />
        </div>
      );
    } else return <IndividualComponentLoader />;
  }
}

export default withStyles(styles)(
  inject(
    "SelectedPlaceholder",
    "ComponentName",
    "ComponentDescription",
    "SaveTrigger"
  )(observer(CodeTemplate))
);
