import React, { useEffect, useState } from "react";

// MUI
import { Grid, styled, TextField, Autocomplete } from "@mui/material";

// Util
import { useNavigate } from "react-router";
import ExistingSessionInfo from "./ExistingSessionInfo";
import { send_request } from "../../../../utils/Request";

const StyledAutocomplete = styled(Autocomplete)({
    backgroundColor: "#FFF !important",
    minWidth: "50%",
});

const StyledTextField = styled(TextField)({
    backgroundColor: "#FFF !important",
    width: "100%%",
});

const Text = styled("span")(({ textColor }) => ({
    fontSize: "14px",
    color: textColor,
}));

function SelectSessionDropdown({ projectId, componentId, StyledSkeleton, setValues, placeholders, setIsLoading, setLoadingInputPHs, initSelected, isTest, version }) {

    const navigate = useNavigate();
    const [sessionIds, setSessionIds] = useState(null);
    const [selected, setSelected] = useState("");

    useEffect(async () => {
        // Fetch all the existing session ids linked to the given project id
        let url = `project-service/project-session/get_recent_existing_session_ids/${projectId}`;
        if (isTest) url += "?includeTestSessions=true";

        let json = await send_request(url, null, null, "GET");
        let dropdownTitleArray = [];
        const responseProjectSessions = json?.data
        if (!json || !responseProjectSessions) {
            setSessionIds(dropdownTitleArray);
            return;
        } else {
            dropdownTitleArray = responseProjectSessions;
        }
        setSessionIds(dropdownTitleArray);
        if (initSelected) handleFetchExisting(initSelected); // If there is an id in the URL
    }, []);

    useEffect(() => {
        return () => {
            // On unmount of selection
            const params = new URLSearchParams(window.location.search);
            params.delete("selectedSession");
            navigate({ search: params.toString() });
        }
    }, [])

    // Function for fetching existing placeholder data values and setting
    // It into the state
    const handleFetchExisting = async (selectedId) => {
        if (!selectedId){
            setSelected("");
            return;
        }

        // Set loading to true
        setIsLoading(true);
        setLoadingInputPHs(true);
        setSelected(selectedId); // Set dropdown

        const params = new URLSearchParams(window.location.search);
        params.append("selectedSession", selectedId);
        navigate({ search: params.toString() });

        const url = `project-service/project-session/get_existing_session_placeholder_data/${selectedId}`;

        let json = await send_request(url, placeholders, "", "POST");

        // If error, set loading to false
        if (!json || !json.data) {
            setIsLoading(false);
            setLoadingInputPHs(false);
            return;
        }

        let existingSessionPHs = json.data;

        // Set the values
        let vals = {};
        for (let pH of placeholders) {
            let type = pH.type;
            vals[pH.key] = {
                type: type,
                value: existingSessionPHs[pH.key] ? existingSessionPHs[pH.key] : "",
            };
        }

        setValues(vals);
        setIsLoading(false);
        setLoadingInputPHs(false);
    };

    return sessionIds ? (
        <Grid container rowGap={2} marginBottom={1}>
            <Grid item xs={12}>
                <Grid container rowGap={2}>
                    <Grid item xs={12}>
                        <b>Select session</b>
                    </Grid>
                    <Grid item xs={12}>
                        <Text>Use the placeholder values from an existing session to run this workflow</Text>
                    </Grid>
                    <Grid item xs={12}>
                        <StyledAutocomplete
                            freeSolo
                            variant="standard"
                            options={sessionIds}
                            getOptionLabel={(option) => {
                                if (!option) return "";
                                return `${option?.sessionId} (${option?.timeAgo})`
                            }}
                            onChange={(event, newValue) => {
                                handleFetchExisting(newValue?.sessionId);
                            }}
                            value={selected}
                            inputValue={selected}
                            renderInput={(params) => <StyledTextField {...params} size="small" placeholder="Type in or search for a session ID" />}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {selected && (
                <Grid item xs={12}>
                    <ExistingSessionInfo selected={selected} projectId={projectId} version={version} />
                </Grid>
            )}
        </Grid>
    ) : (
        <StyledSkeleton variant="rectangular" height="35px" />
    );
}

export default SelectSessionDropdown;
