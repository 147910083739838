import { computed, observable, decorate, action, toJS } from "mobx";
import SequentialFormContainer from '../../SequentialFormContainer';
class FormConditionalStore {
  constructor() {
    this.initialiseEmpty();
  }

  setStatement = (Statement) => {
    this.Statement = Statement
  }

  setFormConditional = (Statement) => {
    if (Statement.Statement) {
      const data = toJS(Statement.Statement.content.Condition);
      const List = [];
      // console.log(data);
      if (data) {
        for (let i = 0; i < data.length; i++) {
          let obj = [];
          if (data[i].operators) {
            for (let j = 0; j < data[i].operators.length; j++) {
              obj.push({
                HasSelected: false,
                dataAnswer: []
              })
            }
            List.push({
              ConditionList: obj
            })
          }
        }
        this.AnswerList = List;
        for (let i = 0; i < data.length; i++) {
          if (data[i].operators) {
            for (let j = 0; j < data[i].operators.length; j++) {
              this.checkHasSelected(data[i].operators[j].key, i, j);
            }
          }
        }
      }
    }

    this.Statement = Statement;
  }

  checkHasSelected = (key, indexStatement, indexCondition) => {
    const data = toJS(SequentialFormContainer.dataQuestion);
    

    const filterData = data.filter(ele => ele.config.key == key)
    if (filterData[0] != null && filterData[0] != undefined && filterData[0] != "") {
      if (filterData[0] && (filterData[0].type == 4
        || filterData[0].type == 5
        || filterData[0].type == 10)) {
        this.AnswerList[indexStatement]
          .ConditionList[indexCondition]
          .HasSelected = true;
        this.AnswerList[indexStatement]
          .ConditionList[indexCondition]
          .dataAnswer = filterData[0].config.answer_list;
      } else {
        this.AnswerList[indexStatement]
          .ConditionList[indexCondition]
          .HasSelected = false;
      }
    }
  }

  addCondition = (indexStatement) => {
    const obj = {
      logic: 'OR',
      key: '',
      function: 'EQUAL',
      value: ''
    }
    this.Statement.Statement.content
      .Condition[indexStatement]
      .operators
      .push(obj);
    this.AnswerList[indexStatement]
      .ConditionList
      .push({
        HasSelected: false,
        dataAnswer: []
      })
  }

  addStatement = () => {
    const obj = {
      operators: [
        {
          logic: 'OR',
          key: '',
          function: 'EQUAL',
          value: ''
        }
      ],
      then: {
        Statement: {
          type: "VALUE",
          content: true
        }
      }
    }
    this.AnswerList.push({
      ConditionList: [
        {
          HasSelected: false,
          dataAnswer: []
        }
      ]
    })
    const { Condition } = this.Statement.Statement.content
    this.Statement.Statement.content
      .Condition
      .splice(Condition.length - 1, 0, obj)
  }

  removeCondition = (indexStatement, indexCondition) => {
    this.Statement.Statement.content
      .Condition[indexStatement]
      .operators
      .splice(indexCondition, 1)
    this.AnswerList[indexStatement]
      .ConditionList
      .splice(indexCondition, 1)
  }

  removeStatement = (indexStatement) => {
    this.Statement.Statement.content
      .Condition
      .splice(indexStatement, 1)
    this.AnswerList
      .splice(indexStatement, 1)
  }

  setThenContent = (content, indexStatement) => {
    this.Statement.Statement.content
      .Condition[indexStatement]
      .then.Statement.content = content;
  }
  setElseContent = (content) => {
    this.Statement.Statement.content
      .else.Statement.content = content;
  }

  setCondition = (content, indexStatement, index, type) => {
    switch (type) {
      case 'logic':
        this.Statement.Statement.content
          .Condition[indexStatement]
          .operators[index]
          .logic = content;
        return;
      case 'key':
        this.Statement.Statement.content
          .Condition[indexStatement]
          .operators[index]
          .key = content;
        this.checkHasSelected(content, indexStatement, index);
        return;
      case 'function':
        this.Statement.Statement.content
          .Condition[indexStatement]
          .operators[index]
          .function = content;
        return;
      case 'value':
        this.Statement.Statement.content
          .Condition[indexStatement]
          .operators[index]
          .value = content;
        return;
    }
  }

  initialiseEmpty = () => {
    this.Statement = {
      Statement: {
        type: "IF",
        content: {
          Condition: [
            {
              operators: [
                {
                  logic: undefined,
                  key: '',
                  function: 'EQUAL',
                  value: ''
                }
              ],
              then: {
                Statement: {
                  type: "VALUE",
                  content: true
                }
              }
            },
            {
              then: {
                Statement: {
                  type: "VALUE",
                  content: false
                }
              }
            }
          ]
        }
      }
    }
    this.AnswerList = [
      {
        ConditionList: [
          {
            HasSelected: false,
            dataAnswer: []
          }
        ]
      }
    ];
  }
  get template() {
    return {
      conditionPredertermine: toJS(this.conditionPredertermine),
      AnswerList: []
    }
  }
}

decorate(FormConditionalStore, {
  Statement: observable,
  AnswerList: observable,
  setThenContent: action,
  setCondition: action,
  addCondition: action,
  initialiseEmpty: action,
  template: computed
})

const formConditionalStore = new FormConditionalStore();
export default formConditionalStore;
