import React, {useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import { Typography, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AppBar from "@mui/material/AppBar";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Fade from "@mui/material/Fade";

import Menu from "@mui/material/Menu";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      marginLeft: theme.spacing(2),
      color: "white",
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block"
      }
    },
    appBar: {
      backgroundColor: theme.palette.primary.main,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar
    },
    accountIconButton: {
      "&:hover": {
        backgroundColor: "inherit",
        borderRadius: 0
      },
      "&:hover $accountIcon": {
        color: "#ffffff !important"
      },
      "&:hover $accountDropDownIcon": {
        color: "#ffffff !important"
      }
    },
    accountIcon: {
      fontSize: 30,
      margin: theme.spacing(1),
      color: alpha("rgb(255,255,255)", 0.7)
    },
    accountDropDownIcon: {
      margin: theme.spacing(0.5),
      color: alpha("rgb(255,255,255)", 0.7)
    },
    accountText: {
      color: "white"
    }
  };
});

const appBar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  const classes = (props.overrideStyles && { ...useStyles() }) || useStyles();
  return (
    <AppBar {...props}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h3">
          {props.titlelProps}
        </Typography>
        {props.children}

        {props.account && (
          <div>
            <div onClick={handleClick}>
              <IconButton
                disableRipple={true}
                disableFocusRipple={true}
                className={classes.accountIconButton}
                size="large"
              >
                <AccountCircle className={classes.accountIcon} />
                <Typography variant="body2" className={classes.accountText}>
                  {props.account.text}
                </Typography>
                <ArrowDropDownIcon className={classes.accountDropDownIcon} />
              </IconButton>
            </div>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={open}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              {props.DropDownList}
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default appBar;
