import React, { Component } from "react";

import { observer, inject } from "mobx-react";
import { autorun, toJS, reaction } from "mobx";
import parseJSONStore from "./ParseJSONStore";
import projectStore from "../ProjectCanvas/ProjectStore";

// Utils
import { getCurrentTime } from "../../utils/getCurrentTime";
import { send_component_save_request, send_request } from "../../utils/Request";

// Custom
import { IndividualComponentLoader } from "../../utils/ComponentLoadingSkeleton";
import { Button, CircularProgress, Grid, Paper, TextField } from "@mui/material";
import ReactJson from "react-json-view";
import ErrorIcon from "@mui/icons-material/Error";
import ChipInput from "../ChipInput/ChipInput";
import PlaceholderJson from "../Component/PlaceholderJson";
import handleJsonFunction from "../Component/HandleJsonFunction";

class ParseJSONTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholders: [],
      loading: false,
      projects: null,
      components: null,
      uploadingSample: false,
      error: false
    };
    this.insertPlaceholder = this.insertPlaceholder.bind(this);
    parseJSONStore.initialiseEmpty();
  }

  componentDidMount() {
    if (this.props.component_id == undefined) return;

    this.setState({ loading: true });

    send_request(
      `project-service/project/component/query/${this.props.component_id}/at-version/${this.props.version}`,
      "",
      {}
    )
      .then(response => {
        if (
          response.data &&
          response.data.components &&
          response.data.components[0].componentData
        ) {
          parseJSONStore.setParseJSONTemplate(
            response.data.components[0]
          );
          this.props.onComponentNameChanged(parseJSONStore.parse_json_name);
          this.props.onComponentDescriptionChanged(response.data.components[0].description);
          this.props.onLastModifiedChanged(parseJSONStore.lastModified);
        }
        this.props.setChildTemplateLoaded(true);
      })
      .catch(err => {
        throw Error(err.message);
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  handleSaveComponent = () => {
    let cData = parseJSONStore.componentData;

    cData.name = toJS(this.props.ComponentName);

    let lastModified = getCurrentTime();
    let data = {
      componentData: {
        data: cData,
        name: toJS(this.props.ComponentName),
        lastModified: lastModified
      },
      componentId: this.props.component_id,
      type: "parse_json",
      description: this.props.ComponentDescription.value
    };

    send_component_save_request(
      "component-service/parse_json/data",
      data,
      "",
      "POST",
      this.props.SaveTrigger
    )
      .then(response => {
        this.props.onLastModifiedChanged(lastModified);
        this.props.showSuccess(data, this.props.SaveTrigger);
        projectStore.savedComponent = true;
      })
      .catch(err => {
        throw err;
      });
  };

  setParseJSONName = autorun(() => {
    parseJSONStore.setParseJSONName(
      this.props.ComponentName
    );
  });

  componentWillUnmount() {
    // Clear the mobX reactions
    this.saveParseJSONTemplate();
    this.reactToPlaceHolder();
  }

  saveParseJSONTemplate = reaction(
    () => this.props.SaveTrigger.triggerValue,
    this.handleSaveComponent
  );

  reactToPlaceHolder = reaction(
    () => toJS(this.props.SelectedPlaceholder),
    (placeholder, reaction) => {
      const { style, key, isNotPlaceholder } = placeholder["selected"];
      this.insertPlaceholder(
        style.default,
        key,
        style,
        this.state.focus,
        isNotPlaceholder
      );
    }
  );

  removeDeletedPlaceholders = savedPHs => {
    let pHs = [];

    for (let i = 0; i < savedPHs.length; i++) {
      let currPH = savedPHs[i];
      if (
        this.props.availablePlaceholders[0].placeholders.some(
          aPH => aPH.key === currPH.key
        )
      ) {
        pHs.push(currPH);
      }
    }
    return pHs;
  };

  insertPlaceholder = (label, text, style, focus, isNotPlaceholder) => {
    if (isNotPlaceholder === true) {
      return;
    }
    const newPlaceholders = ["${" + text + "}"];
    parseJSONStore.setPlaceholders(newPlaceholders);
  };

  handleReactJson = () => {
    let jsonData = {}

    return (
      <ReactJson
        src={jsonData}
        collapsed="true"
        name="Data"
        displayDataTypes={false}
        style={{ fontFamily: "Open Sans !important" }}
        theme={{
          base00: "rgba(1, 1, 1, 0)",
          base01: "rgba(1, 1, 1, 0.1)",
          base02: "rgba(0, 0, 0, 0.2)",
          base03: "rgba(1, 1, 1, 0.3)",
          base04: "rgba(0, 0, 0, 0.4)",
          base05: "rgba(1, 1, 1, 0.5)",
          base06: "rgba(1, 1, 1, 0.6)",
          base07: "rgba(1, 1, 1, 0.7)",
          base08: "rgba(1, 1, 1, 0.8)",
          base09: "rgba(1, 1, 1, 0.8)",
          base0A: "rgba(1, 1, 1, 0.8)",
          base0B: "rgba(1, 1, 1, 0.8)",
          base0C: "rgba(1, 1, 1, 0.8)",
          base0D: "rgba(1, 1, 1, 0.8)",
          base0E: "rgba(1, 1, 1, 0.8)",
          base0F: "rgba(1, 1, 1, 0.8)",
        }}
      />
    )
  }

  handleJson = (key, value) => {
    for (let j = 0; j < value.length; j++) {
      if (Array.isArray(value[j])) {
        for (let x = 0; x < value[j].length; x++) {
          value.push(value[j][x]);
          key.push(key[j] + "[" + x + "]");
        }
      } else if (typeof value[j] === "object" && value[j] !== null) {
        let keyData = Object.keys(value[j]);
        let valueData = Object.values(value[j]);
        for (let y = 0; y < valueData.length; y++) {
          value.push(valueData[y]);
          key.push(key[j] + "." + keyData[y]);
        }
      }
    }
  };


  handleUploadSample = async () => {
    this.setState({ uploadingSample: true })
      try {
        const sampleJson = parseJSONStore.componentData.sample_json;
        JSON.parse(sampleJson);
        await handleJsonFunction.handleAddPlaceholdersJson(sampleJson, parseJSONStore);
        this.setState({ error: false })
      } catch(error) {
        this.setState({ error });
      } finally {
        this.setState({ uploadingSample: false });
      }
  }


  render() {
    const { componentData } = toJS(parseJSONStore);

    const { availablePlaceholders } = this.props;
    const { error, loading, uploadingSample } = this.state;
    return loading ? (
      <IndividualComponentLoader />
    ) : (
      <Paper
        sx={{
          width: "100%",
          padding: "1rem"
        }}
      >
        <Grid
          container
          xs={12}
          direction={"column"}
        >
          <Grid
            xs={12}
            container
            direction={"column"}
            sx={{
              marginBottom: "1rem",
            }}
          >
            <span
              style={{
                fontSize: "14px",
                marginBottom: "8px"
              }}
            >
              Input placeholder containing the JSON to be parsed
            </span>
            <ChipInput
              inputValue={componentData.placeholders}
              onPlaceholderSelected={this.props.onPlaceholderSelected}
              placeholders={availablePlaceholders}
              placeholder="Insert a placeholder"
              onBeforeAdd={(chip) => {
                if (chip.trim() != "") {
                  parseJSONStore.setJSONPlaceholder(chip.trim());
                  return true;
                }
              }}
              onDelete={() => {
                parseJSONStore.deletePlaceholders();
              }}
              onFocus={() => {
              }}
            />
          </Grid>
          <Grid
            xs={12}
            container
            direction={"column"}
            sx={{
              marginBottom: "1rem"
            }}
          >
            <span
              style={{
                fontSize: "14px",
                marginBottom: "8px"
              }}
            >
              Copy and paste a sample JSON below and then click upload sample
            </span>
            <TextField
              value={componentData.sample_json}
              onChange={(e) => {
                const text = e.target.value;
                parseJSONStore.setSampleJSON(text);
              }}
              multiline
              tabIndex={-1}
              minRows={4}
              style={{
                margin: "0",
                borderRadius: "5px",
                backgroundColor: "white",
                boxShadow: "none",
              }}
            />
          </Grid>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 24
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                this.handleUploadSample();
              }}
              disabled={uploadingSample}
              sx={{
                marginTop: "1rem"
              }}
            >
              Upload Sample
            </Button>
          </div>
          {
            error !== false && (
            <p className="err-text">
              <ErrorIcon className="iconDiscard" />
              Error with sample. Please try again.
            </p>
            )
          }
          {
            this.state.uploadingSample && <CircularProgress />
          }
          {
            !this.state.uploadingSample && !error && componentData.samplePlaceholders.length > 0 &&
            <PlaceholderJson
              componentStore={parseJSONStore}
              stepStatus={"setPlaceholders"}
              handleKeyChanges={(error) => {
                this.setState({ error });
                this.props.handleKeyChanges(error);
              }}
              data={componentData.sample_json}
              placeholderJson={componentData.samplePlaceholders}
            />
          }
        </Grid>
      </Paper>
    );
  }
}

export default inject(
  "SelectedPlaceholder",
  "ComponentName",
  "ComponentDescription",
  "SaveTrigger"
)(observer(ParseJSONTemplate));
