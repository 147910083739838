import React from "react";
import { styled } from "@mui/system";
import { Fab, Tooltip } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import config from "../../../config";

const calculatePosition = ({
  aiComponentBuilderOpen,
  openTestPanel,
  openSessionHistory,
  isForm,
}) => {
  const getConfigWidth = (key) => {
    const value = config[key];
    if (typeof value === "string") {
      return parseInt(value.replace("%", "").replace("px", ""), 10);
    }
    return 0; // Return 0 if the config value is not a string
  };

  const baseWidth = getConfigWidth(isForm ? "FORM_PANE_WIDTH" : "PANE_WIDTH");
  const aiComponentBuilderWidth = aiComponentBuilderOpen
    ? getConfigWidth("AI_COMPONENT_BUILDER_PANE_WIDTH")
    : 0;
  const testPanelWidth = openTestPanel
    ? getConfigWidth("TEST_PANE_WIDTH")
    : 0;
  const additionalWidth = aiComponentBuilderWidth + testPanelWidth;
  const sessionHistoryWidth = openSessionHistory
    ? getConfigWidth("SESSION_HISTORY_PANEL_WIDTH")
    : 0;
  const finalWidth = baseWidth + additionalWidth + sessionHistoryWidth;

  return `calc(${finalWidth}% + 8px)`;
};

const StyledFab = styled(Fab)(({ theme, ...props }) => ({
  background: "#DADADA",
  position: "fixed",
  top: "50vh",
  right: calculatePosition(props),
  zIndex: 1000,

}));

const RightArrow = styled(ChevronRightIcon)({
  color: "#000",
});


function ClosePaneButton({
  handleClose,
  aiComponentBuilderOpen,
  openSessionHistory,
  openTestPanel,
  editComponent,
}) {
  const isForm =
    editComponent &&
    ["form", "sequential_form", "form_section"].includes(editComponent.type);
  const isFullScreen = window.location.href.includes("fullScreen=true");
  const isForceStart = window.location.href.includes("force_start");
  const isAIPanelOpen = window.location.href.includes("aiPanelOpen=true");

  if (!isFullScreen && editComponent) {
    return (
      <Tooltip title="Close pane" placement="left">
        <StyledFab
          size="small"
          onClick={handleClose}
          aiComponentBuilderOpen={aiComponentBuilderOpen}
          openTestPanel={openTestPanel}
          openSessionHistory={openSessionHistory}
          isForm={isForm}
          isForceStart={isForceStart}
          isAIPanelOpen={isAIPanelOpen}
        >
          <RightArrow fontSize="medium" />
        </StyledFab>
      </Tooltip>

    );
  }
  return null;
}

export default ClosePaneButton;
