import React, { useState, useEffect, useRef } from "react";

import { Drawer, Grid, Button, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { withStyles } from "@mui/styles";
import AIBuilderStyles from "./AIBuilder/AIBuilderStyles";
const styles = AIBuilderStyles;

// Static messages
import Chat from "./AIBuilder/components/Chat/Chat";
import { StyledAIPanelDrawer } from "./AIBuilder/AIPanel";

// Styled components
const AIButton = styled(Grid)(({ borderColor }) => ({
    border: `1px solid ${borderColor}`,
    background: "#FFF",
    borderRadius: "6px",
    padding: "16px",
    color: "#000",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "&:hover, &:active": {
        cursor: "pointer",
        boxShadow: "4px 4px 0px 0px #00000040",
        backgroundColor: "#F4EDF6",
      },
}));

const AIStarsIcon = styled("img")({
    height: "18px",
    width: "auto",
    marginRight: "8px"
})

const INITIAL_NEW_WORKFLOW_MESSAGE = (handleHelpMeBuild, handleBuildMyOwn) => {
    return {
        role: "assistant",
        showTimer: false,
        content:
        <Grid container gap="16px">
            <Grid item xs={12}>
                👋 Welcome to your new workflow
            </Grid>
            <Grid item xs={12}>
                I can help you design and build a workflow based on your instructions or requirements.
            </Grid>
            <Grid item xs={12}>
                Alternatively, you can also build your workflow manually by dragging and dropping components into the canvas. 
            </Grid>
            <Grid item xs={12}>
                How would you like to proceed?
            </Grid>
            <Grid item xs={12}>
                <Grid container gap="8px">
                    <AIButton item xs={12} borderColor="rgba(148, 75, 168, 1)" onClick={handleHelpMeBuild}>
                        <AIStarsIcon src="/svgs/ai-stars-with-color.svg" />
                        Build a workflow for me
                    </AIButton>
                    <AIButton item xs={12} borderColor="rgba(0, 0, 0, 0.5)" onClick={handleBuildMyOwn}>
                        I will build on my own
                    </AIButton>
                </Grid>
            </Grid>
        </Grid>
    }
}

function AIDisplayHelper(props) {
    const chatRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        setMessages([INITIAL_NEW_WORKFLOW_MESSAGE(props.handleHelpMeBuild, props.handleBuildMyOwn)])
    }, []);

    const renderSidebar = () => {
        return (
            <Grid container className={props.classes.fullChat} direction="column">
                <Grid item>
                    <Grid container direction={"column"}
                        alignItems={messages.length > 0 ? "center" : "flex-start"}
                        className={messages.length > 0 ? props.classes.chatHeader : ""}
                    >
                        <b>New workflow</b>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Chat
                        chatRef={chatRef}
                        classes={props.classes}
                        hideButtons={true}
                        reset={false}
                        messages={messages}
                        loading={loading}
                        userPrompt={""}
                        canInsert={true}
                        showInstruction={true}
                    />
                </Grid>
            </Grid>
        );
    };

    //drawer setup
    return (
        <StyledAIPanelDrawer
            open={props.isOpen}
            anchor={"right"}
            hideBackdrop={true}
            onClose={props.handleCloseDisplayHelper}
            variant="permanent"
        >
        <Grid container className={props.classes.insidePaper} direction="column">
            <Grid item>
                <Grid container padding="4px">
                <Grid item xs={12} style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                    <Button
                        disabled={loading}
                        variant={"string"}
                        sx={{ textTransform: "none", color: "#868686" }}
                        onClick={() => props.handleCloseDisplayHelper()}
                    >
                        <CloseIcon fontSize={"small"} color={"info"} />
                        Close
                    </Button>
                </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                {renderSidebar()}
            </Grid>
        </Grid>
        </StyledAIPanelDrawer>
    );
}

export default withStyles(styles)(AIDisplayHelper);
