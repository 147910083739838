import React, { useState, useEffect } from "react";
import { CustomDialog } from "../../Component/Dialog";

import { styled } from "@mui/system";
import { Button, MenuItem, Select } from "@mui/material";
import { PlayCircleOutline } from "@mui/icons-material";

// Loading
import { Skeleton } from "@mui/material";
import config from "../../../config";

const Dropdown = styled(Select)({
    marginTop: "24px",
    background: "#FFF !important",
});

const StartButton = styled(Button)({
    background: "#55a77a",
    borderRadius: "4px",
    color: "#FFF",
    boxShadow: "none",
    paddingLeft: "20px",
    paddingRight: "20px",
    "&:hover": {
        background: "rgba(85,167,122, 0.85)",
    },
});

const Logo = styled("img")({
    height: "20px",
    width: "auto",
    marginRight: "12px",
});

const Item = styled(MenuItem)({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
});

function MultipleStartTriggersDialog({ isOpen, closeDialog, cData, openADialog }) {
    const [startComponents, setStartComponents] = useState(null);
    const [selectedStart, setSelectedStart] = useState(null);

    useEffect(() => {
        if (!cData) return;

        let comps = getComponentObjects(cData); // Get the object component list

        setSelectedStart(comps[0]); // Set the initial one
        setStartComponents(comps); // Set the state array
    }, []);

    // Gets the component name
    const getCompName = (cData) => {
        return cData.componentData && cData.componentData.name ? cData.componentData.name : `Untitled (${cData.componentId})`;
    };

    // Gets the array of component objects in the format we want
    const getComponentObjects = (cData) => {
        const comps = [];

        for (let i = 0; i < cData.length; i++) {
            switch (cData[i].type) {
                case "form":
                    comps.push({
                        componentId: cData[i].componentId,
                        name: getCompName(cData[i]),
                        logo: "/svgs/form.svg",
                        startTrigger: () => {
                            window.open(`${config.FORM.FORM_FRONT_END}form/${cData[i].componentId}`);
                        },
                    });
                    break;
                case "bulk_assessment":
                    comps.push({
                        componentId: cData[i].componentId,
                        name: getCompName(cData[i]),
                        logo: "/svgs/bulk_assessment.svg",
                        startTrigger: () => {
                            window.open(`${config.FORM.FORM_FRONT_END}form/${cData[i].componentId}`);
                        },
                    });
                    break;
                case "webhook":
                    comps.push({
                        componentId: cData[i].componentId,
                        name: getCompName(cData[i]),
                        logo: "/svgs/webhook.svg",
                        startTrigger: () => {
                            closeDialog();
                            openADialog({ openDialog: true });
                        },
                    });
                    break;
                case "schedule_start":
                    comps.push({
                        componentId: cData[i].componentId,
                        name: getCompName(cData[i]),
                        logo: "/svgs/schedule_start.svg",
                        startTrigger: () => {
                            closeDialog();
                            openADialog({ openScheduleStartDialog: true });
                        },
                    });
                    break;
                case "trigger_from_a_workflow":
                    comps.push({
                        componentId: cData[i].componentId,
                        name: getCompName(cData[i]),
                        logo: "/svgs/trigger_from_a_workflow.svg",
                        startTrigger: () => {
                            closeDialog();
                            openADialog({ openTriggerFromAWorkflowDialog: true });
                        },
                    });
                    break;
                case "trigger_from_zapier":
                    comps.push({
                        componentId: cData[i].componentId,
                        name: getCompName(cData[i]),
                        logo: "/svgs/zapier-icon.svg",
                        startTrigger: () => {
                            closeDialog();
                            openADialog({ openTriggerFromZapierDialog: true });
                        },
                    });
                    break;
                default:
                    break;
            }
        }

        return comps;
    };

    const getComponentMenuItem = (comp) => {
        return (
            <Item value={comp}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Logo src={comp.logo} /> {comp.name}
                </div>
            </Item>
        );
    };

    return (
        <CustomDialog
            isOpen={isOpen}
            title={"Select trigger component"}
            contents={
                <p className={"s-text"}>
                    <div>This workflow has multiple trigger components. Select the trigger you want to open</div>
                    {startComponents && selectedStart && (
                        <Dropdown
                            fullWidth
                            size={"small"}
                            variant={"filled"}
                            value={selectedStart}
                            onChange={(e) => {
                                setSelectedStart(e.target.value);
                            }}
                        >
                            {startComponents.map((sC) => {
                                return getComponentMenuItem(sC);
                            })}
                        </Dropdown>
                    )}
                </p>
            }
            buttons={
                <>
                    <Button variant={"outlined"} color={"primary"} onClick={closeDialog}>
                        Cancel
                    </Button>
                    {selectedStart && (
                        <StartButton startIcon={<PlayCircleOutline />} onClick={() => selectedStart.startTrigger()}>
                            Start
                        </StartButton>
                    )}
                </>
            }
        />
    );
}

export default MultipleStartTriggersDialog;
