import React, { Component } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { CircularProgress, Grid, TextField } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { toJS } from "mobx";
import HandleJsonFunction from "../../Component/HandleJsonFunction";
import { send_request } from "../../../utils/Request";
import GenerateToken from "../../Component/GenerateToken";
import InZapierOrW86Box from "./InZapierOrW86Box";

class SetupAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      errorMessage: "",
      status: this.props.status
    };
  }

  changeTokenStatus = status => {
    this.setState({ hasToken: status });
    this.props.changeTokenStatus(status);
  };

  render() {
    const { component_id } = this.props;

    let zapierContent = (
      <Grid container spacing={2} style={{ padding: "16px" }}>
        <Grid item xs={12} className="textAlignCenter">
          You should now be at the <b>Set up action</b> step pictured below 👇
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          <img
            src="/images/trigger_from_zapier_setupaction.png"
            style={{
              border: "thin solid #C4C4C4",
              borderRadius: "10px",
              width: "80%",
              height: "auto"
            }}
          />
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          ✅ Enter this <b>Sample Endpoint URL</b> into the <b>Endpoint URL</b>{" "}
          field
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          <TextField
            value={
              "https://webhook.workflow86.com/zapier/catch-sample/" +
              component_id
            }
            style={{ width: "100%" }}
            variant="outlined"
            disabled={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CopyToClipboard
                    text={
                      "https://webhook.workflow86.com/zapier/catch-sample/" +
                      component_id
                    }
                  >
                    <FileCopyIcon className="custom-icon-btn" />
                  </CopyToClipboard>
                </InputAdornment>
              )
            }}
          ></TextField>
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          ✅ Generate a <b>token</b> and then enter it into the <b>Token</b>{" "}
          field
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          <GenerateToken
            hasToken={status => {
              this.changeTokenStatus(status);
            }}
            tokenStatus={this.props.hasToken}
            style={{ margin: "0px" }}
            component_id={component_id}
            isSingleLine={true}
            isAlignCenter={true}
          />
        </Grid>

        {this.props.hasToken && (
          <Grid item xs={12} className="textAlignCenter">
            <span className="token-generated-setup-action">
              A token has already been generated. If you have lost this token,
              you will need to generate a new one.
            </span>
          </Grid>
        )}

        <Grid item xs={12} className="textAlignCenter">
          ✅ Set up the data you want to send from Zapier to Workflow86
        </Grid>

        <Grid item xs={12} className="textAlignCenter">
          ✅ You can then click <b>Continue</b> in Zapier{" "}
        </Grid>
      </Grid>
    );

    let workflow86Content = (
      <Grid container spacing={2} style={{ padding: "16px" }}>
        <Grid item xs={12} className="textAlignCenter">
          ✅ Click <b>Next</b> here to continue 👉
        </Grid>
      </Grid>
    );

    return (
      <>
        <Grid container spacing={2}>
          <InZapierOrW86Box zapierOrW86={"Zapier"} content={zapierContent} isExpanded={this.props.isExpanded} />

          <InZapierOrW86Box
            zapierOrW86={"Workflow86"}
            content={workflow86Content}
            isExpanded={this.props.isExpanded}
          />

          <Grid item xs={12} className="dialogContentRight">
            <Grid item xs={12} paddingBottom={this.props.isExpanded ? 0 : "56px !important"}>
              <Button
                onClick={() => {
                  this.props.onChangeState(0, 0);
                }}
                className="btnSpace"
                variant={"outlined"}
                color={"info"}
              >
                {" "}
                Back
              </Button>
              <Button
                onClick={() => {
                  this.props.onChangeState(2, 2);
                }}
                variant={"contained"}
                color={"primary"}
              >
                {" "}
                NEXT
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default SetupAction;
