import { computed, observable, decorate, action, toJS } from "mobx";
import { ComponentFactory } from "./ComponentRegistry";
import _ from "lodash";
import uuid from "uuid";
import { extractNodes, extractEdges } from "../../utils/CanvasUtil";

class PreviewStore {
  constructor() {
    this.clear();
    this.state = {
      zoom_level: 75,
      active_diagram: {},
      locked: true,
      template: {}
    };
  }

  setWorkflow = (workflow) => {
    this.project_id = workflow.projectId;
    this.project_name = workflow.name;
    this.last_modified = workflow.lastModified;
    this.project_description = workflow.description;
    this.components = workflow.components;

    const elements = this.readDiagram(workflow.components);

    this.setCanvas(elements);
    this.setNodes(extractNodes(elements));
    this.setEdges(extractEdges(elements));

    this.imagePath = workflow.imagePath;
    this.projectStatus = workflow.status;
  };

  //create a react-flow custom node
  createNode = (component, template) => {
    let newNode = {
      id: component.componentId,
      type: "component",
      data: {
        logo: template.logo,
        hasInput: template.hasInput ? template.hasInput : false,
        hasOutput: template.hasOutput ? template.hasOutput : false,
        type: component.type,
        displayName: template.displayName,
        description: component.description,
        next: component.nextComponents,
        label: component.name ? component.name : template.displayName,
        baseColor: template.baseColor
      },
      position: { x: component.viewData.x, y: component.viewData.y }
    };

    return newNode;
  };

  //create a react-flow custom edge
  createLink = (newNodeId, nextId, baseColor) => {
    let newLink = {
      id: uuid(),
      source: newNodeId,
      target: nextId,
      markerEnd: {
        type: "arrowclosed",
        color: "#868686",
      },
      type: "link",
      style: { strokeWidth: "3px", stroke: baseColor }
    };
    return newLink;
  };

  //this turns stored data into a react-flow diagram
  readDiagram = (components) => {
    let diagram = [];

    if (components) {
      components.map((component, id) => {
        if (ComponentFactory[component.type] !== undefined) {
          //create nodes
          const newNode = this.createNode(
            component,
            ComponentFactory[component.type]
          );

          diagram.push(newNode);

          if (newNode.data.next && newNode.data.next.length != 0) {
            //create links
            newNode.data.next.map((next, nextId) => {
              const newLink = this.createLink(
                newNode.id,
                next.componentId,
                newNode.data.baseColor
              );
              diagram.push(newLink);
            });
          }
        }
      });
    }
    return diagram;
  };

  setCanvas = (canvas) => {
    this.canvas = canvas;
  };

  setNodes = (nodes) => {
    this.nodes = nodes;
  }

  setEdges = (edges) => {
    this.edges = edges;
  }

  clear = () => {
    this.project_id = "";
    this.project_name = "";
    this.project_description = "";
    this.components !== undefined
      ? (this.components.length = 0)
      : (this.components = []);
  };

  get aggregate() {
    return {
      project_id: this.project_id,
      project_name: this.project_name,
      draftVersion: this.draftVersion,
      publishedVersion: this.publishedVersion,
      project_description: this.project_description,
      imagePath: this.imagePath,
      components: toJS(this.components)
    };
  }
}

decorate(PreviewStore, {
  download: observable,
  template: observable,
  project_id: observable,
  project_name: observable,
  last_modified: observable,
  components: observable,
  downloadTemplate: action,
  setCanvas: action,
  setNodes: action,
  setEdges: action,
  clear: action,
  aggregate: computed
});

const previewStore = new PreviewStore();
console.log("importing now...");
export default previewStore;
