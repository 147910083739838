export const achievements =  [
  {
    id: "FIRST_SESSION",
    title: "🚶‍♂️A journey of a thousand miles...",
    required: "...begins with running your first workflow session",
    tasks: [
      {
        name : "Guide to running your first workflow",
        link : "https://docs.workflow86.com/docs/getting-started/first_workflow"
      }
    ],
    reward : "Get an extra 14 days and 50 extra actions added to your free trial",
    complete: false
  },
  {
    id: 2,
    title: "👋 With a little help from my friends",
    required: "Invite 4 other users into your account",
    tasks: [
      {
        name : "Invite a user now",
        link : ""
      }
    ],
    reward : "Upgrade from 5 user seats to 10 user seats",
    complete: false
  },
  {
    id: 3,
    title: "⚡ Got Zapier? This will make you happier",
    required: "Already have a paid subscriber to Zapier? Get a discount for any Workflow86 plan equivalent to the cost of your Zapier plan. ",
    tasks: [
      {
        name : "Apply here",
        link : ""
      }
    ],
    reward : "Discount to any Workflow86 plan equivalent to your Zapier subscription",
    complete: false
  },
  
]