const AIBuilderStyles = () => ({
  section: {
    background: "#FFFFFF",
    marginTop: "16px",
    marginBottom: "16px",
    padding: "24px 24px 24px 24px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    border: " double 4px transparent",
    borderRadius: "10px",
    backgroundImage:
      "linear-gradient(white, white), linear-gradient(to bottom, #944BA8, #2196F3)",
    backgroundOrigin: "border-box",
    backgroundClip: "padding-box, border-box",
    maxHeight: "100%!important",
    overflowY: "auto"
  },
  loadingMessage: {
    background: "#F5F5F5",
    borderRadius: " 16px 16px 16px 0px",
    padding: "16px"
  },
  fullChat: {
    padding: "0px 16px 16px 16px",
    height: "100%"
  },
  container: {
    marginLeft: "16px",
    marginRight: "16px",
    maxHeight: "100%!important",
    overflowY: "hidden"
  },
  image: {
    marginTop: "16px",
    marginBottom: "16px"
  },
  chat: {
    marginTop: "16px",
    marginBottom: "16px"
  },
  cursor: {
    cursor: "pointer"
  },
  generate: {
    background: "#944BA8",
    "&:active": {
      background: "#7B1FA2"
    },
    "&:hover": { background: "#7B1FA2" }
  },
  secondary: {
    color: "#944BA8"
  },
  icon: {
    height: "16px"
  },
  description: {
    background: "#F5EBFB",
    borderRadius: "6px"
  },
  user: {
    background: "#944BA8",
    borderRadius: " 16px 16px 0px 16px",
    padding: "16px",
    marginTop: "16px",
    maxWidth: "100%!important",
    width: "fit-content",
    alignSelf: "flex-end"
  },
  chatBubbleResponse: {
    background: "#F5F5F5",
    borderRadius: " 16px 16px 16px 0px",
    padding: "16px",
    marginTop: "16px",
    marginBottom: "16px",
    maxWidth: "100%!important"
  },
  userText: {
    color: "white"
  },
  outlined: { marginRight: "8px", marginTop: "16px", marginBottom: "8px" },
  error: {
    marginTop: "16px",
    background: "rgba(225, 0, 0, 0.15)",
    border: "2px solid #E10000",
    borderRadius: "16px 16px 16px 0px",
    padding: "16px",
    maxWidth: "100%!important",
    marginBottom: "16px"
  },
  warning: {
    color: "#E10000",
    marginBottom: "8px"
  },
  aiButton: {
    width: "fit-content",
    background: "#944BA8",
    textTransform: "none",
    "&:active": {
      background: "#7B1FA2"
    },
    "&:hover": { background: "#7B1FA2" }
  },
  bold: {
    margin: "24px 0px 0px 0px"
  },
  chatHeader: {
    marginTop: "8px",
    marginBottom: "16px !important",
    borderBottom: "1px solid #D9D9D9",
    paddingBottom: "8px"
  },
  buttonGroup: {
    marginTop: "16px"
  },
  addMargin: { marginTop: "8px" },
  newWorkflowButton: {
    textTransform: "none",
    // marginTop: "8px",
    borderColor: "#944BA8",
    "&:hover": {
      borderColor: "#944BA8",
      color: "#944BA8"
    }
  },
  AIHelperWorkflowButton: {
    textTransform: "none",
    marginTop: "8px",
    border: "none",
    color: "#FFF",
    background: "#944BA8",
    "&:hover": {
      border: "none",
      color: "#FFF",
      background: "rgba(148, 75, 168, 0.7)"
    }
  },
  buildButton: {
    textTransform: "none",
    // marginTop: "8px",
    background: "#944BA8",
    // border: "1px solid #944BA8",
    color: "#FFF",
    "&:hover": {
      background: "#944BA8"
    }
  },
  stickyButtonContainer: {
    position: "sticky !important",
    bottom: "0px",
    backgroundColor: "#FFF",
    paddingTop: "8px",
    paddingRight: "8px"
  },
  insidePaper: {
    background: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    height: "100%"
  },
  chatButtonsDiv: {
    minHeight: "75px",
    // display: "flex",
    // alignItems: "flex-end"
  },
  chatDiv: {
    height: "100%",
    overflowY: "auto",
    paddingBottom: "8px",
    scrollbarWidth: "thin",
    paddingRight: "8px"
  },
  gpt: {
    maxWidth: "100%!important",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    width: "fit-content",
    padding: "16px",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column"
  },
  animatedLoadingMessage: {
    maxWidth:"fit-content",
    width:"fit-content",
    display: "flex",
    flexDirection: "row",
    background: "#F5F5F5",
    borderRadius: "16px 16px 16px 0px",
    padding: "16px",
    fontSize: "14px",
    alignItems: "center"
  },
  loadingAnimation: {
    marginLeft: "8px"
  },
  closeIcon: {
    padding: "0px",
    margin: "0px",
    color: "inherit",
    fontSize: "small",
  },
  sessionId: {
    fontSize: '11px',
    color: '#999999',
    marginTop: '2px',
  },
});
export default AIBuilderStyles;