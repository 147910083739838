import React, { memo } from "react";
import { styled, Grid } from "@mui/material";
import PropTypes from "prop-types";

import {
  AccountTree,
  Explore,
  Feedback,
  Draw,
  AutoAwesome,
  StickyNote2
} from "@mui/icons-material";
// Styled components
const InitialMessageGrid = styled(Grid)(() => ({
  padding: "16px",
  borderRadius: "16px 16px 16px 0px",
  backgroundColor: "#F5F5F5",
}));

const AIOptionGrid = styled(Grid)(() => ({
  border: "1px solid #944BA8",
  borderRadius: "6px",
  padding: "16px",
  margin: "8px 8px 8px 0px",
  backgroundColor: "#FFFFFF",
  "&:hover, &:active": {
    cursor: "pointer",
    boxShadow: "4px 4px 0px 0px #00000040",
    backgroundColor: "#F4EDF6",
  },
}));

const AIDisabledOptionGrid = styled(Grid)(() => ({
  borderRadius: "6px",
  padding: "16px",
  margin: "8px 8px 8px 0px",
  border: "1px solid #B3B3B3",
  backgroundColor: "#FFFFFF",
  color: "#B3B3B3",
}));

const Icon = styled("span")(() => ({
  marginRight: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const DisabledIcon = styled("span")(() => ({
  marginRight: "8px",
  color: "#B3B3B3",
  "& img": {
    filter:
      "invert(42%) sepia(9%) saturate(748%) hue-rotate(160deg) brightness(89%) contrast(89%)",
  },
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const DescriptionTextGrid = styled(Grid)(() => ({
  fontSize: "12px",
  lineHeight: "24px"
}));

const DisplayName = styled("span")(() => ({
  fontSize: "14px",
  fontWeight: "bold"
}));

const ActionTitle = styled("span")({
  fontWeight: "bold",
  fontSize: "12px",
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start"
});

const ActionIcon = styled("img")({
  marginRight: "8px"
})

// Available ai states
const WORKFLOW_OPTIONS = [
  // {
  //   state: "INITIAL",
  //   displayName: "Explain this workflow",
  //   icon: <Feedback />,
  //   sidebarState: "INITIAL",
  // },

  {
    state: "WORKFLOW_EDITOR",
    displayName: "Edit this workflow",
    description: "Edit this workflow including updating, removing or adding components or databases",
    icon: <Draw />,
    sidebarState: "WORKFLOW_EDITOR",
  },
  {
    state: "WORKFLOW_BUILDER",
    displayName: "Build a new workflow",
    description: "Build a new workflow based on your instructions, a description of a process, or a general requirement",
    icon: <AccountTree />,
    sidebarState: "WORKFLOW",
  }
];

const NOTE_OPTIONS = [
  {
    state: "PROCESS_DISCOVERY",
    displayName: "Process Discovery",
    description: "Identify, document and map processes and workflows from structured or unstructured information",
    icon: <Explore />,
    sidebarState: "DISCOVERY",
  },
];

const COMPONENT_OPTIONS = [
  {
    state: "COMPONENT_BUILDER",
    displayName: "Build new component",
    description: "Build this component as new, overwriting any existing configuration",
    icon: <AutoAwesome />,
    sidebarState: "BUILD",
  },
  {
    state: "COMPONENT_EDITOR",
    displayName: "Edit this component",
    description: "Edit this component making only the specified changes",
    icon: <img src="/svgs/edit_square.svg" alt="Edit Component" />,
    sidebarState: "EDIT",
  },
];

// Add this new styled component for the divider
const Divider = styled('hr')({
  width: '100%',
  border: 'none',
  borderTop: '1px solid #E0E0E0',
  margin: '16px 0',
});

// Message code
const AIPanelInitialMessage = ({
  username,
  setState,
  setSidebarState,
  editComponent,
  isEmpty,
  setShowBuilderWarning
}) => {
  return (
    <InitialMessageGrid container item direction="column">
      <Grid container gap="16px">
        <Grid item xs={12}>
          <span>
            How can I assist with your workflow?
          </span>
        </Grid>
        {editComponent && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <ActionTitle><ActionIcon src="/svgs/line_start_circle.svg" alt="Icon" />Component actions</ActionTitle>
              </Grid>
              <Grid item xs={12}>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  {COMPONENT_OPTIONS.map((option, key) =>
                    (
                      <AIOption
                        key={key}
                        option={option}
                        setState={setState}
                        setSidebarState={setSidebarState}
                        isEmpty={isEmpty}
                        setShowBuilderWarning={setShowBuilderWarning}
                      />
                    )
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
        )}   
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <ActionTitle>
                <ActionIcon src="/svgs/family_history_black.svg" alt="Icon" />
                Workflow-level actions
              </ActionTitle>
            </Grid>
            {WORKFLOW_OPTIONS.map((option, key) => (
              (option.displayName !== "Edit this workflow" || !isEmpty) && (
                <AIOption
                  key={key}
                  option={option}
                  setState={setState}
                  setSidebarState={setSidebarState}
                  isEmpty={isEmpty}
                  setShowBuilderWarning={setShowBuilderWarning}
                />
              )
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <ActionTitle>
                <ActionIcon src="/svgs/sticky_note_2.svg" alt="Icon" />
                Note actions
                </ActionTitle>
            </Grid>
            {NOTE_OPTIONS.map((option, key) => (
              <AIOption
                key={key}
                option={option}
                setState={setState}
                setSidebarState={setSidebarState}
                isEmpty={isEmpty}
                setShowBuilderWarning={setShowBuilderWarning}
              />
            ))}
          </Grid>
        </Grid>

      </Grid>
    </InitialMessageGrid>
  );
};

// Selectable options
const AIOption = memo(
  ({
    option: { icon, displayName, description, state, sidebarState },
    setState,
    setSidebarState,
    isEmpty,
     setShowBuilderWarning
  }) => (
      <AIOptionGrid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        onClick={() => {
          if(state==="WORKFLOW_BUILDER" && !isEmpty)
          {
            setShowBuilderWarning(true);
          }
          else{
            setState(state);
            setSidebarState(sidebarState);
          }
        }}
        gap="12px"
      >
        <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-start">
          <Icon>{icon}</Icon>
          <DisplayName>{displayName}</DisplayName>
        </Grid>
      </AIOptionGrid>
  )
);

const AIDisabledOption = memo(({ option: { icon, displayName, description } }) => (
  <AIDisabledOptionGrid
    container
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    gap="12px"
  >
    <Grid item xs={12} display="flex" alignItems="center" justifyContent="flex-start">
      <DisabledIcon>{icon}</DisabledIcon>
      <DisplayName>{displayName}</DisplayName>
    </Grid>
    <DescriptionTextGrid item xs={12}>
      {description}
    </DescriptionTextGrid>
  </AIDisabledOptionGrid>
));

export default AIPanelInitialMessage;
// props types
AIPanelInitialMessage.propTypes = {
  username: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  setSidebarState: PropTypes.func.isRequired,
  editComponent: PropTypes.object,
};

AIPanelInitialMessage.displayName = "AIPanelInitialMessage";

AIOption.propTypes = {
  option: PropTypes.shape({
    icon: PropTypes.node.isRequired,
    displayName: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    sidebarState: PropTypes.string.isRequired,
  }).isRequired,
  username: PropTypes.string.isRequired,
  setState: PropTypes.func.isRequired,
  setSidebarState: PropTypes.func.isRequired,
};

AIOption.displayName = "AIOption";

AIDisabledOption.propTypes = {
  option: PropTypes.shape({
    icon: PropTypes.node.isRequired,
    displayName: PropTypes.string.isRequired,
  }).isRequired,
};

AIDisabledOption.displayName = "AIDisabledOption";